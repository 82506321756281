import React from "react";

interface PropertyTagProps {
  type: "sell" | "buy" | "both" | "neutral" | "golden" | "noBk" | string;
  primary?: string;
  secondary?: string;
  text?: string;
  children?: React.ReactChild;
  size?: "xs" | "normal" | "no" | "noInverse" | "square";
  align?: string;
  justify?: string;
  width?: string;
  borderRadius?: string;
}

const borderRadiusOptions: { [key: string]: string } = {
  rent: "15px",
  sell: "15px",
  both: "15px",
};

export const propertyTagColors: {
  [key: string]: { text: string; primary: string; secondary: string };
} = {
  rent: {
    text: "Alquiler",
    primary: "#0b34a6",
    secondary: "#bbeaea",
  },
  sell: {
    text: "Venta",
    primary: "#830d14",
    secondary: "#f5d3e7",
  },
  both: {
    text: "Venta y Alquiler",
    primary: "#315e0f",
    secondary: "#c4fb89",
  },
  neutral: {
    text: "",
    primary: "#3d444a",
    secondary: "#ececec",
  },
  golden: {
    text: "",
    primary: "#9c2303",
    secondary: "#fbeaa2",
  },
  noBk: {
    text: "",
    primary: "#202226",
    secondary: "transparent",
  },
};

const sizeProperties = {
  no: {
    padding: "0 10px",
  },
  noInverse: {
    padding: "5px 0",
  },
  square: {
    padding: "5px 5px",
  },
  xs: {
    padding: "2px 10px",
  },
  normal: {
    padding: "2px 15px",
  },
};

export default function PropertyTag({
  type,
  primary,
  secondary,
  text,
  children,
  size = "normal",
  align = "center",
  justify = "center",
  width = "fit-content",
  borderRadius = "5px",
}: PropertyTagProps) {
  return (
    <span
      style={{
        color: primary ? primary : propertyTagColors[type].primary || "black",
        backgroundColor: secondary
          ? secondary
          : propertyTagColors[type].secondary || "black",
        padding: sizeProperties[size].padding,
        display: "flex",
        alignItems: align,
        justifyContent: justify,
        gap: "8px",
        borderRadius: borderRadiusOptions[type]
          ? borderRadiusOptions[type]
          : borderRadius,
        width: width,
      }}
    >
      {children}
      {text ? text : propertyTagColors[type].text || "black"}
    </span>
  );
}
