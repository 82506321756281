import Navbar from "../../components/Navbar";
import DashboardSidebar from "../../components/DashboardSidebar";
import DashboardVendor from "../../components/DashboardVendor";
import Footer from "../../components/Footer";
import { NavigationBackButton } from "../../components/NavigationBackButton";
import { useHistory } from "react-router-dom";

const Vendors = (props: any) => {
  const history = useHistory();
  return (
    <>
      <div className="d-flex flex-column main-container">
        <Navbar searchBarToRight />
        <NavigationBackButton
          onClick={() => {
            history.go(-1);
          }}
        />
        <DashboardSidebar />
        <div className="d-flex ">
          <div className="col-12 offset-md-1 col-md-6 mt-md-0 mb-4 mb-md-0">
            <DashboardVendor />
          </div>
        </div>
        <div className="d-md-none mt-auto">
          <Footer />
        </div>
      </div>
    </>
  );
};

Vendors.defaultProps = {
  center: {
    lat: 18.452223613348835,
    lng: -69.95282818615782,
  },
  zoom: 11,
};

export default Vendors;
