const API = {
  register: "/auth/register",
  login: "/auth/cookie/access",
  logout: "/auth/logout",
  delete_account: "/auth/delete_account",
  refresh: "/auth/cookie/refresh",
  verify: "/auth/token/verify",
  reset_password: "/auth/reset_password",
  forgot_password: "/auth/forgot_password",
  user_profile: "/user/profile",
  change_user_avatar: "/user/avatar",
  add_property: "/property/",
  get_properties: "/property",
  get_property: (id: number) => {
    return `/property/${id}`;
  },
  add_like: "/property/like/",
  dislike: "/property/dislike/",
  get_followees: "/user/following",
  get_followers: "/user/followers",
  follow: "/user/follow",
  unfollow: "/user/follow",
  checkout: "/payment/checkout/",
  portal: "/payment/portal/",
  verification: "/payment/verification/",
  standalone_verification: "/payment/standalone-verification/",
  delete_property: (id: number) => {
    return `/property/${id}/`;
  },
  get_property_comments: (id: number) => {
    return `/property/comment?property=${id}`;
  },
  add_comment: "/property/comment/",
  get_comment: (id: number) => {
    return `/property/comment/${id}`;
  },
  comment_like: "/property/comment/like/",
  comment_dislike: "/property/comment/dislike/",
  comment_delete: (id: number) => {
    return `/property/comment/${id}/`;
  },
  comment_report: "/property/comment/report/",
  get_recommended_vendors: "/seller/recommendation",
  get_tendencies: "/property/tendencies/",
  update_property: (id: number) => {
    return `/property/${id}/`;
  },
  search_place: "/search/place",
  search_user: "/search/users",
  search_tendency: "/search/tendency",
  quick_search: "/search/quick",
  search_iplocation: "/search/iplocation",
  get_properties_by_tendency: (tendency: string, publicationType?: string) => {
    return `/property?tendency=${tendency}${
      publicationType ? `&typePublication=${publicationType}` : ""
    }`;
  },
  get_user_history: "/property/historySearch",
  get_user_favorites: "/property/saved",
  property_save: "/property/saved/",
  request_verification: "/property/verification_request/",
  property_unsave: (propertyId: number) => {
    return `/property/saved/${propertyId}`;
  },
  notifications: "/notification",
  new_notifications: "/notification/new",
  notification_count: "/notification/count",
  get_plans: "/subscription/type/",
  get_user_properties: (userId: number) => {
    return `/user/property/${userId}`;
  },
  get_shortmap_properties: "/property/shortmap",
};

export default API;
