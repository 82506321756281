import { useEffect, useRef, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import crossIcon from "../images/cross-white.svg";
const Icon = styled.img`
  @media screen and (min-width: 992px) {
    height: 25px;
    width: 25px;
  }
  height: 15px;
  width: 15px;
`;

function useOutsideAlerter(ref: any, closeFunction: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeFunction();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

interface IOptionsModalProps {
  active: boolean;
  closeModal: () => void;
  children: ReactNode;
  darkerBackground?: boolean;
}

const OptionsModal = (props: IOptionsModalProps) => {
  const modalSpace = useRef(null);
  useOutsideAlerter(modalSpace, props.closeModal);
  return (
    <Modal
      contentClassName={
        props.darkerBackground ? "modal-background" : "transparent-background"
      }
      show={props.active}
      onHide={props.closeModal}
      fullscreen={true}
    >
      <Modal.Body>
        <div
          style={{
            height: "100%",
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
          }}
        >
          <div className="d-flex justify-content-center my-auto w-100">
            <div
              className="bg-white py-4"
              ref={modalSpace}
              style={{
                border: "1px solid rgba(0, 0, 0, 0)",
                borderRadius: "10px",
                maxWidth: "370px",
                width: "100%",
              }}
            >
              {props.children}
            </div>
            <div className="ms-1 ms-md-2" onClick={props.closeModal}>
              {/* <Icon className="clickable" alt="X" src={crossIcon} /> */}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OptionsModal;
