import styled from "styled-components";

const Icon = styled.img<{ size: any }>`
  @media screen and (min-width: 768px) {
    width: ${(props) => {
      switch (props.size) {
        case "sm":
          return "25px";
        case "sm2":
          return "30px";
        case "sm3":
          return "35px";
        case "xl":
          return "150px";
        default:
          return "50px";
      }
    }};
    height: ${(props) => {
      switch (props.size) {
        case "sm":
          return "25px";
        case "sm2":
          return "30px";
        case "sm3":
          return "35px";
        case "xl":
          return "150px";
        default:
          return "50px";
      }
    }};
  }
  width: ${(props) => {
    switch (props.size) {
      case "sm":
        return "25px";
      case "sm2":
        return "30px";
      case "sm3":
        return "35px";
      case "xl":
        return "75px";
      default:
        return "50px";
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case "sm":
        return "25px";
      case "sm2":
        return "30px";
      case "sm3":
        return "35px";
      case "xl":
        return "75px";
      default:
        return "50px";
    }
  }};
  border-radius: 50%;
  margin: 0 5px;
  object-fit: cover;
`;

interface AvatarProps {
  alt: string;
  className?: any;
  size?: string;
  src: any;
  onClick?: any;
}

const Avatar = (props: AvatarProps) => {
  return (
    <Icon
      alt={props.alt}
      className={props.className}
      size={props.size}
      src={props.src}
      onClick={props.onClick}
    />
  );
};
Avatar.defaultProps = {
  size: "md",
};
export default Avatar;
