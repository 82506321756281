import { Switch, useLocation } from "react-router-dom";
import Login from "../pages/login/Login";
import SignUp from "../pages/sign-up/SignUp";
import Home from "../pages/home/Home";
import RememberMe from "../pages/RememberMe";
import AddProperty from "../pages/add-property/AddProperty";
import Profile from "../pages/profile/Profile";
import Map from "../pages/map/Map";
import Tendency from "../pages/tendency/Tendency";
import Vendors from "../pages/vendors/Vendors";
import Privacy from "../pages/privacy/Privacy";
import Payment from "../pages/payment/Payment";
import paths from "./paths.json";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Notifications from "../pages/notifications/Notifications";
import History from "../pages/history/History";
import ProfileConfiguration from "../pages/profile-configuration/ProfileConfiguration";
import PropertyInfoModal from "../components/PropertyInfoModal";
import NotFound from "../pages/not-found/NotFound";
import CertifyProperty from "../pages/certify-property/CertifyProperty";
import BohiioService from "../pages/bohiio-service/BohiioService";
import Terms from "../pages/terms/Terms";
import AboutUs from "../pages/about-us/AboutUs";
import FollowersPage from "../pages/followers-page/FollowersPage";
import ResetPassword from "../pages/reset-password/ResetPassword";
import RequestVerification from "../pages/request-verification/RequestVerification";
import CancelPlanFeedback from "../pages/cancel-plan-feedback/CancelPlanFeedback";

import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";

const history = createBrowserHistory();

Sentry.init({
  dsn: process.env?.REACT_APP_SENTRY_DSN,
  environment: process.env?.REACT_APP_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history as any
      ),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: parseFloat(
    process.env?.REACT_APP_SENTRY_SAMPLE_RATE || ("1.0" as string)
  ),
});

const Routes = () => {
  const location = useLocation();
  let background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <PublicRoute path={paths.LOGIN}>
          <Login />
        </PublicRoute>
        <PublicRoute path={paths.PRIVACY}>
          <Privacy />
        </PublicRoute>
        <PublicRoute path={paths.SIGNUP}>
          <SignUp />
        </PublicRoute>
        <PrivateRoute path={paths.REMEMBER}>
          <RememberMe />
        </PrivateRoute>
        <PrivateRoute path={paths.ADDPROPERTY}>
          <AddProperty />
        </PrivateRoute>
        <PublicRoute path={paths.USER_PROFILE}>
          <Profile />
        </PublicRoute>
        <PrivateRoute path={paths.NOTIFICATIONS}>
          <Notifications />
        </PrivateRoute>
        <PrivateRoute path={paths.FAVORITES}>
          <History />
        </PrivateRoute>
        <PrivateRoute path={paths.PROFILE_CONFIGURATION}>
          <ProfileConfiguration />
        </PrivateRoute>
        <PrivateRoute path={paths.FOLLOWERS}>
          <FollowersPage />
        </PrivateRoute>
        <PrivateRoute path={paths.FOLLOWEES}>
          <FollowersPage />
        </PrivateRoute>
        <PublicRoute path={paths.REQUEST_VERIFICATION}>
          <RequestVerification />
        </PublicRoute>
        <PrivateRoute path={paths.CANCEL_PLAN_FEEDBACK}>
          <CancelPlanFeedback />
        </PrivateRoute>
        <PublicRoute path={paths.TENDENCY_MAP}>
          <Map />
        </PublicRoute>
        <PublicRoute path={paths.MAP}>
          <Map />
        </PublicRoute>
        <PublicRoute path={paths.TENDENCY_PROPERTIES}>
          <Tendency />
        </PublicRoute>
        <PublicRoute path={paths.TENDENCY}>
          <Tendency />
        </PublicRoute>
        <PublicRoute path={paths.SEARCH_VENDOR}>
          <Vendors />
        </PublicRoute>
        <PublicRoute path={paths.VENDORS}>
          <Vendors />
        </PublicRoute>
        <PublicRoute path={paths.PAYMENT}>
          <Payment />
        </PublicRoute>
        <PublicRoute path={paths.NOTFOUND}>
          <NotFound />
        </PublicRoute>
        <PublicRoute path={paths.TERMS}>
          <Terms />
        </PublicRoute>
        <PublicRoute path={paths.ABOUT_US}>
          <AboutUs />
        </PublicRoute>
        <PublicRoute path={paths.RESET_PASSWORD}>
          <ResetPassword />
        </PublicRoute>
        <PublicRoute path={paths.HOME}>
          <Home />
        </PublicRoute>
      </Switch>

      <PublicRoute
        path="/property/:id"
        children={<PropertyInfoModal active={true} />}
      />
    </>
  );
};

export default Routes;
