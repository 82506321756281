import searchIcon from "../images/search.svg";
import locationMarkerIcon from "../images/location-marker.svg";
import hashtagIcon from "../images/hashtag.svg";
import personIcon from "../images/person.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { SearchContext } from "../context/search/SearchContext";
import paths from "../routes/paths.json";
import { Link, useHistory } from "react-router-dom";
import AuthenticationModal from "./AuthenticationModal";

const MySearchBar = styled.div<{ backgroundColor?: any; width?: any }>`
  @media screen and (min-width: 992px) {
    /* position: relative;
    top: auto; */
    /* margin-left: 10em !important; */
    /* width: 50% !important; */
    background: rgba(0, 0, 0, 0);
    /* padding: 0em; */
  }

  /* position: absolute;
  top: 30px; */

  width: ${(props) => props.width ?? "100%"} !important;
  pointer-events: none;
  /* background: ${(props) => props.backgroundColor ?? "#f9f9f9"}; */
  /* margin-left: 3em; */
  /* padding: 0em 0.5em 0.2em 0.5em; */
`;

const SearchMenuItem = styled.div`
  cursor: pointer;
  padding: 0.5rem 1.25rem;
  &:hover {
    background-color: rgba(128, 128, 128, 0.25);
  }
`;

const SearchMenuIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const TextLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const SearchResultContainer = styled.div`
  position: absolute;
  background: white;
  /* width: calc(100% - 17px); */
  width: 100%;
  border-radius: 15px !important;
  border: solid #ced4da 1px;
  border-top: 0px;
  /* margin-top: -25px; */
  background: white;
  padding-top: 2rem;
  top: 40%;
  z-index: -1;
  margin-left: 0 !important;
  overflow: hidden;
`;
// margin-left: ${(props) => (props.toRight ? "auto" : "0")};

const SearchBarContainer = styled.div<{
  searchBarWidth?: string;
  toRight?: boolean;
}>`
  @media and screen(min-width: 768px) {
    width: 100%;
  }
  position: relative;
  z-index: 2;
  width: ${(props) => props.searchBarWidth ?? "100%"};

  & #searchValue:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
  }
`;

export function useOutsideAlerter(ref: any, menu: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (menu.isOpen && ref.current && !ref.current.contains(event.target)) {
        menu.closeMenu();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, menu]);
}

interface ISearchBarProps {
  searchBarColor?: string;
  searchBarWidth?: string;
  searchBarToRight?: boolean;
}

const SearchBar = ({
  searchBarColor,
  searchBarWidth,
  searchBarToRight,
}: ISearchBarProps) => {
  const searchBarRef = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { places, searchPlaces, quickSearch } = useContext(SearchContext);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showPlaces, setShowPlaces] = useState(false);
  const [showDefaultText, setShowDefaultText] = useState(true);
  const [lastSearch, setLastSearch] = useState("");
  const [hashtagsResults, setHashtagsResults] = useState<any[]>([]);
  const [userResults, setUserResults] = useState<any>(0);
  const [showLogin, setShowLogin] = useState(false);
  const [isFindingResults, setIsFindingResults] = useState(false);
  const [nextPage, setNextPage] = useState("/");
  let showTendenciesOption = true;
  let showVendorsOption = true;
  let showUserOption = true;
  let searchTimeout: NodeJS.Timeout;

  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = (nextPage: string) => {
    setNextPage(nextPage);
    setShowLogin(true);
  };
  const closeSearchBar = () => {
    setShowSearchBar(false);
    setHashtagsResults([]);
    setUserResults([]);
    setShowPlaces(false);
    setShowDefaultText(true);
  };
  const openSearchBar = () => {
    setShowSearchBar(true);
  };
  useOutsideAlerter(searchBarRef, {
    isOpen: showSearchBar,
    closeMenu: closeSearchBar,
  });

  const search = (searchValue: string) => {
    openSearchBar();
    setShowDefaultText(false);
    if (searchValue && searchValue !== lastSearch) {
      setIsFindingResults(true);
      setLastSearch(searchValue);
      if (searchValue.charAt(0) === "#" || searchValue.charAt(0) === "@") {
        (async () => {
          const searchResult = await quickSearch(
            searchValue.replaceAll("@", "").replaceAll("#", "")
          );
          if (searchValue.charAt(0) === "#") {
            setHashtagsResults(searchResult.tendencies);
            setUserResults(0);
          } else {
            setUserResults(searchResult.users);
            setHashtagsResults([]);
          }
          setIsFindingResults(false);
        })();

        return;
      }

      (async () => {
        await searchPlaces(searchValue);
        setShowPlaces(true);
        setIsFindingResults(false);
      })();
      (async () => {
        const searchResult = await quickSearch(searchValue);
        setHashtagsResults(searchResult.tendencies);
        setUserResults(searchResult.users);
        setIsFindingResults(false);
      })();
    }
  };
  useEffect(() => {
    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mustRedirectToMap = ![
    paths.MAP,
    paths.REQUEST_VERIFICATION,
    paths.ADDPROPERTY,
  ].includes(history.location.pathname);
  const mapUrl = mustRedirectToMap ? paths.MAP : history.location.pathname;

  const setNewLocationInUrl = (lat: number, lng: number) => {
    const params = new URLSearchParams(
      history.location.search.replaceAll("?", "")
    );
    params.set("latitude", lat.toString());
    params.set("longitude", lng.toString());
    history.push(`${mapUrl}?${params}`);
    closeSearchBar();
  };

  let title = "";
  if (
    history.location.pathname.includes(paths.ADDPROPERTY) ||
    history.location.pathname.includes(paths.REQUEST_VERIFICATION)
  ) {
    title = t("navbar.shortSearchBar");
    showTendenciesOption = false;
    showVendorsOption = false;
    showUserOption = false;
  } else {
    title = t("navbar.searchBar");
  }

  return (
    <MySearchBar
      className=""
      ref={searchBarRef}
      backgroundColor={searchBarColor}
    >
      <Formik
        initialValues={{
          searchValue: "",
        }}
        onSubmit={({ searchValue }) => {
          search(searchValue);
        }}
      >
        {({ isSubmitting, handleChange }) => (
          <Form
            style={{
              pointerEvents: "all",
            }}
          >
            <SearchBarContainer
              searchBarWidth={searchBarWidth}
              toRight={searchBarToRight}
              className="input-group"
            >
              <Field
                id="searchValue"
                name="searchValue"
                type="search"
                placeholder={title}
                className="form-control border-end-0"
                aria-describedby="search-icon"
                autoComplete="off"
                style={{
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                  background: "#f9f9f9",
                  paddingRight: "2px",
                }}
                onChange={(e: any) => {
                  handleChange(e);
                  if (searchTimeout) {
                    clearTimeout(searchTimeout);
                  }
                  if (e.target.value.length > 3) {
                    searchTimeout = setTimeout(() => {
                      const value: string = e.target.value;
                      search(value);
                    }, 1000);
                  }
                }}
                onClick={openSearchBar}
              />
              <button
                id="search-icon"
                type="submit"
                className="input-group-text border-start-0"
                style={{
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                  background: "#f9f9f9",
                }}
              >
                <img
                  src={searchIcon}
                  alt="search"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
              {showSearchBar && (
                <SearchResultContainer>
                  {showPlaces &&
                    (places.slice(0, 3) ?? []).map((place, index) => {
                      return (
                        <SearchMenuItem
                          key={index}
                          onClick={() =>
                            setNewLocationInUrl(
                              place.geometry.location.lat,
                              place.geometry.location.lng
                            )
                          }
                        >
                          <span
                            className="d-flex"
                            // to={`${mapUrl}?latitude=${place.geometry.location.lat}&longitude=${place.geometry.location.lng}&zoom=12`}
                          >
                            <SearchMenuIcon
                              alt="location"
                              src={locationMarkerIcon}
                            />
                            <span className="poppins-medium-14">
                              {place.formatted_address}
                            </span>
                          </span>
                        </SearchMenuItem>
                      );
                    })}
                  {!showDefaultText &&
                    showTendenciesOption &&
                    hashtagsResults.map((hashtag, index) => {
                      return (
                        <SearchMenuItem
                          className="d-flex"
                          key={index}
                          onClick={() => {
                            // if (isAuthenticated) {
                            history.push(
                              paths.TENDENCY_PROPERTIES.replace(
                                ":tendency",
                                hashtag.tendency
                              )
                            );
                            closeSearchBar();
                            // } else {
                            //   openLoginModal(
                            //     paths.TENDENCY_PROPERTIES.replace(
                            //       ":tendency",
                            //       hashtag.tendency
                            //     )
                            //   );
                            // }
                          }}
                        >
                          <SearchMenuIcon alt="trends" src={hashtagIcon} />
                          <div className="d-flex flex-column">
                            <span className="poppins-medium-14">
                              {hashtag?.tendency}
                            </span>
                            <span
                              className="poppins-regular-12"
                              style={{ opacity: 0.5 }}
                            >
                              {hashtag?.total_publications} publicaciones
                            </span>
                          </div>
                        </SearchMenuItem>
                      );
                    })}
                  {showDefaultText && showTendenciesOption && (
                    <SearchMenuItem
                      className="d-flex"
                      onClick={() => {
                        history.push(paths.TENDENCY);
                        closeSearchBar();
                      }}
                    >
                      <SearchMenuIcon alt="trends" src={hashtagIcon} />
                      <div className="d-flex flex-column">
                        <span className="poppins-medium-14">Tendencias</span>
                        <span
                          className="poppins-regular-12"
                          style={{ opacity: 0.5 }}
                        >
                          Principales tendencias en la zona y el país
                        </span>
                      </div>
                    </SearchMenuItem>
                  )}
                  {!showDefaultText && showUserOption && userResults > 0 && (
                    <SearchMenuItem onClick={() => closeSearchBar()}>
                      <TextLink
                        className="d-flex"
                        to={paths.SEARCH_VENDOR.replace(
                          ":name",
                          lastSearch.replaceAll("@", "").replaceAll("#", "")
                        )}
                      >
                        <SearchMenuIcon alt="users" src={personIcon} />
                        <div className="d-flex flex-column">
                          <span className=" poppins-medium-14">
                            {lastSearch}
                          </span>
                          <span
                            className="poppins-regular-12"
                            style={{ opacity: 0.5 }}
                          >
                            {userResults} usuarios
                          </span>
                        </div>
                      </TextLink>
                    </SearchMenuItem>
                  )}
                  {showDefaultText && showVendorsOption && (
                    <SearchMenuItem
                      className="d-flex"
                      onClick={() => {
                        history.push(paths.VENDORS);
                        closeSearchBar();
                      }}
                    >
                      <SearchMenuIcon alt="users" src={personIcon} />
                      <div className="d-flex flex-column">
                        <span className=" poppins-medium-14">Vendedores</span>
                        <span
                          className="poppins-regular-12"
                          style={{ opacity: 0.5 }}
                        >
                          Vendedores recomendados en la zona y el país
                        </span>
                      </div>
                    </SearchMenuItem>
                  )}
                  {!showDefaultText &&
                  !isFindingResults &&
                  places.length === 0 &&
                  userResults === 0 &&
                  hashtagsResults.length === 0 ? (
                    <div
                      style={{
                        padding: "0 0 1em 0.5em",
                      }}
                    >
                      No hemos encontrado resultados
                    </div>
                  ) : null}
                  {isFindingResults ? (
                    <div
                      style={{
                        padding: "0 0 1em 0.5em",
                      }}
                    >
                      Buscando...
                    </div>
                  ) : null}
                </SearchResultContainer>
              )}
            </SearchBarContainer>
          </Form>
        )}
      </Formik>
      <AuthenticationModal
        nextPage={nextPage}
        active={showLogin}
        closeModal={closeLoginModal}
      />
    </MySearchBar>
  );
};

export default SearchBar;
