import searchIcon from "../images/search.svg";
import chevronIcon from "../images/chevron-down.svg";
import { FieldAttributes } from "formik";
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { debounce } from "./Scroller";
import { useOutsideAlerter } from "./SearchBar";

interface Option<Data> {
  id: string;
  data: Data;
  children: React.ReactNode;
}

const OptionItem = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? "#f1f1f1" : "inherit")};
  transition: 0.2s;
  &:hover {
    background-color: #e1e1e1;
  }
`;

export interface SelectFieldProps<Data> extends FieldAttributes<any> {
  useDebounce?: boolean;
  options: Option<Data>[];
  onSelect?: (selected: Option<Data>) => void;
  onSearch?: (options: Option<Data>[], value: string) => Option<Data>[];
  children?: React.ReactNode;
  onOpen?: () => void;
  noOptions?: React.ReactNode;
  closeOnSelect?: boolean;
}
export default function SelectField<Data>({
  options,
  children,
  onOpen = () => {},
  onSearch = () => [],
  onSelect = () => {},
  useDebounce = true,
  noOptions = "No found results",
  closeOnSelect = true,
}: SelectFieldProps<Data>) {
  const [selected, setSelected] = useState<Option<Data> | undefined>(undefined);
  const [showOptions, setShowOptions] = useState(false);
  const [localOptions, setLocalOptions] = useState(options);
  const selectRef = useRef(null);
  const inputRef = useRef<any>(null);

  const closeDropdownContainer = () => {
    setShowOptions(() => false);
  };

  useOutsideAlerter(selectRef, {
    isOpen: showOptions,
    closeMenu: closeDropdownContainer,
  });

  const onOpenOptions = () => {
    setShowOptions(() => true);
    onOpen();
  };

  const onChange = (options: Option<Data>[], value: string) => {
    if (!inputRef.current) {
      return;
    }

    setLocalOptions(onSearch(options, value));
  };

  const onChangeFunction = useDebounce
    ? debounce(() => onChange(options, inputRef.current.value))
    : () => onChange(options, inputRef.current.value);

  return (
    <>
      <span
        ref={selectRef}
        className="dropdown clickable"
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
          className="selected d-flex align-items-center w-100"
          onClick={onOpenOptions}
        >
          <div style={{ paddingLeft: "3px" }} className="content w-100">
            {children}
          </div>
          <div
            style={{ width: "25px", paddingRight: "3px" }}
            className="d-flex h-100 align-items-center justify-content-center"
          >
            <img src={chevronIcon} alt="" style={{ width: "15px" }} />
          </div>
        </div>
        <div
          style={{
            top: "calc(100% + 5px)",
            position: "absolute",
            padding: "0",
            width: "100%",
            minWidth: "200px",
            boxShadow: "0 0 2px #aaa",
            zIndex: -1,
            backgroundColor: "white",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
          className={`container ${showOptions ? "" : "d-none"}`}
        >
          <div style={{ padding: "5px" }}>
            <div
              style={{
                border: "solid #ced4da 1px",
                borderRadius: "5px",
              }}
              className="search d-flex justify-content-between align-items-center w-100"
            >
              <input
                type="search"
                ref={inputRef}
                onChange={onChangeFunction}
                className="w-100"
                style={{
                  border: "none",
                  outline: "none",
                }}
              />
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ padding: "0 5px" }}
                onClick={() => onChange(options, inputRef.current.value)}
              >
                <img src={searchIcon} alt="" style={{ width: "15px" }} />
              </div>
            </div>
          </div>
          <div
            style={{
              overflowX: "hidden",
              maxHeight: "350px",
              overflowY: "auto",
            }}
          >
            {localOptions.map((v) => (
              <OptionItem
                key={v.id}
                selected={selected?.id === v.id}
                onClick={() => {
                  setSelected(v);
                  onSelect(v);
                  if (closeOnSelect) {
                    setShowOptions(() => false);
                  }
                }}
              >
                {v.children}
              </OptionItem>
            ))}
            {localOptions.length === 0 ? noOptions : <></>}
          </div>
        </div>
      </span>
    </>
  );
}
