import { useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Location as RouterLocation, History } from "history";
import { getUserIpLocation } from "../api/profile";
import {
  updateUrlLocation,
  useLocationContext,
  Location,
} from "../context/location/LocationContext";

export default function useGeolocation() {
  let urlLocation = useUrlGeolocation();
  const history = useHistory();
  const actualLocation = useLocation();

  useEffect(() => {
    if (urlLocation.latitude !== 0 || urlLocation.longitude !== 0) {
      return;
    }

    const params = new URLSearchParams(
      actualLocation.search.replaceAll("?", "")
    );
    params.set("latitude", urlLocation.latitude.toString());
    params.set("longitude", urlLocation.longitude.toString());
    params.set("distance", urlLocation.distance.toString());
    params.set("zoom", urlLocation.zoom.toString());
    // updateUrlLocation(history, actualLocation, params);
  }, [actualLocation, history, urlLocation]);

  return {
    userLocation: urlLocation,
  };
}

function useUrlGeolocation(): Location {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const initialLocation = useLocationContext();

  const latitude = params.get("latitude");
  const longitude = params.get("longitude");
  const distance = params.get("distance") || "1000";
  let zoom = params.get("zoom");

  if (!latitude || !longitude) {
    return initialLocation.location;
  }

  if (!zoom) {
    // let result = initialLocation.zoom;
    zoom = process.env.REACT_APP_DEFAULT_MAP_ZOOM || "12";
    // zoom = (12).toString();
  }

  return {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    distance: parseFloat(distance),
    zoom: parseInt(zoom),
  } as Location;
}
