import { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../../context/language/LanguageContext";
import { Trans } from "react-i18next";
import Navbar from "../../components/Navbar";
import PropertyForm from "../../components/PropertyForm";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import FileUploader from "../../components/FileUploader";
import MapMarker from "../../components/MapMarker";
import LoadingModal from "../../components/LoadingModal";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ProfileContext } from "../../context/profile/ProfileContext";
import paths from "../../routes/paths.json";
import { PropertyContext } from "../../context/property/PropertyContext";
import LeavePageModal from "../../components/LeavePageModal";
import OptionConfirmationCompleteModal from "../../components/OptionConfirmationCompleteModal";
import useGeolocation from "../../hooks/useGeolocation";
import { colorMap } from "../map/Map";
import useScreen from "../../hooks/useScreen";
import { ButtonRed } from "../../styles/common";
import BaseMap from "../../components/Map";

const Button = styled.button`
  background: #ececec;
  border-radius: 5px;
  color: black;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border: none;
  width: 100%;
`;

const ButtonText = styled.button`
  background: none;
  color: #d2190e;
  border: none;
`;

const MobileMapDiv = styled.div`
  position: absolute;
  bottom: 100px;
  z-index: 1000;
  background-color: white;
  border: 1px rgba(0, 0, 0, 0), solid;
  border-radius: 15px;
  cursor: pointer;
`;

const ScrollessDiv = styled.div`
  scrollbar-width: thin;
  overflow: auto;
`;

const BottomDiv = styled.div`
  background-color: white;
  bottom: 0;
  border-radius: 15px;
  position: absolute;
  width: 100%;
  max-height: 65vh;
  z-index: 1000;
  transition: margin 2s;
`;

const MainContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    padding-left: 55px;
  }
  max-width: 100%;
  display: none;
  height: calc(100vh - 52px);
`;

const Home = (props: any) => {
  const location = useLocation();
  const history = useHistory();
  const unblock = history.block((locationToGo: any) => {
    //would need to upgrade router for a better functionality
    // console.log(locationToGo);
    if (location.pathname !== locationToGo.pathname) {
      setIsLeavingPage(true);
      setLocationToLeave(locationToGo);
    }
    return;
  });
  const currentProperty = location.state?.currentProperty;
  useContext(LanguageContext);
  const { profile } = useContext(ProfileContext);
  const { addPropertyPercentage } = useContext(PropertyContext);

  const STATE_MOBILE_MAP = 0;
  const STATE_MOBILE_IMAGE_UPLOAD = 1;
  const STATE_MOBILE_PROPERTY_FORM = 2;
  const [mainImages, setMainImages] = useState<any[]>([]);
  const [latitude, setLatitude] = useState(Number.MAX_SAFE_INTEGER);
  const [longitude, setLongitude] = useState(Number.MAX_SAFE_INTEGER);
  const [mapCenter, setMapCenter] = useState(props.center);
  const [mapLocked, setMapLock] = useState(false);
  const [mobileScreen, setMobileScreen] = useState(STATE_MOBILE_MAP);
  const [loading, setLoading] = useState(false);
  const [isLeavingPage, setIsLeavingPage] = useState(false);
  const [locationToLeave, setLocationToLeave] = useState<any>(() => {});
  const [showLackOfImageModal, setShowLackOfImageModal] = useState(false);
  const [showIncompleteFormModal, setShowIncompleteFormModal] = useState(false);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const { userLocation } = useGeolocation();
  const [propertyColor, setPropertyColor] = useState("");

  useEffect(() => {
    if (currentProperty) {
      setMapLock(true);
      setLatitude(currentProperty.latitude);
      setLongitude(currentProperty.longitude);
      setMapCenter({
        lat: currentProperty.latitude,
        lng: currentProperty.longitude,
      });
      //need to validate if the backend can deal with this
      setMainImages(
        currentProperty.media.map((media: any) => {
          return { ...media, dataURL: media.filename, name: media.filename };
        })
      );
    }
  }, []);

  const onMobileSetMap = () => {
    setMobileScreen(STATE_MOBILE_MAP);
  };

  const onMobileSetPhotoUpload = () => {
    setMobileScreen(STATE_MOBILE_IMAGE_UPLOAD);
  };

  const onMobileSetPropertyForm = () => {
    setMobileScreen(STATE_MOBILE_PROPERTY_FORM);
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);

  const handleAcceptConfirmationModal = () => {
    setMapLock(false);
    setShowConfirmationModal(false);
  };

  return (
    <>
      {loading && (
        <LoadingModal type="bar" percentage={addPropertyPercentage} />
      )}

      <div className="d-flex flex-column justify-md-content-between main-container">
        <Navbar mobileHeight="105px" searchBarColor="rgba(0, 0, 0, 0)" />
        <MainContainer>
          <div className="col-9 bg-white">
            <div style={{ height: "69%", width: "100%" }}>
              <BaseMap
                userLocation={userLocation}
                keyboardShortcuts={!mapLocked}
                gestureHandling={mapLocked ? "none" : "auto"}
                draggableCursor="default"
                onClick={(t) => {
                  if (!mapLocked) {
                    setLatitude(t.lat);
                    setLongitude(t.lng);
                  }
                }}
                onChildClick={(key, childProps) => {
                  if (currentProperty) {
                    handleShowConfirmationModal();
                  }
                }}
              >
                <MapMarker
                  color={propertyColor}
                  locked={mapLocked}
                  lat={latitude}
                  lng={longitude}
                />
              </BaseMap>
            </div>
            {/*possible size calc(30% - 0.5rem) */}
            <div className="mt-1 mb-1" style={{ height: "29%" }}>
              <FileUploader
                files={mainImages}
                setFiles={setMainImages}
                mainIndex={mainImageIndex}
                setMainIndex={setMainImageIndex}
                limit={15}
                maxSizeInMB={15}
              />
            </div>
          </div>
          <ScrollessDiv
            className="col-3 bg-white"
            style={{ height: "inherit" }}
          >
            <PropertyForm
              latitude={latitude}
              longitude={longitude}
              images={mainImages}
              mainImageIndex={mainImageIndex}
              setLoading={setLoading}
              property={currentProperty}
              lackOfImageHandler={() => {
                setShowLackOfImageModal(true);
              }}
              incompleteFormHandler={() => {
                setShowIncompleteFormModal(true);
              }}
              onClickPropertyType={(propertyType: string) =>
                setPropertyColor(colorMap[propertyType])
              }
            />
          </ScrollessDiv>
        </MainContainer>
        <div className="d-md-none d-block">
          <div style={{ height: "calc(100vh - 105px - 130px)", width: "100%" }}>
            <BaseMap
              userLocation={userLocation}
              keyboardShortcuts={!mapLocked}
              gestureHandling={mapLocked ? "none" : "auto"}
              draggableCursor="default"
              onClick={(t) => {
                if (!mapLocked) {
                  setLatitude(t.lat);
                  setLongitude(t.lng);
                }
              }}
              onChildClick={(key, childProps) => {
                if (currentProperty) {
                  handleShowConfirmationModal();
                }
              }}
            >
              <MapMarker
                color={propertyColor}
                locked={mapLocked}
                lat={latitude}
                lng={longitude}
              />
            </BaseMap>
          </div>
          <BottomDiv>
            <div
              className="container-1"
              style={{
                padding:
                  mobileScreen !== STATE_MOBILE_MAP ? "30px 20px 0px 20px" : "",
                position: "absolute",
                bottom: "100%",
                width: "100%",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <ScrollessDiv style={{ maxHeight: "55vh" }}>
                <div
                  className={
                    mobileScreen === STATE_MOBILE_PROPERTY_FORM
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <PropertyForm
                    latitude={latitude}
                    longitude={longitude}
                    images={mainImages}
                    mainImageIndex={mainImageIndex}
                    setLoading={setLoading}
                    property={currentProperty}
                    triggerSubmit={triggerSubmit}
                    lackOfImageHandler={() => {
                      setShowLackOfImageModal(true);
                    }}
                    incompleteFormHandler={() => {
                      setShowIncompleteFormModal(true);
                    }}
                    onClickPropertyType={(propertyType: string) => {
                      setPropertyColor(colorMap[propertyType]);
                    }}
                  />
                </div>
                <div
                  className={
                    mobileScreen === STATE_MOBILE_IMAGE_UPLOAD
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <div style={{ height: "100%" }}>
                    <FileUploader
                      files={mainImages}
                      setFiles={setMainImages}
                      mainIndex={mainImageIndex}
                      setMainIndex={setMainImageIndex}
                      limit={15}
                      maxSizeInMB={15}
                      mobile
                    />
                  </div>
                </div>
              </ScrollessDiv>
            </div>
            <div
              style={{
                borderRadius: "20px 20px 0 0",
                backgroundColor: "white",
              }}
              className={`${
                mobileScreen === STATE_MOBILE_MAP ? "d-none" : "d-flex"
              } justify-content-between px-3 py-3`}
            >
              <span
                className="clickable poppins-regular-14 d-flex justify-content-center align-items-center"
                onClick={onMobileSetMap}
                style={{
                  color: "#D2190E",
                  padding: "3px 30px",
                }}
              >
                Atrás
              </span>
              <span
                style={{
                  backgroundColor: "#D9D9D9",
                  borderRadius: "5px",
                  padding: "3px 30px",
                }}
                className="clickable"
                onClick={onMobileSetMap}
              >
                Cargar
              </span>
            </div>
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 20px",
                borderRadius: "10px 10px 0 0",
              }}
              className={
                mobileScreen === STATE_MOBILE_MAP ? "d-block" : "d-none"
              }
            >
              <div className="d-flex justify-content-center">
                <Button className="" onClick={onMobileSetPhotoUpload}>
                  <Trans i18nKey="addProperty.mobile.photos" />
                </Button>
                <Button className="mx-2" onClick={onMobileSetPropertyForm}>
                  <Trans i18nKey="addProperty.mobile.details" />
                </Button>
                <ButtonRed
                  className=""
                  style={{}}
                  onClick={() => {
                    setMobileScreen(STATE_MOBILE_PROPERTY_FORM);
                    setTriggerSubmit(true);
                    setTimeout(() => {
                      setTriggerSubmit(false);
                    }, 100);
                  }}
                >
                  {currentProperty ? (
                    <Trans i18nKey="addProperty.update" />
                  ) : (
                    <Trans i18nKey="addProperty.publish" />
                  )}
                </ButtonRed>
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <ButtonText
                  className=""
                  onClick={() => {
                    history.push(
                      paths.USER_PROFILE.replace(
                        ":username",
                        profile?.username ?? ""
                      )
                    );
                  }}
                >
                  <Trans i18nKey="addProperty.discard" />
                </ButtonText>
              </div>
            </div>
          </BottomDiv>
        </div>
        <ConfirmationModal
          title="Quieres cambiar la ubicacion?"
          msg="Si modificas la ubicacion de la propiedad se descartara la certificacion que ya existe del inmueble ya que este proceso esta vinculado a la ubicacion"
          acceptText="Cambiar ubicacion"
          cancelText="No cambiar ubicacion"
          show={showConfirmationModal}
          handleAccept={handleAcceptConfirmationModal}
          handleClose={handleCloseConfirmationModal}
        />
        <LeavePageModal
          active={isLeavingPage}
          editting={currentProperty !== undefined}
          closeModal={() => {
            setIsLeavingPage(false);
          }}
          leavePage={() => {
            setIsLeavingPage(false);
            unblock();
            history.push({ ...locationToLeave });
          }}
        />
        <OptionConfirmationCompleteModal
          show={showLackOfImageModal}
          title="¿Quieres publicar sin fotos?"
          msg="Aún no has agregado las fotos de la propiedad, debes agregar al menos una foto de la propiedad, te recomendamos subir varias para que tengas mejores resultados con la publicación."
          confirmText="Subir fotos de la propiedad"
          handleClose={() => {
            setShowLackOfImageModal(false);
            setMobileScreen(STATE_MOBILE_IMAGE_UPLOAD);
          }}
        />
        <OptionConfirmationCompleteModal
          show={showIncompleteFormModal}
          title="¿Quieres publicar sin descripción?"
          msg="Aún no has completado los campos obligatorios de la descripción de la propiedad, te recomendamos completar todos los campos para que tengas mejores resultados con la publicación."
          confirmText="Agregar descripción"
          handleClose={() => {
            setShowIncompleteFormModal(false);
            setMobileScreen(STATE_MOBILE_PROPERTY_FORM);
          }}
        />
      </div>
    </>
  );
};

Home.defaultProps = {
  center: {
    lat: 18.452223613348835,
    lng: -69.95282818615782,
  },
  zoom: 11,
};

export default Home;
