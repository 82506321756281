import {
  KeyboardEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { OnChangeValue } from "react-select";
import { ButtonRed, OnlyMobileContainer } from "../styles/common";
import { PropertyContext } from "../context/property/PropertyContext";
import { ALL_COUNTRIES_CODE, useLocalstorageContext } from "../context/localstorage/LocalstorageContext";

interface Option {
  label: string;
  value: string;
}

const createOption = (label: string) => ({
  label: "#" + label.replace("#", ""),
  value: label,
});

interface MultiTextInputProps {
  hashtags?: string[];
  setHashtags: (arg0: string[]) => void;
  placeholder: string;
  max?: number;
}

const HashtagInput = ({
  hashtags = [],
  setHashtags,
  max,
  placeholder,
}: MultiTextInputProps) => {
  const { getTendencies } = useContext(PropertyContext);
  const [state] = useLocalstorageContext();
  const [values, setValues] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState("");
  const hashtagInputRef = useRef(null);
  const idDebounceInputRef = useRef(0);

  // handle clicks begin -- remove if android browser start supporting keydown events correctly
  const [clicked, setClicked] = useState(0);
  const [oldInput, setOldInput] = useState("");

  useEffect(() => {
    if (clicked > 0 && oldInput.length) {
      hashtagsHandler(true);
      (hashtagInputRef.current as any).focus();
    }
  }, [clicked]);
  // handle clicks end

  useEffect(() => {
    setValues(hashtags.map(createOption));
  }, [hashtags]);

  const handleChange = (value: OnChangeValue<Option, true>) => {
    setValues(value as any);
    setHashtags(value.map(({ label }) => label));
  };

  const handleInputChange = (inputText: string) => {
    if (inputText === "") {
      setOldInput(inputValue);
    }
    if (values.length === max) return;

    setInputValue(inputText.replace(/[^\w ]/g, "").slice(0, 30));
  };

  const hashtagsHandler = (old?: boolean) => {
    let currentValue = inputValue;
    if (old) {
      currentValue = oldInput;
    }
    const lowerInputValue = currentValue.toLowerCase();

    if (!values.map(({ value }) => value).includes(lowerInputValue)) {
      setValues(() => {
        const newValue = [...values, createOption(lowerInputValue)];
        setHashtags(newValue.map(({ label }) => label));
        return newValue;
      });
    }

    setInputValue("");
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    const keyValue = event.key;
    const keyNum = event.keyCode;

    if (
      keyValue === "Enter" ||
      keyValue === "Tab" ||
      keyValue === " " ||
      keyNum === 13 ||
      keyNum === 9 ||
      keyNum === 32
    ) {
      hashtagsHandler();
      event.preventDefault();
      return;
    }
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    clearTimeout(idDebounceInputRef.current);
    idDebounceInputRef.current = setTimeout(async () => {
      const tendencies = await getTendencies(
        state.countryCode !== ALL_COUNTRIES_CODE ? state.countryCode : "",
        inputValue
      );
      return callback(
        tendencies.map((tendency) => ({
          label: "#" + tendency.tendency,
          value: tendency.tendency,
        }))
      );
    }, 300) as unknown as number;
  };

  return (
    <>
      <AsyncCreatableSelect
        ref={hashtagInputRef}
        inputValue={inputValue}
        isClearable
        isMulti
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={values}
        components={{
          DropdownIndicator: null,
        }}
        loadOptions={loadOptions}
      />
      <OnlyMobileContainer>
        <ButtonRed
          style={{
            marginTop: "10px",
          }}
          onClick={(event) => {
            setClicked(clicked + 1);
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          Add
        </ButtonRed>
      </OnlyMobileContainer>
    </>
  );
};

export default HashtagInput;
