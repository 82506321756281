import styled from "styled-components";
import Navbar from "../../components/Navbar";
import { useContext, useEffect, useState } from "react";
import MapMarker from "../../components/MapMarker";
import FileUploader from "../../components/FileUploader";
import useGeolocation from "../../hooks/useGeolocation";
import CertificationNoLocationModal from "../../components/CertificationNoLocationModal";
import PropertyCertification from "../../components/PropertyCertification";
import fileUp from "../../images/file-up.svg";
import { useHistory } from "react-router-dom";
import paths from "../../routes/paths.json";
import BaseMap from "../../components/Map";
import AuthenticationModal from "../../components/AuthenticationModal";
import { AuthContext } from "../../context/auth/AuthContext";
import OptionConfirmationCompleteModal from "../../components/OptionConfirmationCompleteModal";

const ButtonRed = styled.button`
  border: 1px #c92e1c solid;
  background: #c92e1c;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 5px;
`;

const Button = styled.button`
  border: 1px rgba(0, 0, 0, 0) solid;
  background: white;
  color: #c92e1c;
  padding: 0.25rem 2rem;
`;

const ScrollessDiv = styled.div`
  scrollbar-width: thin;
  overflow: auto;
`;

const BottomDiv = styled.div`
  background-color: white;
  bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 15px;
  position: absolute;
  width: 100%;
  max-height: 65vh;
  z-index: 1000;
  transition: margin 2s;
`;

const RequestVerification = (props: any) => {
  const [showConfirmation, setShowConfirmation] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = () => setShowLogin(true);
  const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  const STATE_MOBILE_MAP = 0;
  const STATE_MOBILE_IMAGE_UPLOAD = 1;
  const [isSelectedPropertyLocation, setIsSelectedPropertyLocation] =
    useState(false);
  const [latitude, setLatitude] = useState(Number.MAX_SAFE_INTEGER);
  const [longitude, setLongitude] = useState(Number.MAX_SAFE_INTEGER);
  const [mapCenter, setMapCenter] = useState(props.center);
  const [mapLocked, setMapLock] = useState(false);
  const [mainImages, setMainImages] = useState<any[]>([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [mobileScreen, setMobileScreen] = useState(STATE_MOBILE_MAP);
  const { userLocation } = useGeolocation();
  const [isPropertyCertificationActive, setIsPropertyCertificationActive] =
    useState(false);
  const [
    isCertificationNoLocationModalOpen,
    setIsCertificationNoLocationModalOpen,
  ] = useState(false);

  const openPropertyCertification = () => {
    setIsPropertyCertificationActive(true);
  };

  const closePropertyCertification = () => {
    setIsPropertyCertificationActive(false);
  };

  const openCertificationNoLocationModal = () => {
    setIsCertificationNoLocationModalOpen(true);
  };

  const closeCertificationNoLocationModal = () => {
    setIsCertificationNoLocationModalOpen(false);
  };

  useEffect(() => {
    if (
      userLocation.latitude !== mapCenter.lat &&
      userLocation.longitude !== mapCenter.lng
    ) {
      setMapCenter({ lat: userLocation.latitude, lng: userLocation.longitude });
    }
  }, [mapCenter.lat, mapCenter.lng, userLocation]);

  return (
    <>
      <div className="d-flex flex-column justify-md-content-between main-container">
        <Navbar mobileHeight="52px" searchBarColor="rgba(0, 0, 0, 0)" />
        <div
          className="d-none d-md-flex row mw-100 ms-5 me-1"
          style={{ height: "calc(100vh - 52px)" }}
        >
          <div className="col-9 bg-white">
            <div style={{ height: "100%", width: "100%" }}>
              <BaseMap
                userLocation={userLocation}
                keyboardShortcuts={!mapLocked}
                gestureHandling={mapLocked ? "none" : "auto"}
                onClick={(t) => {
                  if (!mapLocked) {
                    setLatitude(t.lat);
                    setLongitude(t.lng);
                    setIsSelectedPropertyLocation(true);
                  }
                }}
              >
                <MapMarker locked={mapLocked} lat={latitude} lng={longitude} />
              </BaseMap>
            </div>
          </div>
          <div
            className="col-3 bg-white d-flex flex-column justify-content-between"
            style={{ height: "inherit" }}
          >
            <div className="overflow-auto w-100">
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <FileUploader
                  // msg="Cargar documento de referencia"
                  files={mainImages}
                  setFiles={setMainImages}
                  mainIndex={mainImageIndex}
                  setMainIndex={setMainImageIndex}
                  mobile
                  usingIndexes={false}
                  accepts=".jpg,.jpeg,.png,.pdf"
                  limit={3}
                  maxSizeInMB={15}
                  icon={fileUp}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mb-4 mt-4">
              <a href={`${process.env.REACT_APP_LANDING_LINK}#update`}>
                <Button>Descartar</Button>
              </a>
              <ButtonRed
                onClick={() => {
                  if (!isSelectedPropertyLocation) {
                    openCertificationNoLocationModal();
                  } else {
                    openPropertyCertification();
                  }
                }}
              >
                Verificar
              </ButtonRed>
            </div>
          </div>
        </div>
        <div className="d-md-none d-block">
          <div
            style={{
              height: "calc(100vh - 225px)",
              width: "100%",
              marginTop: "52px",
            }}
          >
            <BaseMap
              userLocation={userLocation}
              onClick={(t) => {
                if (!mapLocked) {
                  setLatitude(t.lat);
                  setLongitude(t.lng);
                  setIsSelectedPropertyLocation(true);
                }
              }}
            >
              <MapMarker locked={mapLocked} lat={latitude} lng={longitude} />
            </BaseMap>
          </div>
          <BottomDiv>
            <div
              className={
                mobileScreen !== STATE_MOBILE_MAP ? "d-block" : "d-none"
              }
              style={{
                padding:
                  mobileScreen !== STATE_MOBILE_MAP ? "30px 20px 0px 20px" : "",
                position: "absolute",
                bottom: "100%",
                width: "100%",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <ScrollessDiv style={{ maxHeight: "55vh" }}>
                <div
                  style={{
                    padding: "10px 15px",
                  }}
                >
                  <div
                    className={
                      mobileScreen === STATE_MOBILE_IMAGE_UPLOAD
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <FileUploader
                      // msg="Cargar documento de referencia"
                      files={mainImages}
                      setFiles={setMainImages}
                      mainIndex={mainImageIndex}
                      setMainIndex={setMainImageIndex}
                      mobile
                      usingIndexes={false}
                      accepts=".jpg,.jpeg,.png,.pdf"
                      limit={3}
                      maxSizeInMB={15}
                      icon={fileUp}
                    />
                  </div>
                </div>
              </ScrollessDiv>
            </div>
            <div
              style={{
                padding: "10px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ButtonRed
                  style={{
                    backgroundColor: "#ececec",
                    color: "black",
                    border: "1px solid #ececec",
                    maxWidth: "350px",
                  }}
                  className="d-block text-center w-100"
                  onClick={() => {
                    setMobileScreen(
                      mobileScreen === STATE_MOBILE_MAP
                        ? STATE_MOBILE_IMAGE_UPLOAD
                        : STATE_MOBILE_MAP
                    );
                  }}
                >
                  {mobileScreen === STATE_MOBILE_MAP
                    ? "Cargar documentos de referencia"
                    : "Aceptar"}
                </ButtonRed>
              </div>
              <hr
                style={{ margin: "15px 0" }}
                className={
                  mobileScreen !== STATE_MOBILE_IMAGE_UPLOAD
                    ? "d-block"
                    : "d-none"
                }
              />
              <div
                className={`${
                  mobileScreen === STATE_MOBILE_MAP ? "d-flex" : "d-none"
                } justify-content-center`}
              >
                <a
                  href={`${process.env.REACT_APP_LANDING_LINK}#update`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button>Descartar</Button>
                </a>
                <ButtonRed
                  className="mx-2"
                  onClick={() => {
                    if (!isSelectedPropertyLocation) {
                      openCertificationNoLocationModal();
                    } else {
                      openPropertyCertification();
                    }
                  }}
                >
                  Verificar
                </ButtonRed>
              </div>
            </div>
          </BottomDiv>

          {/* //// */}
          {/* <BottomDiv>
            <div
              className="c"
              style={{
                padding:
                  mobileScreen !== STATE_MOBILE_MAP ? "30px 20px 0px 20px" : "",
                position: "absolute",
                bottom: "100%",
                width: "100%",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <ScrollessDiv style={{ maxHeight: "55vh" }}>
                <div
                  className={
                    mobileScreen === STATE_MOBILE_PROPERTY_FORM
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <PropertyForm
                    latitude={latitude}
                    longitude={longitude}
                    images={mainImages}
                    mainImageIndex={mainImageIndex}
                    setLoading={setLoading}
                    property={currentProperty}
                    triggerSubmit={triggerSubmit}
                    lackOfImageHandler={() => {
                      setShowLackOfImageModal(true);
                    }}
                    incompleteFormHandler={() => {
                      setShowIncompleteFormModal(true);
                    }}
                    onClickPropertyType={(propertyType: string) => {
                      setPropertyColor(colorMap[propertyType]);
                    }}
                  />
                </div>
                <div
                  className={
                    mobileScreen === STATE_MOBILE_IMAGE_UPLOAD
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <div style={{ height: "100%" }}>
                    <FileUploader
                      files={mainImages}
                      setFiles={setMainImages}
                      mainIndex={mainImageIndex}
                      setMainIndex={setMainImageIndex}
                      limit={15}
                      maxSizeInMB={15}
                      mobile
                    />
                  </div>
                </div>
              </ScrollessDiv>
            </div>
            <div
              style={{
                borderRadius: "20px 20px 0 0",
                backgroundColor: "white",
              }}
              className={`${
                mobileScreen === STATE_MOBILE_MAP ? "d-none" : "d-flex"
              } justify-content-between px-3 py-3`}
            >
              <span
                className="clickable poppins-semibold-14 d-flex justify-content-center align-items-center"
                onClick={onMobileSetMap}
                style={{
                  color: "#D2190E",
                  padding: "3px 30px",
                }}
              >
                Atrás
              </span>
              <span
                style={{
                  backgroundColor: "#D9D9D9",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  padding: "3px 30px",
                }}
                className="clickable"
                onClick={onMobileSetMap}
              >
                Cargar
              </span>
            </div>
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 20px",
                borderRadius: "10px 10px 0 0",
              }}
              className={
                mobileScreen === STATE_MOBILE_MAP ? "d-block" : "d-none"
              }
            >
              <div className="d-flex justify-content-center">
                <Button className="" onClick={onMobileSetPhotoUpload}>
                  <Trans i18nKey="addProperty.mobile.photos" />
                </Button>
                <Button className="mx-2" onClick={onMobileSetPropertyForm}>
                  <Trans i18nKey="addProperty.mobile.details" />
                </Button>
                <ButtonRed
                  className=""
                  style={{}}
                  onClick={() => {
                    setMobileScreen(STATE_MOBILE_PROPERTY_FORM);
                    setTriggerSubmit(true);
                    setTimeout(() => {
                      setTriggerSubmit(false);
                    }, 100);
                  }}
                >
                  {currentProperty ? (
                    <Trans i18nKey="addProperty.update" />
                  ) : (
                    <Trans i18nKey="addProperty.publish" />
                  )}
                </ButtonRed>
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <ButtonText
                  className=""
                  onClick={() => {
                    history.push(
                      paths.USER_PROFILE.replace(
                        ":username",
                        profile?.username ?? ""
                      )
                    );
                  }}
                >
                  <Trans i18nKey="addProperty.discard" />
                </ButtonText>
              </div>
            </div>
          </BottomDiv> */}
          {/* //// */}
        </div>

        <PropertyCertification
          startingModalActive={isPropertyCertificationActive}
          closeStartingModal={closePropertyCertification}
          propertyCertificationInfo={{
            latitude,
            longitude,
            documents: mainImages,
          }}
        />
        <CertificationNoLocationModal
          active={isCertificationNoLocationModalOpen}
          closeModal={closeCertificationNoLocationModal}
        />
        <AuthenticationModal active={showLogin} closeModal={closeLoginModal} />
        {/* <OptionConfirmationCompleteModal
          title="Inicio de verificación de propiedad"
          msg=""
          confirmText="Marcar propiedad en el mapa"
          show={showConfirmation}
          handleClose={() => {
            setShowConfirmation(false);
          }}
          handleComplete={() => {
            setShowConfirmation(false);
          }}
        >
          <div>
            <p>
              Para iniciar la verificación, simplemente marca la ubicación
              exacta de la propiedad en nuestro mapa o adjunta un documento de
              referencia de la propiedad, como el Título de propiedad, Acto de
              venta o Certificación.
            </p>
          </div>
        </OptionConfirmationCompleteModal> */}
      </div>
    </>
  );
};

RequestVerification.defaultProps = {
  center: {
    lat: 18.452223613348835,
    lng: -69.95282818615782,
  },
  zoom: 11,
};

export default RequestVerification;
