import styled from "styled-components";
import avatar from "../images/avatar.svg";
import verifiedIcon from "../images/verified-user.svg";
import OptionsModal from "./OptionsModal";

const Icon = styled.img<{ size?: any }>`
  width: ${(props) => props.size ?? "25px"};
  height: ${(props) => props.size ?? "25px"};
`;

const OptionMenu = styled.div`
  background: white;
  border-radius: 5px;
  text-align: center;
  div {
    border-bottom: 1px solid #707070;
    padding: 1rem 4rem;
    cursor: pointer;
  }
  div:first-child {
    border-top: 1px solid #707070;
    color: red;
    font-weight: 700;
  }
  div:last-child {
    font-weight: 700;
    border-bottom: 0px;
  }
`;

interface ProfileShortModalProps {
  darkerBackground?: boolean;
  user: any;
  propertyNumber: Number;
  show: boolean;
  handleAccept: () => void;
  handleClose: () => void;
}

function ProfileShortModal({
  user,
  propertyNumber,
  show,
  handleAccept,
  handleClose,
  darkerBackground,
}: ProfileShortModalProps) {
  return (
    <>
      <OptionsModal
        active={show}
        closeModal={handleClose}
        darkerBackground={darkerBackground}
      >
        <div className="poppins-regular-12 text-center">
          <div className="mt-3 mb-5">
            <Icon
              size="100px"
              src={user?.profile?.avatar ?? avatar}
              style={{ borderRadius: "50%", objectFit: "cover" }}
              alt="avatar"
            />
            <div className="d-flex justify-content-center align-items-center mt-2">
              <span className="poppins-bold-20 me-1">
                {user.first_name} {user.last_name}
              </span>
              {user?.profile?.subscription &&
                user.profile.subscription.name.toLowerCase() !== "free" && (
                  <Icon size={"15px"} alt="verified" src={verifiedIcon} />
                )}
            </div>
            <span className="poppins-regular-14">
              @{user.username} <strong>{propertyNumber} propiedades</strong>
            </span>
          </div>
          <OptionMenu>
            <div onClick={handleAccept}>Dejar de seguir</div>
            <div onClick={handleClose}>Cancelar</div>
          </OptionMenu>
        </div>
      </OptionsModal>
    </>
  );
}

export default ProfileShortModal;
