import { useContext } from "react";
import { useQuery } from "react-query";
import avatar from "../images/avatar.svg";
import styled from "styled-components";
import { PropertyContext } from "../context/property/PropertyContext";
import {
  ALL_COUNTRIES_CODE,
  useLocalstorageContext,
} from "../context/localstorage/LocalstorageContext";

interface TendencyListProps {
  search?: string;
  onClick: (tendency: string) => void;
}

interface TendencyOptionProps
  extends React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  text: string;
}

const TendencyOptionContainer = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  gap: 5px;
  padding: 5px;
  &:hover {
    background-color: #e9e9e9;
  }
`;

const TendencyOption = (props: TendencyOptionProps) => {
  return (
    <span {...props}>
      <TendencyOptionContainer className="clickable">
        <span style={{ width: "100%" }}>#{props.text}</span>
      </TendencyOptionContainer>
    </span>
  );
};

const TendencyList = ({ search, onClick }: TendencyListProps) => {
  const { getTendencies } = useContext(PropertyContext);
  const [state] = useLocalstorageContext();
  const {
    data: tendencies,
    isLoading,
    isError,
    refetch,
  } = useQuery<any>({
    initialData: [],
    queryKey: `tendnecy-search-${search}`,
    queryFn: () =>
      getTendencies(
        state.countryCode !== ALL_COUNTRIES_CODE ? state.countryCode : "",
        search || ""
      ),
  });
  return (
    <div style={{ width: "100%" }}>
      {isLoading ? <div style={{ padding: "5px" }}>Buscando...</div> : null}
      {!isLoading && !isError && search && tendencies.length === 0 ? (
        <div style={{ padding: "5px" }}>
          No hemos encontrado usuarios relacionados
        </div>
      ) : null}
      {isError ? (
        <div style={{ padding: "5px" }}>
          Ha ocurrido algo inesperado, haz clic{" "}
          <span
            style={{ color: "blue" }}
            className="clickable"
            onClick={() => refetch()}
          >
            aqui
          </span>{" "}
          para reintentar
        </div>
      ) : null}
      <ul
        className="tendency-list-container"
        style={{ width: "100%", paddingLeft: 0, marginBottom: 0 }}
      >
        {tendencies.map(({ tendency }: any) => (
          <TendencyOption
            key={tendency}
            onClick={() => onClick(tendency)}
            text={tendency}
          />
        ))}
      </ul>
    </div>
  );
};

export default TendencyList;
