import { ReactNode } from "react";
import styled from "styled-components";

const Text = styled.p`
  color: red;
  font-size: 0.7rem;
  margin: 0;
  padding: 0;
`;

interface ErrorTextProps {
  children: ReactNode;
}

const ErrorText = ({ children }: ErrorTextProps) => {
  return (
    <>
      <Text className="text-end">{children}</Text>
    </>
  );
};

export default ErrorText;
