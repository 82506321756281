const MapPlaceholder = (props: any) => {
  return (
    <div
      className="map-placeholder text-center flex-grow-1"
      style={{ margin: props.collapsed ? "5em 0" : "20em 0" }}
    >
      <h1 className="poppins-bold-24">
        No encontramos propiedades con estas caracteristicas
      </h1>
      <p className="poppins-medium-16">Prueba con otros filtros</p>
    </div>
  );
};

export default MapPlaceholder;
