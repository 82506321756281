import { useHistory, useLocation } from "react-router-dom";
import DashboardFollowers from "../../components/DashboardFollowers";
import DashboardSidebar from "../../components/DashboardSidebar";
import Navbar from "../../components/Navbar";
import { NavigationBackButton } from "../../components/NavigationBackButton";

const FollowersPage = () => {
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const history = useHistory();
  return (
    <>
      <div
        style={{
          backgroundColor: "#f9f9f9",
        }}
        className="d-flex flex-column main-container"
      >
        <Navbar
          searchBarOption={
            <NavigationBackButton
              onClick={() => {
                history.go(-1);
              }}
            />
          }
          searchBarToRight
        />

        <DashboardSidebar />
        <div className="d-flex">
          <div className="col-12 offset-md-1 col-md-6 pt-3">
            <DashboardFollowers isFollowersList={location === "followers"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowersPage;
