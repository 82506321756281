import React, { useState, useContext, useMemo } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../context/auth/AuthContext";
import avatar from "../images/avatar.svg";
import heart from "../images/heart.svg";
import AuthenticationModal from "./AuthenticationModal";
import Avatar from "./Avatar";
import { Comment, Property } from "../context/property/types";
import { formatDate } from "../utils";
import {
  PAGE_LIMIT,
  PropertyContext,
} from "../context/property/PropertyContext";
import heartActive from "../images/heart-red.svg";
import chevron from "../images/chevron-down.svg";
import paths from "../routes/paths.json";
import optionIcon from "../images/option.svg";
import CommentOptionsModal from "./CommentOptionModal";
import { ProfileContext } from "../context/profile/ProfileContext";
import { useInfiniteQuery } from "react-query";

const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin: 0 5px;
`;

export const AnswerCommentButton = styled.span`
  padding: 0.5em 1em;
  border-radius: 10px;
  transition: 0.3s;
  color: #065fd4;
  font-weight: bold;
  &:hover {
    background-color: #e3eeff;
  }
`;

interface CommentProps {
  comment: Comment;
  setText: any;
  setFocus: any;
  property: Property;
  refetchComments?: Function;
  shorten?: boolean;
  updateComment: (comment: Comment) => void;
  handleDelete?: () => void;
  parent?: number;
  onReply?: (comment: Comment, parent: number) => void;
}

const CommentComponent = ({
  comment,
  setText,
  setFocus,
  property,
  refetchComments,
  shorten = false,
  updateComment,
  handleDelete = () => {},
  parent = -1,
  onReply = () => {},
}: CommentProps) => {
  const { profile } = useContext(ProfileContext);
  const {
    likeComment,
    dislikeComment,
    deleteComment,
    reportComment,
    getComments,
  } = useContext(PropertyContext);
  const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  const [showLogin, setShowLogin] = useState(false);
  const [showPropertyOptions, setShowPropertyOptions] = useState(false);
  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = () => setShowLogin(true);
  const closePropertyOptionsModal = () => setShowPropertyOptions(false);
  const openPropertyOptionsModal = () => setShowPropertyOptions(true);

  const { data, isError, isLoading, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery<any>({
      getNextPageParam: (lastPage, allPage) => {
        return allPage.length * PAGE_LIMIT < lastPage.count
          ? allPage.length
          : undefined;
      },
      queryKey: `comments-${comment.property}-${comment.id}`,
      queryFn: (context) => {
        if (comment.childs === 0) {
          return;
        }
        return getComments(comment.property, context.pageParam, comment.id);
      },
      enabled: false,
    });

  const childComments = useMemo<any>(
    () =>
      data?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page.results];
      }, []) || [],
    [data]
  );

  const showLoadMoreComments =
    (comment.childs > 0 && childComments.length === 0) ||
    (hasNextPage && !isLoading);

  const fetchComments = () => {
    if (childComments.length === 0) {
      refetch();
      return;
    }
    fetchNextPage();
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <Link
          to={paths.USER_PROFILE.replace(
            ":username",
            comment.commentator.username
          )}
        >
          <Avatar
            src={
              comment.commentator.profile.avatar
                ? comment.commentator.profile.avatar
                : avatar
            }
            alt="avatar"
            size="sm"
            onClick={() => {
              history.push(
                paths.USER_PROFILE.replace(
                  ":username",
                  comment.commentator.username
                )
              );
            }}
          />
        </Link>
        <div className="d-flex flex-column">
          <div>
            <span
              className="poppins-semibold-14 ms-1 clickable"
              onClick={() => {
                history.push(
                  paths.USER_PROFILE.replace(
                    ":username",
                    comment.commentator.username
                  )
                );
              }}
            >{`${comment.commentator.first_name} ${comment.commentator.last_name}`}</span>
            <span className="ms-2 poppins-medium-10">
              {formatDate(new Date(comment.created))}{" "}
            </span>
          </div>
          <span
            className="poppins-regular-12 clickable ms-1"
            style={{ color: "grey" }}
            onClick={() => {
              if (isAuthenticated) {
                setText("@" + comment.commentator.username + " ");
                setFocus();
              } else openLoginModal();
            }}
          >{`@${comment.commentator.username}`}</span>
        </div>
        <Icon
          className="ms-auto clickable"
          src={comment.myself_liked ? heartActive : heart}
          alt="heart"
          onClick={async () => {
            if (isAuthenticated) {
              if (!comment.myself_liked) {
                try {
                  await likeComment(comment.id);
                  updateComment({
                    ...comment,
                    myself_liked: true,
                    likes: comment.likes + 1,
                  });
                } catch (e) {
                  console.error(e);
                }
              } else {
                try {
                  await dislikeComment(comment.id);
                  updateComment({
                    ...comment,
                    myself_liked: false,
                    likes: comment.likes - 1,
                  });
                } catch (e) {
                  console.error(e);
                }
              }
            } else {
              openLoginModal();
            }
          }}
        />
      </div>
      <div
        className="ms-5 my-1 poppins-light-13 me-4"
        style={{ wordBreak: "break-word" }}
      >
        {comment.text
          .split(/\r?\n/)
          .filter((line: string, index: number) => {
            if (shorten) {
              return index === 0;
            } else {
              return true;
            }
          })
          .map((line: string, index) => {
            return (
              <div
                key={index}
                style={
                  shorten
                    ? {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }
                    : {}
                }
              >
                {line.split(" ").map((word: string, index) => {
                  let wordWithoutFirstCharacter = word.slice(1);
                  if (word[0] === "@") {
                    wordWithoutFirstCharacter =
                      wordWithoutFirstCharacter.toLocaleLowerCase();
                    return (
                      <React.Fragment key={index}>
                        <Link
                          to={`/profile/${wordWithoutFirstCharacter}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <span className="poppins-bold-13">
                            <span style={{ color: "red" }}>@</span>
                            {wordWithoutFirstCharacter}
                          </span>
                        </Link>{" "}
                      </React.Fragment>
                    );
                  } else if (word[0] === "#") {
                    wordWithoutFirstCharacter =
                      wordWithoutFirstCharacter.toLocaleLowerCase();
                    return (
                      <>
                        <Link
                          className="poppins-bold-13"
                          to={`/tendency/${wordWithoutFirstCharacter}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <span style={{ color: "red" }}>#</span>
                          {wordWithoutFirstCharacter}
                        </Link>{" "}
                      </>
                    );
                  } else {
                    return word + " ";
                  }
                })}
                <br />
              </div>
            );
          })}
      </div>
      <div className="ms-5 poppins-medium-10" style={{ color: "#707070" }}>
        <span
          className="clickable"
          style={{ marginRight: "5px", fontWeight: "bold" }}
          onClick={() => {
            if (isAuthenticated) {
              onReply(comment, parent);
              setText("@" + comment.commentator.username + " ");
              setFocus();
            } else openLoginModal();
          }}
        >
          Responder{" "}
        </span>
        <span
          className="clickable"
          style={{ marginRight: "5px", fontWeight: "bold" }}
          onClick={isAuthenticated ? () => {} : openLoginModal}
        >
          {comment.likes} Me gusta{" "}
        </span>
        <span>
          {isAuthenticated && (
            <Icon
              className="clickable"
              src={optionIcon}
              onClick={openPropertyOptionsModal}
            />
          )}
        </span>
      </div>
      <div className="ms-5 poppins-medium-10" style={{ color: "#707070" }}>
        <div className="mt-2">
          {childComments.map((d: any) => (
            <div className="mb-2" key={d.id}>
              <CommentComponent
                setFocus={setFocus}
                setText={setText}
                updateComment={() => {
                  refetch();
                  updateComment(d);
                }}
                comment={d}
                parent={comment.id}
                property={property}
                onReply={onReply}
              />
            </div>
          ))}
        </div>
        {showLoadMoreComments && !isLoading ? (
          <AnswerCommentButton
            onClick={() => fetchComments()}
            className="clickable"
          >
            <img
              style={{
                height: "1.2em",
                width: "1.2em",
                marginRight: "0.5em",
              }}
              src={chevron}
              alt=""
            />
            Ver respuestas
          </AnswerCommentButton>
        ) : null}
        {isLoading ? (
          <AnswerCommentButton>Cargando...</AnswerCommentButton>
        ) : null}
        {isError ? (
          <span onClick={() => refetch()}>
            Ocurrio algo inesperado, click aqui para reintentar
          </span>
        ) : null}
      </div>

      <AuthenticationModal
        active={showLogin}
        closeModal={closeLoginModal}
        darkerBackground
      />
      <CommentOptionsModal
        darkerBackground
        active={showPropertyOptions}
        closeModal={closePropertyOptionsModal}
        property={property}
        comment={comment}
        allowDelete={
          property.owner.myself || comment.commentator.id === profile?.id
        }
        handleDelete={async () => {
          if (isAuthenticated) {
            try {
              if (property.owner.myself) {
                await deleteComment(comment.id);
                handleDelete();
                if (refetchComments) refetchComments();
                closePropertyOptionsModal();
              } else if (comment.commentator.id === profile?.id) {
                await deleteComment(comment.id);
                handleDelete();
                if (refetchComments) refetchComments();
                closePropertyOptionsModal();
              }
            } catch (e) {
              console.error(e);
            }
          } else {
            openLoginModal();
          }
        }}
        handleReport={async () => {
          if (isAuthenticated) {
            if (property.owner.id != profile?.id) {
              await reportComment(comment.id);
              closePropertyOptionsModal();
            }
          } else {
            openLoginModal();
          }
        }}
      />
    </>
  );
};

export default CommentComponent;
