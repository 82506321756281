const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const getPriceNotation = (price: string | number) => {
  let intg = price.toString().split(".")[0];
  let decimals = price.toString().split(".")[1];
  return [
    intg
      .split("")
      .reverse()
      .join("")
      .match(/[0-9]{1,3}/g)
      ?.join(",")
      .split("")
      .reverse()
      .join(""),
    decimals ? decimals : "00",
  ].join(".");
};

function dataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

function formatDate(date: Date) {
  let today = new Date();
  let dateDistance = new Date(today.valueOf() - date.valueOf());
  let yearDistance = dateDistance.getUTCFullYear() - 1970;
  let monthDistance = dateDistance.getUTCMonth();
  let dayDistance = dateDistance.getUTCDate() - 1;
  let hoursDistance = dateDistance.getUTCHours();
  let minutesDistance = dateDistance.getUTCMinutes();
  let secondsDistance = dateDistance.getUTCSeconds();
  if (yearDistance > 1 || monthDistance > 1) {
    return date.toLocaleDateString();
  } else if (dayDistance >= 7) {
    return `${(dayDistance / 7).toFixed(0)} W`;
  } else if (dayDistance > 1) {
    return `${dayDistance} D`;
  } else if (hoursDistance > 1) {
    return `${hoursDistance} h`;
  } else if (minutesDistance > 1) {
    return `${minutesDistance} m`;
  } else {
    return `${secondsDistance} s`;
  }
}

const transformCommentText = (text: string) => {
  return text
    .split(/\r?\n/)
    .map((line: string) => {
      return line
        .split(" ")
        .map((word) =>
          word[0] === "#"
            ? `#${word.toLocaleLowerCase().replace(/[^\w ]/g, "")}`
            : word
        )
        .join(" ");
    })
    .join("\n");
};

const CONSTANTS = {
  WITHOUT_CERTIFICATION: "WITHOUT",
};

export {
  getBase64,
  getPriceNotation,
  dataURIToBlob,
  formatDate,
  transformCommentText,
  CONSTANTS,
};
