import styled from "styled-components";
import OptionsModal from "./OptionsModal";
import { useContext, useState } from "react";
import SharePropertyModal from "./SharePropertyModal";
import ReportProfileModal from "./ReportProfileModal";
import OptionConfirmationCompleteModal from "./OptionConfirmationCompleteModal";
import OptionConfirmationModal from "./OptionConfirmationModal";
import { AuthContext } from "../context/auth/AuthContext";

const OptionMenu = styled.div`
  background: white;
  border-radius: 5px;
  text-align: center;
  div {
    border-bottom: 2px solid gray;
    padding: 1rem 4rem;
    cursor: pointer;
  }
  .red-text {
    color: red;
    font-weight: 700;
  }
  div:last-child {
    font-weight: 700;
    border-bottom: 0px;
  }
`;

interface IProfileOptionsModalProps {
  profile: any;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const ProfileOptionsModal = ({
  active,
  closeModal,
  darkerBackground,
  profile,
}: IProfileOptionsModalProps) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [showSharePropertyModal, setShowSharePropertyModal] = useState(false);
  const [showReportProfileModal, setShowReportProfileModal] = useState(false);
  const [showBlockProfileModal, setShowBlockProfileModal] = useState(false);
  const [showBlockProfileCompleteModal, setShowBlockProfileCompleteModal] =
    useState(false);
  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <OptionMenu className="poppins-regular-12">
          {/* <div
            className="red-text"
            onClick={() => {
              setShowBlockProfileModal(true);
            }}
          >
            Bloquear
          </div> */}
          {isAuthenticated && (
            <div
              className="red-text"
              onClick={() => {
                setShowReportProfileModal(true);
              }}
            >
              Reportar
            </div>
          )}
          <div
            onClick={async () => {
              await navigator.clipboard.writeText(
                `https://bohiio.com/profile/${profile.username}`
              );
              closeModal();
            }}
          >
            Copiar enlace
          </div>
          <div
            onClick={() => {
              closeModal();
              setShowSharePropertyModal(true);
            }}
          >
            Compartir
          </div>
          <div onClick={closeModal}>Cancelar</div>
        </OptionMenu>
      </OptionsModal>
      <SharePropertyModal
        isProfile
        propertyUrl={`https://bohiio.com/profile/${profile.username}`}
        isActive={showSharePropertyModal}
        onHide={() => setShowSharePropertyModal(false)}
      />
      <ReportProfileModal
        profile={profile}
        active={showReportProfileModal}
        closeModal={() => setShowReportProfileModal(false)}
      />
      <OptionConfirmationModal
        msg="No podrá encontrar tu perfil ni tus publicaciones en bohiio. Bohiio no le avisará a esta persona que la bloqueaste."
        acceptText="Bloquear"
        cancelText="Cancelar"
        show={showBlockProfileModal}
        title={`¿Bloquear a @${profile.username}?`}
        handleAccept={() => {
          //TODO: actually block the user
          setShowBlockProfileModal(false);
          setShowBlockProfileCompleteModal(true);
        }}
        handleClose={() => {
          setShowBlockProfileModal(false);
        }}
      />
      <OptionConfirmationCompleteModal
        msg="Puedes desbloquear a esta persona cuando quieras desde su perfil."
        confirmText="Cerrar"
        show={showBlockProfileCompleteModal}
        title={`Bloqueaste a @${profile.username}`}
        handleClose={() => {
          setShowBlockProfileCompleteModal(false);
        }}
      />
    </>
  );
};

export default ProfileOptionsModal;
