import OptionsModal from "./OptionsModal";

interface LeavePageModalProps {
  active: boolean;
  closeModal: () => void;
  leavePage: () => void;
  editting?: boolean;
  darkerBackground?: boolean;
}

const LeavePageModal = ({
  active,
  closeModal,
  leavePage,
  darkerBackground,
  editting = false,
}: LeavePageModalProps) => {
  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <div
          className="poppins-regular-12 text-center"
          style={{ padding: "3rem 2rem" }}
        >
          <div>
            <p className="poppins-bold-12">¿Quieres salir de la pantalla?</p>
            {!editting && (
              <p>
                Se descartará la publicación que estas realizando, así como
                todas las informaciones que has ingresado hasta el momento sobre
                ella.
              </p>
            )}
            {editting && (
              <p>
                Se descartarán los cambios que estás realizando en la
                publicación.
              </p>
            )}
          </div>
          <div
            style={{
              padding: "1rem 3rem",
              cursor: "pointer",
              color: "white",
              background: "red",
              borderRadius: "5px",
              fontWeight: 700,
            }}
            onClick={closeModal}
          >
            Seguir publicando
          </div>
          <div
            style={{
              padding: "1rem 3rem",
              cursor: "pointer",
              fontWeight: 700,
            }}
            onClick={leavePage}
          >
            Descartar publicación
          </div>
        </div>
      </OptionsModal>
    </>
  );
};

export default LeavePageModal;
