import styled from "styled-components";
import * as Yup from "yup";
import ErrorText from "./ErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useRef, useState } from "react";
import { getBase64 } from "../utils";
import Avatar from "./Avatar";
import countryCodes, { CountryProperty } from "country-codes-list";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { ButtonRed } from "../styles/common";
import UserList from "./UserList";
import TendencyList from "./TendencyList";
import loadingGif from "../images/loading.gif";
import {
  replaceWord,
  useCommentUserHashtagInput,
} from "../hooks/useCustomInput";

// const Button = styled.button`
//   border: 1px #ececec solid;
//   background: #ececec;
//   color: black;
//   margin: 20px 0px;
//   padding: 0.5em 1em;
//   border-radius: 5px;
// `;
export const USERNAME_VALIDATION_REGEX =
  /^([a-z0-9_](?:(?:[a-z0-9_]|(?:\.(?!\.))){0,28}(?:[a-z0-9_]))?)$/;
const WhiteButton = styled.button`
  border: 1px white solid;
  background: white;
  color: #d2190e;
  padding: 0.5em 1em;
  margin: 20px 0px;
`;

const FloatingInput = styled.div`
  & .form-control,
  .form-select {
    padding-top: 1rem !important;
    padding-bottom: 0.15rem !important;
    height: calc(2.6rem) !important;
  }
  & label {
    padding-top: 0.5rem !important;
  }
`;

const FormContainer = styled.div`
  & .form-control:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
`;

const INITIAL_AREA_CODE = "DO: +1";

const ProfileChangeInfo = (props: any) => {
  const myCountryCodesObject: object = countryCodes.customList(
    "countryCode" as CountryProperty,
    "{countryCode}: +{countryCallingCode}"
  );

  // console.log("myCountryCodesObject", myCountryCodesObject);

  const inputRef = useRef(null);
  const inputBiografyRef = useRef<any>(null);

  const [, setImageFile] = useState<any>({});
  const idDebounceRef = useRef<number>(-1);
  useEffect(() => {
    return () => {
      clearTimeout(idDebounceRef.current);
    };
  }, []);

  const [searchUserText, setSearchUserText] = useState("");
  const [searchTendencyText, setSearchTendencyText] = useState("");
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const [lastPositionCursorIndex, setLastPositionCursorIndex] = useState(-1);
  useEffect(() => {
    if (lastPositionCursorIndex === -1) return;

    setLastPositionCursorIndex(-1);
    inputBiografyRef.current.setSelectionRange(
      lastPositionCursorIndex,
      lastPositionCursorIndex
    );
  }, [lastPositionCursorIndex]);

  const {
    isTriggeredUser,
    isTriggeredHashtag,
    onChange: onChangeUserCommentText,
    resetWord,
    initialWordIndex,
    endWordIndex,
  } = useCommentUserHashtagInput({
    onCloseTrigger: () => {
      setSearchUserText("");
      setSearchTendencyText("");
      clearTimeout(idDebounceRef.current);
    },
  });

  const setCommentText = (event: any) => {
    const breakTrigger = " ";
    const text = event.target.value as string;
    console.log();
    onChangeUserCommentText(event);

    if (
      (isTriggeredUser || isTriggeredHashtag) &&
      event.nativeEvent.data !== breakTrigger
    ) {
      clearTimeout(idDebounceRef.current);
      idDebounceRef.current = setTimeout(() => {
        const searchWord = text.substring(initialWordIndex, endWordIndex + 1);
        if (isTriggeredUser) {
          setSearchUserText(searchWord);
        } else {
          setSearchTendencyText(searchWord);
        }
      }, 300) as unknown as number;
    }
  };

  const onImageUpload = async (e: any) => {
    const newImage = {
      dataURL: await getBase64(e.target.files[0]),
      name: e.target.files[0].name,
    };

    setImageFile(newImage);
    setIsLoadingImage(true);
    await props.handleProfileImageChange({ file: e.target.files[0] });
    setIsLoadingImage(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: props.first_name,
        lastName: props.last_name,
        username: props.username,
        city: props.city,
        country: props.country,
        biography: props.biography,
        email: props.email,

        phone: props.phone ?? "",
        phone_code: props.phone_code ?? INITIAL_AREA_CODE,
        client_phone: props.client_phone ?? "",
        client_phone_code: props.client_phone_code ?? INITIAL_AREA_CODE,

        profession: props.profession,
        company: props.company,
        webpage: props.webpage,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Requerido"),
        lastName: Yup.string(),
        username: Yup.string()
          .required("Requerido")
          .matches(
            USERNAME_VALIDATION_REGEX,
            "Nombre de usuario no puede contener espacios, mayusculas or caracteres especiales diferentes de . o _"
          ),
        phone: Yup.string().required("Requerido"),
        email: Yup.string().email("Correo invalido").required("Requerido"),
      })}
      onSubmit={async (values, FormikBag) => {
        let formattedBiography = values.biography;
        formattedBiography = formattedBiography.replace(
          /[#|@][^-\s]*/g,
          (v: string) => v.toLowerCase()
        );

        await props.handleProfileChangeInfo({
          username: values.username,
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          profile: {
            biography: formattedBiography,
            country: values.country,
            city: values.city,
            province: props.province,
            language: props.language,
            phone: values.phone,
            phone_code: values.phone_code,
            client_phone: values.client_phone,
            client_phone_code: values.client_phone_code,
            profession: values.profession,
            company: values.company,
            webpage: values.webpage,
            subscription: {
              name: "remove this",
            },
          },
        });
      }}
    >
      {({ dirty, touched, errors, values, setFieldValue, handleChange }) => (
        <Form className="px-1 px-md-2 px-xl-4">
          <FormContainer>
            <div className="d-flex flex-column flex-md-row">
              <Avatar
                className="me-2 clickable"
                alt="avatar"
                src={isLoadingImage ? loadingGif : props.avatar}
                onClick={() => (inputRef.current as any)?.click()}
              />
              <div>
                <p className="poppins-medium-27 d-none d-md-inline">
                  {props.first_name} {props.last_name}
                </p>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  ref={inputRef}
                  hidden
                  disabled={isLoadingImage}
                  onChange={onImageUpload}
                  onClick={(event) => ((event.target as any).value = null)}
                />
                <p
                  className="poppins-light-14 clickable"
                  style={{ color: "#D2190E" }}
                  onClick={() => (inputRef.current as any)?.click()}
                >
                  Cambiar la imagen del perfil
                </p>
              </div>
            </div>
            <div className="row poppins-medium-14 align-items-center g-3">
              <div className="col-12 col-md-4">Nombre</div>
              <div className="col-12 col-md-8">
                <Field
                  name="firstName"
                  type="text"
                  placeholder="Nombre"
                  className="form-control"
                />
                <ErrorMessage name="firstName">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </div>
              <div className="col-12 col-md-4">Apellido</div>
              <div className="col-12 col-md-8">
                <Field
                  name="lastName"
                  type="text"
                  placeholder="Apellido"
                  className="form-control"
                />
                <ErrorMessage name="lastName">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </div>
              <p
                className="col-12 poppins-ligth-12 mt-1"
                style={{ color: "rgba(0, 0, 0, 0.5)" }}
              >
                El nombre completo ayuda a que las personas puedan encontrarte
                mas fácil
              </p>
              <div className="col-12 col-md-4">Nombre de usuario</div>
              <div className="col-12 col-md-8">
                <Field
                  name="username"
                  type="text"
                  placeholder="Nombre de usuario"
                  className="form-control"
                />
                <ErrorMessage name="username">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </div>
              <p
                className="col-12 poppins-ligth-12 mt-1"
                style={{ color: "rgba(0, 0, 0, 0.5)" }}
              >
                Este es el nombre con el cual las personas podrán hacer mención
                de ti en los comentarios y las publicaciones.
              </p>
              <div className="col-12">Ubicacion</div>
              <div className="col-6">
                <Field
                  name="city"
                  type="text"
                  placeholder="Ciudad"
                  className="form-control"
                />
              </div>
              <div className="col-6">
                <Field
                  name="country"
                  type="text"
                  placeholder="País"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-4">Presentación</div>
              <div className="col-12 col-md-8" style={{ position: "relative" }}>
                {searchUserText || searchTendencyText ? (
                  <div
                    style={{
                      bottom: "calc(100% - 3px)",
                      position: "absolute",
                      width: "calc(100% - var(--bs-gutter-x))",
                      backgroundColor: "white",
                      border: "1px solid #ced4da",
                      borderBottom: "none",
                      borderRadius: "5px 5px 0 0",
                      maxHeight: "250px",
                      overflowY: "auto",
                    }}
                  >
                    {searchUserText ? (
                      <UserList
                        onClick={(username) => {
                          const insertedText = "@" + username;
                          const lastIndexWordPosition =
                            endWordIndex + insertedText.length - 2;
                          setSearchUserText("");
                          resetWord();
                          setFieldValue(
                            "biography",
                            replaceWord(
                              initialWordIndex - 1,
                              endWordIndex - 1,
                              values.biography,
                              insertedText
                            )
                          );
                          inputBiografyRef.current?.focus();
                          setLastPositionCursorIndex(lastIndexWordPosition);
                        }}
                        search={searchUserText}
                      />
                    ) : null}
                    {searchTendencyText ? (
                      <TendencyList
                        onClick={(hashtag) => {
                          const insertedText = "#" + hashtag;
                          const lastIndexWordPosition =
                            endWordIndex + insertedText.length - 2;
                          setFieldValue(
                            "biography",
                            replaceWord(
                              initialWordIndex - 1,
                              endWordIndex - 1,
                              values.biography,
                              insertedText
                            )
                          );
                          setSearchTendencyText("");
                          resetWord();
                          inputBiografyRef.current?.focus();
                          setLastPositionCursorIndex(lastIndexWordPosition);
                        }}
                        search={searchTendencyText}
                      />
                    ) : null}
                  </div>
                ) : null}
                <Field
                  name="biography"
                  className="form-control mb-3 "
                  as="textarea"
                  rows="5"
                  placeholder="Escribe una breve descripción de ti"
                  maxLength="150"
                  style={{
                    lineHeight: "normal",
                    borderTop:
                      searchUserText || searchTendencyText
                        ? "none"
                        : "1px solid #ced4da",
                  }}
                  innerRef={inputBiografyRef}
                  onChange={(e: any) => {
                    handleChange(e);
                    setCommentText(e);
                  }}
                />
              </div>
              <div className="col-12 text-end">Información personal</div>
              <div className="col-12 col-md-4">Correo Electrónico</div>
              <div className="col-12 col-md-8">
                <Field
                  name="email"
                  type="text"
                  placeholder="correo"
                  className="form-control"
                />
                <ErrorMessage name="email">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </div>
              {/* <div className="offset-4 col-8">
              <Button>Confirmar correo electrónico</Button>
            </div> */}
              <div className="col-4 mt-0">Número de Teléfono</div>
              <div className="col-8">
                <div className="row">
                  <div className="col-5">
                    <FloatingInput className="form-floating mb-4">
                      <Field
                        id="phone_code"
                        name="phone_code"
                        as="select"
                        className="form-control mb-4"
                        selected={values.phone_code}
                      >
                        {Object.entries(myCountryCodesObject)
                          .filter(([countryCode, _]: [string, string]) =>
                            hasFlag(countryCode)
                          )
                          .sort((a, b) => {
                            if (a[0] === "DO") return -1;
                            else if (b[0] === "DO") return 1;
                            else return 0;
                          })
                          .map(
                            ([countryCode, countryValue]: [string, string]) => {
                              return (
                                <option
                                  key={countryValue}
                                  value={countryValue}
                                >{`${getUnicodeFlagIcon(
                                  countryCode
                                )} ${countryValue}`}</option>
                              );
                            }
                          )}
                      </Field>
                      <ErrorMessage name="phone_code">
                        {(msg) => <ErrorText>{msg}</ErrorText>}
                      </ErrorMessage>
                    </FloatingInput>
                  </div>
                  <div className="col-7">
                    <FloatingInput className="form-floating mb-4">
                      <Field
                        id="phone"
                        name="phone"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage name="phone">
                        {(msg) => <ErrorText>{msg}</ErrorText>}
                      </ErrorMessage>
                    </FloatingInput>
                  </div>
                </div>
              </div>
              {/* <div className="offset-4 col-8">
              <Button>Confirmar número de teléfono</Button>
            </div> */}
              <div className="col-4 mt-0">
                Número de Contacto
                <br />
                <div className="d-flex align-items-center ">
                  <input
                    type="checkbox"
                    checked={
                      values.phone_code === values.client_phone_code &&
                      values.phone === values.client_phone
                    }
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        setFieldValue("client_phone_code", values.phone_code);
                        setFieldValue("client_phone", values.phone);
                      }
                    }}
                  />
                  <label className="poppins-light-10">Usar mismo numero</label>{" "}
                </div>
              </div>

              <div className="col-8">
                <div className="row">
                  <div className="col-5">
                    <FloatingInput className="form-floating mb-4">
                      <Field
                        id="client_phone_code"
                        name="client_phone_code"
                        as="select"
                        className="form-control mb-4"
                        selected={values.client_phone_code}
                      >
                        {Object.entries(myCountryCodesObject)
                          .filter(([countryCode, _]: [string, string]) =>
                            hasFlag(countryCode)
                          )
                          .sort((a, b) => {
                            if (a[0] === "DO") return -1;
                            else if (b[0] === "DO") return 1;
                            else return 0;
                          })
                          .map(
                            ([countryCode, countryValue]: [string, string]) => {
                              return (
                                <option
                                  value={countryValue}
                                  key={countryValue}
                                >{`${getUnicodeFlagIcon(
                                  countryCode
                                )} ${countryValue}`}</option>
                              );
                            }
                          )}
                      </Field>
                      {/* <ErrorMessage name="client_phone_code">
                      {(msg) => <ErrorText>{msg}</ErrorText>}
                    </ErrorMessage> */}
                    </FloatingInput>
                  </div>
                  <div className="col-7">
                    <FloatingInput className="form-floating mb-4">
                      <Field
                        id="client_phone"
                        name="client_phone"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage name="client_phone">
                        {(msg) => <ErrorText>{msg}</ErrorText>}
                      </ErrorMessage>
                    </FloatingInput>
                  </div>
                </div>
              </div>
              <p
                className="col-12 poppins-ligth-12 mt-1"
                style={{ color: "rgba(0, 0, 0, 0.5)" }}
              >
                Este es el teléfono de contacto para tus clientes potenciales.
                Debes tener WhatsApp asociado a este número para recibir los
                mensajes.
              </p>
              <div className="col-12 col-md-4">Profesión</div>
              <div className="col-12 col-md-8">
                <Field
                  name="profession"
                  type="text"
                  placeholder="Profesión"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-4">Compañía</div>
              <div className="col-12 col-md-8">
                <Field
                  name="company"
                  type="text"
                  placeholder="Tu empresa ó Inmobiliaria"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-4">Pagina web</div>
              <div className="col-12 col-md-8">
                <Field
                  name="webpage"
                  type="text"
                  placeholder="Tu pagina web"
                  className="form-control"
                />
              </div>
              <div className="col-12 d-flex justify-content-around align-items-center">
                <ButtonRed
                  disabled={!dirty}
                  style={{ opacity: dirty ? 1 : 0.5, height: "40px" }}
                >
                  Guardar
                </ButtonRed>
                <WhiteButton
                  className="poppins-regular-14"
                  onClick={props.deleteAccount}
                >
                  Cerrar mi cuenta de bohiio
                </WhiteButton>
              </div>
            </div>
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileChangeInfo;
