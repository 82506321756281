import styled from "styled-components";
import OptionsModal from "./OptionsModal";

const ButtonRed = styled.button`
  border: 1px #d2190e solid;
  background: #d2190e;
  color: white;
  padding 0.25rem 2rem;
  border-radius: 5px;
`;

interface IHideLocationCompleteModalProps {
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const HideLocationCompleteModal = ({
  active,
  closeModal,
  darkerBackground,
}: IHideLocationCompleteModalProps) => {
  return (
    <OptionsModal
      active={active}
      closeModal={closeModal}
      darkerBackground={darkerBackground}
    >
      <div className="poppins-regular-12 text-center">
        <p className="poppins-bold-12">Ubicación ocultada</p>
        <p>
          Ocultamos la informacion de tu propiedad ningún usuario aparte de ti
          podrá saber dónde se encuentra la propiedad.
        </p>
        <ButtonRed onClick={closeModal}>Cerrar</ButtonRed>
      </div>
    </OptionsModal>
  );
};

export default HideLocationCompleteModal;
