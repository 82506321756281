import paths from "../routes/paths.json";
import { TextLink } from "../styles/common";
import avatar from "../images/avatar.svg";
import verifiedUser from "../images/verified-user.svg";
import styled from "styled-components";
import { Vendor } from "../context/vendor/types";
import { useContext, useState } from "react";
import { FollowersContext } from "../context/followers/FollowersContext";
import ProfileShortModal from "./ProfileShortModal";
import { AuthContext } from "../context/auth/AuthContext";
import AuthenticationModal from "./AuthenticationModal";

const VendorNameContainer = styled.div`
  display: flex;
  @media screen and (min-width: 768px) {
    justify-content: center;
  }
`;

const VendorImg = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  @media screen and (min-width: 768px) {
    height: 100px;
    width: 100px;
  }
`;

const SmallIcon = styled.img`
  height: 15px;
  width: 15px;
`;

const ButtonRed = styled.button`
border: 1px #d2190e solid;
background: inherit;
color: black;
padding 0.25rem 0.5rem;
border-radius: 2.5px;
text-align: center;
width: 80px;
`;

const ButtonPressedRed = styled(ButtonRed)`
  background: #d2190e;
  color: white;
`;
interface IProps {
  vendor: Vendor;
  vendorIndex: number;
  updateVendor: Function;
}

const VendorComponent = ({ vendor, vendorIndex, updateVendor }: IProps) => {
  const { follow, unfollow } = useContext(FollowersContext);
  const { isAuthenticated } = useContext(AuthContext);
  const [showProfileShortModal, setShowProfileShortModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  return (
    <div className="w-100">
      <div className="d-flex flex-md-column align-items-center text-md-center h-100 justify-content-between w-100 gap-2">
        <div className="d-flex flex-md-column align-items-center text-md-center h-100 w-100">
          <TextLink
            to={paths.USER_PROFILE.replace(":username", vendor.username)}
          >
            <VendorImg
              className="m-2"
              src={vendor.profile.avatar ?? avatar}
              alt="avatar"
            />
          </TextLink>
          <TextLink
            className="poppins-semibold-19"
            style={{
              textDecoration: "none",
              color: "black",
            }}
            to={paths.USER_PROFILE.replace(":username", vendor.username)}
          >
            <VendorNameContainer>
              <span style={{ flexShrink: 0 }}>
                {vendor.first_name} {vendor.last_name}{" "}
              </span>
              <span>
                {vendor.profile.subscription &&
                  vendor.profile.subscription.name.toLowerCase() !== "free" && (
                    <SmallIcon
                      className="ms-2"
                      src={verifiedUser}
                      alt="verified"
                    />
                  )}
              </span>
            </VendorNameContainer>
            <div className="poppins-light-12">
              <span className="poppins-light-11">@{vendor.username}</span>{" "}
              <span className="poppins-bold-14">{vendor.properties}</span>{" "}
              Propiedades
            </div>
          </TextLink>
        </div>
        <div className="flex-md-grow-1">
          {!vendor.myself && !vendor.myself_follow && (
            <ButtonRed
              className="poppins-semibold-12"
              onClick={() => {
                (async () => {
                  if (isAuthenticated) {
                    await follow(vendor.id);
                    // setVendors([
                    //   ...vendors.slice(0, index),
                    //   { ...vendor, myself_follow: true },
                    //   ...vendors.slice(index + 1),
                    // ]);
                    updateVendor({ ...vendor, myself_follow: true });
                  } else {
                    setShowAuthModal(true);
                  }
                })();
              }}
            >
              Seguir
            </ButtonRed>
          )}
          {!vendor.myself && vendor.myself_follow && (
            <ButtonPressedRed
              className="poppins-semibold-12"
              onClick={() => {
                (async () => {
                  setShowProfileShortModal(true);
                })();
              }}
            >
              Siguiendo
            </ButtonPressedRed>
          )}
        </div>
      </div>
      <ProfileShortModal
        show={showProfileShortModal}
        user={vendor}
        propertyNumber={vendor.properties}
        handleAccept={async () => {
          try {
            await unfollow(vendor.id);
            updateVendor({ ...vendor, myself_follow: false });
            setShowProfileShortModal(false);
          } catch (e) {
            console.error(e);
          }
        }}
        handleClose={() => {
          setShowProfileShortModal(false);
        }}
      />
      <AuthenticationModal
        active={showAuthModal}
        closeModal={() => {
          setShowAuthModal(false);
        }}
      />
    </div>
  );
};

export default VendorComponent;
