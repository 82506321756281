import { useCallback, useState } from "react";

export const replaceWord = (
  initialIndex: number,
  endIndex: number,
  text: string,
  insertedText: string
): string => {
  return (
    text.substring(0, initialIndex) +
    insertedText +
    text.substring(endIndex + 1)
  );
};

interface onCloseTriggerProps {
  wordStartIndex: number;
  wordEndIndex: number;
  word: string;
  keyValue: string;
  text: string;
}

interface useCustomInputProps {
  startTrigger: string;
  closeTrigger: string;
  onCloseTrigger?: (props: onCloseTriggerProps) => void;
}

export const useCustomInput = ({
  startTrigger,
  closeTrigger = "",
  onCloseTrigger = () => {},
}: useCustomInputProps) => {
  const [initialWordIndex, setInitialWordIndex] = useState(-1);
  const [endWordIndex, setEndWordIndex] = useState(-1);
  const [actualText, setActualText] = useState("");
  const setWordIndex = (initialIndex: number, endIndex: number) => {
    setInitialWordIndex(initialIndex);
    setEndWordIndex(endIndex);
  };

  const resetWord = useCallback(() => {
    setWordIndex(-1, -1);
    setActualText("");
  }, []);

  const onChange = useCallback(
    (event: any) => {
      setActualText(event.target.value || "");
      if (event.nativeEvent.data === startTrigger) {
        setInitialWordIndex(event.target.selectionStart);
        setEndWordIndex(event.target.selectionStart);
        return;
      }

      if (initialWordIndex === -1) {
        return;
      }

      if (event.nativeEvent.data === closeTrigger) {
        onCloseTrigger({
          word: event.target.value.substring(initialWordIndex, endWordIndex),
          wordStartIndex: initialWordIndex,
          wordEndIndex: endWordIndex,
          keyValue: event.nativeEvent.data,
          text: event.target.value,
        });
        resetWord();
        return;
      }

      setEndWordIndex(event.target.selectionEnd);
    },
    [
      closeTrigger,
      endWordIndex,
      initialWordIndex,
      onCloseTrigger,
      resetWord,
      startTrigger,
    ]
  );
  return {
    word:
      initialWordIndex !== -1 && endWordIndex !== -1
        ? actualText.substring(initialWordIndex, endWordIndex + 1)
        : "",
    resetWord,
    setWordIndex,
    onChange,
    isTriggered: initialWordIndex !== -1,
    initialWordIndex,
    endWordIndex,
  };
};

interface useCommentUserHashtagInputProps {
  onCloseTrigger: (props: onCloseTriggerProps) => void;
}

export const useCommentUserHashtagInput = ({
  onCloseTrigger,
}: useCommentUserHashtagInputProps) => {
  const {
    isTriggered: isTriggeredUser,
    onChange: onChangeUser,
    resetWord: resetUserWord,
    setWordIndex: setUserWordIndex,
    word: userWord,
    initialWordIndex: userInitialWordIndex,
    endWordIndex: userEndWordIndex,
  } = useCustomInput({
    startTrigger: "@",
    closeTrigger: " ",
    onCloseTrigger: onCloseTrigger,
  });

  const {
    isTriggered: isTriggeredHashtag,
    onChange: onChangeHashtag,
    resetWord: resetHashtagWord,
    setWordIndex: setHashtagWordIndex,
    word: hashtagWord,
    initialWordIndex: hashtagInitialWordIndex,
    endWordIndex: hashtagEndWordIndex,
  } = useCustomInput({
    startTrigger: "#",
    closeTrigger: " ",
    onCloseTrigger: onCloseTrigger,
  });

  const onChange = (event: any) => {
    onChangeUser(event);
    onChangeHashtag(event);
  };

  const resetWord = () => {
    resetUserWord();
    resetHashtagWord();
  };

  return {
    word: isTriggeredUser ? userWord : isTriggeredHashtag ? hashtagWord : "",
    resetWord,
    onChange,
    isTriggeredUser,
    isTriggeredHashtag,
    setUserWordIndex,
    setHashtagWordIndex,
    initialWordIndex: isTriggeredUser
      ? userInitialWordIndex
      : hashtagInitialWordIndex,
    endWordIndex: isTriggeredUser ? userEndWordIndex : hashtagEndWordIndex,
  };
};
