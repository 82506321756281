import API from "./endpoint";
import engine from "./engine";

interface PasswordChangeBody {
  old_password: string;
  new_password: string;
}

interface ChangeImage {
  file: any;
}

interface ChangeUserProfile {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  profile: {
    biography: string;
    country: string;
    province: string;
    language: string;
    phone: string;
    phone_code: string;
  };
}

interface WrapperRequest<Data> {
  result: Data;
  message: string;
}

export interface IpLocation {
  city: string;
  country_name: string;
  country_code: string;
  province: string;
  latitude: number;
  longitude: number;
}

const changePasswordEndpoint = async (data: PasswordChangeBody) => {
  await engine.put(
    `${process.env.REACT_APP_BOHIIO_API}${API.reset_password}`,
    data
  );
};

const getProfileInfo = async (username: string | null = null) => {
  const res = await engine.get(
    `${process.env.REACT_APP_BOHIIO_API}${API.user_profile}${
      username ? "?username=" + username : ""
    }`
  );
  return res.data;
};

const updateProfileInfo = async (data: ChangeUserProfile) => {
  const res = await engine.put(
    `${process.env.REACT_APP_BOHIIO_API}${API.user_profile}`,
    data
  );
  if (res.status !== 200) throw "Unable to change user information";
  return res.data;
};

const updateProfileImage = async (data: ChangeImage) => {
  var body = new FormData();
  body.append("avatar", data.file);
  const res = await engine.put(
    `${process.env.REACT_APP_BOHIIO_API}${API.change_user_avatar}`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (res.status !== 201) throw "Unable to change user avatar";
};

const getUserIpLocation = async () => {
  try {
    const res = await engine.get<WrapperRequest<IpLocation>>(
      `${process.env.REACT_APP_BOHIIO_API}${API.search_iplocation}`
    );
    return res.data;
  } catch (e) {
    throw new Error("Can't get user location with IP");
  }
};

export {
  changePasswordEndpoint,
  getProfileInfo,
  updateProfileInfo,
  updateProfileImage,
  getUserIpLocation,
};
