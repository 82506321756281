import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import countryCodes, { CountryProperty } from "country-codes-list";
import { Location as RouterLocation, History } from "history";
import { IpLocation, getUserIpLocation } from "../../api/profile";
import { AuthContext } from "../auth/AuthContext";
import {
  ALL_COUNTRIES_CODE,
  useLocalstorageContext,
} from "../localstorage/LocalstorageContext";
import { SearchContext, apiSearchPlaces } from "../search/SearchContext";
import { Place } from "../search/types";

const namePermission = "geolocation";

export enum LocationOrigin {
  Ip,
  Device,
}

export interface LocationState {
  loading: boolean;
  location: Location;
  origin: LocationOrigin;
}
export interface Location {
  latitude: number;
  longitude: number;
  distance: number;
  zoom: number;
}

const countries = countryCodes.customList(
  "countryCode" as CountryProperty,
  "{countryNameEn}"
) as { [key: string]: string };

async function getIpLocation(
  initialLocation: Location,
  ipLocation: IpLocation | undefined
): Promise<Location> {
  try {
    const result = await getUserIpLocation();
    return {
      latitude: result.result.latitude,
      longitude: result.result.longitude,
      distance: initialLocation.distance,
      zoom: initialLocation.zoom,
    };
  } catch (e) {
    return {
      latitude: ipLocation ? ipLocation.latitude : initialLocation.latitude,
      longitude: ipLocation ? ipLocation.longitude : initialLocation.longitude,
      distance: initialLocation.distance,
      zoom: initialLocation.zoom,
    };
  }
}

async function getUserGrantedLocation(initialValue: Location) {
  const promiseGetCurrentLocation = new Promise<Location>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (p) => {
        resolve({
          latitude: p.coords.latitude,
          longitude: p.coords.longitude,
          distance: initialValue.distance,
          zoom: initialLocation.zoom,
        });
      },
      (e) => reject(e),
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 1000,
      }
    );
  });

  try {
    return await promiseGetCurrentLocation;
  } catch (e) {
    return initialValue;
  }
}
const initialLocation: Location = {
  latitude: 0,
  longitude: 0,
  distance: 100,
  zoom: parseInt(process.env.REACT_APP_DEFAULT_MAP_ZOOM || "12"),
};

const initialState: LocationState = {
  loading: true,
  location: initialLocation,
  origin: LocationOrigin.Ip,
};

interface UserLocationResult {
  location: Location;
  origin: LocationOrigin;
}
async function getUserLocation(
  location: Location,
  ipLocation: IpLocation | undefined,
  country: string = ""
): Promise<UserLocationResult> {
  const perm = await navigator.permissions.query({ name: namePermission });
  if (perm.state === "denied" || !("geolocation" in navigator)) {
    return {
      location:
        country && country !== ALL_COUNTRIES_CODE
          ? await getCountryLocation(country)
          : await getIpLocation(location, ipLocation),
      origin: LocationOrigin.Ip,
    };
  } else {
    const result = await getUserGrantedLocation(location);
    if (result === location) {
      return {
        location:
          country && country !== ALL_COUNTRIES_CODE
            ? await getCountryLocation(country)
            : await getIpLocation(location, ipLocation),
        origin: LocationOrigin.Ip,
      };
    }
    return {
      location: result,
      origin: LocationOrigin.Device,
    };
  }
}

async function getCountryLocation(country: string): Promise<Location> {
  const places = (await apiSearchPlaces(countries[country])).data as Place[];
  if (places.length === 0) {
    throw new Error("no location country found");
  }

  return {
    distance: 1000,
    latitude: places[0].geometry.location.lat,
    longitude: places[0].geometry.location.lng,
    zoom: 5,
  };
}

export function updateUrlLocation(
  history: History,
  location: any,
  filter: URLSearchParams
) {
  location.search = `?${filter.toString()}`;
  history.push(location);
}

const LocationContext = createContext<LocationState>(initialState);

export const LocationProvider: FC = ({ children }) => {
  const idWatcherRef = useRef(0);
  const [storage] = useLocalstorageContext();
  const { userLocation } = useContext(AuthContext);
  const [state, setState] = useState<LocationState>({
    loading: true,
    location: initialLocation,
    origin: LocationOrigin.Ip,
  });

  useEffect(() => {
    if (storage.countryCode === ALL_COUNTRIES_CODE) {
      return;
    }
  }, [storage.countryCode]);

  const getLocation = useCallback(() => {
    idWatcherRef.current = setInterval(async () => {
      try {
        const result = await getUserLocation(
          initialLocation,
          userLocation,
          storage.countryCode
        );
        setState({
          location: result.location,
          loading: false,
          origin: result.origin,
        });
        if (result.origin === LocationOrigin.Ip) {
          clearInterval(idWatcherRef.current);
        }
      } catch (error) {
        setState((prevState) => ({
          location: prevState.location,
          loading: false,
          origin: prevState.origin,
        }));
      }
    }, 1000) as unknown as number;
  }, [storage.countryCode, userLocation]);

  useEffect(() => {
    getLocation();
    return () => {
      clearInterval(idWatcherRef.current);
    };
  }, []);

  return (
    <LocationContext.Provider value={state}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  return useContext(LocationContext);
};
