import React from "react";
import BaseModal, { BaseModalprops } from "./BaseModal";
interface BaseShortModalProps extends BaseModalprops {
  children: React.ReactNode;
}
export default function BaseShortModal(props: BaseShortModalProps) {
  return (
    <BaseModal {...props}>
      <div
        className="bg-white px-3 px-md-5 py-4"
        style={{
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "10px",
          maxWidth: "380px",
        }}
      >
        {props.children}
      </div>
    </BaseModal>
  );
}
