import axios from "axios";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import { isExpired, getNewToken } from "./api";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./i18n";
import "./index.scss";

const history = createBrowserHistory();
const root = document.getElementById("root");

axios.interceptors.request.use(
  async (req) => {
    if (req.method !== "get") {
      let token = window.sessionStorage.getItem("access_token");

      if (await isExpired()) {
        try {
          await getNewToken();
          token = window.sessionStorage.getItem("access_token");
        } catch {
          window.sessionStorage.removeItem("access_token");
        }
      }

      req.headers["Authorization"] = `Bearer ${token}`;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      window.sessionStorage.removeItem("access_token");
    }

    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

if (root) {
  // eslint-disable-next-line
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];

  if (path) {
    history.replace(path);
  }

  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
