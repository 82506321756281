import styled from "styled-components";

const SVG = styled.svg`
  .f5ef8bf7-ab25-4451-9b9f-d6eaa1a8b2f4 {
    fill-rule: evenodd;
  }
`;

export default function WhatsAppIcon() {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.16 80.65">
      <defs></defs>
      <g id="f5f959b9-f2c8-4378-a0a3-5ee8d055543f" data-name="Capa 2">
        <g id="b0a51384-9461-4ad8-8653-b7c3705e9e83" data-name="Capa 3">
          <path
            className="f5ef8bf7-ab25-4451-9b9f-d6eaa1a8b2f4"
            d="M79.13,30.93A40,40,0,0,0,40.09,0a49.26,49.26,0,0,0-7.65.8A40,40,0,0,0,5,59.12a2.51,2.51,0,0,1,.39.94,2.55,2.55,0,0,1,0,1C3.52,67.19,1.81,73.51.16,79.68c-.28,1.11-.28,1.11.75.8,6.49-1.75,12.92-3.41,19.39-5a1.61,1.61,0,0,1,1.44.1A40.07,40.07,0,0,0,79.57,46.83,40,40,0,0,0,79.13,30.93ZM45.72,72.8a15.92,15.92,0,0,1-4.62.4,34.51,34.51,0,0,1-18-4.6,2.09,2.09,0,0,0-1-.44,2.28,2.28,0,0,0-1.07.1c-3.62.89-7.15,1.84-10.74,2.76-.74.25-.81.12-.65-.64.94-3.32,1.84-6.69,2.89-10.12A2.57,2.57,0,0,0,12.6,59a2.34,2.34,0,0,0-.51-1.12,33,33,0,0,1,1.19-37.28,31.71,31.71,0,0,1,33.85-13A31.37,31.37,0,0,1,72.55,32.84,33,33,0,0,1,45.72,72.78Z"
          />
          <path
            className="f5ef8bf7-ab25-4451-9b9f-d6eaa1a8b2f4"
            d="M50.57,59.11a10.71,10.71,0,0,1-4.16-.58A40.6,40.6,0,0,1,24.75,42.64a22.7,22.7,0,0,1-4.64-9.42,10.93,10.93,0,0,1,3.6-10.71,6.29,6.29,0,0,1,2.58-1,6.44,6.44,0,0,1,2.76.18,5.63,5.63,0,0,1,.89,1.11c1.13,2.82,2.34,5.46,3.5,8.19a2.28,2.28,0,0,1,.09.86,2.23,2.23,0,0,1-.26.83A15.78,15.78,0,0,1,30.81,36a1.39,1.39,0,0,0-.41.46,1.52,1.52,0,0,0-.19.59,1.28,1.28,0,0,0,.06.61,1.44,1.44,0,0,0,.3.54A26.71,26.71,0,0,0,44.23,49.9a1.53,1.53,0,0,0,2.06-.47c1-1.16,2-2.45,3-3.71a1.75,1.75,0,0,1,2.23-.58c2,.94,3.89,1.84,5.77,2.7a12.54,12.54,0,0,0,1.34.62c2,1,2,1.13,1.75,3.25A8.41,8.41,0,0,1,53.24,59,7.26,7.26,0,0,1,50.57,59.11Z"
          />
        </g>
      </g>
    </SVG>
  );
}
