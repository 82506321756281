import styled from "styled-components";
import backIcon from "../images/back.svg";

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

interface IPropsNavigationBackButton {
  onClick: (arg1: any) => void;
}

export const NavigationBackButton = ({
  onClick,
}: IPropsNavigationBackButton) => {
  return (
    <div
      className="d-md-none"
      style={{
        // position: "fixed",
        // top: "65px",
        // left: 0,
        // zIndex: 1027,
        order: -1,
      }}
      onClick={onClick}
    >
      <Icon src={backIcon} alt="back" />
    </div>
  );
};
