import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { ProfileContext } from "../context/profile/ProfileContext";
import { PropertyContext } from "../context/property/PropertyContext";
import HistoryProperty from "./HistoryProperty";
import PropertyCertification from "./PropertyCertification";
import styled from "styled-components";

const HistoryCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding: 0 15px;

  @media screen and (min-width: 1024px) {
    padding: 0;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

//Has favorite property to filter the search for favorite properties
const HistoryBoard = (props: any) => {
  const { getUserFavorites } = useContext(PropertyContext);
  const [state, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { unsaveProperty } = useContext(PropertyContext);
  const { profile } = useContext(ProfileContext);
  const removeProperty = async (propertyId: any) => {
    if (profile?.id) {
      await unsaveProperty(profile?.id, propertyId);
    }
  };

  const [isPropertyCertificationActive, setIsPropertyCertificationActive] =
    useState(false);
  const [propertyCertificationInfo, setPropertyCertificationInfo] = useState({
    propertyId: -1,
    isPropertyOwner: true,
  });

  const openPropertyCertification = (
    propertyId: number,
    isPropertyOwner: boolean
  ) => {
    setPropertyCertificationInfo({
      propertyId: propertyId,
      isPropertyOwner: isPropertyOwner,
    });
    setIsPropertyCertificationActive(true);
  };

  const closePropertyCertification = () => {
    setIsPropertyCertificationActive(false);
  };

  const { ref, inView } = useInView();
  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      "userFavorites",
      ({ pageParam = 0 }) => getUserFavorites(pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length;
          return lastPage.length !== 0 ? nextPage : null;
        },
        staleTime: Infinity,
      }
    );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasNextPage]);

  const properties = useMemo(() => {
    let list: any[] = [];
    data?.pages.forEach((page) => {
      list = list.concat(
        ...page.map((savedProperty: any, index: number) => {
          return {
            savedProperty: savedProperty,
            removeProperty: async (deleted = false) => {
              if (!deleted) await removeProperty(savedProperty.property.id);
              page.splice(index, 1);
              forceUpdate();
            },
          };
        })
      );
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, data?.pages]);

  return (
    <>
      {isSuccess && (
        <HistoryCardContainer>
          {properties.map(({ savedProperty, removeProperty }, index) => {
            return (
              <HistoryProperty
                key={index}
                index={index}
                property={{
                  ...savedProperty.property,
                  is_property_saved: true,
                }}
                removeProperty={removeProperty}
                addedDate={savedProperty.created}
                openCertificationModal={openPropertyCertification}
              />
            );
          })}
          {properties.length > 0 && (
            <div className="loader" ref={ref}>
              {isFetchingNextPage && hasNextPage
                ? "Loading..."
                : "No search left"}
            </div>
          )}
        </HistoryCardContainer>
      )}

      {isSuccess && properties.length === 0 && (
        <div
          className="px-3 px-md-none"
          style={{ marginTop: "220px", width: "100%" }}
        >
          <div className="text-center">
            <p className="poppins-bold-20">
              Aún no tienes propiedades guardadas
            </p>
            <p className="poppins-medium-16">
              Marca la estrella de tu propiedad favorita
            </p>
          </div>
        </div>
      )}

      <PropertyCertification
        startingModalActive={isPropertyCertificationActive}
        closeStartingModal={closePropertyCertification}
        propertyCertificationInfo={propertyCertificationInfo}
      />
    </>
  );
};

export default HistoryBoard;
