import { forwardRef } from "react";
import CommentInput from "./CommentInput";
import { ReplyComment } from "./PropertyInfo";
import styled from "styled-components";

interface PublishCommentInputProps {
  replyComment: ReplyComment;
  commentText: string;
  setCommentText: (arg0: string) => void;
  onReplyComment: (arg0: ReplyComment) => void;
  isAuthenticated: boolean;
  handleAddComment: () => void;
  setShowLogin: any;
  children?: React.ReactNode;
}

const CommentInputContainer = styled.div<{
  hasComment: boolean;
  hasChildren: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ hasChildren }) =>
    hasChildren ? "rgb(249, 249, 249)" : "inherit"};
  border: ${({ hasChildren }) =>
    hasChildren ? "1px solid rgb(199, 200, 201)" : "inherit"};
  border-bottom: ${({ hasChildren }) => (hasChildren ? "none" : "inherit")};
  & .comment-container--input {
    display: flex;
  }
  & .base-comment-container {
    border-radius: 5px 5px 0 0;
    background-color: ${({ hasChildren }) =>
      hasChildren ? "rgb(249, 249, 249)" : "inherit"};
    border: 1px solid rgb(199, 200, 201);
    border-bottom: none;
    left: -1px;
    width: calc(100% + 2px);
    z-index: 10000;
  }
  & .tendency-list-container,
  & .user-list-container {
    max-height: 250px;
    overflow: auto;
  }
`;

interface getLastWordInfoProps {
  text: string;
  idDebounceTimeout: number;
  setSearchUserText: (text: string) => void;
  setSearchTendencyText: (text: string) => void;
}

export function getLastWordInfo({
  idDebounceTimeout,
  setSearchTendencyText,
  setSearchUserText,
  text,
}: getLastWordInfoProps) {
  const words = text.split(" ");
  const lastWord = words[words.length - 1];
  let newDebounceId = idDebounceTimeout;
  if (lastWord.length > 1 && "@#".includes(lastWord[0])) {
    clearTimeout(idDebounceTimeout);
    newDebounceId = setTimeout(() => {
      if (lastWord && lastWord[0] === "@") {
        setSearchUserText(lastWord.replace("@", ""));
      } else if (lastWord && lastWord[0] === "#") {
        setSearchTendencyText(lastWord.replace("#", ""));
      }
    }, 300) as unknown as number;
  } else {
    setSearchUserText("");
    setSearchTendencyText("");
  }
  return newDebounceId;
}

interface replaceLastWordProps {
  text: string;
  newWord: string;
}
export function replaceLastWord({ text, newWord }: replaceLastWordProps) {
  const words = text.split(" ");
  words[words.length - 1] = newWord;
  return words.join(" ");
}

const PublishCommentInput = forwardRef(
  (
    {
      replyComment,
      commentText,
      setCommentText,
      onReplyComment,
      handleAddComment,
      isAuthenticated,
      setShowLogin,
      children,
    }: PublishCommentInputProps,
    ref: any
  ) => {
    const hasChildren = replyComment.comment || children;
    return (
      <div className="flex-grow-1">
        <CommentInputContainer
          hasChildren={!!hasChildren}
          hasComment={!!children}
        >
          <CommentInput
            className="form-control"
            placeholder={"Comenta esta propiedad"}
            commentText={commentText}
            setCommentText={setCommentText}
            answerComment={replyComment.comment}
            ref={ref}
            onRemoveAnswer={() =>
              onReplyComment({ parent: -1, comment: undefined })
            }
            children={children}
            inputRight={
              <button
                className="poppins-medium-14 clickable unset-button "
                style={{
                  color: commentText === "" ? "#F7D0CC" : "#D2190E",
                  alignSelf: "end",
                  paddingBottom: "5px",
                  paddingRight: hasChildren ? "5px" : "inherit",
                }}
                disabled={commentText === ""}
                onClick={() => {
                  if (isAuthenticated) handleAddComment();
                  else setShowLogin(true);
                }}
              >
                Publicar
              </button>
            }
          />
        </CommentInputContainer>
        <hr style={{ margin: "0 0 10px 0" }} />
      </div>
    );
  }
);

export default PublishCommentInput;
