import ProfileChangePassword from "./ProfileChangePassword";
import ProfileForgotPassword from "./ProfileForgotPassword";

const PASSWORD_CHANGE = 10;
const PASSWORD_RESET = 11;

const ProfileSecurityConfiguration = (props: any) => {
  const toPasswordReset = () => {
    props.setScreen(PASSWORD_RESET);
  };
  return (
    <>
      {props.screen === PASSWORD_CHANGE && (
        <ProfileChangePassword
          first_name={props.first_name}
          last_name={props.last_name}
          username={props.username}
          avatar={props.avatar}
          resetPassword={toPasswordReset}
        />
      )}
      {props.screen === PASSWORD_RESET && (
        <ProfileForgotPassword
          first_name={props.first_name}
          last_name={props.last_name}
          username={props.username}
          avatar={props.avatar}
        />
      )}
    </>
  );
};

export default ProfileSecurityConfiguration;
