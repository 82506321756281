import { useEffect, useRef, useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";
import styled from "styled-components";
import share from "../images/share.svg";
import facebookIcon from "../images/facebook-share.svg";
import twitterIcon from "../images/twitter-share.svg";
import linkedinIcon from "../images/linkedin-share.svg";
import pinterestIcon from "../images/pinterest-share.svg";
import whatsappIcon from "../images/whatsapp-share.svg";
import telegramIcon from "../images/telegram-share.svg";
import emailIcon from "../images/email-share.svg";

const Icon = styled.img`
  height: 25px;
  width: 25px;
  border-radius: 50%;
`;

interface SharePropertyButtonProps {
  propertyUrl: string;
}

function useOutsideAlerter(ref: any, closeFunction: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeFunction();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

const SharePropertyButton = ({ propertyUrl }: SharePropertyButtonProps) => {
  const [isActive, setIsActive] = useState(false);
  const modalSpace = useRef(null);
  useOutsideAlerter(modalSpace, () => {
    setIsActive(false);
  });
  return (
    <>
      <div
        className="mx-1 clickable d-flex align-items-center justify-content-end"
        style={{
          position: "relative",
        }}
      >
        <Icon src={share} alt="share" onClick={() => setIsActive(true)} />
        <div
          style={{
            position: "absolute",
            top: "25px",
            zIndex: 1,
          }}
        >
          {isActive && (
            <>
              <div
                className="d-flex g-3 p-2"
                ref={modalSpace}
                style={{
                  background: "white",
                  boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.3)",
                  borderRadius: "5px",
                }}
              >
                <FacebookShareButton className="me-2" url={propertyUrl}>
                  <Icon src={facebookIcon}></Icon>
                </FacebookShareButton>
                <TwitterShareButton
                  className="me-2"
                  url={propertyUrl}
                  title="Echa un vistazo a esta propiedad en bohiio"
                >
                  <Icon src={twitterIcon}></Icon>
                </TwitterShareButton>
                <WhatsappShareButton
                  className="me-2"
                  url={propertyUrl}
                  title="Echa un vistazo a esta propiedad en bohiio"
                >
                  <Icon src={whatsappIcon}></Icon>
                </WhatsappShareButton>
                <TelegramShareButton
                  className="me-2"
                  url={propertyUrl}
                  title="Echa un vistazo a esta propiedad en bohiio"
                >
                  <Icon src={telegramIcon}></Icon>
                </TelegramShareButton>
                <LinkedinShareButton
                  className="me-2"
                  url={propertyUrl}
                  title="Echa un vistazo a esta propiedad en bohiio"
                  summary="¿Qué te parece esta propiedad?"
                >
                  <Icon src={linkedinIcon}></Icon>
                </LinkedinShareButton>
                <PinterestShareButton
                  className="me-2"
                  url={propertyUrl}
                  media={"no image"}
                  description="Echa un vistazo a esta propiedad en bohiio"
                >
                  <Icon src={pinterestIcon}></Icon>
                </PinterestShareButton>
                <EmailShareButton
                  url={propertyUrl}
                  subject="Echa un vistazo a esta propiedad en bohiio"
                  body="¿Qué te parece esta propiedad?"
                >
                  <Icon src={emailIcon}></Icon>
                </EmailShareButton>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SharePropertyButton;
