import { useContext } from "react";
import { useQuery } from "react-query";
import { SearchContext } from "../context/search/SearchContext";
import avatar from "../images/avatar.svg";
import styled from "styled-components";

interface UserListProps {
  search?: string;
  onClick: (username: string) => void;
}

interface UserOptionProps
  extends React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  username: string;
  imageurl: string;
  first_name: string;
  last_name: string;
}

const UserOptionContainer = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  gap: 5px;
  padding: 5px;
  &:hover {
    background-color: #e9e9e9;
  }
`;

const UserOption = (props: UserOptionProps) => {
  return (
    <span {...props}>
      <UserOptionContainer className="clickable">
        <img
          src={props.imageurl}
          alt={`@${props.username} icon`}
          style={{ width: "25px", height: "25px", borderRadius: "100%" }}
        />
        <div style={{ width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>@{props.username}</span>{" "}
          <span>
            | {props.first_name} {props.last_name}
          </span>
        </div>
      </UserOptionContainer>
    </span>
  );
};

const UserList = ({ search, onClick }: UserListProps) => {
  const { searchUsers } = useContext(SearchContext);
  const {
    data: users,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    initialData: [],
    queryKey: `user-search-${search}`,
    queryFn: () => searchUsers(search || ""),
  });
  return (
    <div style={{ width: "100%" }}>
      {isLoading ? <div style={{ padding: "5px" }}>Buscando...</div> : null}
      {!isLoading && !isError && search && users.length === 0 ? (
        <div style={{ padding: "5px" }}>
          No hemos encontrado usuarios relacionados
        </div>
      ) : null}
      {isError ? (
        <div style={{ padding: "5px" }}>
          Ha ocurrido algo inesperado, haz clic{" "}
          <span
            style={{ color: "blue" }}
            className="clickable"
            onClick={() => refetch()}
          >
            aqui
          </span>{" "}
          para reintentar
        </div>
      ) : null}
      <ul
        className="user-list-container"
        style={{ width: "100%", paddingLeft: 0, marginBottom: 0 }}
      >
        {users.map(({ username, profile, last_name, first_name }: any) => (
          <UserOption
            key={username}
            onClick={() => onClick(username)}
            username={username}
            imageurl={profile.avatar ? profile.avatar : avatar}
            first_name={first_name}
            last_name={last_name}
          />
        ))}
      </ul>
    </div>
  );
};

export default UserList;
