import styled from "styled-components";
import logo from "../images/logo-bw.svg";
import leftIcon from "../images/left.svg";
import rightIcon from "../images/right.svg";
import share from "../images/share-bw.svg";
import heart from "../images/heart-bw.svg";
import crossIcon from "../images/cross-white.svg";
import { useCallback, useEffect } from "react";

const ButtonRed = styled.button`
  border: 1px #d2190e solid;
  background: #d2190e;
  color: white;
  padding 0.25rem 2rem;
  border-radius: 5px;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 5px;
`;

const WhiteCircle = styled.img`
  @media screen and (min-width: 992px) {
    height: 40px;
    width: 40px;
  }
  color: grey;
  border: 1px white solid;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  text-align: center;
  font: 700 2rem/2.4rem Poppins; //poppins-bold-32
  cursor: pointer;
  &:hover {
    background: white;
  }
`;

interface PropertyPhotosProps {
  index: number;
  photos: any[];
  handleClose: () => void;
  setPhotoIndex: (arg0: number) => void;
  showNextPhotoIcon: boolean;
  showPreviousPhotoIcon: boolean;
}

const PropertyPhotos = (props: PropertyPhotosProps) => {
  const updatePropertyPhoto = useCallback(
    (index: any) => {
      props.setPhotoIndex(
        Math.max(0, Math.min(props.photos.length - 1, index))
      );
    },
    [props]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "ArrowLeft") updatePropertyPhoto(props.index - 1);
      if (event.key === "ArrowRight") updatePropertyPhoto(props.index + 1);
    },
    [props.index, updatePropertyPhoto]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return (
    <div
      className="d-flex flex-column justify-content-between text-white"
      style={{ height: "100%" }}
    >
      <div className="d-flex justify-content-between mx-3 mt-3 align-items-center">
        <div className="col-7">
          <img src={logo} alt="logo" style={{ width: "120px" }} />
        </div>
        <div className="col-5 d-flex justify-content-between align-items-center">
          <span>{/* <ButtonRed>Mensaje al vendedor </ButtonRed> */}</span>
          <span>
            {/* <Icon className="clickable" src={heart} alt="like" />
            Me gusta */}
          </span>
          <span>
            {/* <Icon className="clickable" src={share} alt="share" />
            Compartir */}
          </span>
          <Icon
            className="clickable"
            src={crossIcon}
            onClick={props.handleClose}
          />
        </div>
      </div>
      <div className="my-auto overflow-y-auto">
        <div className="d-flex justify-content-around align-items-center">
          <WhiteCircle
            src={leftIcon}
            onClick={() => {
              updatePropertyPhoto(props.index - 1);
            }}
            style={{
              opacity: props.showPreviousPhotoIcon ? "1" : "0",
            }}
          />
          <div style={{ width: "100%", height: "85vh" }}>
            {props.photos[props.index].type === "VIDEO" ? (
              <video
                muted
                width="100%"
                height="100%"
                style={{ objectFit: "contain" }}
                controls
              >
                <source
                  src={props.photos[props.index].filename}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                className="h-100 w-100"
                src={props.photos[props.index].filename}
                alt="property"
                style={{ objectFit: "contain" }}
              />
            )}
          </div>
          <WhiteCircle
            src={rightIcon}
            onClick={() => {
              updatePropertyPhoto(props.index + 1);
            }}
            style={{
              opacity: props.showNextPhotoIcon ? "1" : "0",
            }}
          />
        </div>
        <div className="text-center mt-2">
          {props.index + 1} de {props.photos.length}
        </div>
      </div>
    </div>
  );
};

export default PropertyPhotos;
