import styled from "styled-components";
import avatar from "../images/avatar.svg";
import heart from "../images/heart-bw-fill.svg";
import message from "../images/comment-bw.svg";
import { useTranslation } from "react-i18next";
import { useContext, useRef } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import { getPriceNotation } from "../utils";
import { Property, PropertyDetail } from "../context/property/types";
import MediaViewer from "./MediaViewer";
import { useLocation, useHistory } from "react-router-dom";
import paths from "../routes/paths.json";
import CertifiedPropertyStrip from "./CertifiedPropertyStrip";
import PropertyTag from "./PropertyTag";
import parkingIcon from "../images/parking.svg";
import bathroomIcon from "../images/bathroom.svg";
import bedroomIcon from "../images/bedroom.svg";

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const AvatarIcon = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

const SmallIcon = styled.img`
  width: 15px;
  height: 15px;
  margin: 0 2px;
`;

const TopRightTriangle = styled.div`
  width: 0;
  height: 0;
  float: right;
  margin-left: -50px;
  border-top: 50px solid white;
  border-left: 50px solid transparent;
`;

const RedConversationBox = styled.div`
  background: #d2190e;
  position: relative;
  padding: 2px 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 10px solid #d2190e;
    width: 0px;
    height: 0px;
    left: calc(50% - 10px);
    background: rgba(0, 0, 0, 0);
    top: calc(-10px - 0.5rem);
    z-index: 1001;
  }
`;

interface PropertyCardProps {
  property: Property;
  imgSize?: number;
  imgSizeUnit?: "%" | "px";
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  openPropertyCertification: (
    propertyId: number,
    isPropertyOwner: boolean
  ) => void;
}

const PropertyCard = (props: PropertyCardProps) => {
  useContext(LanguageContext);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const mediaRef = useRef<HTMLVideoElement>(null);
  const openPropertyInfoModal = () => {
    mediaRef.current?.pause();
    goToProperty();
  };

  const goToProperty = () => {
    history.push(`/property/${props.property.id}`, {
      background: location,
      property: props.property,
    });
  };

  const renderDetails = (details: PropertyDetail) => {
    let detailsList = [];
    if (details.bedroom) {
      detailsList.push(
        `${props.property.detail.bedroom} ${t(`propertyForm.rooms`)}`
      );
    }
    if (Number(details.bathroom)) {
      detailsList.push(
        `${Number(props.property.detail.bathroom)} ${t(
          `propertyForm.bathrooms`
        )}`
      );
    }
    if (details.parkingSlot) {
      detailsList.push(
        `${props.property.detail.parkingSlot} ${t(`propertyForm.parkings`)}`
      );
    }

    return detailsList.join(" - ");
  };

  return (
    <div className="">
      <div className="d-flex flex-column mb-1" style={{ position: "relative" }}>
        <div style={{ position: "relative" }}>
          <div className="strip">
            <CertifiedPropertyStrip
              property={props.property}
              marginSize="small"
              openPropertyCertification={props.openPropertyCertification}
            />
          </div>
          <div className="content" onClick={openPropertyInfoModal}>
            <MediaViewer
              media={props.property.media?.[0]}
              height={props.imgSize}
              unit={props.imgSizeUnit}
              videoRef={mediaRef}
              objectFit={props.objectFit}
              alt="property"
              borderRadius="4px"
            />
            <div
              style={{
                // marginTop: "-1.5rem",
                display: "flex",
                zIndex: 1001,
                position: "absolute",
                bottom: "5px",
                left: "5px",
              }}
            >
              <RedConversationBox
                className="work-sans-medium-14 text-white"
                style={{
                  display:
                    !props.property.comment_count && !props.property.likes
                      ? "none"
                      : "",
                }}
              >
                {props.property.comment_count ? (
                  <span className="me-1 d-flex align-items-center">
                    <SmallIcon src={message} />
                    <span>{props.property.comment_count}</span>
                  </span>
                ) : null}
                {props.property.likes ? (
                  <span className="d-flex align-items-center">
                    <SmallIcon src={heart} />
                    <span>{props.property.likes}</span>
                  </span>
                ) : null}
              </RedConversationBox>
            </div>
            <div
              className="d-flex align-self-end text-white me-3 bebas-neue-regular-32"
              style={{
                // marginTop: "-1.5rem",
                textShadow: "0px 3px 6px #000000",
                // float: "right",
                position: "absolute",
                bottom: "-10px",
                right: "0",
                zIndex: 1001,
              }}
            >
              {props.property.typePublication !== "rent" &&
                `${props.property.detail.sellCurrency} $${getPriceNotation(
                  props.property.detail.sellPrice
                )}`}
              {props.property.typePublication === "rent" &&
                `${props.property.detail.rentCurrency} $${getPriceNotation(
                  props.property.detail.rentPrice
                )}`}
            </div>
          </div>
        </div>
        <div className="property-card-content d-flex align-items-center justify-content-between mt-1 mb-2">
          <div className="me-2">
            <div className="poppins-bold-14 relative mb-1 d-flex">
              <PropertyTag
                type={props.property.typePublication}
                text={t(
                  `propertyForm.publicationType.${props.property.typePublication}`
                )}
                size="no"
              />
            </div>
            <div></div>
            <div className="poppins-regular-12">
              {props.property.detail.bedroom > 0 ? (
                <span style={{ marginRight: "10px" }}>
                  <Icon
                    src={bedroomIcon}
                    alt=""
                    style={{ marginRight: "5px" }}
                  />
                  {Number(props.property.detail.bedroom)}{" "}
                  {t(`propertyForm.roomsAbr`)}
                </span>
              ) : null}
              {Number(props.property.detail.bathroom) > 0 ? (
                <span style={{ marginRight: "10px" }}>
                  <Icon
                    src={bathroomIcon}
                    alt=""
                    style={{ marginRight: "5px" }}
                  />
                  {Number(props.property.detail.bathroom)}{" "}
                  {t(`propertyForm.bathroomsAbr`)}
                </span>
              ) : null}
              {props.property.detail.parkingSlot > 0 ? (
                <span style={{ marginRight: "10px" }}>
                  <Icon
                    src={parkingIcon}
                    alt=""
                    style={{ marginRight: "5px" }}
                  />
                  {Number(props.property.detail.parkingSlot)}{" "}
                  {t(`propertyForm.parkingsAbr`)}
                </span>
              ) : null}
            </div>
            <div className="poppins-semibold-12">
              {`${props.property.location.sector}, 
              ${props.property.location.province}, 
              ${props.property.location.country}`}
            </div>
          </div>
          <AvatarIcon
            className="clickable"
            src={props.property.owner.profile.avatar ?? avatar}
            alt="author"
            onClick={() => {
              history.push(
                paths.USER_PROFILE.replace(
                  ":username",
                  props.property.owner.username
                )
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
