import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import paths from "../routes/paths.json";
import ErrorText from "./ErrorText";
import logo from "../images/logo.svg";
import eye from "../images/eye.svg";
import eyeSlash from "../images/eye-slash.svg";
import { FormEvent, useContext, useState } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import { AuthContext } from "../context/auth/AuthContext";
import { FloatingInput } from "../styles/common";

const Button = styled.button`
  background: #d2190e;
  color: white;
  width: 100%;
  font-weight: bold;
  height: 30px;
  border: 0 solid transparent;
  border-radius: 5px;
  &:hover {
  }
`;

const LoginModalForm = (props: any) => {
  useContext(LanguageContext);
  const { handleAuthentication, login } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [passwordVisibilty, setPasswordVisibility] = useState(false);
  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibilty);
  };
  return (
    <Formik
      initialValues={{ user: "", password: "" }}
      validationSchema={Yup.object({
        user: Yup.string().required("Requerido"),
        password: Yup.string().required("Requerido"),
      })}
      onSubmit={async ({ user, password }, FormikBag) => {
        try {
          await login({ username: user, password });
          handleAuthentication?.();
          props.closeModal();
          history.push(props.nextPage);
        } catch (e) {
          FormikBag.setFieldError(
            "password",
            "Usuario o contraseña no es correcta. Compruébala."
          );
        }

        FormikBag.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <div>
          <div className="text-center py-5">
            <img src={logo} alt="logo" className="w-50" />
          </div>
          <Form>
            <FloatingInput className="form-floating mb-2">
              <Field
                id="user"
                name="user"
                type="text"
                placeholder={t("login.userInput")}
                className="form-control"
                onChange={(e: FormEvent<HTMLInputElement>) => {
                  formikProps.setFieldValue(
                    "user",
                    e.currentTarget.value.toLowerCase()
                  );
                }}
              />
              <label htmlFor="user">{t("login.userInput")}</label>
              <ErrorMessage name="user">
                {(msg) => <ErrorText>{msg}</ErrorText>}
              </ErrorMessage>
            </FloatingInput>
            <FloatingInput className="input-group form-floating">
              <Field
                id="password"
                name="password"
                type={passwordVisibilty ? "text" : "password"}
                placeholder={t("login.password")}
                className="form-control border-end-0"
                aria-describedby="password-icon"
              />
              <label htmlFor="password" style={{ zIndex: 5 }}>
                {t("login.password")}
              </label>
              <span
                id="password-icon"
                className="input-group-text bg-transparent border-start-0"
                onClick={changePasswordVisibility}
              >
                <img
                  src={passwordVisibilty ? eyeSlash : eye}
                  alt="password visibility"
                  className="opacity-50"
                  style={{ height: "25px", width: "25px" }}
                />
              </span>
            </FloatingInput>
            <ErrorMessage name="password">
              {(msg) => <ErrorText>{msg}</ErrorText>}
            </ErrorMessage>

            <div className="d-flex justify-content-center mt-3">
              <Button type="submit" className="">
                <Trans i18nKey="login.submit" />
              </Button>
            </div>
          </Form>
          <p
            className="poppins-bold-14 clickable text-center"
            style={{
              marginTop: "30px",
              marginBottom: "70px",
              textDecoration: "underline",
            }}
            onClick={props.toForgotPassword}
          >
            <Trans i18nKey="login.reset_password" />
          </p>
          <p className="poppins-light-14 text-center mb-0">
            <Trans i18nKey="login.signUp">
              ¿No tienes una cuenta?
              <span
                className="poppins-semibold-14 text-black clickable"
                onClick={props.toRegister}
                style={{ textDecoration: "underline" }}
              >
                Regístrate
              </span>
            </Trans>
          </p>
        </div>
      )}
    </Formik>
  );
};

LoginModalForm.defaultProps = {
  nextPage: paths.HOME,
};

export default LoginModalForm;
