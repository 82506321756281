import { createContext, FC } from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext<any>([]);

export const LanguageProvider: FC = ({ children }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: "es" | "en") => {
    i18n.changeLanguage(language);
  };

  return (
    <LanguageContext.Provider value={[i18n.language, changeLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};
