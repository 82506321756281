import { useState, useContext, useMemo } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import { Trans, useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import switchIcon from "../images/switch.svg";
import switchActiveIcon from "../images/switch-active.svg";
import pyramid from "../images/reverse-pyramid.svg";
import styled from "styled-components";
import { FloatingInput } from "../styles/common";
import { SetFilterProp } from "../pages/map/Map";

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
`;

const ButtonRed = styled.button`
  border: 1px #D9D9D9 solid;
  background: #D9D9D9;
  color: black;
  margin: 20px 0 0 0;
  padding 0.5rem 2rem;
  border-radius: 5px;
  width: 100%;
  transition: .2s;
  &:hover {
    background: #707070;
    border: 1px #707070 solid;
    color: white;
  }
`;

export interface FilterExtraState {
  [key: string]: boolean;
  isFurnished: boolean;
  hasStudyRoom: boolean;
  hasElevator: boolean;
  hasFamilyRoom: boolean;
  hasPool: boolean;
  hasGazebo: boolean;
  hasBalcony: boolean;
  hasLobby: boolean;
  hasPowerPlant: boolean;
  hasBackyard: boolean;
  hasSocialArea: boolean;
  hasJacuzzi: boolean;
  hasGym: boolean;
  hasPicuzzi: boolean;
  hasPlayArea: boolean;
  hasSauna: boolean;
  hasTank: boolean;
  hasWaterWell: boolean;
  hasDressingRoom: boolean;
  hasTerrace: boolean;
  hasWalInCloset: boolean;
  hasParking: boolean;
  hasServiceRoom: boolean;
}

export interface FilterState {
  [key: string]: any;
  publicationType?: string;
  propertyType?: string;
  currentUse?: string;
  condition?: string;
  landAreaStart?: number;
  landAreaEnd?: number;
  constructionAreaStart?: number;
  constructionAreaEnd?: number;
  roomsStart?: number;
  roomsEnd?: number;
  bathroomsStart?: number;
  bathroomsEnd?: number;
  priceStart?: number;
  priceEnd?: number;
  extra: FilterExtraState;
}

export const initialExtraFilterState: FilterExtraState = {
  isFurnished: false,
  hasStudyRoom: false,
  hasElevator: false,
  hasFamilyRoom: false,
  hasPool: false,
  hasGazebo: false,
  hasBalcony: false,
  hasLobby: false,
  hasPowerPlant: false,
  hasBackyard: false,
  hasSocialArea: false,
  hasJacuzzi: false,
  hasGym: false,
  hasPicuzzi: false,
  hasPlayArea: false,
  hasSauna: false,
  hasTank: false,
  hasWaterWell: false,
  hasDressingRoom: false,
  hasTerrace: false,
  hasWalInCloset: false,
  hasParking: false,
  hasServiceRoom: false,
};

interface FilterProps {
  onClickSearchButton: (filters: SetFilterProp[]) => void;
  initialState: FilterState;
}

const Filter = ({ onClickSearchButton, initialState }: FilterProps) => {
  const formInitialState = useMemo(
    () => ({
      publicationType: initialState.publicationType,
      propertyType: initialState.propertyType,
      currentUse: initialState.currentUse,
      condition: initialState.condition,
      landAreaStart: initialState.landAreaStart,
      landAreaEnd: initialState.landAreaEnd,
      constructionAreaStart: initialState.constructionAreaStart,
      constructionAreaEnd: initialState.constructionAreaEnd,
      roomsStart: initialState.roomsStart,
      roomsEnd: initialState.roomsEnd,
      bathroomsStart: initialState.bathroomsStart,
      bathroomsEnd: initialState.bathroomsEnd,
      priceStart: initialState.priceStart,
      priceEnd: initialState.priceEnd,
    }),
    [
      initialState.bathroomsEnd,
      initialState.bathroomsStart,
      initialState.condition,
      initialState.constructionAreaEnd,
      initialState.constructionAreaStart,
      initialState.currentUse,
      initialState.landAreaEnd,
      initialState.landAreaStart,
      initialState.priceEnd,
      initialState.priceStart,
      initialState.propertyType,
      initialState.publicationType,
      initialState.roomsEnd,
      initialState.roomsStart,
    ]
  );
  const extras = [
    "isFurnished",
    "hasStudyRoom",
    "hasElevator",
    "hasFamilyRoom",
    "hasPool",
    "hasGazebo",
    "hasBalcony",
    "hasLobby",
    "hasPowerPlant",
    "hasBackyard",
    "hasSocialArea",
    "hasJacuzzi",
    "hasGym",
    "hasPicuzzi",
    "hasPlayArea",
    "hasSauna",
    "hasTank",
    "hasWaterWell",
    "hasDressingRoom",
    "hasTerrace",
    "hasWalInCloset",
    "hasParking",
    "hasServiceRoom",
  ];

  const publicationTypes = ["rent", "sell", "both"];

  const propertyTypes = [
    "apartment",
    "house",
    "villa",
    "pieceOfLand",
    "farm",
    "industrialShip",
    "office",
    "building",
    "penthouse",
    "business",
    "businessPremise",
  ];

  const propertyUses = ["residential", "commercial", "mixed", "none"];

  const propertyConditions = [
    "new",
    "secondHand",
    "remodeled",
    "toRemodel",
    "inConstruction",
    "barter",
    "blueprint",
    "escrow",
    "none",
  ];
  const [filterExtraState, setFilterExtraState] = useState(initialState.extra);
  useContext(LanguageContext);
  const { t } = useTranslation();
  const [moreFilters, setMoreFilters] = useState(false);
  const showMoreFilters = () => {
    setMoreFilters(true);
  };
  const hideMoreFilters = () => {
    setMoreFilters(false);
  };

  return (
    <>
      <div className="h-100">
        <Formik
          initialValues={formInitialState}
          validationSchema={Yup.object({
            publicationType: Yup.string(),
            propertyType: Yup.string(),
            currentUse: Yup.string(),
            condition: Yup.string(),
          })}
          onSubmit={async (values, FormikBag) => {
            let filters: SetFilterProp[] = [];

            if (values.publicationType) {
              filters.push({
                name: `typePublication`,
                value: values.publicationType,
              });
            }
            if (values.propertyType) {
              filters.push({
                name: `propertyType`,
                value: values.propertyType,
              });
            }
            if (values.currentUse) {
              filters.push({ name: `use`, value: values.currentUse });
            }
            if (values.condition) {
              filters.push({ name: `condition`, value: values.condition });
            }
            if (values.landAreaStart) {
              filters.push({
                name: `sinceTerrain`,
                value: values.landAreaStart.toString(),
              });
            }
            if (values.landAreaEnd) {
              filters.push({
                name: `untilTerrain`,
                value: values.landAreaEnd.toString(),
              });
            }
            if (values.constructionAreaStart) {
              filters.push({
                name: `sinceConstruction`,
                value: values.constructionAreaStart.toString(),
              });
            }
            if (values.constructionAreaEnd) {
              filters.push({
                name: `untilConstruction`,
                value: values.constructionAreaEnd.toString(),
              });
            }
            if (values.roomsStart) {
              filters.push({
                name: `sinceBedroom`,
                value: values.roomsStart.toString(),
              });
            }
            if (values.roomsEnd) {
              filters.push({
                name: `untilBedroom`,
                value: values.roomsEnd.toString(),
              });
            }
            if (values.bathroomsStart) {
              filters.push({
                name: `sinceBathroom`,
                value: values.bathroomsStart.toString(),
              });
            }
            if (values.bathroomsEnd) {
              filters.push({
                name: `untilBathroom`,
                value: values.bathroomsEnd.toString(),
              });
            }
            if (values.priceStart) {
              filters.push({
                name: `sincePrice`,
                value: values.priceStart.toString(),
              });
            }
            if (values.priceEnd) {
              filters.push({
                name: `untilPrice`,
                value: values.priceEnd.toString(),
              });
            }

            let attributes: string[] = [];
            Object.keys(filterExtraState).forEach((item) => {
              if (filterExtraState[item]) {
                attributes.push(item);
              }
            });

            if (attributes.length > 0) {
              filters.push({ name: `attributes`, value: attributes.join(",") });
            }

            onClickSearchButton(filters);
          }}
        >
          {({ values, resetForm, setValues, submitForm }) => (
            <Form className="poppins-medium-12 h-100 d-flex flex-column">
              <div
                className="row mw-100 g-2 align-items-center overflow-auto"
                style={{ height: "100%" }}
              >
                <div className="col-6 poppins-medium-16 d-none d-md-inline">
                  <Icon src={pyramid} alt="filter" />
                  Filtros
                </div>
                <div
                  className="d-none d-md-inline col-6 poppins-medium-16 text-start text-md-end clickable"
                  style={{ opacity: 0.7 }}
                  onClick={() => {
                    resetForm({ values: formInitialState });
                    setValues(formInitialState);
                    setFilterExtraState(initialExtraFilterState);
                    submitForm();
                  }}
                >
                  Limpiar
                </div>
                <div className="col-12 mb-1">
                  <FloatingInput className="form-floating">
                    <Field
                      id="publicationType"
                      name="publicationType"
                      as="select"
                      className="form-select"
                    >
                      <option value="">Todos</option>
                      {publicationTypes.map((publicationType) => {
                        return (
                          <option value={publicationType} key={publicationType}>
                            {t(
                              `propertyForm.publicationType.${publicationType}`
                            )}
                          </option>
                        );
                      })}
                    </Field>
                    <label htmlFor="publicationType">Tipo de publicación</label>
                  </FloatingInput>
                </div>
                <div className="col-12 mb-1">
                  <FloatingInput className="form-floating">
                    <Field
                      ide="propertyType"
                      name="propertyType"
                      as="select"
                      className="form-select"
                    >
                      <option value="">Todos</option>
                      {propertyTypes.map((propertyType) => {
                        return (
                          <option value={propertyType} key={propertyType}>
                            {t(`propertyForm.propertyType.${propertyType}`)}
                          </option>
                        );
                      })}
                    </Field>
                    <label htmlFor="propertyType">Tipo de propiedad</label>
                  </FloatingInput>
                </div>
                <div className="col-12 mb-1">
                  <FloatingInput className="form-floating">
                    <Field
                      id="currentUse"
                      name="currentUse"
                      as="select"
                      className="form-select"
                    >
                      <option value="">Todos</option>
                      {propertyUses.map((propertyUse) => {
                        return (
                          <option value={propertyUse} key={propertyUse}>
                            {t(`propertyForm.currentUse.${propertyUse}`)}
                          </option>
                        );
                      })}
                    </Field>
                    <label htmlFor="currentUse">Uso actual</label>
                  </FloatingInput>
                </div>
                <div className="col-12 mb-1">
                  <FloatingInput className="form-floating">
                    <Field
                      id="condition"
                      name="condition"
                      as="select"
                      className="form-select"
                    >
                      <option value="">Todos</option>
                      {propertyConditions.map((propertyCondition) => {
                        return (
                          <option
                            value={propertyCondition}
                            key={propertyCondition}
                          >
                            {t(`propertyForm.condition.${propertyCondition}`)}
                          </option>
                        );
                      })}
                    </Field>
                    <label htmlFor="condition">Condición</label>
                  </FloatingInput>
                </div>
                <div className="col-6 mb-1">
                  <FloatingInput className="form-floating">
                    <Field
                      id="priceStart"
                      name="priceStart"
                      type="number"
                      step={
                        values.publicationType === "sell" ? "100000" : "10000"
                      }
                      min="0"
                      max={
                        values.publicationType === "sell"
                          ? "50000000"
                          : "300000"
                      }
                      placeholder="Precio desde"
                      className="form-control"
                    />
                    <label htmlFor="priceStart">Precio desde</label>
                  </FloatingInput>
                </div>
                <div className="col-6 mb-1">
                  <FloatingInput className="form-floating">
                    <Field
                      id="priceEnd"
                      name="priceEnd"
                      type="number"
                      step={
                        values.publicationType === "sell" ? "100000" : "10000"
                      }
                      min="0"
                      max={
                        values.publicationType === "sell"
                          ? "50000000"
                          : "300000"
                      }
                      placeholder="Hasta"
                      className="form-control"
                    />
                    <label htmlFor="priceEnd">Hasta</label>
                  </FloatingInput>
                </div>
                <div className="col-12 poppins-medium-16 text-end">
                  {moreFilters && (
                    <span className="clickable" onClick={hideMoreFilters}>
                      menos
                    </span>
                  )}
                  {!moreFilters && (
                    <span className="clickable" onClick={showMoreFilters}>
                      mas
                    </span>
                  )}
                </div>
                {moreFilters && (
                  <>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="landAreaStart"
                          name="landAreaStart"
                          type="number"
                          step="500"
                          min="0"
                          max="20000"
                          placeholder="Terreno desde"
                          className="form-control"
                        />
                        <label htmlFor="landAreaStart">Terreno desde</label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="landAreaEnd"
                          name="landAreaEnd"
                          type="number"
                          step="500"
                          min="0"
                          max="20000"
                          placeholder="Hasta"
                          className="form-control"
                        />
                        <label htmlFor="landAreaEnd">Hasta</label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="constructionAreaStart"
                          name="constructionAreaStart"
                          type="number"
                          step="100"
                          min="0"
                          max="5000"
                          placeholder="Construcción"
                          className="form-control"
                        />
                        <label htmlFor="constructionAreaStart">
                          Construcción desde
                        </label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="constructionAreaEnd"
                          name="constructionAreaEnd"
                          type="number"
                          step="100"
                          min="0"
                          max="5000"
                          placeholder="Hasta"
                          className="form-control"
                        />
                        <label htmlFor="constructionAreaEnd">Hasta</label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="roomsStart"
                          name="roomsStart"
                          type="number"
                          min="0"
                          max="15"
                          placeholder="Habitaciones"
                          className="form-control"
                        />
                        <label htmlFor="roomsStart">Habitaciones desde</label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="roomsEnd"
                          name="roomsEnd"
                          type="number"
                          min="0"
                          max="15"
                          placeholder="Hasta"
                          className="form-control"
                        />
                        <label htmlFor="roomsEnd">Hasta</label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="bathroomsStart"
                          name="bathroomsStart"
                          type="number"
                          min="0"
                          step="0.5"
                          max="10"
                          placeholder="Baños"
                          className="form-control"
                        />
                        <label htmlFor="bathroomsStart">Baños desde</label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="bathroomsEnd"
                          name="bathroomsEnd"
                          type="number"
                          min="0"
                          step="0.5"
                          max="10"
                          placeholder="Hasta"
                          className="form-control"
                        />
                        <label htmlFor="bathroomsEnd">Hasta</label>
                      </FloatingInput>
                    </div>
                    {/* <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="parkingsStart"
                          name="parkingsStart"
                          type="number"
                          min="0"
                          max="10"
                          placeholder="Parqueos"
                          className="form-control"
                        />
                        <label htmlFor="parkingsStart">Parqueos desde</label>
                      </FloatingInput>
                    </div>
                    <div className="col-6 mb-1">
                      <FloatingInput className="form-floating">
                        <Field
                          id="parkingsEnd"
                          name="parkingsEnd"
                          type="number"
                          min="0"
                          max="10"
                          placeholder="Hasta"
                          className="form-control"
                        />
                        <label htmlFor="parkingsEnd">Hasta</label>
                      </FloatingInput>
                    </div> */}
                  </>
                )}
                <h5 className="poppins-medium-16 col-12">
                  <Trans i18nKey="propertyForm.services.title" />
                </h5>
                {Object.keys(filterExtraState).map((name) => {
                  return (
                    <div
                      className="col-6 d-flex align-items-center clickable poppins-regular-14"
                      key={`service-${name}`}
                      onClick={() => {
                        setFilterExtraState((prev) => {
                          const newState = { ...prev };
                          newState[name] = !newState[name];
                          return newState;
                        });
                      }}
                    >
                      <Icon
                        src={
                          filterExtraState[name] ? switchActiveIcon : switchIcon
                        }
                        style={{
                          opacity: filterExtraState[name] ? "1" : "0.5",
                        }}
                        alt="switch"
                      />
                      <span
                        className="ms-2"
                        style={{
                          opacity: filterExtraState[name] ? "1" : "0.5",
                        }}
                      >
                        {t(`propertyForm.services.${name}`)}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="d-md-none row mt-3">
                <div
                  className="col-6 poppins-medium-16 text-start text-md-end clickable"
                  style={{ opacity: 0.7 }}
                  onClick={() => {
                    resetForm();
                    setValues(formInitialState);
                    setFilterExtraState(initialExtraFilterState);
                    submitForm();
                  }}
                >
                  Limpiar
                </div>
                <div className="col-6 d-inline d-md-none text-end">
                  <ButtonRed type="submit" style={{ margin: 0, width: "auto" }}>
                    Buscar
                  </ButtonRed>
                </div>
              </div>
              <ButtonRed className="d-none d-md-inline" type="submit">
                Buscar
              </ButtonRed>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Filter;
