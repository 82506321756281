import authApi from "./authApi";
import API from "./endpoint";

interface LoginBody {
  username: string;
  password: string;
}

interface SignUpBody {
  username: string;
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  phone_code: string;
  phone: string;
}

const login = async (data: LoginBody) => {
  const res = await authApi.post(API.login, data);

  if (res.status === 200) {
    window.sessionStorage.setItem("access_token", res.data.access);
  } else {
    throw new Error("login unsuccessful");
  }
};

const signUp = async (data: SignUpBody) => {
  const res = await authApi.post(API.register, data);

  if (res.status !== 200) {
    throw new Error("sign up unsuccessful");
  }
};

const isExpired = async () => {
  try {
    const res = await authApi.get(API.verify, {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem(
          "access_token"
        )}`,
      },
    });

    return res.status !== 200;
  } catch {
    return true;
  }
};

const getNewToken = async () => {
  try {
    const res = await authApi.post(API.refresh, undefined, {
      withCredentials: true,
    });

    if (res.status === 200) {
      window.sessionStorage.setItem("access_token", res.data.access);
    } else {
      window.sessionStorage.removeItem("access_token");
      throw new Error("Bad / expired refresh cookie found");
    }
  } catch {
    window.sessionStorage.removeItem("access_token");
    throw new Error("Not refresh cookie found");
  }
};

const logout = async () => {
  try {
    const access_token = window.sessionStorage.getItem("access_token");
    const res = await authApi.post(
      API.logout,
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (res.status === 205) {
      window.sessionStorage.removeItem("access_token");
    } else {
      throw new Error("request unsuccessful");
    }
  } catch {
    throw new Error("request unsuccessful");
  }
};

export { login, signUp, isExpired, getNewToken, logout, API };
