import styled from "styled-components";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const TermsContent = styled.div`
  word-break: break-word;
  li {
    padding: 1rem 0.5rem;
  }
  li:last-child {
    padding: 0 0.5rem;
  }

  @media screen and (min-width: 768px) {
    width: calc(100% - 80px);
    margin-left: 80px;
  }
`;

const Terms = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-between main-container">
        <Navbar />
        <TermsContent className="text-justify poppins-light-16 px-3 my-3 px-md-2 my-md-5">
          <h1 className="poppins-regular-26">Condiciones de uso de Bohiio</h1>
          <p className="poppins-bold-16 mt-4 mb-5">
            Actualizado el 22 de septiembre de 2021
          </p>
          <p>
            Gracias por su interés en la familia comercial de Bohiio LLC . Al
            hacer clic en un botón de registro o envío de una nueva cuenta, o al
            utilizar nuestros sitios web, redes, aplicaciones móviles u otros
            servicios proporcionados por la Empresa Bohiio LLC (colectivamente,
            los “Servicios”), o acceder a cualquier contenido proporcionado por
            nosotros a través de los Servicios, usted acepta estar sujeto a los
            siguientes términos de uso, que se actualizan periódicamente (los
            “Términos de uso”).
          </p>
          <ol className="poppins-bold-16" style={{ paddingLeft: 0 }}>
            <li>
              <h2 className="poppins-bold-16">Rol de bohiio</h2>
              <p className="poppins-light-16">
                Bohiio es una Red Social Inmobiliaria que le ayuda a realizar
                las diversas tareas en una transacción de bienes raíces. Sin
                embargo, a menos que se especifique explícitamente en los
                términos de uso de un Servicio en particular (cada uno de ellos,
                los “Términos del producto”), LOS SERVICIOS NO TIENEN LA
                INTENCIÓN DE proporcionarle ningún tipo de asesoramiento
                financiero, inmobiliario o relacionado. Comprende y acepta que
                los Servicios pueden incluir anuncios. Para ayudar a que los
                anuncios sean relevantes y útiles para usted, la Empresa Bohiio
                LLC puede publicar anuncios basados ​​en la información que
                recopilamos a través de los Servicios. Consulte la Política de
                privacidad para obtener más detalles.
              </p>
            </li>
            <li>
              <h2 className="poppins-bold-16">
                Marcas y Negocios de Bohiio LLC
              </h2>
              <ol
                className="poppins-bold-16"
                type="A"
                style={{ paddingLeft: 0 }}
              >
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">
                      Marcas y Negocios de Bohiio LLC.
                    </span>{" "}
                    Los servicios ofrecidos por la marca y el modelo comercial
                    de la empresa pueden estar sujetos a los Términos de un
                    Producto o términos de uso adicionales según estén
                    disponibles en sus respectivos sitios web, redes,
                    aplicaciones móviles u otros.
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">Bohiio LLC</span> no
                    proporcionan ni utilizan ninguna información para su uso en
                    decisiones relacionadas con préstamos, y usted reconoce y
                    acepta no utilizar la información obtenida de Bohiio LLC y
                    otras empresas relacionadas a Bohiio para decisiones
                    relacionadas con préstamos.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <span>Elegibilidad; Cuentas y registro.</span>
              <p className="poppins-light-16">
                Debe tener al menos 18 años para utilizar los Servicios. Al
                aceptar estos Términos de uso, usted declara y garantiza que:
                (a) tiene al menos 18 años de edad; (b) no ha sido previamente
                suspendido o retirado de los Servicios; y (c) su registro y uso
                de los Servicios cumple con todas las leyes y regulaciones
                aplicables de su país. Para acceder a algunas funciones de los
                Servicios, es posible que deba registrarse para obtener una
                cuenta y aceptar los Términos de un Producto, en la medida que
                sea aplicable a ese servicio, que puede estar incorporado en
                este documento o disponible en un sitio separado de Bohiio LLC.
                Cuando se registre para obtener una cuenta, se le pedirá que nos
                proporcione cierta información sobre usted, como dirección de
                correo electrónico, número de teléfono u otra información de
                contacto. Usted acepta que la información que proporciona es
                precisa y que la mantendrá precisa y actualizada en todo
                momento. Cuando se registre, se le pedirá que proporcione una
                contraseña. Usted es el único responsable de mantener la
                confidencialidad de su cuenta y contraseña, y usted es
                responsable de todas las acciones realizadas a través de su
                cuenta. No puede compartir su (s) cuenta (s) de usuario con
                otros. A menos que haya celebrado un acuerdo comercial con
                nosotros que le permita utilizar los Servicios para
                transacciones en nombre de otra persona, solo puede utilizar los
                Servicios para transacciones en su propio nombre.
              </p>
            </li>
            <li>
              Uso de los Servicios; Restricciones.
              <ol
                className="poppins-bold-16"
                type="A"
                style={{ paddingLeft: 0 }}
              >
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">
                      Uso de los servicios.
                    </span>{" "}
                    Siempre que cumpla con estos Términos de uso, le otorgamos
                    una licencia no exclusiva, limitada, revocable, personal e
                    intransferible para usar los Servicios y para descargar y
                    usar cualquier Aplicación de Bohiio LLC (como se define en
                    adelante (B) a continuación) en su dispositivo móvil en
                    forma de código objeto, para su uso personal. Si usted es un
                    profesional inmobiliario o hipotecario que actúa en su
                    capacidad profesional, también puede utilizar los Servicios
                    para proporcionar información, en la medida permitida por la
                    ley aplicable, a sus clientes y para tomar medidas en nombre
                    de sus clientes (“Uso Pro” ). Si utiliza los Servicios para
                    un uso profesional, declara y garantiza que ha obtenido
                    todas las autorizaciones y consentimientos necesarios de su
                    cliente. Salvo que se indique expresamente en el presente,
                    estos Términos de uso no le otorgan una licencia para usar,
                    reproducir, distribuir, mostrar o proporcionar acceso a
                    cualquier parte de los Servicios en sitios web de terceros o
                    de otro modo. Los Servicios pueden incluir software para su
                    uso en relación con el Servicios. Los Servicios no se pueden
                    utilizar para transacciones en bienes raíces comerciales,
                    que incluyen, entre otros, propiedades de zona comercial y
                    alquileres de vacaciones.
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">
                      Aplicaciones móviles.
                    </span>{" "}
                    Para utilizar cualquier función de aplicación móvil de los
                    Servicios (cada una, una “Aplicación”), debe tener un
                    dispositivo móvil compatible. No garantizamos que ninguna
                    aplicación sea compatible con su dispositivo móvil. Puede
                    usar datos móviles en conexión con una aplicación y puede
                    incurrir en cargos adicionales de su proveedor de servicios
                    inalámbricos por usar una aplicación. Acepta que es el único
                    responsable de los cargos aplicables. Podemos actualizar
                    cualquier aplicación y podemos actualizar automáticamente de
                    forma electrónica la versión de cualquier aplicación que
                    haya instalado en un dispositivo móvil. Usted acepta todas
                    las actualizaciones automáticas y comprende que estos
                    Términos de uso se aplicarán a todas las versiones
                    actualizadas de una Aplicación. Cualquier software de código
                    abierto de terceros incluido en una aplicación está sujeto a
                    la licencia de código abierto aplicable y puede estar
                    disponible directamente del creador bajo una licencia de
                    código abierto. Estos Términos de uso no se aplican a su uso
                    de software obtenido de una fuente de terceros bajo una
                    licencia de código abierto.
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">Uso de contenido.</span>{" "}
                    Sujeto a las restricciones establecidas en estos Términos de
                    uso, puede copiar información de los Servicios sin la ayuda
                    de ningún proceso automatizado y solo según sea necesario
                    para su uso personal o Uso profesional para ver, guardar,
                    imprimir, enviar por fax y / o correo electrónico. enviar
                    dicha información. No obstante lo anterior, los datos de
                    nivel agregado proporcionados en las Páginas de información
                    local de Bohiio (los “Datos agregados”) pueden usarse para
                    usos no personales, por ejemplo, análisis del mercado
                    inmobiliario. Solo puede mostrar y distribuir trabajos
                    derivados de los Datos agregados (por ejemplo, dentro de un
                    gráfico), siempre que las Empresas de Bohiio LLC se citen
                    como fuente en todas las páginas donde se muestran los Datos
                    agregados, incluidos los “Datos proporcionados por Bohiio
                    LLC Group”. Dicha cita no puede incluir ninguno de nuestros
                    logotipos sin nuestra aprobación previa por escrito o
                    implicar cualquier relación entre usted y las Empresas de
                    Bohiio más allá de que Bohiio sean la fuente de los Datos
                    agregados. Tiene prohibido mostrar los datos de otras
                    empresas de Bohiio. sin nuestra aprobación previa por
                    escrito.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <span>
                Uso prohibido. AL UTILIZAR LOS SERVICIOS DE BOHIIO, USTED ACEPTA
                NO:
              </span>
              <p className="poppins-light-16">
                Reproducir, modificar, distribuir, mostrar o proporcionar acceso
                a, crear trabajos derivados de, descompilar, desensamblar o
                realizar ingeniería inversa en cualquier parte de los Servicios,
                excepto según lo permitan explícitamente los Términos de
                cualquier Producto en la medida aplicable a los Servicios de ese
                producto; proporcionar / publicar / autorizar un enlace a
                cualquiera de los Servicios (que incluye, entre otros, la página
                de perfil de un agente) desde un sitio web de terceros que no
                sea un sitio web relacionado con bienes raíces que sea propiedad
                o esté operado por una institución o profesional de bienes
                raíces o prestamistas eliminar o modificar cualquier aviso de
                derechos de autor u otros avisos de propiedad intelectual que
                aparezcan en los Servicios; utilizar los Servicios de cualquier
                forma que sea ilegal o perjudique a las Empresas Bohiio, sus
                proveedores de servicios, proveedores, afiliados o cualquier
                otro usuario; usar los Servicios de cualquier manera para
                discriminar a cualquier individuo o clase de individuos
                protegidos por las leyes federales, estatales o locales de su
                país o región, que pueda tener un impacto discriminatorio contra
                cualquier individuo o clase de individuos, o que de otro modo
                promueva actividades o resultados ilegales, racistas o
                discriminatorios; distribuir o publicar spam u otros mensajes no
                solicitados, cartas en cadena, esquemas piramidales o
                comunicaciones similares a través de los Servicios; hacerse
                pasar por otra persona, tergiversar su afiliación con otra
                persona o entidad, o hacer cualquier representación ante un
                tercero con falsos pretextos; reproducir, mostrar públicamente o
                hacer accesible de otra manera en o a través de cualquier otro
                sitio web, aplicación o servicio cualquier revisión,
                calificación o información de perfil sobre bienes raíces,
                préstamos u otros profesionales, imágenes subyacentes o
                información sobre listados de bienes raíces u otros datos o
                contenido disponible a través de los Servicios, excepto según lo
                permitido explícitamente por nosotros para una parte particular
                de los Servicios; cargar datos no válidos, virus, gusanos u
                otros agentes de software a los Servicios; publicar, reproducir,
                mostrar públicamente o hacer accesible cualquier contenido que,
                a nuestro exclusivo criterio y discreción, consideremos ilegal,
                ofensivo u objetable, incluido, entre otros, el contenido que
                acosa, discrimina, degrada, amenaza o menosprecia a cualquier
                individuo o clase de individuos; interferir o comprometer la
                integridad del sistema o la seguridad de los Servicios, o eludir
                cualquier medida que podamos usar para prevenir o restringir el
                acceso a los Servicios; realizar consultas automatizadas
                (incluido el rastreo de pantallas y bases de datos, arañas,
                robots, rastreadores, omitir “captcha” o precauciones similares,
                o cualquier otra actividad automatizada con el propósito de
                obtener información de los Servicios) en los Servicios; utilizar
                cualquiera de las marcas comerciales de las compañías de Bohiio
                como parte de su nombre de usuario o dirección de correo
                electrónico en los Servicios; acceder o utilizar cualquiera de
                los Servicios para desarrollar productos o servicios
                competitivos; o intentar, permitir o alentar a un tercero a
                realizar cualquiera de las acciones anteriores.
              </p>
            </li>
            <li>
              <span>Tarifas.</span>
              <ol
                type="A"
                className="poppins-bold-16"
                style={{ paddingLeft: 0 }}
              >
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">Generalmente.</span> Es
                    posible que deba pagar tarifas para acceder a determinadas
                    funciones de los Servicios. Todas las tarifas están en
                    dólares estadounidenses y no son reembolsables. Si cambiamos
                    las tarifas para la totalidad o parte de cualquiera de los
                    Servicios, incluso agregando tarifas o cargos, le
                    notificaremos con anticipación sobre dichos cambios. Si no
                    acepta los cambios, podemos dejar de proporcionarle la parte
                    correspondiente de los Servicios. Nuestros procesadores de
                    pago de terceros autorizados cobrarán el método de pago que
                    especificó en el momento de la compra o según lo acordado
                    como parte del Servicio seleccionado. Usted nos autoriza a
                    cobrar todas las tarifas descritas en estos Términos de uso
                    por los Servicios que seleccione en ese método de pago. Si
                    paga alguna tarifa con una tarjeta de crédito, podemos
                    solicitar la autorización previa de su cuenta de tarjeta de
                    crédito antes de su compra para verificar que la tarjeta de
                    crédito sea válida y tenga los fondos o el crédito
                    necesarios disponibles para cubrir su compra.
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">Suscripciones.</span> Los
                    Servicios pueden incluir características que permiten pagos
                    recurrentes automáticamente por cargos periódicos (“Servicio
                    de suscripción”). Si decide activar un Servicio de
                    suscripción, nos autoriza a cobrar periódicamente, en el
                    futuro y hasta la cancelación de los pagos recurrentes o de
                    su cuenta, todas las sumas acumuladas en o antes de la fecha
                    de vencimiento del pago de las sumas acumuladas. La
                    suscripción continuará a menos que y hasta que cancele su
                    suscripción, o la cancelemos. Debe cancelar su Servicio de
                    suscripción antes de que se renueve para evitar la
                    facturación de la próxima tarifa de suscripción periódica a
                    su cuenta. Facturaremos la tarifa de suscripción periódica
                    al método de pago que nos proporcione durante el registro (o
                    un método de pago diferente sí cambia su información de
                    pago). Podemos cambiar la tarifa de suscripción para
                    cualquier período de suscripción posterior, pero le
                    avisaremos con anticipación de cualquier aumento antes de
                    que se aplique. A menos que se indique lo contrario en su
                    Condiciones del producto, puede cancelar un Servicio de
                    suscripción a través de la página de configuración de su
                    cuenta; poniéndose en contacto con nosotros en{" "}
                    <a href="mailto:cliente@bohiio.com">cliente@bohiio.com</a>;
                    o, si corresponde, comunicándose con su asesor comercial.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <span>Materiales de usuario.</span>
              <ol
                className="poppins-bold-16"
                type="A"
                style={{ paddingLeft: 0 }}
              >
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">Definición de UGC;</span>{" "}
                    Concesión de licencia. Ciertas partes de los Servicios
                    pueden permitir a los usuarios cargar o proporcionar a
                    Bohiio y su grupo de empresas imágenes, fotos, videos,
                    datos, texto, listados y otro contenido (“Materiales del
                    usuario”). En ausencia de cualquier término adicional
                    provisto en los Términos de su Producto, al cargar o
                    proporcionar Materiales de usuario a los Servicios, usted
                    nos otorga una licencia mundial irrevocable, perpetua y
                    libre de regalías para: (i) usar, copiar, distribuir,
                    transmitir, exhibir públicamente, realizar públicamente,
                    reproducir, editar, modificar, preparar trabajos derivados o
                    incorporarlos a otros trabajos, y traducir sus Materiales de
                    usuario, en relación con los Servicios o en cualquier otro
                    medio; y (ii) sublicenciar estos derechos, en la medida
                    máxima permitida por la ley aplicable. No le pagaremos por
                    sus Materiales de usuario ni por ejercer ningún derecho
                    relacionado con sus Materiales de usuario establecido en la
                    oración anterior. Podemos eliminar o modificar sus
                    Materiales de usuario en cualquier momento. Usted es el
                    único responsable de todos los Materiales de usuario
                    realizados. a través de su (s) cuenta (s) de usuario en los
                    Servicios o que de otro modo ponga a disposición a través de
                    los Servicios. Para todos los Materiales del usuario, usted
                    declara y garantiza que es el creador y propietario de los
                    Materiales del usuario, o que tiene las licencias, los
                    derechos, los consentimientos y los permisos necesarios
                    (incluidos todos los permisos requeridos por la ley de
                    privacidad y propiedad intelectual aplicable) para autorizar
                    a Bohiio y sus empresas y otros usuarios accedan y utilicen
                    sus Materiales de usuario según sea necesario para ejercer
                    las licencias otorgadas por usted en virtud de estos
                    Términos de uso.
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">
                      Descargo de responsabilidad de UGC.
                    </span>{" "}
                    No tenemos la obligación de editar o controlar sus
                    Materiales de usuario o los Materiales de usuario de
                    cualquier otro Usuario y no seremos responsables de ninguna
                    manera por los Materiales de usuario. Sin embargo, podemos,
                    en cualquier momento y sin previo aviso, filtrar, eliminar,
                    editar o bloquear cualquier contenido del Usuario en los
                    Servicios, incluidos los Materiales del Usuario, que, a
                    nuestro exclusivo criterio, viole estos Términos de Uso o
                    que consideremos objetables de otro modo, incluido cualquier
                    violación de nuestra Política de Buen Vecino, descrita en la
                    Sección 13 (B). Usted comprende que al usar los Servicios,
                    puede estar expuesto a Materiales de usuario de otros
                    usuarios y reconoce que los Materiales de usuario pueden ser
                    inexactos, ofensivos, indecentes u objetables. Usted acepta
                    renunciar y renuncia a cualquier derecho o recurso legal o
                    equitativo que pueda tener contra nosotros con respecto a
                    los Materiales del usuario. Renunciamos expresamente a toda
                    responsabilidad en relación con los Materiales del usuario.
                    Si un usuario o propietario de contenido le notifica que los
                    Materiales del usuario supuestamente no cumplen con Estos
                    Términos de uso, podemos investigar la alegación y
                    determinar, a nuestro exclusivo criterio, si eliminamos esos
                    Materiales de usuario, lo cual nos reservamos el derecho de
                    hacer en cualquier momento y sin previo aviso. Para obtener
                    más información sobre nuestro manejo del contenido
                    infractor, consulte la Sección 11 a continuación.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <span>
                Terceros / Servicios vinculados / Información enviada.
              </span>
              <ol
                className="poppins-bold-16"
                type="A"
                style={{ paddingLeft: 0 }}
              >
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">Generalmente.</span> Los
                    Servicios incluyen enlaces a productos, servicios y sitios
                    web de terceros, así como materiales proporcionados por
                    terceros, y pueden incluir funciones que permitan la
                    distribución de sus Materiales de usuario o su información
                    personal (colectivamente, su “Información de usuario”). a
                    terceros que no están bajo nuestro control (cada uno, un
                    “Proveedor externo”). Los proveedores externos son los
                    únicos responsables de sus servicios. Usted es responsable
                    de su uso y envío de la Información del usuario a cualquier
                    tercero, y sus tratos o negocios realizados con cualquier
                    tercero que surjan en relación con los Servicios son
                    únicamente entre usted y dicho tercero. Su uso de sitios,
                    servicios o productos de terceros puede estar sujeto a
                    términos de uso y políticas de privacidad de terceros
                    asociados u otros acuerdos, que usted es el único
                    responsable de cumplir. No respaldamos ni asumimos ninguna
                    responsabilidad por dichos productos, servicios, sitios web
                    y materiales, o por el uso de un proveedor externo. de su
                    información de usuario. Al utilizar una herramienta que
                    permite transferir la Información del usuario, usted acepta
                    que podemos transferir la Información del usuario
                    correspondiente u otra información a los terceros
                    correspondientes, que no están bajo nuestro control. Si
                    envía un formulario de contacto o indica de otro modo su
                    interés en ponerse en contacto con un proveedor externo,
                    puede recibir llamadas de telemercadeo del proveedor externo
                    utilizando la información de contacto que proporcionó. Los
                    proveedores externos pueden conservar su información de
                    contacto y cualquier otra información recibida por el
                    proveedor externo al procesar un formulario de contacto u
                    otro formulario de solicitud. No somos responsables de
                    ningún daño o costo de ningún tipo que surja o esté
                    relacionado de alguna manera con sus relaciones con estos
                    terceros.
                  </p>
                </li>
                <li>
                  <span>Ciertos servicios de terceros.</span>
                  <ol
                    className="poppins-bold-16"
                    type="i"
                    style={{ paddingLeft: 0 }}
                  >
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">
                          Referencias y ventas de clientes potenciales.
                        </span>{" "}
                        Como parte de los Servicios, es posible que se le
                        conecte con un profesional de bienes raíces. Usted nos
                        autoriza a realizar dicha referencia o venta de clientes
                        potenciales y reconoce que se nos puede prestar una
                        valiosa consideración por facilitar dicha conexión. Como
                        parte de los Servicios, puede estar conectado con una
                        parte que brinde servicios de organizaciones de
                        préstamos hipotecarios, servicios de título y depósito
                        en garantía u otros servicios de liquidación. . En el
                        caso de que cualquiera de estas partes tenga una
                        relación de afiliación con cualquiera de las Empresas de
                        Bohiio, entonces una o más de las Empresas de Bohiio
                        pueden recibir un beneficio financiero o de otro tipo
                        por la referencia entre dichos afiliados.
                      </p>
                    </li>
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">
                          Productos financieros no afiliados.
                        </span>{" "}
                        Si elige comunicarse con un banco no afiliado,
                        prestamista hipotecario, institución financiera,
                        facilitador de préstamos hipotecarios, corredor de
                        préstamos u otro profesional hipotecario a través de los
                        Servicios, completando un formulario de contacto, otro
                        formulario de solicitud o indicando de otra manera su
                        interés en comunicarse (o ser contactado por) un
                        Proveedor Tercero (incluidas las solicitudes de
                        cotizaciones de tasas hipotecarias) en los Servicios,
                        usted autoriza a las compañías de Bohiio y su
                        Marketplace, en particular a proporcionar la información
                        que envía al Proveedor Tercero. Sí incluye su nombre,
                        información de contacto y otra información en una
                        solicitud, su identidad ya no será anónima para el
                        proveedor externo. Su envío de información y cualquier
                        solicitud de cotizaciones a través de los Servicios no
                        es una solicitud de crédito. En el caso de productos
                        financieros no afiliados, las Empresas Bohiio solo
                        brindan un servicio administrativo a los consumidores y
                        a los Terceros participantes. Proveedores. Los
                        estándares de aprobación de préstamos son establecidos y
                        mantenidos únicamente por Proveedores Terceros
                        individuales. Las decisiones en cualquier transacción
                        relacionada no afiliada son tomadas por Proveedores
                        Terceros participantes y no son tomadas por Bohiio o
                        alguna de sus Compañías, Bohiio no es responsables de
                        las actividades de suscripción o decisiones crediticias
                        de ningún Proveedor Tercero. Cualquier cotización no
                        vinculante proporcionada por las Empresas de Bohiio para
                        los productos financieros de terceros proveedores no
                        tiene la intención de ser Estimaciones de préstamos
                        oficiales como se define en la Ley de Procedimientos de
                        Liquidación de Bienes Raíces o la Ley de Veracidad en
                        los Préstamos o servir como una divulgación de cualquier
                        términos o condiciones del préstamo bajo cualquier ley
                        estatal o federal de acuerdo al país que pertenezca. Las
                        tasas de interés que se muestran a través de los
                        Servicios son solo para fines informativos y reflejan
                        citas no vinculantes de los términos que un Proveedor
                        externo podría ofrecer a un solicitante que se ajusta al
                        perfil anónimo de un consumidor. Las tasas de interés
                        reales pueden variar. Los consumidores deben confiar en
                        su propio juicio para decidir qué producto de préstamo
                        disponible, qué condiciones y qué proveedor externo se
                        adaptan mejor a sus necesidades y medios financieros.
                        Excepto como se establece en la Sección 8 (b) (i)
                        anterior o según se le revele a usted o se disponga en
                        los Términos de otros Productos, las Empresas de Bohiio:
                        (a) no son un Tercero Proveedor, facilitador de
                        préstamos, procesador de préstamos o asegurador; (b) no
                        ayude ni ayude a los solicitantes a obtener préstamos,
                        solicite préstamos a solicitantes o Terceros
                        Proveedores, ni ofrezca o negocie los términos de las
                        solicitudes o préstamos; (c) no aceptar solicitudes de
                        hipotecas, hacer préstamos o decisiones crediticias, ni
                        precalificar o aprobar previamente a los solicitantes de
                        préstamos; (d) no es un agente de ningún consumidor ni
                        de ningún tercero proveedor; (e) no respalde, refiera o
                        recomiende a ningún Tercero Proveedor que pague a las
                        compañías de bohiio o los productos de ningún Tercero
                        Proveedor que pague a la compañía de bohiio; (f) no son
                        responsables de los errores o retrasos causados ​​por
                        los consumidores o cualquier Proveedor Tercero en el
                        proceso de préstamo; y (g) no Garantizar la oferta o
                        aceptación en cualquier programa de préstamos en
                        particular o términos, condiciones o tarifas de
                        préstamos específicos con cualquier Proveedor Tercero, o
                        que las tarifas o términos serán los mejores
                        disponibles.
                      </p>
                    </li>
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">
                          Productos de alquiler.
                        </span>{" "}
                        Si elige enviar su información para enviar una
                        verificación de antecedentes o de crédito, está sujeto a
                        los términos de Checkr y Experian como se describe en la
                        Sección 8 (C) a continuación. No procesamos ni
                        almacenamos información de antecedentes o verificación
                        de crédito relacionada con sus productos de alquiler y
                        no controlamos como un propietario utiliza la
                        información de antecedentes o verificación de crédito
                        para evaluar sus solicitudes. No garantizamos la
                        aceptación de una solicitud por parte del propietario,
                        pero sí exigimos que todos los propietarios que utilicen
                        los Servicios cumplan con la Ley de Vivienda Justa y
                        otras leyes aplicables.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">
                      Términos adicionales para servicios de terceros.
                    </span>{" "}
                    Ciertos aspectos de los Servicios incluyen herramientas de
                    terceros que están sujetas a términos adicionales de
                    terceros, que incluyen, entre otros, los siguientes:
                  </p>
                  <ol
                    className="poppins-bold-16"
                    type="i"
                    style={{ paddingLeft: 0 }}
                  >
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">
                          Windows Live Virtual Earth.
                        </span>{" "}
                        Las imágenes de Windows Live Virtual Earth son
                        suministradas por Microsoft Corporation y su uso está
                        sujeto a los Términos de uso de Microsoft MapPoint
                        disponibles en{" "}
                        <a
                          href="http://www.microsoft.com/maps/assets/docs/terms.aspx"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://www.microsoft.com/maps/assets/docs/terms.aspx
                        </a>
                      </p>
                    </li>
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">
                          Mapas de Google.
                        </span>{" "}
                        Algunos de los Servicios implementan el servicio de
                        mapas web de Google Maps. Su uso de Google Maps está
                        sujeto a los términos de uso de Google, disponibles en{" "}
                        <a
                          href="http://www.google.com/intl/en_us/help/terms_maps.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://www.google.com/intl/en_us/help/terms_maps.html
                        </a>
                        , y la Política de privacidad de Google, disponible en{" "}
                        <a
                          href="https://www.google.com/intl/ALL/policies/privacy/index.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.google.com/intl/ALL/policies/privacy/index.html.
                        </a>
                      </p>
                    </li>
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">Stripe.</span> Algunos
                        de los Servicios le permiten usar Stripe Connect para
                        realizar pagos a otros usuarios, y pueden incluir
                        tarifas de procesamiento o solicitud adicionales
                        detalladas cuando elige conectarse a Stripe. Su uso de
                        Stripe está sujeto al Acuerdo de cuenta conectada de
                        Stripe, disponible en{" "}
                        <a
                          href="https://stripe.com/us/connect-account/legal"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://stripe.com/us/connect-account/legal.
                        </a>{" "}
                        Además, al usar Stripe, acepta no usar Stripe (y los
                        Servicios en general) para fines comerciales prohibidos,
                        como se indica en{" "}
                        <a
                          href="https://stripe.com/us/prohibited-businesses"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://stripe.com/us/prohibited-businesses.
                        </a>
                      </p>
                    </li>
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">Checkr.</span> Algunos
                        de los Servicios permiten a los usuarios utilizar los
                        servicios de nuestro socio de verificación de
                        antecedentes, Checkr, para enviar una solicitud y
                        verificación de antecedentes a un propietario. Si usted
                        es un inquilino que utiliza los servicios de Checkr,
                        autoriza a Bohiio a obtener su informe de verificación
                        de antecedentes, incluido el historial criminal y de
                        desalojo, y a compartir esa información con los
                        propietarios a los que envía una solicitud y acepta los
                        Términos de uso de Checkr disponibles en{" "}
                        <a
                          href="https://checkr.com/terms-of-service/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://checkr.com/terms-of-service/
                        </a>
                        . Si usted es un propietario que utiliza los servicios
                        de Checkr, acepta utilizar los informes de verificación
                        de antecedentes de conformidad con la ley y acepta los
                        Términos de uso del informe de verificación de
                        antecedentes de Checkr disponibles.
                      </p>
                    </li>
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">Plaid.</span> La
                        función de pagos de alquiler le permite vincular su
                        cuenta bancaria a través de Plaid con el fin de realizar
                        o recibir pagos de alquiler. Al usar Plaid, usted acepta
                        que Plaid transfiera, almacene y procese su información
                        personal y financiera de acuerdo con la Política de
                        privacidad de Plaid, disponible en{" "}
                        <a
                          href="https://plaid.com/legal"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://plaid.com/legal.
                        </a>
                      </p>
                    </li>
                    <li>
                      <p className="poppins-light-16">
                        <span className="poppins-bold-16">Experian.</span>{" "}
                        Algunos de los Servicios le permiten utilizar el
                        servicio Experian Connect para enviar una solicitud de
                        verificación de crédito al propietario. Si utiliza los
                        servicios de Experian, acepta los Términos y condiciones
                        de Experian Connect, disponibles en{" "}
                        <a
                          href="https://connect.experian.com/legal/terms.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://connect.experian.com/legal/terms.html.
                        </a>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>Propiedad intelectual</p>
              <p className="poppins-light-16">
                Los Servicios son propiedad de la Empresas de Bohiio y están
                operados por ellas. Las interfaces de usuario, el diseño, la
                información, los datos, el código, los productos, el software,
                los gráficos y todos los demás elementos de los Servicios (los
                “Materiales de las Empresas de Bohiio”) que proporcionamos están
                protegidos por la propiedad intelectual y otras leyes y son
                propiedad de las Empresas Bohiio o los terceros licenciantes de
                las Empresas de Bohiio. Salvo lo expresamente permitido por
                estos Términos de uso, no puede hacer uso de los Materiales de
                las Empresas Bohiio, y las Empresas Bohiio se reservan todos los
                derechos sobre los Materiales y Servicios de las Empresas Bohiio
                que no se otorgan expresamente en estos Términos de uso.
              </p>
              <p style={{ textDecoration: "underline" }}>
                Avisos de propiedad intelectual:
              </p>
              <p>
                Los nombres de empresas, productos y servicios reales
                mencionados en este documento pueden ser marcas comerciales de
                sus respectivos propietarios. Todos los derechos no otorgados
                expresamente en este documento están reservados. No afirmamos
                los derechos de autor ni otorgamos ningún derecho sobre las
                imágenes o descripciones subyacentes de los listados de bienes
                raíces proporcionados a través de los Servicios. Cualquier uso
                de estas imágenes y descripciones está sujeto al permiso del
                propietario de los derechos de autor y a los requisitos de la
                ley aplicable.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">Comentarios.</span> Si elige
                proporcionar comentarios y sugerencias con respecto a los
                Servicios, incluidos los relacionados con cualquiera de los
                Materiales de las Empresas de Bohiio (“Comentarios”), por la
                presente nos otorga una regalía sin restricciones, perpetua,
                irrevocable, no exclusiva, totalmente pagada, derecho libre a
                utilizar los Comentarios de cualquier manera y para cualquier
                propósito, incluido el mejoramiento de los Servicios o la
                creación de otros productos y servicios.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  DMCA; Reclamaciones por infracción de derechos de autor.
                </span>{" "}
                Respetamos los derechos de propiedad intelectual de los demás y
                pedimos que todos los que utilicen los Servicios hagan lo mismo.
                Cualquiera que crea que su trabajo ha sido reproducido en los
                Servicios de una manera que constituye una infracción de
                derechos de autor puede notificar a nuestro agente de derechos
                de autor de acuerdo con el Título 17, Código de los Estados
                Unidos, Sección 512 (c) (2), proporcionando la siguiente
                información:
              </p>
              <ol
                className="poppins-bold-16"
                type="i"
                style={{ paddingLeft: 0 }}
              >
                <li>
                  <p className="poppins-light-16">
                    Identificación de la obra protegida por derechos de autor
                    que afirma que se ha infringido;
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    Identificación del material que alega que infringe y debe
                    eliminarse, incluida una descripción de dónde se encuentra
                    en los Servicios para que el agente de derechos de autor
                    pueda localizarlo;
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    Su dirección, número de teléfono y, si está disponible,
                    dirección de correo electrónico, para que el agente de
                    derechos de autor pueda comunicarse con usted acerca de su
                    queja; y
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    Una declaración firmada de que la información anterior es
                    precisa; que cree de buena fe que el uso identificado del
                    material no está autorizado por el propietario de los
                    derechos de autor, su agente o la ley; y, bajo pena de
                    perjurio, que usted es el propietario de los derechos de
                    autor o está autorizado para actuar en nombre del
                    propietario de los derechos de autor en esta situación.
                  </p>
                </li>
              </ol>
              <p
                className="poppins-light-16"
                style={{ textDecoration: "underline" }}
              >
                Los avisos de reclamos por infracción de derechos de autor deben
                enviarse de la siguiente manera:
              </p>
              <p className="poppins-light-16">
                By Mail:
                <br />
                Bohiio LLC
                <br />
                1314 E 28th St Mission,
                <br />
                Mission, TX 78574
                <br />
                Atención: Agente de derechos de autor
                <br />
                <a href="mailto:cliente@bohiio.com">cliente@bohiio.com</a>
              </p>
              <p className="poppins-light-16">
                Si envía un aviso de infracción de derechos de autor por correo
                electrónico, podemos comenzar a investigar la supuesta
                infracción de derechos de autor; sin embargo, debemos recibir su
                declaración firmada por correo o como un archivo adjunto a su
                correo electrónico antes de que se nos requiera tomar alguna
                medida.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  Desactivación / Eliminación / Cambios al Acuerdo.
                </span>{" "}
                Excepto como se indica en los Términos de cualquier Producto,
                puede desactivar su cuenta en cualquier momento seleccionando
                “desactivar cuenta” en la configuración de su cuenta. Puede
                eliminar su cuenta y todos los datos de su cuenta enviando una
                solicitud para “eliminar sus datos”. Si desactiva o elimina su
                cuenta, sigue obligado a pagar todas las tarifas pendientes, si
                las hubiera, incurridas antes de la terminación en relación con
                su uso de los Servicios. Si viola alguna disposición de estos
                Términos de uso, su permiso de las Empresas de Bohiio para
                utilizar los Servicios terminará automáticamente. Además,
                podemos, a nuestro exclusivo criterio, desactivar, suspender o
                cancelar su acceso a su cuenta y los Servicios en cualquier
                momento y por cualquier motivo, con o sin previo aviso. Podemos
                alterar, suspender o descontinuar los Servicios o cualquier
                parte de los Servicios sin previo aviso. No seremos responsables
                de ningún cambio en los Servicios o de cualquier suspensión o
                terminación de su acceso o uso de los Servicios. Nos reservamos
                el derecho de cambiar estos Términos de uso en cualquier momento
                a su entera discreción en el futuro. Haremos todos los esfuerzos
                comercialmente razonables para notificarle sobre cualquier
                cambio material en estos Términos de uso. Su uso continuado de
                los Servicios después de que las actualizaciones sean efectivas
                representará su aceptación de la versión revisada de los
                Términos de uso. Su uso continuado de los Servicios después de
                la efectividad de dichos cambios constituirá la aceptación y el
                acuerdo de dichos cambios. Además, renuncia a cualquier derecho
                que pueda tener de recibir un aviso específico de dichos cambios
                en estos Términos de uso. Usted es responsable de revisar
                periódicamente estos Términos de uso.
              </p>
            </li>
            <li>
              <p>Política de privacidad / Otros términos.</p>
              <ol
                className="poppins-bold-16"
                type="A"
                style={{ paddingLeft: 0 }}
              >
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">
                      Política de privacidad.
                    </span>{" "}
                    Las Empresas de Bohiio recopilarán, usarán, almacenarán y
                    divulgarán información personal de acuerdo con nuestra
                    Política de privacidad. Consulte la Política de privacidad
                    para obtener más información, que se incorpora y forma parte
                    de estos Términos de uso.
                  </p>
                </li>
                <li>
                  <p className="poppins-light-16">
                    <span className="poppins-bold-16">Otros términos.</span> Su
                    uso de los Servicios está sujeto a todas las pautas, reglas,
                    Términos del Producto y acuerdos adicionales aplicables a
                    los Servicios o ciertas características de los Servicios que
                    podemos publicar o vincular desde los Servicios, como las
                    reglas aplicables a un producto o contenido disponible a
                    través de los Servicios. Si usted es un Tercero Proveedor no
                    afiliado que participa en cualquier Servicio ofrecido por
                    Bohiio o nuestro Marketplace, también está sujeto a los
                    Términos de uso del prestamista de Bohiio LLC. Todos estos
                    términos se incorporan y se hacen una parte de estos
                    Términos de uso.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">Indemnización.</span> Usted
                acepta indemnizar, defender y eximir de responsabilidad a las
                Empresas de Bohiio, sus afiliadas y sus respectivos directores,
                funcionarios, empleados y agentes de todas y cada una de las
                reclamaciones y demandas realizadas por terceros debido a o que
                surjan de: (a) su acceso o uso de los Servicios; (b) su
                incumplimiento de estos Términos de uso; (c) su violación de
                cualquier ley o los derechos de un tercero; (d) cualquier
                disputa o problema entre usted y un tercero; (e) cualquier
                Material de usuario que cargue o ponga a disposición a través de
                los Servicios; (f) su mala conducta intencional; y (g) acceso o
                uso de los Servicios por parte de terceros utilizando su cuenta
                y contraseña. Bohiio LLC se reservan el derecho, a su cargo, de
                asumir la defensa y el control exclusivos de cualquier asunto
                que de otro modo esté sujeto a indemnización de su parte y, en
                ese caso, usted acepta asociarse con la defensa de las Empresas
                de Bohiio de esa afirmación.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">Sin garantías.</span> LAS
                EMPRESAS DE BOHIIO PROPORCIONAN LOS SERVICIOS “TAL CUAL”, “CON
                TODAS LAS FALLAS” Y “SEGÚN ESTÉN DISPONIBLE”, Y EL RIESGO TOTAL
                EN CUANTO A LA CALIDAD, EL RENDIMIENTO, LA EXACTITUD Y EL
                ESFUERZO SATISFACTORIOS CORRE CON USTED. EN LA MÁXIMA MEDIDA
                PERMITIDA POR LA LEY APLICABLE, LAS EMPRESAS DE BOHIIO Y SUS
                PROVEEDORES NO HACEN DECLARACIONES, GARANTÍAS O CONDICIONES,
                EXPRESAS O IMPLÍCITAS. LAS EMPRESAS DE BOHIIO Y SUS PROVEEDORES
                RECHAZAN EXPRESAMENTE TODAS LAS GARANTÍAS O CONDICIONES,
                EXPRESAS, ESTATUTARIAS E IMPLÍCITAS, INCLUYENDO SIN LIMITACIÓN:
                (A) GARANTÍAS O CONDICIONES DE COMERCIABILIDAD, ADECUACIÓN PARA
                UN PROPÓSITO PARTICULAR, DESARROLLO, DESEMPEÑO, TRABAJO NO HAY
                GRAVAMENES, GRAVAMENES Y NO INFRACCIÓN; (B) GARANTÍAS O
                CONDICIONES QUE SURJAN A TRAVÉS DEL CURSO DE NEGOCIACIÓN O USO
                COMERCIAL; Y (C) GARANTÍAS O CONDICIONES DE ACCESO O USO
                ININTERRUMPIDO O SIN ERRORES. NINGÚN CONSEJO O INFORMACIÓN, YA
                SEA ORAL O ESCRITO, OBTENIDO POR USTED A TRAVÉS DE LOS SERVICIOS
                O CUALQUIER MATERIAL DISPONIBLE A TRAVÉS DE LOS SERVICIOS CREARÁ
                CUALQUIER GARANTÍA CON RESPECTO A LA ENTIDAD DE CUALQUIER
                EMPRESA BOHIIO O LOS SERVICIOS QUE NO SE INDIQUE EXPRESIVAMENTE
                EN ESTOS TÉRMINOS DE USO. USTED ASUME TODOS LOS RIESGOS POR
                CUALQUIER DAÑO QUE PUEDA RESULTAR DE SU USO O ACCESO A LOS
                SERVICIOS, SU TRATO CON CUALQUIER OTRO USUARIO Y CUALQUIER
                MATERIAL, INCLUYENDO TODOS LOS MATERIALES DEL USUARIO Y DE LAS
                EMPRESAS BOHIIO, DISPONIBLES A TRAVÉS DE LOS SERVICIOS. USTED
                ENTIENDE Y ACEPTA QUE SU USO DE LOS SERVICIOS, Y EL USO, ACCESO,
                DESCARGA U OBTENCIÓN DE MATERIALES A TRAVÉS DE LOS SERVICIOS Y
                CUALQUIER SITIO O SERVICIO ASOCIADO, ES BAJO SU PROPIA
                DISCRECIÓN Y RIESGO, Y QUE USTED ES EL ÚNICO RESPONSABLE DE
                CUALQUIER DAÑO A SU PROPIEDAD (INCLUYENDO SU SISTEMA DE
                COMPUTADORA O DISPOSITIVO MÓVIL UTILIZADO EN RELACIÓN CON LOS
                SERVICIOS), O LA PÉRDIDA DE DATOS QUE RESULTEN DEL USO DE LOS
                SERVICIOS O DE LA DESCARGA O USO DE DICHOS MATERIALES. ALGUNAS
                JURISDICCIONES PUEDEN PROHIBIR UNA EXENCIÓN DE RESPONSABILIDAD O
                GARANTÍAS Y USTED PUEDE TENER OTROS DERECHOS QUE VARÍAN DE
                JURISDICCIÓN A JURISDICCIÓN.{" "}
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  Limitación de responsabilidad / recurso exclusivo.
                </span>{" "}
                EN NINGÚN CASO LAS EMPRESAS DE BOHIIO O CUALQUIERA DE SUS
                FILIALES SERÁN RESPONSABLES DE CUALQUIER DAÑO INDIRECTO,
                CONSECUENCIAL, ESPECIAL, INCIDENTAL O PUNITIVO (INCLUYENDO DAÑOS
                POR PÉRDIDA DE BENEFICIOS, FONDO DE VOLUNTAD O CUALQUIER OTRA
                PÉRDIDA INTANGIBLE) QUE SURJA DE, O COMO RESULTADO DE ESTOS
                TÉRMINOS DE USO O SU USO O ACCESO, O INCAPACIDAD PARA USAR O
                ACCEDER A LOS SERVICIOS O CUALQUIER MATERIAL DE LOS SERVICIOS,
                YA SEA BASADO EN: (A) INCUMPLIMIENTO DEL CONTRATO; (B)
                INCUMPLIMIENTO DE GARANTÍA; (C) NEGLIGENCIA; O (D) CUALQUIER
                OTRA CAUSA DE ACCIÓN, INCLUSO SI LAS EMPRESAS DE BOHIIO HAN SIDO
                ADVERTIDAS DE LA POSIBILIDAD DE DICHOS DAÑOS. EN LA MEDIDA
                MÁXIMA PERMITIDA POR LA LEY APLICABLE, LAS EMPRESAS DE BOHIIO NO
                ASUMEN RESPONSABILIDAD NI RESPONSABILIDAD POR CUALQUIER (I)
                ERROR, ERROR O INEXACTITUD DE LOS MATERIALES; (II) LESIONES
                PERSONALES O DAÑOS A LA PROPIEDAD, DE CUALQUIER NATURALEZA, QUE
                RESULTEN DE SU ACCESO O USO DE LOS SERVICIOS; (III) CUALQUIER
                ACCESO NO AUTORIZADO O USO DE NUESTROS SERVIDORES SEGUROS Y / O
                CUALQUIER INFORMACIÓN PERSONAL ALMACENADA EN ELLOS; (IV)
                CUALQUIER INTERRUPCIÓN O CESE DE TRANSMISIÓN HACIA O DESDE LOS
                SERVICIOS; (V) CUALQUIER ERROR, VIRUS, CABALLOS TROYANOS O
                SIMILARES QUE PUEDAN SER TRANSMITIDOS A O A TRAVÉS DE NUESTROS
                SERVICIOS POR CUALQUIER TERCERO; (VI) CUALQUIER ERROR U OMISIÓN
                EN CUALQUIER MATERIAL O POR CUALQUIER PÉRDIDA O DAÑO INCURRIDO
                COMO RESULTADO DEL USO DE CUALQUIER MATERIAL PUBLICADO, ENVIADO
                POR CORREO ELECTRÓNICO, TRANSMITIDO O DE OTRA MANERA DISPONIBLE
                A TRAVÉS DE LOS SERVICIOS; O (VII) MATERIALES DEL USUARIO O LA
                CONDUCTA DIFAMATORIA, OFENSIVA O ILEGAL DE CUALQUIER TERCERO. LA
                RESPONSABILIDAD TOTAL DE LAS EMPRESAS DE BOHIIO Y CUALQUIERA DE
                SUS AFILIADAS ANTE USTED POR TODAS LAS RECLAMACIONES QUE SURJAN
                O ESTÉN RELACIONADAS CON EL USO O LA INCAPACIDAD DE USO DE
                CUALQUIER PARTE DE LOS SERVICIOS O DE OTRO MODO BAJO ESTOS
                TÉRMINOS DE USO, YA SEA POR CONTRATO, AGRAVIO , O DE OTRO MODO,
                SE LIMITA A LO MAYOR DE: (1) LA CANTIDAD QUE HAYA PAGADO A LAS
                EMPRESAS DE BOHIIO POR LOS SERVICIOS EN LOS 12 MESES ANTES DE
                LOS EVENTOS O CIRCUNSTANCIAS QUE DARON DERECHO A LOS RECLAMOS; O
                (2) $ 100. ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O
                LIMITACIÓN DE RESPONSABILIDAD POR DAÑOS CONSECUENTES. POR
                CONSIGUIENTE, LAS LIMITACIONES ANTERIORES PUEDEN NO APLICARSE EN
                SU CASO. CADA DISPOSICIÓN DE ESTOS TÉRMINOS DE USO QUE DISPONE
                UNA LIMITACIÓN DE RESPONSABILIDAD, RENUNCIA DE GARANTÍAS O
                EXCLUSIÓN DE DAÑOS TIENE LA INTENCIÓN Y ASIGNA LOS RIESGOS ENTRE
                LAS PARTES BAJO ESTOS TÉRMINOS. ESTA ASIGNACIÓN ES UN ELEMENTO
                ESENCIAL DEL ACUERDO DE LAS PARTES. LAS LIMITACIONES DE ESTA
                SECCIÓN SE APLICARÁN INCLUSO SI CUALQUIER RECURSO LIMITADO FALLA
                SU PROPÓSITO ESENCIAL.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  Elección de la ley; Disputas.
                </span>{" "}
                Estos Términos de uso se rigen por las leyes del estado de
                Washington, sin dar efecto a las disposiciones sobre conflicto
                de leyes. Usted acepta someterse a la jurisdicción y competencia
                personal y exclusiva de los tribunales estatales y federales con
                sede en Mission, Texas, EE. UU. para todas y cada una de las
                disputas, reclamos y acciones que surjan de o en conexión con
                los Servicios o de otro modo bajo estos Términos de uso. Las
                Empresas del grupo Bohiio operan los Servicios desde sus
                oficinas en Mission, Texas y no garantizamos que los Servicios
                sean apropiados o estén disponibles para su uso en otros
                lugares.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">General.</span> Usted acepta
                no exportar desde ningún lugar ninguna parte de los Servicios
                que se le brindan, o cualquier producto directo de los mismos,
                excepto en cumplimiento y con todas las licencias y aprobaciones
                requeridas por las leyes, reglas y regulaciones de exportación
                aplicables. Todos los servicios utilizados por el gobierno de
                los EE. UU. Se proporcionan con los derechos de licencia
                comercial descritos en este documento. Estos Términos de uso
                solo pueden modificarse mediante un acuerdo escrito firmado por
                representantes autorizados de las partes de estos Términos de
                uso. Si se determina que alguna parte de estos Términos de uso
                es inválida o inaplicable, entonces la disposición inválida o
                inaplicable será reemplazada por una disposición válida y
                exigible que se asemeje más a la intención de la disposición
                original y el resto de estos Términos de uso será continuar en
                efecto. Los títulos de las secciones en estos Términos de uso se
                utilizan únicamente para la conveniencia de las partes y no
                tienen ningún significado legal o contractual. Podemos ceder
                este Acuerdo, en su totalidad o en parte, en cualquier momento
                con o sin aviso a usted. No puede ceder estos Términos de uso,
                ni ceder, transferir o sublicenciar sus derechos, si los
                hubiera, en los Servicios. Nuestra falta de actuación con
                respecto a una infracción, o nuestra elección de renunciar a la
                infracción, por usted u otros, no renuncia a su derecho a actuar
                con respecto a infracciones posteriores o similares. Salvo que
                se indique expresamente en el presente, estos Términos de uso, y
                todos los términos y acuerdos expresamente incorporados,
                constituyen el acuerdo completo entre usted y las Empresas de
                Bohiio con respecto a los Servicios y reemplazan todas las
                comunicaciones anteriores o contemporáneas de cualquier tipo
                entre usted y las Empresas de Bohiio. con respecto a los
                Servicios.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  Consentimiento para las comunicaciones.
                </span>{" "}
                Al utilizar los Servicios, usted acepta recibir determinadas
                comunicaciones electrónicas de nuestra parte, tal como se
                describe con más detalle en la Política de privacidad. Lea la
                Política de privacidad para obtener más información. Usted
                acepta que cualquier aviso, acuerdo, divulgación, encuesta u
                otra comunicación que le enviemos electrónicamente satisfará
                todos los requisitos legales de comunicación, incluido que esas
                comunicaciones se realicen por escrito.
              </p>
              <p className="poppins-light-16">
                Los Servicios pueden proporcionar formularios web, enlaces o
                información de contacto, incluidos números de teléfono, que
                pueden conectarlo con las Empresas de Bohiio o con terceros,
                como agentes inmobiliarios y Terceros proveedores. Las
                comunicaciones a través de estos métodos pueden enrutarse a
                través de un servicio de terceros (“Servicio de
                comunicaciones”). Las llamadas se pueden grabar o monitorear con
                fines de garantía de calidad, capacitación o servicio al
                cliente. Se le notificará al comienzo de una llamada si se puede
                grabar o monitorear. Usted da su consentimiento para dicha
                grabación y supervisión por parte de las Empresas Bohiio o el
                Servicio de Comunicaciones. También usamos el Servicio de
                Comunicaciones para rastrear llamadas telefónicas y mensajes de
                texto entre usted y los profesionales de bienes raíces para que
                nosotros y el profesional de bienes raíces podamos acceder a
                ciertos detalles sobre el contacto. Como parte de este proceso,
                nosotros y el Servicio de Comunicaciones recibiremos en tiempo
                real y almacenaremos datos sobre su llamada o mensaje de texto,
                incluida la fecha y hora de la llamada o mensaje de texto. su
                número de teléfono y el contenido del mensaje de texto. Usted da
                su consentimiento a estas prácticas por nuestra parte y por el
                Servicio de Comunicaciones. La información está sujeta a la
                Política de privacidad.{" "}
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  Aviso a los residentes de California.
                </span>{" "}
                Si es residente de California, según la Sección 1789.3 del
                Código Civil de California, puede comunicarse con la Unidad de
                Asistencia para Quejas de la División de Servicios al Consumidor
                del Departamento de Asuntos del Consumidor de California por
                escrito en 1625 N. Market Blvd., Suite S-202, Sacramento ,
                California 95834, o por teléfono al (800) 952-5210 para resolver
                una queja relacionada con el Servicio o para recibir más
                información sobre el uso del Servicio.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  Información de contacto y licencia Divulgaciones.
                </span>{" "}
                Los Servicios son ofrecidos por Bohiio LLC y sus afiliadas,
                ubicadas en 1314 E 28th St Mission, TX 78574, EE. UU. Puede
                comunicarse con las Empresas de Bohiio enviando correspondencia
                a esa dirección o enviando un correo electrónico a
                hola@bohiio.com. Para ver una lista completa de las licencias de
                corretaje de bienes raíces de Bohiio LLC.
              </p>
            </li>
            <li>
              <p className="poppins-light-16">
                <span className="poppins-bold-16">
                  Aviso para los usuarios de Apple.
                </span>{" "}
                Si está utilizando nuestras aplicaciones móviles en un
                dispositivo iOS, se aplican los términos de esta Sección 22.
                Usted reconoce que estos Términos de uso son únicamente entre
                usted y las Empresas de Bohiio, no con Apple, y Apple no es
                responsable de los Servicios ni de los Materiales relacionados.
                Apple no tiene la obligación de proporcionar ningún servicio de
                mantenimiento o soporte con respecto a los Servicios. Si los
                Servicios no cumplen con cualquier garantía aplicable, puede
                notificar a Apple y Apple le reembolsará cualquier precio de
                compra aplicable para la aplicación móvil; y, en la medida
                máxima permitida por la ley aplicable, Apple no tiene ninguna
                otra obligación de garantía con respecto a los Servicios. Apple
                no es responsable de abordar ninguna reclamación suya o de un
                tercero en relación con los Servicios o su posesión o uso de los
                Servicios, incluidos: (a) reclamaciones de responsabilidad por
                productos; (b) cualquier reclamo de que los Servicios no se
                ajustan a cualquier requisito legal o reglamentario aplicable; y
                (c) reclamaciones derivadas de la protección del consumidor o
                similar legislación. Apple no es responsable de la
                investigación, defensa, resolución o descargo de cualquier
                reclamo de terceros de que los Servicios o su posesión y uso de
                la aplicación móvil infrinjan los derechos de propiedad
                intelectual de ese tercero. Acepta cumplir con los términos
                aplicables de terceros al utilizar los Servicios. Apple y las
                subsidiarias de Apple son terceros beneficiarios de la Sección
                22 de estos Términos de uso, y tras su aceptación de estos
                Términos o uso, Apple tendrá el derecho (y se considerará que ha
                aceptado el derecho) de hacer cumplir esta Sección 22 de estos
                Condiciones de uso en su contra. Por la presente, declara y
                garantiza que: (i) no se encuentra en un país que esté sujeto a
                un embargo del gobierno de los EE. UU., O que esté en el Título
                15, Parte 740 Suplemento 1, Grupo de países E del Código de
                Regulaciones Federales de EE. UU.; y (ii) no figura en ninguna
                lista del gobierno de EE. UU. de partes prohibidas o
                restringidas.
              </p>
            </li>
          </ol>
        </TermsContent>
        <Footer />
      </div>
    </>
  );
};

export default Terms;
