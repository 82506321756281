import { ErrorMessage, Field, FieldAttributes } from "formik";
import { FloatingInput } from "../styles/common";
import { useState } from "react";
import ErrorText from "./ErrorText";
import eye from "../images/eye.svg";
import eyeSlash from "../images/eye-slash.svg";

export interface PasswordFieldProps extends FieldAttributes<any> {
  label?: string;
}
export default function PasswordField(props: PasswordFieldProps) {
  const [passwordVisibilty, setPasswordVisibility] = useState(false);
  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibilty);
  };
  return (
    <>
      <FloatingInput className="input-group form-floating">
        <Field
          {...props}
          type={passwordVisibilty ? "text" : "password"}
          className={`form-control border-end-0 ${props.className || ""}`}
          aria-describedby="password-icon"
        />
        <label htmlFor={props.name} style={{ zIndex: 5 }}>
          {props.label}
        </label>
        <span
          className="input-group-text bg-transparent border-start-0"
          onClick={changePasswordVisibility}
        >
          <img
            src={passwordVisibilty ? eyeSlash : eye}
            alt="field icon"
            className="opacity-50"
            style={{ height: "25px", width: "25px" }}
          />
        </span>
      </FloatingInput>
      <ErrorMessage name={props.name}>
        {(msg) => <ErrorText>{msg}</ErrorText>}
      </ErrorMessage>
    </>
  );
}
