import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Property } from "../context/property/types";
import OptionsModal from "./OptionsModal";
import { useContext, useState } from "react";
import SharePropertyModal from "./SharePropertyModal";
import DeletePropertyConfirmationModal from "./DeletePropertyConfirmationModal";
import DeactivateCommentsConfirmationModal from "./DeactivateCommentsConfirmationModal";
import HidePropertyConfirmationModal from "./HidePropertyConfirmationModal";
import ReportPropertyModal from "./ReportPropertyModal";
import { AuthContext } from "../context/auth/AuthContext";
import { CONSTANTS } from "../utils";

const OptionMenu = styled.div`
  background: white;
  border-radius: 5px;
  text-align: center;
  div {
    border-bottom: 2px solid gray;
    padding: 1rem 4rem;
    cursor: pointer;
  }
  div:first-child {
    color: red;
    font-weight: 700;
  }
  div:last-child {
    font-weight: 700;
    border-bottom: 0px;
  }
`;

interface IPropertyOptionsModalProps {
  property: Property;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
  handleClose?: Function;
  openCertificationModal: (
    propertyId: number,
    isPropertyOwner: boolean
  ) => void;
  deleteProperty: Function;
}

const PropertyOptionsModal = ({
  active,
  closeModal,
  darkerBackground,
  property,
  handleClose,
  openCertificationModal,
  deleteProperty,
}: IPropertyOptionsModalProps) => {
  const history = useHistory();
  const [showSharePropertyModal, setShowSharePropertyModal] = useState(false);
  const [showDeletePropertyModal, setShowDeletePropertyModal] = useState(false);
  const [showHidePropertyModal, setShowHidePropertyModal] = useState(false);
  const [showReportPropertyModal, setShowReportPropertyModal] = useState(false);
  const [showDeactivateCommentsModal, setShowDeactivateCommentsModal] =
    useState(false);

  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <OptionMenu className="poppins-regular-12">
          {property?.owner.myself && (
            <>
              {property.certificationStatus ===
                CONSTANTS.WITHOUT_CERTIFICATION &&
                property.region === "DO" && (
                  <div
                    onClick={() =>
                      openCertificationModal(property.id, property.owner.myself)
                    }
                  >
                    Certificar propiedad
                  </div>
                )}
              {/* <div
                onClick={() => {
                  history.push(`/add-property`, {
                    currentProperty: property,
                  });
                }}
              >
                Editar publicación
              </div> */}
              <div onClick={() => setShowDeletePropertyModal(true)}>
                Eliminar publicación
              </div>
              {/* <div onClick={() => setShowDeactivateCommentsModal(true)}>
                Desactivar comentarios
              </div> */}
              {/* <div
                onClick={() => {
                  setShowHidePropertyModal(true);
                }}
              >
                Ocultar publicación
              </div> */}
              <div
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    `https://bohiio.com/property/${property.id}`
                  );
                  closeModal();
                }}
              >
                Copiar enlace
              </div>
              <div
                onClick={() => {
                  closeModal();
                  setShowSharePropertyModal(true);
                }}
              >
                Compartir
              </div>
              <div onClick={closeModal}>Cancelar</div>
            </>
          )}
          {!property?.owner.myself && (
            <>
              {isAuthenticated && (
                <>
                  <div
                    onClick={() => {
                      setShowReportPropertyModal(true);
                    }}
                  >
                    Reportar publicación
                  </div>
                </>
              )}
              <div
                onClick={() => {
                  history.push(`/profile/${property?.owner.username}`);
                }}
              >
                Ir al perfil del vendedor
              </div>
              <div
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    `https://bohiio.com/property/${property?.id}`
                  );
                  closeModal();
                }}
              >
                Copiar enlace
              </div>
              <div
                onClick={() => {
                  closeModal();
                  setShowSharePropertyModal(true);
                }}
              >
                Compartir
              </div>
              <div onClick={closeModal}>Cancelar</div>
            </>
          )}
        </OptionMenu>
      </OptionsModal>
      <SharePropertyModal
        darkerBackground
        propertyUrl={`https://bohiio.com/property/${property?.id}`}
        isActive={showSharePropertyModal}
        onHide={() => setShowSharePropertyModal(false)}
      />
      <DeletePropertyConfirmationModal
        darkerBackground
        active={showDeletePropertyModal}
        closeModal={() => {
          setShowDeletePropertyModal(false);
        }}
        property={property}
        handleClose={handleClose}
        onDeleteProperty={deleteProperty}
      />
      <DeactivateCommentsConfirmationModal
        darkerBackground
        active={showDeactivateCommentsModal}
        closeModal={() => {
          setShowDeactivateCommentsModal(false);
        }}
        property={property}
      />
      <HidePropertyConfirmationModal
        darkerBackground
        active={showHidePropertyModal}
        closeModal={() => {
          setShowHidePropertyModal(false);
        }}
        property={property}
      />
      <ReportPropertyModal
        darkerBackground
        active={showReportPropertyModal}
        closeModal={() => {
          setShowReportPropertyModal(false);
        }}
        property={property}
      />
    </>
  );
};

export default PropertyOptionsModal;
