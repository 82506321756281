import { useContext, useState } from "react";
import styled from "styled-components";
import { ProfileContext } from "../context/profile/ProfileContext";
import { ButtonRed } from "../styles/common";
import { PaymentContext } from "../context/payment/PaymentContext";
import LoadingModal from "./LoadingModal";
import BaseModal from "./BaseModal";
import { getPriceNotation } from "../utils";

const Button = styled.button`
  border: 1px rgba(0, 0, 0, 0) solid;
  width: 100%;
  background: white;
  color: #d2190e;
  text-align: center;
  margin: 0 auto;
`;

interface ICertifyPaymentOptionModalProps {
  active: boolean;
  closeModal: () => void;
  propertyCertificationInfo: any;
  openOrderCompleteModal: any;
  openReceiptModal: () => void;
  openCertificationNotSupportedModal: () => void;
  price?: number;
  discountedPrice?: number;
  priceCurrency?: string;
  discountedPriceCurrency?: string;
  darkerBackground?: boolean;
}

const CertifyPaymentOptionModal = (props: ICertifyPaymentOptionModalProps) => {
  const { profile } = useContext(ProfileContext);
  const { verification, standaloneVerification, addLoadPercentage } =
    useContext(PaymentContext);
  const [loading, setLoading] = useState(false);

  const requestVerification = async (useCredit: boolean) => {
    if (props.propertyCertificationInfo.propertyId) {
      await verification(
        useCredit,
        props.propertyCertificationInfo.propertyId,
        props.openOrderCompleteModal
      );
    } else {
      try {
        setLoading(true);
        props.closeModal();
        await standaloneVerification(
          useCredit,
          props.propertyCertificationInfo,
          props.openOrderCompleteModal,
          props.openCertificationNotSupportedModal
        );
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingModal type="bar" percentage={addLoadPercentage} />}
      <BaseModal
        active={props.active}
        closeModal={props.closeModal}
        darkerBackground={props.darkerBackground ?? false}
        id="certify-payment"
      >
        <div
          className="row"
          style={{
            width: "100%",
            margin: "auto",
            position: "relative",
            maxWidth: "380px",
          }}
        >
          <div
            className="col-12 bg-white poppins-light-14 p-5"
            style={{
              border: "1px solid rgba(0, 0, 0, 0)",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <p className="poppins-medium-16">
              <span className="poppins-bold-16">
                Aprovecha nuestra oferta <br />
              </span>
              <span>
                <span style={{ color: "#D2190E" }}>-40% </span>
                de descuento en verificaciones.
              </span>
            </p>
            {props.propertyCertificationInfo.isPropertyOwner ? (
              <p>
                Brinda a tus clientes mayor seguridad y tranquilidad para tomar
                esa decisión final con tu propiedad.
              </p>
            ) : (
              <p>
                La verificación implica una evaluación jurídica y catastral de
                los inmuebles. Durante este proceso, revisamos si la propiedad
                tiene su documentación en regla y si hay algún inconveniente
                relacionado con la ubicación o los linderos.
              </p>
            )}
            <p className="poppins-semibold-14 text-start">Costo regular</p>
            <p
              className="bebas-neue-book-40"
              style={{ textDecorationLine: "line-through" }}
            >
              RD$ 3,300.00{props.priceCurrency}
              {props.price ? getPriceNotation(props.price) : ""}
            </p>
            <hr style={{ background: "black", opacity: "1" }} />
            <p className="poppins-semibold-14 text-start">Paga ahora</p>
            <p className="bebas-neue-bold-40">
              RD$ 1,990.00{props.discountedPriceCurrency}
              {props.discountedPrice
                ? getPriceNotation(props.discountedPrice)
                : ""}
            </p>
            <ButtonRed
              className="poppins-semibold-14 mb-3 w-100"
              onClick={() => requestVerification(false)}
            >
              ¡Pagar ahora!
            </ButtonRed>
            {(profile?.profile.verifications ?? -1) > 0 && (
              <Button
                className="poppins-semibold-14"
                onClick={() => requestVerification(true)}
              >
                Usar mi crédito
              </Button>
            )}
          </div>
          {/* <div className="col-2 clickable" onClick={props.closeModal}>
          <Icon className="clickable" alt="X" src={crossIcon} />
        </div> */}
        </div>
      </BaseModal>
    </>
  );
};

export default CertifyPaymentOptionModal;
