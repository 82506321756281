import { createContext, FC } from "react";
import axios_engine from "../../api/engine";
import API from "../../api/endpoint";
import { Vendor } from "./types";

interface IVendorContext {
  getRecommendedVendors: (country?: string) => Promise<Vendor[]>;
}
export const VendorContext = createContext<IVendorContext>(
  {} as IVendorContext
);

export const VendorProvider: FC = ({ children }) => {
  const getRecommendedVendors = async (country = "") => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_recommended_vendors}${
          country ? `?country=${country}` : ""
        }`
      );

      return res.data.results;
    } catch (error) {}
  };
  return (
    <VendorContext.Provider value={{ getRecommendedVendors }}>
      {children}
    </VendorContext.Provider>
  );
};
