import styled from "styled-components";
import avatar from "../images/avatar.svg";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Tendency } from "../context/property/types";
import { PropertyContext } from "../context/property/PropertyContext";
import paths from "../routes/paths.json";
import {
  ALL_COUNTRIES_CODE,
  useLocalstorageContext,
} from "../context/localstorage/LocalstorageContext";

const TendencyImg = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

const TextLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const DasboardTendency = (props: any) => {
  const [storage] = useLocalstorageContext();
  const [hashtags, setHashtags] = useState<Tendency[]>([]);
  const [photo, setPhoto] = useState<any>({});
  const { getTendencies, getPropertiesByTendency } =
    useContext(PropertyContext);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const country =
        storage.countryCode !== ALL_COUNTRIES_CODE ? storage.countryCode : "";
      const res = (await getTendencies(country)) ?? [];
      if (mounted) {
        setHashtags(res);
        if (res.length > 0) {
          const res2 = await getPropertiesByTendency(res[0].tendency);
          setPhoto(
            res2.results[0].media?.find((element: any) => {
              return element.type === "IMAGE";
            })
          );
        }
      }
    })();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);

  return (
    <>
      <div className="d-flex flex-column mx-3">
        {hashtags.length > 0 && (
          <>
            <div className="d-flex align-items-center py-5">
              <TextLink
                className="poppins-semibold-md-54 poppins-semibold-40"
                to={paths.TENDENCY_PROPERTIES.replace(
                  ":tendency",
                  hashtags[0] ? hashtags[0].tendency ?? "" : ""
                )}
              >
                <TendencyImg alt="tendecy" src={photo.filename ?? avatar} />
              </TextLink>
              <div style={{ maxWidth: "calc(100% - 100px)" }}>
                <TextLink
                  className="poppins-semibold-md-54 poppins-semibold-40"
                  to={paths.TENDENCY_PROPERTIES.replace(
                    ":tendency",
                    hashtags[0] ? hashtags[0].tendency ?? "" : ""
                  )}
                  style={{
                    display: "block",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  #{hashtags[0] ? hashtags[0].tendency ?? "" : ""}
                </TextLink>
                <TextLink
                  to={paths.TENDENCY_PROPERTIES.replace(
                    ":tendency",
                    hashtags[0] ? hashtags[0].tendency ?? "" : ""
                  )}
                >
                  <div>
                    <span
                      className="poppins-bold-26"
                      style={{ color: "#D2190E" }}
                    >
                      {hashtags[0] ? hashtags[0].total_publications ?? "" : ""}
                    </span>{" "}
                    <span className="poppins-light-26">Propiedades</span>
                  </div>
                </TextLink>
              </div>
            </div>
            <div className="my-2 me-2 mb-5">
              <div className="d-flex flex-wrap poppins-medium-16">
                {hashtags.slice(1).map((hashtag, index) => {
                  return (
                    <TextLink
                      to={paths.TENDENCY_PROPERTIES.replace(
                        ":tendency",
                        hashtag.tendency
                      )}
                      className="px-2"
                      key={index}
                    >
                      <span style={{ color: "#D2190E" }}>#</span>
                      {hashtag.tendency}
                    </TextLink>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {hashtags.length === 0 && (
          <div className="px-3 px-md-none" style={{ marginTop: "220px" }}>
            <div className="text-center">
              <p className="poppins-bold-20">
                Aún no hay tendencias para mostrar
              </p>
              <p className="poppins-medium-16">
                Se el primero en{" "}
                <TextLink
                  style={{ textDecoration: "underline" }}
                  to={paths.ADDPROPERTY}
                >
                  publicar
                </TextLink>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DasboardTendency;
