import { forwardRef } from "react";
import "../styles/scroller-style.css";
export type ScrollerSwapAxys = "x" | "y";
export type ScrollerStrictness = "mandatory" | "proximity";
export type ScrollerSnapAlign = "start" | "end" | "center";
export type ScrollerSnapStop = "always" | "normal";
export const SCROLLER_CONTAINER_CLASSNAME = "scroller-container";

export interface ScrollerItem {
  id: string;
  children: React.ReactNode;
}
export interface ScrollerProps {
  items: ScrollerItem[];
  swapAxys?: ScrollerSwapAxys;
  strictness?: ScrollerStrictness;
  snapAlign?: ScrollerSnapAlign;
  snapStop?: ScrollerSnapStop;
  centerItems?: boolean;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
}

export function debounce(func: any, timeout = 300) {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply({}, args);
    }, timeout);
  };
}

const Scroller = forwardRef(function (
  {
    items,
    swapAxys = "y",
    strictness = "mandatory",
    snapAlign = "center",
    snapStop = "always",
    centerItems = false,
    onScroll = () => {},
  }: ScrollerProps,
  ref: any
) {
  return (
    <div
      onScroll={debounce(onScroll, 50)}
      className={`scroller scroller-snap-${swapAxys} scroller-snap-${strictness} full-space`}
      ref={ref}
    >
      {items.map((item) => (
        <div
          key={item.id}
          className={` ${SCROLLER_CONTAINER_CLASSNAME} scroller-snap-${snapStop} scroller-snap-${snapAlign} full-space ${
            centerItems ? "scroller-items-center" : ""
          }`}
        >
          {item.children}
        </div>
      ))}
    </div>
  );
});

export default Scroller;
