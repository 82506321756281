import { useContext } from "react";
import Navbar from "../../components/Navbar";
import SimpleFooter from "../../components/SimpleFooter";
import { ButtonRed } from "../../styles/common";
import { ProfileContext } from "../../context/profile/ProfileContext";

function CancelPlanFeedback() {
  const { profile } = useContext(ProfileContext);
  return (
    <div className="d-flex flex-column justify-content-md-between main-container">
      <Navbar />
      <div className="d-flex overflow-auto mx-3">
        <div className="col-12 offset-md-2 col-md-5 poppins-regular-14">
          <div className="poppins-bold-28 my-3">
            {profile?.first_name}, lamentamos que hayas cancelado tu plan
          </div>
          <div className="poppins-medium-18" style={{ color: "red" }}>
            Cancelaste tu suscripcion por algunas de estas razones:
          </div>
          <div className="my-2">
            <div>
              <input type="radio" name="Input1" id="input1" value="ans1" />
              <label htmlFor="input1">
                No estoy obteniendo los resultados que esperaba
              </label>
            </div>
            <div>
              <input type="radio" name="Input2" id="input2" value="ans2" />
              <label htmlFor="input2"> No estoy a gusto con el servicio </label>
            </div>
            <div>
              <input type="radio" name="Input3" id="input3" value="ans3" />
              <label htmlFor="input3">
                No estoy obteniendo ningun beneficio por el plan
              </label>
            </div>
            <div>
              <input type="radio" name="Input4" id="input4" value="ans4" />
              <label htmlFor="input4"> Me parece costoso el servicio </label>
            </div>
            <div>
              <input type="radio" name="Input5" id="input5" value="ans5" />
              <label htmlFor="input5"> Otro </label>
            </div>
          </div>
          <div className="my-3" style={{ color: "gray", width: "80%" }}>
            Gracias por tomar el tiempo valoramos mucho tu opinión, cuéntanos
            cómo podríamos mejorar nuestros servicios en este formulario
          </div>
          <div>
            <textarea
              style={{ width: "100%", lineHeight: "normal" }}
            ></textarea>
          </div>
          <div>
            <ButtonRed> Enviar </ButtonRed>
          </div>
        </div>
      </div>
      <SimpleFooter />
    </div>
  );
}

export default CancelPlanFeedback;
