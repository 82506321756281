import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PropertyContext } from "../context/property/PropertyContext";
import { Property } from "../context/property/types";
import styled from "styled-components";
import crossIcon from "../images/cross-white.svg";
import PropertyInfo from "./PropertyInfo";

const Icon = styled.img`
  height: 18px;
  width: 18px;
`;

const PropertyInfoContainer = styled.div`
  @media screen and (min-width: 768px) {
    height: 100%;
  }
  height: auto;
`;

interface PropertyInfoModalProps {
  active: boolean;
  closeModal?: () => void;
  property?: any;
}

const PropertyInfoModal = ({
  active,
  closeModal,
  property,
}: PropertyInfoModalProps) => {
  const history = useHistory();
  const location = useLocation();
  const params: any = useParams();

  const { getProperty } = useContext(PropertyContext);

  const [currentProperty, setCurrentProperty] = useState<Property | null>(null);

  useEffect(() => {
    if (location.state?.property) {
      setCurrentProperty(location.state.property);
    } else if (property) {
      setCurrentProperty(property);
    } else {
      (async () => {
        setCurrentProperty(await getProperty(params.id));
      })();
    }
  }, []);

  const handleModalHide = () => {
    history.go(-1);

    if (closeModal) {
      closeModal();
    }
  };

  return (
    <>
      <Modal
        contentClassName="property-detail-background"
        show={active}
        onHide={handleModalHide}
        fullscreen
      >
        <Modal.Body style={{ padding: "1rem 0" }}>
          <PropertyInfoContainer className="d-flex justify-content-center">
            {currentProperty && (
              <div
                className="col-12 col-lg-10 px-0"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "10px",
                }}
              >
                <PropertyInfo
                  property={currentProperty}
                  closeModal={handleModalHide}
                  zoom={18}
                />
              </div>
            )}
            <div className="d-none d-lg-inline ms-2" onClick={handleModalHide}>
              <Icon className="clickable" alt="X" src={crossIcon} />
            </div>
          </PropertyInfoContainer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PropertyInfoModal;
