import styled from "styled-components";
import language from "../images/language-bw.svg";
import avatar from "../images/avatar.svg";
import compass from "../images/compass.svg";
import verifiedUser from "../images/verified-user.svg";
import housePhoto from "../images/house.png";
import apartmentPhoto from "../images/apartment.png";
import commercialLocalPhoto from "../images/commercial.png";
import pieceOfLandPhoto from "../images/piece-of-land.png";
import farmPhoto from "../images/farm.png";
import industrialShipPhoto from "../images/industrial-ship.png";
import villaPhoto from "../images/villa.png";
import leftIcon from "../images/left.svg";
import rightIcon from "../images/right.svg";
import { LanguageContext } from "../context/language/LanguageContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import { AuthContext } from "../context/auth/AuthContext";
import AuthenticationModal from "./AuthenticationModal";
import { VendorContext } from "../context/vendor/VendorContext";
import { Vendor } from "../context/vendor/types";
import { Tendency } from "../context/property/types";
import { PropertyContext } from "../context/property/PropertyContext";
import { ButtonRed, ScrollTable, TextLink } from "../styles/common";
import paths from "../routes/paths.json";
import { ProfileContext } from "../context/profile/ProfileContext";
import {
  ALL_COUNTRIES_CODE,
  useLocalstorageContext,
} from "../context/localstorage/LocalstorageContext";
import { AnswerCommentButton } from "./Comment";

const FixedDiv = styled.div`
  position: fixed;
  right: 3.5%;
  top: 70px;
  width: 31%;
  height: calc(100vh - 75px);
  overflow-y: auto;
  padding: 0 12px;
  z-index: 1;
`;

const ExplorerDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: white;
  text-align: center;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  &:hover {
    opacity: 1;
  }
`;

const SmallIcon = styled.img`
  height: 15px;
  width: 15px;
`;

const Icon = styled.img`
  height: 15px;
  width: 15px;
`;

const VendorImg = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const WhiteCircle = styled.img`
  position: absolute;
  color: grey;
  border: 1px white solid;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  font: 700 2rem/2.4rem Poppins; //poppins-bold-32
  cursor: pointer;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  &:hover {
    background: white;
  }
  z-index: 1;
`;

const ExplorerPhotos = styled.div`
  height: 100px;
  width: 100px;
  min-width: 100px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  & img {
    width: 100%;
    height: 100%;
  }
`;

const ButtonHover = styled.span`
  &:hover {
    color: #065fd4;
  }
`;

// const ButtonRed = styled.button`
//   border: 1px #d2190e solid;
//   background: #f9f9f9;
//   color: black;
//   margin: 20px 0px;
//   padding 0.5rem 0.75rem;
//   border-radius: 5px;
//   transition: 0.2s;
//   &:hover {
//     background: #d2190e;
//     color: white;
//   }
// `;

const DashboardSidebar = (props: any) => {
  const { getRecommendedVendors } = useContext(VendorContext);
  const [currentLanguage, changeLanguage] = useContext(LanguageContext);
  const { getTendencies } = useContext(PropertyContext);
  const history = useHistory();
  const [showLogin, setShowLogin] = useState(false);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [hashtags, setHashtags] = useState<Tendency[]>([]);
  const closeLoginModal = () => setShowLogin(false);
  const explorerRef = useRef<HTMLDivElement>(null);
  const [storage] = useLocalstorageContext();
  useEffect(() => {
    const country =
      storage.countryCode !== ALL_COUNTRIES_CODE ? storage.countryCode : "";
    (async () => {
      setHashtags((await getTendencies(country))?.slice(0, 10) ?? []);
    })();
    (async () => {
      setVendors((await getRecommendedVendors(country))?.slice(0, 5) ?? []);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);

  return (
    <>
      <FixedDiv className="d-none d-md-block pt-3">
        <p className="poppins-semibold-20">Explora cerca de ti</p>
        <WhiteCircle
          src={leftIcon}
          style={{
            marginTop: "40px",
            left: 2,
          }}
          onClick={() => {
            if (explorerRef.current) {
              explorerRef.current.scrollTo({
                left:
                  explorerRef.current.scrollLeft -
                  explorerRef.current.clientWidth,
                top: 0,
                behavior: "smooth",
              });
            }
          }}
          alt="left icon"
        />
        <WhiteCircle
          src={rightIcon}
          style={{
            marginTop: "40px",
            right: 2,
          }}
          onClick={() => {
            if (explorerRef.current) {
              explorerRef.current.scrollTo({
                left:
                  explorerRef.current.scrollLeft +
                  explorerRef.current.clientWidth,
                top: 0,
                behavior: "smooth",
              });
            }
          }}
          alt="right icon"
        />
        <div className="overflow-hidden d-flex mb-3" ref={explorerRef}>
          <ExplorerPhotos
            key="home"
            onClick={() =>
              history.push("/map?propertyType=house", { propertyType: "house" })
            }
          >
            <img src={housePhoto} alt="" />
            <ExplorerDiv>Casas</ExplorerDiv>
          </ExplorerPhotos>
          <ExplorerPhotos
            key="apartment"
            onClick={() =>
              history.push("/map?propertyType=apartment", {
                propertyType: "apartment",
              })
            }
          >
            <img src={apartmentPhoto} alt="" />
            <ExplorerDiv>Apartamentos</ExplorerDiv>
          </ExplorerPhotos>
          <ExplorerPhotos
            key="commercial"
            style={{}}
            onClick={() =>
              history.push("/map?propertyType=businessPremise", {
                propertyType: "businessPremise",
              })
            }
          >
            <img src={commercialLocalPhoto} alt="" />
            <ExplorerDiv>Locales Comerciales</ExplorerDiv>
          </ExplorerPhotos>
          <ExplorerPhotos
            key="land"
            style={{}}
            onClick={() =>
              history.push("/map?propertyType=pieceOfLand", {
                propertyType: "pieceOfLand",
              })
            }
          >
            <img src={pieceOfLandPhoto} alt="" />
            <ExplorerDiv>Terrenos</ExplorerDiv>
          </ExplorerPhotos>
          <ExplorerPhotos
            key="villa"
            onClick={() =>
              history.push("/map?propertyType=villa", { propertyType: "villa" })
            }
          >
            <img src={villaPhoto} alt="" />
            <ExplorerDiv>Villas</ExplorerDiv>
          </ExplorerPhotos>
          <ExplorerPhotos
            key="farm"
            onClick={() =>
              history.push("/map?propertyType=farm", { propertyType: "farm" })
            }
          >
            <img src={farmPhoto} alt="" />
            <ExplorerDiv>Fincas</ExplorerDiv>
          </ExplorerPhotos>
          <ExplorerPhotos
            key="ship"
            onClick={() =>
              history.push("/map?propertyType=industrialShip", {
                propertyType: "industrialShip",
              })
            }
          >
            <img src={industrialShipPhoto} alt="" />
            <ExplorerDiv>Naves Industriales</ExplorerDiv>
          </ExplorerPhotos>
        </div>
        <p className="poppins-semibold-20 mb-1">Tendencias</p>
        <div className="d-flex flex-wrap mb-1 gap-1">
          {hashtags.map((hashtag, index) => {
            return (
              <TextLink
                to={`/tendency/${hashtag.tendency}`}
                className=""
                key={index}
              >
                <span style={{ color: "#D2190E" }}>#</span>
                {hashtag.tendency}
              </TextLink>
            );
          })}
        </div>
        <div onClick={() => history.push("/tendency")}>
          <ButtonHover className="poppins-bold-12 clickable px-0">
            Ver mas tendencias
          </ButtonHover>
        </div>
        <p className="poppins-semibold-20 mt-4">Vendedores recomendados</p>
        <div className="poppins-medium-12">
          {vendors.map((vendor, index) => {
            return (
              <div className="d-flex my-2" key={index}>
                <TextLink
                  to={paths.USER_PROFILE.replace(":username", vendor.username)}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <VendorImg
                    className="me-2"
                    src={vendor.profile.avatar ?? avatar}
                    alt="avatar"
                  />
                </TextLink>
                <div className="d-flex align-items-center ">
                  <TextLink
                    to={paths.USER_PROFILE.replace(
                      ":username",
                      vendor.username
                    )}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <span className="poppins-bold-18">
                        {vendor.first_name} {vendor.last_name}
                      </span>
                      {vendor.profile.subscription &&
                        vendor.profile.subscription.name.toLowerCase() !==
                          "free" && (
                          <SmallIcon
                            className="ms-2"
                            src={verifiedUser}
                            alt="verified"
                          />
                        )}
                    </div>

                    <div className="">
                      <span className="me-2 poppins-light-11">
                        @{vendor.username} {vendor.properties} propiedades
                      </span>
                    </div>
                  </TextLink>
                </div>
              </div>
            );
          })}
        </div>
        <div onClick={() => history.push("/vendors")}>
          <ButtonHover className="poppins-bold-12 clickable px-0">
            Ver mas vendedores
          </ButtonHover>
        </div>
        <div className="mt-4">
          <div className="poppins-bold-20">Genera ingresos con Bohiio</div>
          <a href={`${process.env.REACT_APP_LANDING_LINK}#clients`}>
            <ButtonRed className="poppins-regular-16 mt-2 mb-3">
              Más información
            </ButtonRed>
          </a>
        </div>
        {/* <div className="px-2 pt-2 poppins-light-12">Certifica tu propiedad</div> */}
        <div className="d-flex flex-wrap poppins-light-12 pb-2 align-items-center gap-2">
          <TextLink className="" to={paths.ABOUT_US}>
            Quienes somos
          </TextLink>
          <div className="">Preguntas frecuentes</div>
          <TextLink className="" to={paths.PRIVACY}>
            Privacidad
          </TextLink>
          <TextLink className="" to={paths.TERMS}>
            Términos
          </TextLink>
          <div
            className=" clickable"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:help@bohiio.com?subject=Help";
            }}
          >
            Ayuda
          </div>
          <div
            className="poppins-light-12 d-flex align-items-center gap-1 clickable"
            onClick={() =>
              currentLanguage === "es"
                ? changeLanguage("en")
                : changeLanguage("es")
            }
          >
            <Icon src={language} alt="language" />
            <Trans i18nKey="footer.bar.6" />
          </div>
        </div>
        <div className="poppins-light-12">
          Bohiio LLC, Mission, TX 78574, EE. UU.
        </div>
      </FixedDiv>
      <AuthenticationModal active={showLogin} closeModal={closeLoginModal} />
    </>
  );
};

export default DashboardSidebar;
