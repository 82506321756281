import { ButtonRed } from "../styles/common";

export interface ResetPasswordAccountModalProps {
  email: string;
  closeModal: () => void;
}
export default function ResetPasswordAccountModal(
  props: ResetPasswordAccountModalProps
) {
  return (
    <div style={{ textAlign: "center", padding: "30px 0" }}>
      <h4 style={{ fontWeight: "bold" }} className="mb-4">
        Te hemos enviado un email
      </h4>
      <p className="mb-3">
        Te enviamos un correo electrónico con instrucciones para restablecer la
        contraseña a <label style={{ fontWeight: "bold" }}>{props.email}</label>
        . Si no lo ves en tu bandeja de entrada, revisa la carpeta de correo no
        deseado.
      </p>
      <ButtonRed
        type="button"
        className="w-100"
        onClick={props.closeModal}
        style={{ borderRadius: "5px" }}
      >
        Aceptar
      </ButtonRed>
    </div>
  );
}
