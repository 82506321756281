import { ReactNode, createContext } from "react";
import axios_engine from "../../api/engine";
import { NotificationResponse, Notification } from "./types";
import API from "../../api/endpoint";

interface INotificationContext {
  getNotifications: (page?: number) => Promise<Notification[]>;
  getNotificationCount: () => Promise<number>;
  getNewNotifications: () => Promise<NotificationResponse>;
}

export const NotificationContext = createContext<INotificationContext>(
  {} as INotificationContext
);

interface ProviderProps {
  children: ReactNode;
}

export const NotificationProvider = ({ children }: ProviderProps) => {
  const PAGE_LIMIT = 20;

  const getNotifications = async (page?: number) => {
    try {
      let url = `${process.env.REACT_APP_BOHIIO_API}${API.notifications}`;

      if (typeof page === "number") {
        url += `?limit=${PAGE_LIMIT}&offset=${page * PAGE_LIMIT}`;
      }

      let res = await axios_engine.get(url);
      return res.data.results;
    } catch (e) {
      //throw new Error("Couldn't get the notifications");
    }
  };

  const getNewNotifications = async () => {
    try {
      let res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.new_notifications}`
      );
      return res.data;
    } catch (e) {
      //throw new Error("Couldn't get the notifications");
    }
  };

  const getNotificationCount = async () => {
    try {
      let res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.notification_count}`
      );
      return res.data.new;
    } catch (e) {
      //throw new Error("Couldn't get the notification count");
    }
  };

  return (
    <NotificationContext.Provider
      value={{ getNotifications, getNewNotifications, getNotificationCount }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
