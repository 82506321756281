import { useState } from "react";
import { Property } from "../context/property/types";
import HideLocationCompleteModal from "./HideLocationCompleteModal";
import OptionsModal from "./OptionsModal";

interface IHidePropertyConfirmationModalProps {
  property: Property;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const HidePropertyConfirmationModal = ({
  active,
  closeModal,
  darkerBackground,
  property,
}: IHidePropertyConfirmationModalProps) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <div className="poppins-regular-12 text-center">
          <div style={{ padding: "0 2rem" }}>
            <p className="poppins-bold-12">¿Quieres ocultar la publicación?</p>
            <p>
              Cuando la publicación esta oculta el inmueble no se mostrará en tu
              perfil y no aparecerá en los resultados de nuestro motro de
              busqueda.
            </p>
          </div>
          <div
            style={{
              borderBottom: "2px solid gray",
              padding: "1rem 4rem",
              cursor: "pointer",
              color: "red",
              fontWeight: 700,
            }}
            onClick={() => {
              //TODO: hide property call
              setShowCompleteModal(true);
            }}
          >
            Ocultar publicación
          </div>
          <div
            style={{
              padding: "1rem 4rem",
              cursor: "pointer",
              fontWeight: 700,
            }}
            onClick={closeModal}
          >
            Cancelar
          </div>
        </div>
      </OptionsModal>
      <HideLocationCompleteModal
        darkerBackground
        active={showCompleteModal}
        closeModal={() => {
          setShowCompleteModal(false);
          closeModal();
        }}
      />
    </>
  );
};

export default HidePropertyConfirmationModal;
