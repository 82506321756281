import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { LanguageContext } from "../context/language/LanguageContext";
import { AuthContext } from "../context/auth/AuthContext";
import logo from "../images/logo.svg";
import menu from "../images/menu.svg";
import avatar from "../images/avatar.svg";
import publish from "../images/publish.svg";
import publishActive from "../images/publish-active.svg";
import notifications from "../images/notifications.svg";
import notificationsActive from "../images/notifications-active.svg";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import AuthenticationModal from "./AuthenticationModal";
import Avatar from "./Avatar";
import paths from "../routes/paths.json";
import { ProfileContext } from "../context/profile/ProfileContext";
import NavbarMenu from "./NavbarMenu";
import SearchBar from "./SearchBar";
import { NotificationContext } from "../context/notification/NotificationContext";
import SelectField from "./SelectField";
import countryCodes, { CountryProperty } from "country-codes-list";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import {
  ALL_COUNTRIES_CODE,
  LocalStorageAction,
  useLocalstorageContext,
} from "../context/localstorage/LocalstorageContext";
import { LOCAL_STORAGE_NAME } from "../App";
import useScreen from "../hooks/useScreen";

const NavbarBody = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 50px;
  }
`;

//change the containing div for this if a sticky header is needed and add extra div on the pages
const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1026;
  padding: 2.5px 15px 2.5px 15px;
  background-color: white;

  @media screen and (min-width: 768px) {
    padding: 2.5px 15px 2.5px 15px;
    height: 52px;
  }
`;

const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  transition: 0.2s;
  border-radius: 100%;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const Icon = styled.img`
  height: 25px;
  width: 25px;
  position: relative;
`;

const Spacer: any = styled.div<{ mobileHeight?: any }>`
  @media screen and (min-width: 768px) {
    height: 52px;
  }
  height: ${(props) => props.mobileHeight ?? "105.5px"};
`;

const SearchBarContainer = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 600px;
    width: 60%;
  }
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const UserOptionContainer = styled.div`
  gap: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    max-width: 400px;
    gap: 30px;
  }
`;

const UserNavigationContainer = styled.div`
  gap: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    gap: 20px;
  }
`;

const SelectCountryContainer = styled.span`
  background-color: #f9f9f9;
  border-radius: 5px;
  display: block;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 200px;
    width: 200px;
  }
`;

const SelectCountryContent = styled.span`
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  height: 1.5em;
  align-items: center;
  width: 1.2em;
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 170px;
  }
`;

interface INavbarProps {
  mobileHeight?: string;
  searchBarColor?: string;
  searchBarWidth?: string;
  className?: string;
  searchBarToRight?: boolean;
  searchBarOption?: React.ReactNode;
}

interface CountryOptions {
  id: string;
  children: React.ReactNode;
  data: {
    code: string;
    name: unknown;
  };
}

const Navbar = ({
  mobileHeight,
  searchBarColor,
  searchBarWidth,
  className,
  searchBarToRight,
  searchBarOption,
}: INavbarProps) => {
  const [storage, dispatch] = useLocalstorageContext();
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [nextPage, setNextPage] = useState("/");
  const [navbarMenu, setNavbarMenu] = useState(false);
  const [newNotifications, setNewNotifications] = useState(0);
  const { isAuthenticated } = useContext(AuthContext);
  const { getNotificationCount } = useContext(NotificationContext);
  const { isMobile } = useScreen();

  const countriesOptions = useMemo(() => {
    const options = Object.entries(
      countryCodes.customList(
        "countryCode" as CountryProperty,
        "{countryNameLocal}"
      )
    ).filter(([countryCode, _]: [string, unknown]) => hasFlag(countryCode));

    options.push([ALL_COUNTRIES_CODE, "Todos los países"]);
    options.sort((a, b) => {
      if (a[0] === "DO" || a[0] === ALL_COUNTRIES_CODE) return -1;
      else if (b[0] === "DO" || b[0] === ALL_COUNTRIES_CODE) return 1;
      else return 0;
    });
    return options.map(([countryCode, countryName]) => ({
      id: countryCode,
      children: (
        <div
          style={{
            padding: "2px 5px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: "1em",
            color: "#7a7a7a",
          }}
        >
          {countryCode !== ALL_COUNTRIES_CODE
            ? getUnicodeFlagIcon(countryCode)
            : ""}{" "}
          {!isMobile ? countryName : ""}
        </div>
      ),
      data: {
        code: countryCode,
        name: countryName,
      },
    }));
  }, []);

  const [selectedCountry, setSelectedCountry] = useState<CountryOptions>(
    countriesOptions.find((r) => r.id === storage.countryCode) ||
      countriesOptions[0]
  );

  useContext(LanguageContext);
  const { profile } = useContext(ProfileContext);
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = (nextPage: string) => {
    setNextPage(nextPage);
    setShowLogin(true);
  };
  const openNavbarMenu = () => setNavbarMenu(true);
  const closeNavbarMenu = () => setNavbarMenu(false);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isAuthenticated) {
        let notificationCount = await getNotificationCount();
        if (isMounted) {
          setNewNotifications(notificationCount);
        }
      } else if (isMounted) {
        setNewNotifications(0);
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    setCollapsed(!(location === "" && window.innerWidth > 992));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className={className}>
        <NavbarBody className="d-flex py-1 bg-white align-items-center">
          <div className="d-flex" style={{ gap: "15px" }}>
            <div>
              <Icon src={menu} alt="menu" onClick={handleCollapsedChange} />
            </div>
            <a href={process.env.REACT_APP_LANDING_LINK} className="clickable">
              <img src={logo} alt="logo" style={{ width: "100px" }} />
            </a>
          </div>
          <div className="w-100 order-md-0 order-2 d-md-flex justify-content-center">
            <div
              className="d-inline d-md-none order-1"
              style={{ flexBasis: "100%" }}
            >
              <hr style={{ margin: "0.7em 0" }} />
            </div>
            <SearchBarContainer className="order-2 order-md-0 mt-md-0 w-100">
              <SearchBar
                searchBarColor={searchBarColor}
                searchBarWidth={searchBarWidth}
                searchBarToRight={searchBarToRight}
              />
              {searchBarOption}
            </SearchBarContainer>
          </div>
          <UserOptionContainer>
            <div>
              <SelectCountryContainer>
                <SelectField
                  onSearch={(options, text) =>
                    options.filter((value: any) =>
                      value.data.name
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .includes(
                          text
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .toLowerCase()
                        )
                    )
                  }
                  options={countriesOptions}
                  onSelect={(option) => {
                    setSelectedCountry(
                      countriesOptions.find((value) => value.id === option.id)!
                    );

                    dispatch({
                      type: LocalStorageAction.update,
                      id: LOCAL_STORAGE_NAME,
                      data: { id: "countryCode", value: option.id },
                    });
                  }}
                  noOptions={
                    <div className="text-center p-3">
                      No encontramos tu país
                    </div>
                  }
                >
                  <SelectCountryContent>
                    {selectedCountry.data.code !== ALL_COUNTRIES_CODE
                      ? getUnicodeFlagIcon(selectedCountry.data.code)
                      : ""}{" "}
                    {selectedCountry.data.name}
                  </SelectCountryContent>
                </SelectField>
              </SelectCountryContainer>
            </div>
            <UserNavigationContainer>
              <IconContainer
                className="clickable"
                onClick={
                  isAuthenticated
                    ? () => {
                        history.push(paths.ADDPROPERTY);
                      }
                    : () => openLoginModal(paths.ADDPROPERTY)
                }
              >
                <Icon
                  src={location === "add-property" ? publishActive : publish}
                  alt="add property"
                  className=""
                />
              </IconContainer>
              <IconContainer
                className="clickable"
                style={{
                  position: "relative",
                }}
                onClick={
                  isAuthenticated
                    ? () => {
                        history.push(paths.NOTIFICATIONS);
                      }
                    : () => openLoginModal(paths.NOTIFICATIONS)
                }
              >
                {location === "notifications" && (
                  <Icon
                    src={notificationsActive}
                    alt="notifications"
                    className=""
                  />
                )}
                {location !== "notifications" && (
                  <>
                    <Icon
                      src={notifications}
                      alt="notifications"
                      className=""
                    />

                    {newNotifications > 0 && (
                      <span
                        className="poppins-medium-12"
                        style={{
                          display: "inline-block",
                          background: "#dc2204",
                          color: "white",
                          borderRadius: "15px",
                          width: newNotifications > 9 ? "25px" : "20px",
                          position: "absolute",
                          top: "0",
                          right: "-3px",
                          border: "2px solid white",
                          textAlign: "center",
                        }}
                      >
                        {newNotifications > 9 ? `+${9}` : newNotifications}
                      </span>
                    )}
                  </>
                )}
              </IconContainer>
              <span
                className="clickable"
                style={{ paddingLeft: "5px" }}
                onClick={() => {
                  openNavbarMenu();
                }}
              >
                <Avatar
                  src={profile?.profile?.avatar ?? avatar}
                  alt="profile"
                  size="sm3"
                  className="mx-0"
                />
              </span>
            </UserNavigationContainer>
          </UserOptionContainer>
          <NavbarMenu
            isOpen={navbarMenu}
            closeMenu={closeNavbarMenu}
            openLoginModal={() => {
              openLoginModal(path);
              closeNavbarMenu();
            }}
          />
        </NavbarBody>
      </Container>
      <AuthenticationModal
        nextPage={nextPage}
        active={showLogin}
        closeModal={closeLoginModal}
      />
      <Spacer className={className} mobileHeight={mobileHeight} />
      <Sidebar
        onClick={handleCollapsedChange}
        className={className}
        collapsed={collapsed}
      />
    </>
  );
};

export default Navbar;
