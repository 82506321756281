import { Formik, Field, Form } from "formik";
import styled from "styled-components";

const Divider = styled.hr`
  background-color: black;
  opacity: 1;
`;

const ProfileNotifications = (props: any) => {
  return (
    <Formik
      initialValues={{
        likes: "",
        comments: "",
        likesInComment: "",
        savedPropertiesInteraction: "",
        mentions: "",
        followers: "",
        messages: "",
        notifications: "",
      }}
      onSubmit={() => {}}
    >
      <Form>
        <div>
          <p className="poppins-medium-22">Me gusta</p>
          <div className="poppins-light-14">
            <div>
              <Field type="radio" name="likes" value="deactivated" />
              Desactivadas
            </div>
            <div>
              <Field type="radio" name="likes" value="partial" />
              De personas a las que sigo
            </div>
            <div>
              <Field type="radio" name="likes" value="full" />
              De todos
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              A una persona le gusten tus propiedades.
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-22">Comentarios</p>
          <div className="poppins-light-14">
            <div>
              <Field type="radio" name="comments" value="deactivated" />
              Desactivadas
            </div>
            <div>
              <Field type="radio" name="comments" value="partial" />
              De personas a las que sigo
            </div>
            <div>
              <Field type="radio" name="comments" value="full" />
              De todos
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Una persona comente en tus propiedades.
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-22">Me gusta en comentarios</p>
          <div className="poppins-light-14">
            <div>
              <Field type="radio" name="likesInComments" value="deactivated" />
              Desactivadas
            </div>
            <div>
              <Field type="radio" name="likesInComments" value="followers" />
              De personas a las que sigo
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              A una persona le guste tu comentarios.
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-22">
            Interacción de propiedades favoritas
          </p>
          <div className="poppins-light-14">
            <div>
              <Field
                type="radio"
                name="savedPropertiesInteraction"
                value="deactivated"
              />
              Desactivadas
            </div>
            <div>
              <Field
                type="radio"
                name="savedPropertiesInteraction"
                value="partial"
              />
              De personas a las que sigo
            </div>
            <div>
              <Field
                type="radio"
                name="savedPropertiesInteraction"
                value="full"
              />
              De todos
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Todo lo que suceda con las propiedades que tengas como favoritas.
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-22">
            Menciones, comentarios y publicaciones
          </p>
          <div className="poppins-light-14">
            <div>
              <Field type="radio" name="mentions" value="deactivated" />
              Desactivadas
            </div>
            <div>
              <Field type="radio" name="mentions" value="partial" />
              De personas a las que sigo
            </div>
            <div>
              <Field type="radio" name="mentions" value="full" />
              De todos
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Cada vez que te mencionen en un comentario o en una publicación.
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-22">Seguimiento automático</p>
          <div className="poppins-light-14">
            <div>
              <Field type="radio" name="followers" value="deactivated" />
              Desactivadas
            </div>
            <div>
              <Field type="radio" name="followers" value="full" />
              De todos
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Restricción para las personas que quieran seguirte.
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-22">Mensajes</p>
          <div className="poppins-light-14">
            <div>
              <Field type="radio" name="messages" value="deactivated" />
              Desactivadas
            </div>
            <div>
              <Field type="radio" name="messages" value="full" />
              De todos
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Cuando las personas te envíen un mensaje directo.
            </p>
          </div>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-22">
            Avisos y notificaciones de b
            <span style={{ color: "#D2190E" }}>o</span>hiio
          </p>
          <div className="poppins-light-14">
            <div>
              <Field type="radio" name="messages" value="deactivated" />
              No recibir notificaciones
            </div>
            <div>
              <Field type="radio" name="messages" value="full" />
              Recibir todas
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              Recibir todas las notificaciones de bohiio.
            </p>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ProfileNotifications;
