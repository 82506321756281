//import logo from "../../images/logo.svg";
import styled from "styled-components";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const StylelessAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  &: hover {
    color: inherit;
  }
`;

const PrivacyContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: calc(100% - 60px);
    margin-left: 60px;
  }

  & table {
    table-layout: fixed;
  }

  & td {
    word-break: break-word;
  }
`;

const Privacy = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-between main-container">
        <Navbar />
        <PrivacyContainer className="text-justify poppins-light-16 px-3 my-3 px-md-2 my-md-5">
          <h1 className="poppins-regular-26">Privacidad</h1>
          <p className="poppins-bold-16 mt-4 mb-5">
            Fecha de vigencia: 23 de Septiembre de 2021
          </p>
          <p>
            Sabemos que al utilizar nuestra plataforma Bohiio para comprar,
            alquilar o vender una propiedad, o al conectarte con un profesional
            de bienes raíces, nos estás confiando sus datos. También sabemos que
            tenemos la responsabilidad de respetar su privacidad, por eso
            trabajamos arduamente en ello. Esta política explica qué información
            recopilamos, por qué la usamos, con quién la compartimos y cómo la
            protegemos, junto con las herramientas que puede utilizar para
            administrar su privacidad.
          </p>
          <ul
            className="poppins-bold-16"
            style={{ listStyleType: "none", paddingLeft: 0 }}
          >
            <li>
              <StylelessAnchor href="#introduction">
                Introducción
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#gathered-info">
                Información recopilada por Bohiio
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#info-usage">
                Por qué Bohiio usa su información
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#sharing-info">
                Compartiendo su información
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#tools-and-privacy">
                Herramientas y opciones de privacidad
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#info-protection">
                Protegiendo su información
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#policy-applications">
                Aplicabilidad de esta política
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#legal-disclosures">
                Divulgaciones legales
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#contact-us">
                Contactando a Bohiio
              </StylelessAnchor>
            </li>
            <li>
              <StylelessAnchor href="#policy-changes">
                Cambios a esta política
              </StylelessAnchor>
            </li>
          </ul>
          <h2 id="introduction" className="poppins-bold-16 mt-5 mb-4">
            Introducción
          </h2>
          <p>
            Tenemos una amplia gama de productos y servicios. El propósito es
            cubrir todas las necesidades de nuestros clientes, durante el ciclo
            de vida de sus inmuebles. Los principales servicios que podemos
            ofrecer son: alquiler, compra, venta, financiamiento y mejoramiento
            de propiedades inmobiliarias.
          </p>
          <p>
            Sabemos que la decisión de elegir un nuevo hogar es una de las
            decisiones mas importantes que se toman en la vida, por eso tenemos
            el compromiso de hacer que tu experiencia al tomar esta decisión sea
            única. Por esto te brindamos una plataforma segura y actualizada con
            una gran cantidad de información sobre hogares, vecindarios,
            condiciones del mercado, profesionales inmobiliarios locales y
            opciones de financiamiento, también usamos la información que nos
            brinda para mejorar tu experiencia de servicio y ayudarle a
            encontrar esa propiedad con la que sueña.
          </p>
          <p>
            Evaluamos cuidadosamente cómo usamos los datos para asegurarnos de
            que estamos usando su información para brindarle valor. En esta
            página, nuestro objetivo es explicar todo lo que necesita saber
            sobre su privacidad en Bohiio. Si tiene alguna pregunta que no
            hayamos respondido aquí, siempre puede contactarnos en{" "}
            <a href="mailto:privacy@bohiio.com">privacy@bohiio.com</a>
          </p>
          <h2 id="gathered-info" className="poppins-bold-16 mt-5 mb-4">
            Información recopilada por Bohiio
          </h2>
          <p>
            Cuando utiliza nuestros servicios, recopilamos una variedad de
            información sobre usted y sus dispositivos. Parte de esta
            información lo identifica directamente (como una dirección de correo
            electrónico), mientras que parte de ella está asociada con usted a
            través de su cuenta, perfil o dispositivo (como las casas que elige
            guardar o los datos de su ubicación, las propiedades que les gusta y
            las propiedades en las que comenta).
          </p>
          <p>
            El tipo de datos que recopilamos sobre usted depende de cómo utilice
            Bohiio. Si solo usa nuestros servicios para buscar propiedades,
            tendremos menos información sobre usted que si lo ayudamos a vender
            o alquilar su propiedad, obtener una hipoteca, firmar su contrato de
            arrendamiento o si usa cualquiera de nuestros otros servicios de
            transacciones. Incluso si no tiene una cuenta con nosotros, es
            posible que aún recopilemos algunos datos sobre usted, como su
            dirección de correo electrónico y número de teléfono si elige
            comunicarse con un agente de bienes raíces a través de nuestra
            plataforma, o su dirección IP y otra información del dispositivo
            cuando navega por nuestros sitios o utiliza nuestras aplicaciones.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Información que nos proporciona o crea utilizando nuestros servicios
          </h3>
          <p>
            Cuando utiliza nuestros servicios, a menudo le pedimos información
            sobre usted. Esto generalmente incluye cosas básicas como su nombre,
            dirección de correo electrónico y número de teléfono. Si utiliza
            nuestros servicios de transacciones, es posible que le solicitemos
            datos más confidenciales, como sus ingresos, datos bancarios y
            puntaje crediticio. Si aporta información, como reseñas de agentes,
            también la almacenaremos.
          </p>
          <p>
            También puede proporcionar información sobre otras personas a través
            de nuestros servicios. Por ejemplo, si comparte la ficha de una casa
            con alguien, recopilaremos la dirección de correo electrónico de esa
            persona como parte de la información de su cuenta.
          </p>
          <p>
            A continuación, se muestran algunos ejemplos de la información que
            recopilamos para nuestros principales productos y servicios y cómo
            la recopilamos.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Propiedades en tendencia
          </h3>
          <p>
            Propiedades en tendencia amplía las ofertas de las propiedades mas
            destacadas en las ciudades de búsqueda seleccionada tanto en EE. UU.
            como en el país de el cual se conecte. Si solicita una oferta por su
            propiedad, le pediremos información sobre usted y su propiedad. Esto
            incluye información de contacto (como una dirección de correo
            electrónico o un número de teléfono), información de identificación
            sobre usted y detalles sobre su hogar. También podemos tomar algunas
            fotos de su hogar para saber qué tipo de reparaciones pueden ser
            necesarias. Si aceptamos comprar su casa, necesitaremos recopilar
            más información de usted que sea típica de cualquier transacción de
            bienes raíces, incluida la información financiera
          </p>
          <p>
            Recopilamos información similar de contacto y relacionada con la
            transacción de usted si está interesado en comprar una casa
            propiedad de Bohiio. Si elige recorrer una casa propiedad de Bohiio,
            recopilamos información para verificar su identidad. Bohiio puede
            recopilar información sobre usted de terceros si es necesario para
            verificar su identidad.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">Inicio Buscar</h3>
          <p>
            Cuando utiliza el motor de búsqueda de propiedades de Bohiio para
            encontrar una propiedad, comprar o alquilar, le brindamos muchas
            herramientas para encontrar su próxima propiedad. Si nos dice lo que
            está buscando, como el tipo de la propiedad o las especificaciones,
            guardamos esa información para que podamos adaptar sus resultados de
            búsqueda a lo que está buscando.
          </p>
          <p>
            También lo ayudamos a conectarse con agentes inmobiliarios locales,
            constructores de inmobiliarios, administradores de propiedades,
            oficiales de préstamos hipotecarios y otros profesionales
            inmobiliarios. Si elige ponerse en contacto con uno de estos
            profesionales a través de nuestra plataforma, le pedimos su nombre,
            dirección de correo electrónico y número de teléfono para que
            podamos comunicarnos con usted y conectarlo con la persona adecuada.
          </p>
          <p>
            También puede reclamar una propiedad en Bohiio y editar datos de la
            casa, como los metros cuadrados, la distribución del inmueble y
            otras informaciones. Vinculamos la información que nos proporciona a
            su cuenta.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Alquiler de herramientas
          </h3>
          <p>
            Puede utilizar Bohiio para solicitar una vivienda de alquiler,
            firmar su contrato de arrendamiento e incluso pagar el alquiler.
            Cuando elige utilizar estas funciones, recopilamos información suya
            para procesar su transacción.
          </p>
          <p>
            Durante el proceso de solicitud de alquiler, recopilamos su
            información como su dirección de correo electrónico y número de
            teléfono, historial de empleo, historial de alquiler, ingresos y
            puntaje de crédito.Puede utilizar nuestras herramientas para obtener
            su informe de crédito y verificación de antecedentes penales para
            incluirlo en su solicitud, pero Bohiio no almacena su número de
            Seguro Social, Documento de identidad de su ciudad y no recopilamos
            ni vemos los informes de verificación de antecedentes o crédito. Nos
            asociamos con Experian, Checkr o cualquier institución de consulta
            en su país o región. El informe estará solo a disposición para usted
            y los propietarios a los que elija enviarlos.
          </p>
          <p>
            Cuando paga su alquiler a través de Bohiio, recopilamos la
            información de su transacción, pero no almacenamos los detalles de
            su cuenta de pago. En su lugar, utilizamos socios de confianza para
            recopilar y almacenar esos datos. Si utiliza su cuenta bancaria para
            pagar el alquiler, nuestro proveedor de servicios Stripe recopila
            información personal y financiera de su banco u otra institución
            financiera. Al utilizar Bohiio para realizar los pagos de su
            alquiler, le otorga a Bohiio y Stripe el derecho, el poder y la
            autoridad para actuar en su nombre para acceder y transmitir su
            información personal y financiera de la institución financiera
            correspondiente. Stripe transferirá, almacenará y procesará su
            información de acuerdo con la Política de privacidad de Stripe.
          </p>
          <p>
            Cuando paga su alquiler a través de Bohiio, recopilamos la
            información de su transacción, pero no almacenamos los detalles de
            su cuenta de pago. En su lugar, utilizamos socios de confianza para
            recopilar y almacenar esos datos. Si utiliza su cuenta bancaria para
            pagar el alquiler, nuestro proveedor de servicios Stripe recopila
            información personal y financiera de su banco u otra institución
            financiera. Al utilizar Bohiio para realizar los pagos de su
            alquiler, le otorga a Bohiio y Stripe el derecho, el poder y la
            autoridad para actuar en su nombre para acceder y transmitir su
            información personal y financiera de la institución financiera
            correspondiente. Stripe transferirá, almacenará y procesará su
            información de acuerdo con la Política de privacidad de Stripe.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Préstamos hipotecarios de Bohiio y servicios de cierre de Bohiio
          </h3>
          <p>
            Esta política de privacidad no se aplica a los préstamos
            hipotecarios de Bohiio ni a los servicios de cierre de Bohiio.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Servicios y productos para profesionales
          </h3>
          <p>
            Si es un profesional de bienes raíces, es posible que pueda conectar
            su cuenta de correo electrónico de terceros (como Gmail) a su cuenta
            de Bohiio. Si habilita esa conexión, Bohiio accederá a sus mensajes,
            contactos y configuraciones solo para brindarle los servicios
            solicitados. No accedemos ni recopilamos ninguno de los contenidos
            de sus mensajes de correo electrónico para proporcionar publicidad
            basada en intereses. Usamos la información recibida de las API de
            Gmail solo de conformidad con{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional-requirements-for-specific-api-scopes"
              target="_blank"
              rel="noreferrer"
            >
              https://developers.google.com/terms/api-services-user-data-policy#additional-requirements-for-specific-api-scopes
            </a>
          </p>
          <p>
            También recopilamos sus datos de contacto y pago, junto con
            información profesional como afiliación de corretaje, listados y
            ventas asociados e historial de licencias.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Información de fuentes públicas y terceros
          </h3>
          <p>
            Bohiio recopila información de fuentes públicas, como registros de
            impuestos y propiedades del condado y del estado, agencias
            gubernamentales y servicios de listados múltiples (o MLS). La mayor
            parte de esta información se refiere a propiedades, no a personas.
            Pero cierta información que recopilamos, como la identidad del
            propietario de una propiedad o la información de la licencia de
            bienes raíces, puede estar relacionada con personas. La demás
            información es suministrada directamente por los usuarios de la
            plataforma.
          </p>
          <p>
            También recopilamos información sobre usted de varios terceros para
            brindarle un mejor servicio, o como parte del proceso de solicitud
            de empleo. Esto puede incluir información demográfica y otros
            atributos sobre usted.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Información que recopilamos cuando utiliza nuestros servicios
          </h3>
          <p>
            Junto con la información que nos proporciona directamente,
            recopilamos una variedad de información automáticamente a medida que
            utiliza los servicios de Bohiio.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Información de la actividad
          </h3>
          <p>
            Recopilamos información sobre cómo usa Bohiio. Esto incluye cosas
            como el historial de búsqueda de su propiedad, las propiedades que
            ve, la actividad de compra, en qué ha hecho clic y otros usos de
            nuestras funciones, y la cantidad de tiempo que pasa mirando
            diferentes partes de nuestros sitios web. Cuando llama o envía un
            mensaje a través de Bohiio a un profesional de bienes raíces
            utilizando nuestra plataforma, recopilamos datos sobre su llamada o
            mensaje de texto, incluida la fecha y hora de la llamada o mensaje
            de texto, su número de teléfono y el contenido de la llamada o
            mensaje de texto.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Información del dispositivo
          </h3>
          <p>
            Al igual que otros sitios web y aplicaciones, recopilamos datos
            sobre los navegadores y dispositivos que utiliza para acceder a
            Bohiio. Los datos que recopilamos incluyen el modelo y la
            configuración del navegador o dispositivo, el sistema operativo, los
            identificadores únicos y la versión de la aplicación que está
            utilizando. También recopilamos datos sobre cómo interactúan sus
            navegadores y dispositivos con nuestros servicios, incluida la
            dirección IP, los informes de fallas, la actividad del sistema y la
            fecha, la hora y la URL del sitio que visitó antes de Bohiio.
          </p>
          <p>
            Puede controlar nuestra recopilación de ciertos datos, como el
            modelo de su dispositivo móvil o el idioma que usa su dispositivo
            móvil, ajustando la configuración de privacidad y seguridad en su
            dispositivo móvil.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Información sobre la ubicación
          </h3>
          <p>
            Si habilita los servicios de ubicación en su dispositivo móvil,
            Bohiio recopila la ubicación de su dispositivo. Usamos esa
            información para proporcionarle información y anuncios basados ​​en
            la ubicación, como casas y profesionales de bienes raíces en su
            área, y para ofrecer servicios basados ​​en la ubicación, como
            permitirle desbloquear y recorrer las propiedades de Bohiio ofrece
            en su propio horario y reclamar su propiedad en Bohiio. Si no desea
            utilizar estas funciones, puede desactivar los servicios de
            ubicación en su dispositivo.
          </p>
          <p>
            También recopilamos información de ubicación para fines similares
            desde su navegador si lo habilita. Puede deshabilitar el acceso a
            través de la configuración de su navegador.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Cookies, etiquetas de píxeles y otras tecnologías de seguimiento
          </h3>
          <p>
            Nosotros y nuestros socios utilizamos varias herramientas para
            recopilar datos cuando visita nuestros sitios y aplicaciones,
            incluidas cookies, etiquetas de píxeles y otras tecnologías
            similares. Las cookies son fragmentos de datos electrónicos que se
            pueden transferir a su computadora u otro dispositivo para
            identificar su navegador.
          </p>
          <p>
            Cuando usa Bohiio, nosotros y nuestros socios podemos usar cookies y
            otras herramientas para recopilar información sobre cómo ve y usa
            nuestros servicios y contenido, y para conectar su actividad con
            otros datos que almacenamos sobre usted. El uso de cookies nos ayuda
            a brindarle un mejor servicio al comprender lo que le interesa,
            realizar un seguimiento de las tendencias, medir la efectividad de
            los anuncios, guardar sus preferencias y almacenar información que
            tal vez desee recuperar de manera regular, como sus casas favoritas.
            También permitimos que socios específicos aprobados recopilen datos
            de su navegador o dispositivo con fines publicitarios y de medición
            utilizando sus propias cookies o herramientas similares.
          </p>
          <p>
            En cualquier momento, puede utilizar nuestras herramientas de
            preferencia de cookies para administrar los tipos de cookies y otras
            tecnologías de seguimiento con las que se siente cómodo. Consulte
            nuestro para obtener información sobre cómo acceder a estas
            herramientas. También puede deshabilitar las cookies por completo
            ajustando la configuración de su navegador. Sin embargo, si elige
            deshabilitar algunas o todas las cookies, es posible que muchas
            partes de nuestros servicios ya no funcionen.
          </p>
          <p>
            Además, si nos proporciona su dirección de correo electrónico,
            podemos utilizar un formulario codificado e ilegible (llamado
            versión “hash”) de su dirección de correo electrónico para enviarle
            anuncios personalizados en Bohiio o en otros sitios web, incluso a
            través de la audiencia personalizada de Facebook. Característica u
            otras herramientas similares. Haga clic{" "}
            <a
              href="https://www.facebook.com/about/ads/#types"
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/about/ads/#types
            </a>{" "}
            para obtener información sobre cómo puede controlar los anuncios que
            ve en Facebook.
          </p>
          <p>
            Para obtener información sobre cómo puede administrar sus
            preferencias de cookies, consulte la sección de esta política
            llamada “Sus herramientas y opciones de privacidad”.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">Redes sociales</h3>
          <p>
            Si utiliza inicios de sesión en redes sociales (como Google y
            Facebook) para iniciar sesión en Bohiio, podemos acceder a la
            información de su perfil público en las redes sociales. Siempre
            puede limitar este uso compartido utilizando la configuración de su
            servicio de redes sociales.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">Inferencias</h3>
          <p>
            Para mejorar nuestros servicios y hacerlos mas útiles para usted,
            utilizamos datos sobre su actividad para comprender e inferir sus
            preferencias. Por ejemplo, si normalmente busca una propiedad en
            alquiler en lugar de propiedades para comprar, podemos asumir que no
            está buscando comprar una casa y mostrarle más información útil para
            los inquilinos.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">Información comercial</h3>
          <p>
            Recopilamos información sobre los productos y servicios que compra o
            considera comprarnos. Esto incluye productos para profesionales,
            como nuestros productos publicitarios, así como servicios al
            consumidor de Bohiio.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">Información audiovisual</h3>
          <p>
            Si visita una casa propiedad de Bohiio, podemos capturar su imagen y
            voz usando cámaras y equipo de grabación de audio por razones de
            seguridad.
          </p>
          <h2 id="info-usage" className="poppins-bold-16 mt-5 mb-4">
            Por qué Bohiio usa su información / Completando sus transacciones
          </h2>
          <p>
            Cuando usa Bohiio para completar transacciones de bienes raíces,
            como comprar o vender su propiedad, solicitar una vivienda o local
            de alquiler o firmar un contrato de arrendamiento, usamos su
            información personal para verificar su identidad y procesar y
            completar su transacción.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Brindar y mejorar nuestros servicios
          </h3>
          <p>
            Usamos su información para brindarle nuestros servicios. Esto
            incluye cosas como devolver los resultados de la búsqueda a
            propiedades y procesar su solicitud de alquiler. También usamos su
            información para mejorar nuestros servicios existentes y desarrollar
            otros nuevos. Por ejemplo, rastreamos cómo usa nuestros sitios web y
            aplicaciones y usamos esa información para solucionar problemas y
            ajustar cosas para mejorar su experiencia.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Comunicarse contigo y conectarte con los demás
          </h3>
          <p>
            Usamos la información de contacto que nos proporciona, como su
            número de teléfono y dirección de correo electrónico, para
            comunicarnos con usted acerca de nuestros servicios. Esto incluye
            cosas como responder a comentarios, preguntas y solicitudes que nos
            envíe. También incluye notificarle sobre actualizaciones y cambios
            de transacciones o servicios. También podemos informarle sobre
            nuestros servicios, ofertas, promociones, noticias y otras
            actualizaciones que creemos que pueden ser de su interés.
          </p>
          <p>
            Si nos lo solicita, también usamos su información para conectarlo
            con profesionales de bienes raíces, cómo cuando desea comunicarse
            con un agente para discutir una casa que encuentra a la venta en
            nuestra plataforma.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Personalizando tu experiencia
          </h3>
          <p>
            Usamos la información que recopilamos sobre usted y su actividad en
            nuestros servicios para personalizar los servicios que ofrecemos.
            También lo usamos para mostrar publicidad, contenido o funciones que
            creemos que le pueden gustar. Esto incluye cosas como resultados de
            búsqueda personalizados que coinciden con sus preferencias y
            criterios de búsqueda anteriores y, si nos lo solicita, lo conecta
            con el profesional de bienes raíces más adecuado para ayudarlo.
          </p>
          <p>
            Sí sabemos que está trabajando con un agente de bienes raíces en
            particular, podemos compartir información sobre sus propiedades
            guardadas y búsquedas con ese agente para que pueda brindarle un
            servicio más eficaz. Tu controlas lo que compartimos con los
            profesionales inmobiliarios que encuentras en nuestra plataforma.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Otros usos de su información
          </h3>
          <p>
            También usamos sus datos para detectar, investigar y prevenir
            transacciones fraudulentas y otras actividades ilegales y para
            proteger los derechos y la propiedad de Bohiio, sus clientes y
            otros.
          </p>
          <p>
            Ocasionalmente, puede haber una razón por la que nos gustaría
            utilizar su información de una manera que no se describe aquí. Si
            eso sucede, se lo haremos saber para que pueda decidir si se siente
            cómodo con ello.
          </p>
          <h2 id="sharing-info" className="poppins-bold-16 mt-5 mb-4">
            Compartiendo su información
          </h2>
          <p>
            Entendemos que compartir información con terceros es una gran
            responsabilidad. Nos comprometemos a compartir información solo
            cuando sea necesario para un negocio legítimo o un propósito legal,
            y solo con destinatarios que la protegerán.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Información que nos pide que compartamos
          </h3>
          <p>
            Compartimos información cuando nos pide que la compartamos. Por
            ejemplo, si elige ponerse en contacto con un agente de bienes
            raíces, prestamista hipotecario, inversionista, constructor,
            administrador de propiedades u otro profesional de bienes raíces a
            través de nuestra plataforma, les enviaremos su nombre e información
            de contacto. Del mismo modo, sí solicita una vivienda de alquiler a
            través de Bohiio, la información de su solicitud se enviará a los
            propietarios que seleccione.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Proveedores de servicios y socios comerciales
          </h3>
          <p>
            Cuando Bohiio contrata a un proveedor de servicios para ayudar a
            operar nuestro negocio, es posible que necesitemos darles acceso a
            la información para brindar su servicio. Les permitimos usar la
            información solo para realizar el servicio que les pedimos que
            realicen. Examinamos cuidadosamente a todos los proveedores de
            servicios, y Bohiio siempre es responsable de la privacidad de su
            información que se comparte con nuestros proveedores de servicios.
          </p>
          <p>
            Cuando Bohiio se asocia con otras empresas para ofrecerle productos
            y servicios, podemos compartir información con esos socios solo
            cuando sea necesario para proporcionar esos productos y servicios y
            solo sujeto a los términos de esta política de privacidad.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Transferencias legales y de cumplimiento
          </h3>
          <p>
            Cuando Bohiio necesite compartir datos para satisfacer un requisito
            legal o reglamentario, incluida la respuesta a una citación u otra
            solicitud legal de datos del gobierno, compartiremos información
            solo cuando sea necesario para cumplir con ese requisito. Le
            informaremos antes de compartir su información en estas situaciones,
            a menos que tengamos prohibido hacerlo. Para obtener más información
            sobre cómo respondemos a las solicitudes de datos, consulte
            Solicitudes de información gubernamental y civil de su país. También
            podemos compartir información si es necesario para hacer cumplir
            nuestros derechos legales, detectar o prevenir fraudes o problemas
            de seguridad y proteger la seguridad pública.
          </p>
          <p>
            Si Bohiio está involucrado en una fusión, adquisición o cualquier
            forma de transferencia o venta de parte o la totalidad de su
            negocio, ya sea como empresa en marcha o como parte de una quiebra,
            liquidación o procedimiento similar, podemos compartir su
            información en relación con esa transacción.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Datos públicos y no identificados
          </h3>
          <p>
            Podemos compartir información que se recopila de fuentes públicas o
            que usted elige compartir públicamente (como revisiones públicas y
            datos domésticos) con terceros. También podemos compartir
            información agregada y / o no identificada que no se puede utilizar
            razonablemente para identificarlo.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Ventas de información personal
          </h3>
          <p>
            Bohiio permite que ciertas empresas coloquen tecnologías de
            seguimiento como cookies y píxeles en nuestros sitios, lo que les
            permite a esas empresas recibir información sobre su actividad en
            Bohiio que está asociada con su navegador o dispositivo. Las
            empresas pueden utilizar esos datos para ofrecerle anuncios más
            relevantes en nuestros sitios u otros. Según algunas leyes
            estatales, compartir datos para publicidad en línea como esta puede
            considerarse una “venta” de información. A excepción de este tipo de
            intercambio, Bohiio no vende su información.
          </p>
          <p>
            Siempre tienes el control total sobre si estas tecnologías funcionan
            en tus dispositivos. Puede desactivarlos por completo utilizando
            nuestras herramientas de preferencia de consentimiento de cookies
            (consulte el Centro de privacidad de Bohiio para obtener
            instrucciones) o haciendo clic en el enlace “No vender mi
            información” en nuestros sitios web.
          </p>
          <h2 id="tools-and-privacy" className="poppins-bold-16 mt-5 mb-4">
            Herramientas y opciones de privacidad
          </h2>
          <p>
            Hemos puesto a disposición una variedad de herramientas de
            privacidad en nuestros sitios. Con el Centro de privacidad de
            Bohiio, puede ver qué tipo de datos recopilamos sobre usted y los
            tipos de terceros con los que los compartimos. También puede
            descargar una copia de sus datos de Bohiio o cualquier otra empresa
            del grupo comercial y llevarla con usted a un servicio fuera de
            Bohiio. Si desea eliminar sus datos de Bohiio o cualquier otra
            empresa del grupo comercial o cerrar su cuenta, también puede
            hacerlo utilizando el Centro de privacidad de Bohiio.
          </p>
          <p>
            Proporcionamos estas herramientas para su beneficio y nunca lo
            discriminaremos por usarlas. Pero si elige eliminar sus datos o
            cerrar su cuenta, no podremos ofrecerle servicios que requieran que
            usemos sus datos.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Manejando la preferencia de cookies
          </h3>
          <p>
            Los servidores de anuncios de terceros, los proveedores de redes de
            anuncios y los anunciantes de terceros de Bohiio proporcionan
            contenido publicitario que aparece en Bohiio. Para medir qué tan
            efectivos son esos anuncios y hacerlos más relevantes, los
            proveedores de anuncios pueden usar sus propias tecnologías de
            seguimiento para recopilar su información. Se utilizan para
            registrar la actividad de los usuarios, como las páginas visitadas,
            y para saber qué tipos de información son de mayor interés para los
            usuarios. Para obtener más información sobre las opciones que tiene
            sobre estas tecnologías (como optar por no participar), visite el{" "}
            <a href="www.networkadvertising.org/" target="_blank">
              www.networkadvertising.org/
            </a>{" "}
            ,{" "}
            <a href="www.aboutads.info/choices/" target="_blank">
              www.aboutads.info/choices/
            </a>
            ,{" "}
            <a
              href="https://www.d1.sc.omtrdc.net/optout.html"
              target="_blank"
              rel="noreferrer"
            >
              https://www.d1.sc.omtrdc.net/optout.html
            </a>
            , y{" "}
            <a href="privacychoice.org/trackerblock" target="_blank">
              privacychoice.org/trackerblock
            </a>
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Administrar las preferencias de comunicación / Suscripción de correo
            electrónico
          </h3>
          <p>
            Bohiio puede enviarle correos electrónicos por diversas razones. Por
            ejemplo, si solicita un servicio de verificación para una propiedad,
            le enviaremos correos electrónicos con los de detalles y estatus de
            dicha solicitud. Si marca como favorita una propiedad, lo
            mantendremos informado sobre las actualizaciones de estado, como
            cuando esa propiedad tiene personas interesadas en ella o cuándo
            tiene una oferta pendiente. Cuando utilice nuestros servicios de
            transacciones, le enviaremos actualizaciones de estado y otra
            información sobre su transacción. Si tiene una cuenta con Bohiio,
            puede seleccionar sus preferencias a través de la configuración de
            su cuenta. Además, puede administrar los tipos de correo electrónico
            que recibe siguiendo las instrucciones incluidas en cada correo
            electrónico que le enviamos. Incluso si cancela su suscripción a
            algunas suscripciones por correo electrónico, es posible que aún
            necesitemos enviarle un correo electrónico con información
            transaccional importante u otra información no publicitaria.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Llamadas y mensajes de texto
          </h3>
          <p>
            Algunos de nuestros servicios le permiten proporcionarnos su número
            de teléfono. Si nos da su número, podemos llamarlo o enviarle un
            mensaje de texto, por ejemplo, para brindarle información sobre cómo
            obtener una oferta para su propiedad a través de Bohiio te lo
            compra. Nos comunicaremos con usted a través de estos métodos solo
            de acuerdo con los requisitos legales aplicables, y siempre puede
            optar por no recibir más llamadas o mensajes de texto.
          </p>
          <h2 id="info-protection" className="poppins-bold-16 mt-5 mb-4">
            Protección de su información
          </h2>
          <p>
            Entendemos que no importa cómo recopilemos su información, tenemos
            la responsabilidad de protegerla. Nos tomamos esa responsabilidad en
            serio. Creamos seguridad en nuestros productos y funciones desde
            cero para ayudar a proteger los datos del acceso, divulgación,
            alteración y destrucción no autorizados.
          </p>
          <p>
            Siempre estamos buscando amenazas y tomamos medidas razonables para
            responder a esas amenazas, protegiendo la información que los
            usuarios comparten con nosotros. Por supuesto, ningún método de
            transmisión o almacenamiento de datos por Internet puede
            proporcionar protección garantizada a su información. Si algo
            llegara a ocurrir con su información, se lo haremos saber tan pronto
            como podamos y haremos todo lo posible para corregirlo.
          </p>
          <p>
            Bohiio tiene su sede en los Estados Unidos y la información que
            recopilamos se rige principalmente por las leyes estadounidenses.
            Cuando estamos sujetos a las leyes de privacidad de otros países,
            cumplimos con esos requisitos. Si accede o utiliza nuestros
            servicios o nos proporciona información, su información será
            procesada y almacenada en los Estados Unidos.
          </p>
          <h2 id="policy-applications" className="poppins-bold-16 mt-5 mb-4">
            Aplicabilidad de esta política
          </h2>
          <p>
            Esta política se aplica a todos nuestros servicios que se vinculan a
            ella, incluido Bohiio LLC.
          </p>
          <p>
            Nuestras aplicaciones y servicios pueden vincularse a los sitios web
            de otras empresas. Algunas funciones también pueden implicar el
            intercambio de su información con sitios web de terceros. Estos
            sitios web de terceros pueden recopilar información sobre los
            usuarios en esos sitios web. Bohiio no controla esos sitios y esta
            política de privacidad no se aplica a estos sitios web externos ni a
            terceros.
          </p>
          <h2 id="legal-disclosures" className="poppins-bold-16 mt-5 mb-4">
            Divulgaciones legales
          </h2>
          <p>
            En esta sección, proporcionamos algunas divulgaciones requeridas
            legalmente según las leyes de privacidad estatales. No debería haber
            nada sorprendente en esta sección, y hemos intentado explicar
            nuestras prácticas de privacidad en un lenguaje sencillo a lo largo
            del resto de la política.
          </p>
          <p>
            Según la Ley de Privacidad del Consumidor de California o CCPA, los
            residentes de California tienen ciertos derechos con respecto a sus
            datos, que incluyen:
          </p>
          <p>
            El derecho a conocer las categorías de información personal que
            hemos recopilado y las categorías de fuentes de las que obtuvimos la
            información (consulte la Información recopilada por Bohiio y el
            cuadro que se encuentra a continuación).
          </p>
          <p>
            El derecho a conocer los propósitos comerciales para compartir
            información personal (consulte Por qué Bohiio usa su información)
          </p>
          <p>
            El derecho a conocer las categorías de terceros con los que hemos
            compartido información personal (consulte Cómo compartir su
            información)
          </p>
          <p>
            El derecho a acceder a la información personal específica que hemos
            recopilado y el derecho a eliminar su información (consulte
            Herramientas y opciones de privacidad).
          </p>
          <p>
            Los residentes de California también tienen derecho a no ser
            discriminados si eligen ejercer sus derechos de privacidad.
          </p>
          <h3 className="poppins-bold-16 mt-5 mb-4">
            Categorías de información personal según la Ley de privacidad del
            consumidor de California
          </h3>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Categoría y fuentes de información</th>
                  <th>Como lo usamos</th>
                  <th>Tipos de personas afectadas</th>
                  <th>Como lo compartimos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="poppins-bold-16">Identificadores</span>{" "}
                    Recopilamos esta información de nuestros usuarios, clientes
                    y socios comerciales. También generamos identificadores
                    internamente.
                  </td>
                  <td>
                    Para permitir el uso de nuestros sitios, servicios y
                    productos, para comunicarnos con usted, para comprender cómo
                    nuestros usuarios interactúan con nuestros sitios y para
                    mejorar nuestras ofertas.
                  </td>
                  <td>
                    Personas que utilizan nuestros sitios o aplicaciones
                    móviles; crear una cuenta de usuario; recibir una oferta de
                    Bohiio; envíenos una consulta sobre la compra de una
                    propiedad, producto o servicio de nosotros; solicite estar
                    conectado con un profesional de bienes raíces; comprar
                    publicidad de nosotros, obtener un servicio de nosotros; y
                    profesionales inmobiliarios con los que nos conectamos con
                    los usuarios
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios y con socios comerciales, incluidos aquellos a
                    quienes nos indica que enviemos esta información.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">
                      Información personal bajo
                    </span>{" "}
                    <a
                      href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1798.80.&lawCode=CIVWe"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1798.80.&lawCode=CIVWe
                    </a>{" "}
                    Recopilamos esta información de nuestros usuarios, clientes
                    y socios comerciales.
                  </td>
                  <td>
                    Para permitir el uso de nuestros sitios, servicios y
                    productos, para comunicarnos con usted, para comprender cómo
                    nuestros usuarios interactúan con nuestros sitios y para
                    mejorar nuestras ofertas.
                  </td>
                  <td>
                    Personas que crean una cuenta de usuario; recibir una oferta
                    de Bohiio; envíenos una consulta sobre la compra de una
                    Propiedad, producto o servicio de nosotros; solicite estar
                    conectado con un profesional de bienes raíces; comprar
                    publicidad de nosotros; obtener un servicio de nosotros; y
                    profesionales inmobiliarios con los que nos conectamos con
                    los usuarios
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios y con socios comerciales, incluidos aquellos a
                    quienes nos indica que enviemos esta información.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">Clasificaciones</span>{" "}
                    protegidas Recopilamos esta información de nuestros usuarios
                    y socios comerciales
                  </td>
                  <td>
                    Para comprender cómo nuestros usuarios interactúan con
                    nuestros sitios y para realizar investigaciones sobre las
                    tendencias y los mercados de vivienda y bienes raíces.
                  </td>
                  <td>
                    Personas que nos compran o venden una casa; utilice nuestro
                    servicio de envío de solicitudes de alquiler; entrar en una
                    sociedad comercial con nosotros;
                  </td>
                  <td>No compartimos</td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">Información</span>{" "}
                    comercial Recopilamos esta información de nuestros clientes
                    y la generamos internamente durante las transacciones con
                    nuestros clientes.
                  </td>
                  <td>
                    Para realizar transacciones con usted si utiliza nuestros
                    servicios o productos, y para habilitar las transacciones
                    que ha solicitado
                  </td>
                  <td>
                    Gente que nos vende una casa; comprarnos una casa, producto
                    o servicio; comprar publicidad de nosotros
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios y con socios comerciales, incluidos aquellos a
                    quienes nos indica que enviemos esta información.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">
                      Información biométrica
                    </span>
                  </td>
                  <td>No recopilamos estos datos</td>
                  <td>Ninguno</td>
                  <td>Ninguno</td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">
                      Información sobre la actividad
                    </span>{" "}
                    de la red electrónica Recopilamos esta información de
                    nuestros usuarios y clientes.
                  </td>
                  <td>
                    Para permitir el uso de nuestros sitios, servicios y
                    productos, para comunicarnos con usted, para comprender cómo
                    nuestros usuarios interactúan con nuestros sitios y para
                    mejorar nuestras ofertas.
                  </td>
                  <td>
                    Personas que usan nuestros sitios o aplicaciones móviles.
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios y con socios comerciales, incluidos aquellos a
                    quienes nos indica que enviemos esta información.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">
                      Datos de geolocalización
                    </span>{" "}
                    Recopilamos esta información de nuestros usuarios y
                    clientes.
                  </td>
                  <td>
                    Para permitir el uso de nuestros sitios, servicios y
                    productos, para comprender cómo nuestros usuarios
                    interactúan con nuestros sitios y para mejorar nuestras
                    ofertas.
                  </td>
                  <td>
                    Personas que utilizan nuestros sitios o aplicaciones móviles
                    y nos permiten recopilar sus datos de ubicación.
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">
                      Información de audio, electrónica, visual, térmica,
                      olfativa o similar
                    </span>{" "}
                    Recopilamos esta información de nuestros usuarios y
                    clientes.
                  </td>
                  <td>
                    Para identificarlo, para permitir el uso de nuestros sitios,
                    servicios y productos, y con fines de seguridad.
                  </td>
                  <td>
                    Personas que nos llaman; visitar o recorrer un inmueble
                    propiedad de Bohiio
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">
                      Información profesional o relacionada con el empleo
                    </span>{" "}
                    Recopilamos esta información de nuestros clientes y socios
                    comerciales
                  </td>
                  <td>Para proporcionar servicios o productos que solicitó</td>
                  <td>
                    Personas que utilizan nuestros servicios de aplicaciones de
                    alquiler; contáctenos en un contexto de empresa a empresa;
                    obtener un préstamo hipotecario de nosotros; Cómpranos una
                    casa
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios y con socios comerciales, incluidos aquellos a
                    quienes nos indica que enviemos esta información.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">
                      Información educativa
                    </span>{" "}
                    Recopilamos esta información de nuestros clientes.
                  </td>
                  <td>Para proporcionar servicios o productos que solicitó</td>
                  <td>
                    Personas que utilizan nuestros servicios de aplicaciones de
                    alquiler; obtener un préstamo hipotecario de nosotros;
                    Cómpranos una casa
                  </td>
                  <td>
                    Compartimos estos datos con nuestros proveedores de
                    servicios y con socios comerciales, incluidos aquellos a
                    quienes nos indica que enviemos esta información.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="poppins-bold-16">Inferencias</span> Las
                    generamos internamente
                  </td>
                  <td>
                    Para comprender cómo nuestros usuarios interactúan con
                    nuestros sitios, para desarrollar y ofrecer servicios y
                    productos más relevantes y útiles, y para mejorar los
                    productos y servicios que ofrecemos.
                  </td>
                  <td>
                    Personas que utilizan nuestros sitios o aplicaciones
                    móviles, incluidos los usuarios que: reciben una oferta de
                    Bohiio; solicite estar conectado con profesionales de bienes
                    raíces; o solicita una hipoteca a través de nosotros
                  </td>
                  <td>No compartimos</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            Bohiio cree que todos deben tener los mismos derechos de privacidad
            independientemente del lugar donde viva. Nuestras herramientas de
            privacidad están disponibles para todos nuestros usuarios.
          </p>
          <h2 id="contact-us" className="poppins-bold-16 mt-5 mb-4">
            Contactando a Bohiio
          </h2>
          <p>
            Si tiene alguna pregunta sobre esta política de privacidad o las
            prácticas de privacidad de Bohiio, consulte el Centro de soporte de
            privacidad para obtener preguntas frecuentes y otra información.
          </p>
          <p>
            También puede enviarnos un correo electrónico a{" "}
            <a href="mailto:privacy@bohiio.com">privacy@bohiio.com</a>, o puede
            escribirnos a:
          </p>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            <li>By Mail:</li>
            <li> Bohiio LLC</li>
            <li> 1314 E 28th St Mission,</li>
            <li>Mission, TX 78574</li>
            <li>Attention: Copyright Agent</li>
            <li>
              <a href="mailto:customer@bohiio.com">customer@bohiio.com</a>
            </li>
          </ul>
          <h2 id="policy-changes" className="poppins-bold-16 mt-5 mb-4">
            Cambios a esta política
          </h2>
          <p>
            Siempre estamos revisando nuestras prácticas de privacidad para
            asegurarnos de que cumplan con esta política. A veces, necesitaremos
            actualizar la política para reflejar los cambios en nuestros
            productos, tecnología y usos de los datos. No cambiaremos la
            política de una manera que reduzca sus derechos o cambie
            significativamente la forma en que usamos o compartimos los datos
            sin avisarle con anticipación y darle la oportunidad de dejar de
            usar nuestros servicios si lo desea.
          </p>
        </PrivacyContainer>
        <Footer />
      </div>
    </>
  );
};

Privacy.defaultProps = {
  center: {
    lat: 18.452223613348835,
    lng: -69.95282818615782,
  },
  zoom: 11,
};

export default Privacy;
