import { Notification } from "../context/notification/types";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../utils";
import React, { useContext, useEffect, useState } from "react";
import { PropertyContext } from "../context/property/PropertyContext";

interface IProps {
  notification: Notification;
}

const Notifications = ({ notification }: IProps) => {
  const location = useLocation();
  const { getComment } = useContext(PropertyContext);
  const [propertyId, setPropertyId] = useState(0);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (
        notification.notification_object.entity_type.table === "PropertyComment"
      ) {
        let comment = await getComment(
          notification.notification_object.entity_id
        );
        if (isMounted) {
          setPropertyId(comment.property);
        }
      } else {
        setPropertyId(notification.notification_object.entity_id);
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="poppins-light-14">
          {notification.notification_object.description
            .split(" ")
            .map((word: string, index) => {
              const wordWithoutFirstCharacter = word.slice(1);
              if (word[0] === "@") {
                return (
                  <React.Fragment key={index}>
                    <Link
                      to={`/profile/${wordWithoutFirstCharacter}`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span className="poppins-bold-13">
                        <span style={{ color: "red" }}>@</span>
                        {wordWithoutFirstCharacter}
                      </span>
                    </Link>{" "}
                  </React.Fragment>
                );
              } else if (word[0] === "#") {
                return (
                  <React.Fragment key={index}>
                    <Link
                      className="poppins-bold-13"
                      to={`/tendency/${wordWithoutFirstCharacter}`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <span style={{ color: "red" }}>#</span>
                      {wordWithoutFirstCharacter}
                    </Link>{" "}
                  </React.Fragment>
                );
              } else if (
                (notification.notification_object.entity_type.table ===
                  "Property" ||
                  notification.notification_object.entity_type.table ===
                    "PropertyComment") &&
                (word === "propiedad." || word === "propiedad:")
              ) {
                return (
                  <React.Fragment key={index}>
                    <Link
                      className="poppins-bold-13"
                      to={{
                        pathname: `/property/${propertyId}`,
                        state: { background: location },
                      }}
                    >
                      {word}
                    </Link>{" "}
                  </React.Fragment>
                );
              } else {
                return word + " ";
              }
            })}
        </div>

        <span
          className="flex-grow-1 text-end poppins-light-14"
          style={{ width: "50px" }}
        >
          {formatDate(new Date(notification.notification_object.created))}
        </span>
      </div>
    </>
  );
};

export default Notifications;
