import { Route, Redirect } from "react-router-dom";
import paths from "./paths.json";
import { AuthContext } from "../context/auth/AuthContext";
import { useContext, useEffect, useState } from "react";

import * as Sentry from "@sentry/react";

interface PublicRouteProps {
  children: React.ReactNode;
  path: string;
}

const SentryRoute = Sentry.withSentryRouting(Route);

const PublicRoute = ({ children, ...rest }: PublicRouteProps) => {
  const { handleAuthentication, getNewToken } = useContext(AuthContext);
  const [internalLoading, setInternalLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await getNewToken();
        handleAuthentication?.();
      } catch {
      } finally {
        setInternalLoading(false);
      }
    })();
  }, []);

  if (internalLoading) return <></>;

  return <SentryRoute {...rest} render={({ location }) => children} />;
};

export default PublicRoute;
