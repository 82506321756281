import { useContext } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import { Trans } from "react-i18next";
import language from "../images/language-bw.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import paths from "../routes/paths.json";

const LinkTab = styled(Link)`
  color: black;
  text-decoration: none;
`;

const SimpleFooter = () => {
  const [currentLanguage, changeLanguage] = useContext(LanguageContext);

  return (
    <div>
      <div className="d-none d-md-flex flex-row justify-content-center mb-3 poppins-light-12">
        <span className="px-3">Bohiio LLC, Mission, TX 78574, EE. UU.</span>
        <LinkTab to={paths.ABOUT_US}>
          <span className="px-3">
            <Trans i18nKey="footer.bar.1" />
          </span>
        </LinkTab>
        <span className="px-3">
          <Trans i18nKey="footer.bar.2" />
        </span>
        <LinkTab to={paths.PRIVACY}>
          <span className="px-3">
            <Trans i18nKey="footer.bar.3" />
          </span>
        </LinkTab>
        <LinkTab to={paths.TERMS}>
          <span className="px-3">
            <Trans i18nKey="footer.bar.4" />
          </span>
        </LinkTab>
        <span
          className="px-3 clickable"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "mailto:help@bohiio.com?subject=Help";
          }}
        >
          <Trans i18nKey="footer.bar.5" />
        </span>
        <span
          className="px-3"
          onClick={() =>
            currentLanguage === "es"
              ? changeLanguage("en")
              : changeLanguage("es")
          }
        >
          <img src={language} alt="language icon" />
          <Trans i18nKey="footer.bar.6" />
        </span>
      </div>
    </div>
  );
};

export default SimpleFooter;
