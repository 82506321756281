import { useContext, useRef } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { getBase64 } from "../utils";
import imgPlaceholder from "../images/img-placeholder-2.svg";
import crossIcon from "../images/cross-red-circle.svg";
import pinIcon from "../images/pin.svg";
import pinActiveIcon from "../images/pin-active.svg";
import { LanguageContext } from "../context/language/LanguageContext";

const ScrollessDiv = styled.div`
  scrollbar-width: thin;
  overflow: auto;
  overflow-y: hidden;
  height: 100%;
  width: auto;
`;

const ScrollTable = styled.table`
  border-spacing: 10px;
  border-collapse: separate;
  height: 100%;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: auto;
  }
`;

const ImageDiv = styled.div`
  @media screen and (min-width: 768px) {
    width: 300px;
  }
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  max-height: 220px;
  overflow: hidden;
  background-color: #ececec;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  min-height: 100%;
  border-radius: 15px;
  object-fit: cover;
`;

const RemoveImageButton = styled.img`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0.6em;
  right: 0.8em;
`;

const PinImageButton = styled.img`
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0.6em;
  left: 0.8em;
  z-index: 1001;
`;

const ButtonRed = styled.button`
  border: 1px #666666 solid;
  background: #666666;
  color: black;
  padding: 0.25rem 2rem;
  border-radius: 5px;
  color: white;
`;

const BYTES_TO_MEGABYTES = 1048576;

interface FileUploaderProps {
  files: any[];
  setFiles: any;
  mainIndex: number;
  setMainIndex: Function;
  mobile?: boolean;
  msg?: string;
  usingIndexes?: boolean;
  accepts?: string;
  limit?: number;
  maxSizeInMB?: number;
  icon?: string;
}

const FileUploader = ({
  files,
  setFiles,
  mainIndex,
  setMainIndex,
  mobile = false,
  msg,
  usingIndexes = true,
  accepts = ".jpg,.jpeg,.png,.mp4",
  limit = Number.MAX_SAFE_INTEGER, //Should be an actual "safe" number
  maxSizeInMB = Number.MAX_SAFE_INTEGER, //Should be an actual "safe" number
  icon = imgPlaceholder,
}: FileUploaderProps) => {
  useContext(LanguageContext);

  const inputRef = useRef(null);

  const onImageUpload = async (e: any) => {
    const newFiles = await Promise.all(
      [...e.target.files]
        .filter((file: File) => {
          return file.size / BYTES_TO_MEGABYTES <= maxSizeInMB;
        })
        .map(async (file: File) => {
          return {
            dataURL: await getBase64(file),
            name: file.name,
          };
        })
    );
    let rejectedFilesNames = [...e.target.files].reduce(
      (accumulator: string, currentVal: File) =>
        currentVal.size / BYTES_TO_MEGABYTES > maxSizeInMB
          ? (accumulator += `${currentVal.name}\n`)
          : "",
      ""
    );

    if (rejectedFilesNames.length > 0) {
      //could be changed for a modal
      alert(
        `No se pudieron cargar los archivos, revisa que el formato sea JPG, PNG o MP4 y el tamaño máximo de cada archivo 15 MB:\n${rejectedFilesNames}`
      );
    }

    setFiles([...files, ...newFiles].splice(0, limit));
  };

  const onImageRemove = (deleteIndex: number) => {
    setFiles(files.filter((_, index) => deleteIndex !== index));
    if (mainIndex > deleteIndex) {
      setMainIndex(mainIndex - 1);
    }
    if (mainIndex >= files.length - 1) {
      setMainIndex(files.length - 2);
    }
    if (files.length === 1) {
      setMainIndex(0);
    }
  };

  const availableFormatArray = accepts.split(".");
  let availableFormatText = "";
  let realIndex = 0;
  for (let index = 0; index < availableFormatArray.length; index++) {
    const format = availableFormatArray[index]
      .replaceAll(",", "")
      .toUpperCase();
    if (!format) {
      continue;
    }
    if (realIndex > 0 && index < availableFormatArray.length - 1) {
      availableFormatText += `, ${format}`;
    } else if (realIndex > 0 && index === availableFormatArray.length - 1) {
      availableFormatText += ` o ${format}`;
    } else {
      availableFormatText = format;
    }
    realIndex++;
  }

  return (
    <ScrollessDiv>
      <ScrollTable>
        <tbody>
          <tr>
            {limit > files.length && (
              <td style={{ height: "100%" }}>
                <input
                  type="file"
                  accept={accepts}
                  multiple
                  ref={inputRef}
                  hidden
                  onChange={onImageUpload}
                  onClick={(event) => ((event.target as any).value = null)}
                />
                <ImageDiv
                  className="d-flex flex-column align-items-center justify-content-between py-3"
                  style={{
                    color: "#161823",
                  }}
                  onClick={() => (inputRef.current as any)?.click()}
                >
                  <img
                    className="flex-grow-1 mb-3"
                    src={icon}
                    width="55px"
                    alt="placeholder"
                  />
                  <div className="d-flex flex-column justify-content-between flex-grow-1">
                    {!msg && (
                      <div
                        style={{
                          color: "#999999",
                          fontSize: "15px",
                        }}
                      >
                        <ul>
                          <li>{availableFormatText}</li>
                          <li>
                            <Trans
                              className="d-none d-md-inline"
                              i18nKey="addProperty.maxFileSize"
                            >
                              <h1 className="poppins-regular-14 text-center">
                                Peso máximo por archivo
                              </h1>
                            </Trans>{" "}
                            {maxSizeInMB} MB
                          </li>
                          <li>
                            {limit}{" "}
                            <Trans
                              className="d-none d-md-inline"
                              i18nKey="addProperty.maxFileQuantity"
                            >
                              <h1 className="poppins-regular-14 text-center">
                                archivos máximo
                              </h1>
                            </Trans>
                          </li>
                        </ul>
                        <ButtonRed className="w-100">Buscar archivos</ButtonRed>
                      </div>
                    )}
                    {msg && (
                      <>
                        <h1 className="poppins-regular-14 text-center">
                          {msg}
                        </h1>
                        <ButtonRed>Subir</ButtonRed>
                      </>
                    )}
                  </div>
                </ImageDiv>
              </td>
            )}
            {!mobile && (
              <>
                {files.map((image, index) => (
                  <td style={{ height: "100%" }} key={index}>
                    <ImageDiv>
                      {usingIndexes && (
                        <PinImageButton
                          className="clickable"
                          src={mainIndex === index ? pinActiveIcon : pinIcon}
                          style={{
                            opacity: mainIndex === index ? "1" : "0.8",
                          }}
                          onClick={() => {
                            setMainIndex(index);
                          }}
                        />
                      )}
                      {image.name.includes(".mp4") ? (
                        <video
                          muted
                          width="100%"
                          height="100%"
                          style={{
                            objectFit: "contain",
                            borderRadius: "15px",
                          }}
                          controls
                        >
                          <source src={image.dataURL} type="video/mp4" />
                        </video>
                      ) : (
                        <Img src={image.dataURL} alt="" />
                      )}
                      <RemoveImageButton
                        className="clickable"
                        src={crossIcon}
                        onClick={() => onImageRemove(index)}
                      />
                    </ImageDiv>
                  </td>
                ))}
              </>
            )}
          </tr>
          {mobile && (
            <>
              {files.map((image, index) => (
                <tr key={index}>
                  <td style={{ height: "100%" }}>
                    <ImageDiv>
                      {usingIndexes && (
                        <PinImageButton
                          className="clickable"
                          src={mainIndex === index ? pinActiveIcon : pinIcon}
                          onClick={() => {
                            setMainIndex(index);
                          }}
                        />
                      )}
                      {image.name.includes(".mp4") ? (
                        <video
                          muted
                          width="100%"
                          height="100%"
                          style={{
                            objectFit: "contain",
                            borderRadius: "15px",
                          }}
                          controls
                        >
                          <source src={image.dataURL} type="video/mp4" />
                        </video>
                      ) : (
                        <Img src={image.dataURL} alt="" />
                      )}
                      <RemoveImageButton
                        className="clickable"
                        src={crossIcon}
                        onClick={() => onImageRemove(index)}
                      />
                    </ImageDiv>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </ScrollTable>
    </ScrollessDiv>
  );
};

export default FileUploader;
