import { Property } from "../context/property/types";
import OptionsModal from "./OptionsModal";
import { PropertyContext } from "../context/property/PropertyContext";
import { useContext, useState } from "react";
import BaseShortModal from "./BaseShortModal";
import BaseModalContent from "./BaseModalContent";

interface IDeletePropertyConfirmationModalProps {
  property: Property;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
  handleClose?: Function;
  onDeleteProperty: Function;
}

const DeletePropertyConfirmationModal = ({
  active,
  closeModal,
  darkerBackground,
  property,
  handleClose,
  onDeleteProperty,
}: IDeletePropertyConfirmationModalProps) => {
  const [showDeletedSuccessfulModal, setShowDeletedSuccessfulModal] =
    useState(false);
  const { deleteProperty } = useContext(PropertyContext);
  const handleDeleteProperty = async (id: number) => {
    try {
      await deleteProperty?.(id);
      setShowDeletedSuccessfulModal(true);
    } catch (e) {
      alert(e);
    }
  };
  const onCloseNotificationModal = () => {
    setShowDeletedSuccessfulModal(false);
    onDeleteProperty();
    handleClose?.();
    closeModal();
  };
  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <div className="poppins-regular-12 text-center">
          <div style={{ padding: "0 2rem" }}>
            <p className="poppins-bold-12">
              ¿Quieres eliminar esta publicación?
            </p>
            <p>
              Al eliminar tu publicación la propiedad desaparecera de tu perfil
              y nuestros motores de búsqueda.
            </p>
          </div>
          <div
            style={{
              borderBottom: "2px solid gray",
              padding: "1rem 4rem",
              cursor: "pointer",
              color: "red",
              fontWeight: 700,
            }}
            onClick={() => {
              handleDeleteProperty(property.id);
            }}
          >
            Eliminar propiedad
          </div>
          <div
            style={{
              padding: "1rem 4rem",
              cursor: "pointer",
              fontWeight: 700,
            }}
            onClick={closeModal}
          >
            Cancelar
          </div>
        </div>
      </OptionsModal>
      <BaseShortModal
        active={showDeletedSuccessfulModal}
        closeModal={onCloseNotificationModal}
      >
        <BaseModalContent
          title="Publicación borrada"
          buttonContent="Aceptar"
          buttonAction={onCloseNotificationModal}
        >
          La publicación fue eliminada correctamente.
        </BaseModalContent>
      </BaseShortModal>
    </>
  );
};

export default DeletePropertyConfirmationModal;
