import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Routes from "./routes/Routes";
import { LanguageProvider } from "./context/language/LanguageContext";
import { PropertyProvider } from "./context/property/PropertyContext";
import { AuthProvider } from "./context/auth/AuthContext";
import { ProfileProvider } from "./context/profile/ProfileContext";
import { FollowersProvider } from "./context/followers/FollowersContext";
import { VendorProvider } from "./context/vendor/VendorContext";
import { SearchProvider } from "./context/search/SearchContext";
import { NotificationProvider } from "./context/notification/NotificationContext";
import ReactGA from "react-ga4";
import { PaymentProvider } from "./context/payment/PaymentContext";
import { LocationProvider } from "./context/location/LocationContext";
import { LocalstorageProvider } from "./context/localstorage/LocalstorageContext";

const queryClient = new QueryClient();
ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
export const LOCAL_STORAGE_NAME = "bohiio-state-v1";
const App = () => {
  return (
    <>
      <LocalstorageProvider id={LOCAL_STORAGE_NAME}>
        <SearchProvider>
          <AuthProvider>
            <LocationProvider>
              <PropertyProvider>
                <FollowersProvider>
                  <ProfileProvider>
                    <LanguageProvider>
                      <VendorProvider>
                        <NotificationProvider>
                          <PaymentProvider>
                            <QueryClientProvider client={queryClient}>
                              <Router>
                                <Routes />
                              </Router>
                            </QueryClientProvider>
                          </PaymentProvider>
                        </NotificationProvider>
                      </VendorProvider>
                    </LanguageProvider>
                  </ProfileProvider>
                </FollowersProvider>
              </PropertyProvider>
            </LocationProvider>
          </AuthProvider>
        </SearchProvider>
      </LocalstorageProvider>
    </>
  );
};

export default App;
