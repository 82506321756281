import React, { useContext, useEffect, useState } from "react";
import { VendorContext } from "../context/vendor/VendorContext";
import { Vendor } from "../context/vendor/types";
import { useParams } from "react-router-dom";
import { SearchContext } from "../context/search/SearchContext";
import VendorComponent from "./VendorComponent";
import { TextLink } from "../styles/common";
import paths from "../routes/paths.json";
import styled from "styled-components";
import {
  ALL_COUNTRIES_CODE,
  useLocalstorageContext,
} from "../context/localstorage/LocalstorageContext";

export const UserContainer = styled.div`
  width: calc(100%);

  @media screen and (min-width: 762px) {
    width: calc(100% / 2);
    margin-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    width: calc(100% / 4);
  }
`;

interface IParams {
  name: string;
}

const DashboardVendor = (props: any) => {
  const params = useParams<IParams>();

  const { getRecommendedVendors } = useContext(VendorContext);
  const { searchUsers } = useContext(SearchContext);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [storage] = useLocalstorageContext();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const country =
        storage.countryCode !== ALL_COUNTRIES_CODE ? storage.countryCode : "";
      const result = params.name
        ? await searchUsers(params.name)
        : await getRecommendedVendors(country);
      if (isMounted) {
        setVendors(result ?? []);
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, storage]);

  return (
    <div className="md-row mw-100 me-2 me-md-0">
      {(vendors.length || !params.name) && (
        <>
          <div className="poppins-bold-20 col-12 d-none d-md-inline">
            {params.name
              ? "Resultados"
              : !vendors.length
              ? ""
              : "Vendedores recomendados" + storage.countryCode !==
                ALL_COUNTRIES_CODE
              ? ""
              : " en la zona y el país"}
          </div>
          <div className="poppins-bold-20 col-12 d-md-none ms-2 mt-lg-0">
            {params.name
              ? "Resultados"
              : !vendors.length
              ? ""
              : "Vendedores recomendados"}
          </div>
          <div className="vendors w-100 d-flex flex-column flex-wrap flex-md-row align-items-center text-md-center h-100 justify-content-start">
            {vendors.map((vendor, index) => {
              return (
                <UserContainer key={index}>
                  <VendorComponent
                    vendor={vendor}
                    vendorIndex={index}
                    updateVendor={(vendorNewData: Vendor) => {
                      setVendors([
                        ...vendors.slice(0, index),
                        vendorNewData,
                        ...vendors.slice(index + 1),
                      ]);
                    }}
                  />
                </UserContainer>
              );
            })}
          </div>
        </>
      )}
      {!vendors.length && !params.name && (
        <div className="px-3 px-md-none" style={{ marginTop: "220px" }}>
          <div className="text-center">
            <p className="poppins-bold-20">
              Aún no hay vendedores recomendados para mostrar
            </p>
            <p className="poppins-medium-16">
              Se el primero con{" "}
              <TextLink
                style={{ textDecoration: "underline" }}
                to={paths.PAYMENT}
              >
                nuestros planes
              </TextLink>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardVendor;
