import {
  useEffect,
  useContext,
  useState,
  RefObject,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useInfiniteQuery } from "react-query";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import Navbar from "../../components/Navbar";
import DashboardSidebar from "../../components/DashboardSidebar";
import DashboardProperty from "../../components/DashboardProperty";
import { PropertyContext } from "../../context/property/PropertyContext";
import { ProfileContext } from "../../context/profile/ProfileContext";
import Scroller from "../../components/Scroller";
import useScreen from "../../hooks/useScreen";
import paths from "../../routes/paths.json";
import AuthenticationModal from "../../components/AuthenticationModal";
import { AuthContext } from "../../context/auth/AuthContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import WelcomeModal from "../../components/WelcomeModal";
import {
  ALL_COUNTRIES_CODE,
  useLocalstorageContext,
} from "../../context/localstorage/LocalstorageContext";

const PropertiesContainer = styled.div`
  height: calc(100vh - 120px);
  position: relative;
  overflow: auto;

  @media screen and (min-width: 768px) {
    height: calc(100vh - 57px);
    /* margin-left: 55px; */
  }
`;

const DashboardPropertiesContainer = styled.div`
  max-height: 100vh;
  overflow-y: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0.1em;
  }
  @media screen and (min-width: 768px) {
    align-items: center;
  }
`;

const Home = () => {
  const [storage] = useLocalstorageContext();
  const { getProperties } = useContext(PropertyContext);
  const { profile } = useContext(ProfileContext);
  const { isAuthenticated, userLocation } = useContext(AuthContext);
  const history = useHistory();
  const [video, setVideo] = useState<RefObject<HTMLVideoElement>>();
  const propertyContainerRef = useRef<HTMLDivElement>(null);
  const [state, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { isMobile } = useScreen();
  const [showLogin, setShowLogin] = useState(false);
  const [showWelcomePage, setShowWelcomePage] = useState(false);
  const [nextPage, setNextPage] = useState("/");
  const closeLoginModal = () => setShowLogin(false);
  const closeWelcomeModal = () => setShowWelcomePage(false);
  const openLoginModal = (nextPage: string) => {
    setNextPage(nextPage);
    setShowLogin(true);
  };
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("remix_property_page")) {
      history.push("/");
      history.push(`/property/${urlParams.get("remix_property_page")}`);
    }
  }, [location.search]);

  useEffect(() => {
    if (storage.showWelcomeModal) {
      setShowWelcomePage(
        userLocation?.country_code === "DO" &&
          window.location.pathname === paths.HOME
      );
    }
  }, [userLocation, storage?.showWelcomeModal]);

  const onChangeVideo = useCallback(
    (newVideo: RefObject<HTMLVideoElement>) => {
      // console.log("changing video");
      if (video?.current) {
        // console.log("pausing video");
        video.current.pause();
      }
      setVideo(newVideo);
    },
    [video]
  );

  const { ref, inView } = useInView();
  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      `properties-${storage.countryCode}`,
      ({ pageParam = 0 }) =>
        getProperties(
          pageParam,
          storage.countryCode !== ALL_COUNTRIES_CODE
            ? storage.countryCode
            : undefined
        ),
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length;
          return lastPage.length !== 0 ? nextPage : null;
        },
        staleTime: Infinity,
      }
    );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const LoadMorePropertiesComponent = useMemo(
    () => (
      <div className="loader" ref={ref}>
        {isFetchingNextPage && hasNextPage ? "Loading..." : "No search left"}
      </div>
    ),
    [hasNextPage, isFetchingNextPage, ref]
  );

  const properties = useMemo(() => {
    let list: any[] = [];
    data?.pages.forEach((page) => {
      list = list.concat(
        ...page.map((property: any, index: number) => {
          return {
            property: property,
            updateProperty: async (property?: any) => {
              if (property) {
                page.splice(index, 1, property);
              } else {
                page.splice(index, 1);
              }
              forceUpdate();
            },
          };
        })
      );
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, data?.pages]);

  const mobileProperties = useMemo(() => {
    let list: any = [];
    data?.pages.forEach((page) => {
      list = list.concat(
        ...page.map((property, index) => {
          return {
            id: property.id.toString(),
            children: (
              <DashboardPropertiesContainer>
                <DashboardProperty
                  property={property}
                  user={profile}
                  onPlayVideo={onChangeVideo}
                  updateProperty={(property?) => {
                    if (property) {
                      page.splice(index, 1, property);
                    } else {
                      page.splice(index, 1);
                    }
                    forceUpdate();
                  }}
                />
              </DashboardPropertiesContainer>
            ),
          };
        })
      );
    });
    list.push({
      id: "load-more",
      children: LoadMorePropertiesComponent,
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages, LoadMorePropertiesComponent, profile, onChangeVideo, state]);

  return (
    <>
      <div className="d-flex flex-column main-container">
        <Navbar />
        <DashboardSidebar />
        <PropertiesContainer className="d-flex">
          <div className="col-12 offset-md-3 col-md-4">
            {/* <PreviousPropertyButton 
              className="d-none d-md-block"
              src={ChevronIcon}
              onClick={() => {
                if (propertyContainerRef.current) {
                  propertyContainerRef.current.scrollTo({
                    top:
                    propertyContainerRef.current.scrollTop -
                    propertyContainerRef.current.clientWidth,
                    left: 0,
                    behavior: "smooth",
                  });
                }
              }}
              alt="previous property"
            /> */}
            {properties.length > 0 && isSuccess && isMobile && (
              <Scroller
                items={mobileProperties}
                swapAxys="y"
                ref={propertyContainerRef}
              />
            )}
            {properties.length > 0 && isSuccess && !isMobile && (
              <ul style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {properties.map(({ property, updateProperty }) => (
                  <li
                    style={{
                      listStyle: "none",
                      marginBottom: "40px",
                      backgroundColor: "white",
                    }}
                    key={property.id}
                  >
                    <DashboardProperty
                      property={property}
                      updateProperty={updateProperty}
                      user={profile}
                      onPlayVideo={onChangeVideo}
                    />
                  </li>
                ))}
                <li style={{ listStyle: "none", marginBottom: "20px" }}>
                  {LoadMorePropertiesComponent}
                </li>
              </ul>
            )}
            {/* <NextPropertyButton 
                className="d-none d-md-block"
                src={ChevronIcon}
                onClick={() => {
                  if (propertyContainerRef.current) {
                    propertyContainerRef.current.scrollTo({
                      top:
                      propertyContainerRef.current.scrollTop +
                      propertyContainerRef.current.clientWidth,
                      left: 0,
                      behavior: "smooth",
                    });
                  }
                }}
                alt="next property"
              /> */}
            {!properties.length && (
              <div className="px-3 px-md-none" style={{ marginTop: "220px" }}>
                <div className="text-center ">
                  <p className="poppins-bold-20">
                    Aún no hay propiedades publicadas para mostrar
                  </p>
                  <p className="poppins-medium-16">
                    Se el primero en{" "}
                    <span
                      className="clickable"
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        isAuthenticated
                          ? history.push(paths.ADDPROPERTY)
                          : openLoginModal(paths.ADDPROPERTY);
                      }}
                    >
                      publicar
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </PropertiesContainer>
      </div>
      <AuthenticationModal
        nextPage={nextPage}
        active={showLogin}
        closeModal={closeLoginModal}
      />
      <WelcomeModal active={showWelcomePage} closeModal={closeWelcomeModal} />
    </>
  );
};

export default Home;
