import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";
import styled from "styled-components";
import facebookIcon from "../images/facebook-share.svg";
import twitterIcon from "../images/twitter-share.svg";
import linkedinIcon from "../images/linkedin-share.svg";
import pinterestIcon from "../images/pinterest-share.svg";
import whatsappIcon from "../images/whatsapp-share.svg";
import telegramIcon from "../images/telegram-share.svg";
import emailIcon from "../images/email-share.svg";
import OptionsModal from "./OptionsModal";

const Icon = styled.img`
  height: 35px;
  width: 35px;
  border-radius: 50%;
`;
interface SharePropertyModalProps {
  propertyUrl: string;
  isActive: boolean;
  onHide: () => void;
  darkerBackground?: boolean;
  isProfile?: boolean;
}

const SharePropertyModal = ({
  propertyUrl,
  isActive,
  onHide,
  darkerBackground = false,
  isProfile = false,
}: SharePropertyModalProps) => {
  return (
    <>
      <OptionsModal
        active={isActive}
        closeModal={onHide}
        darkerBackground={darkerBackground}
      >
        {/* <div className="d-flex">
            <span>link de la propiedad:</span>
            <div className="ms-2 flex-grow-1">
              <input value={propertyUrl} style={{ width: "100%" }} disabled />
            </div>
          </div> */}
        <div className="d-flex g-3 px-4" style={{ background: "white" }}>
          <FacebookShareButton className="me-2" url={propertyUrl}>
            <Icon src={facebookIcon}></Icon>
          </FacebookShareButton>
          <TwitterShareButton
            className="me-2"
            url={propertyUrl}
            title={
              isProfile
                ? "Echa un vistazo a este vendedor en bohiio"
                : "Echa un vistazo a esta propiedad en bohiio"
            }
          >
            <Icon src={twitterIcon}></Icon>
          </TwitterShareButton>
          <WhatsappShareButton
            className="me-2"
            url={propertyUrl}
            title={
              isProfile
                ? "Echa un vistazo a este vendedor en bohiio"
                : "Echa un vistazo a esta propiedad en bohiio"
            }
          >
            <Icon src={whatsappIcon}></Icon>
          </WhatsappShareButton>
          <TelegramShareButton
            className="me-2"
            url={propertyUrl}
            title={
              isProfile
                ? "Echa un vistazo a este vendedor en bohiio"
                : "Echa un vistazo a esta propiedad en bohiio"
            }
          >
            <Icon src={telegramIcon}></Icon>
          </TelegramShareButton>
          <LinkedinShareButton
            className="me-2"
            url={propertyUrl}
            title={
              isProfile
                ? "Echa un vistazo a este vendedor en bohiio"
                : "Echa un vistazo a esta propiedad en bohiio"
            }
            summary={
              isProfile
                ? "¿Qué te parece este vendedor?"
                : "¿Qué te parece esta propiedad?"
            }
          >
            <Icon src={linkedinIcon}></Icon>
          </LinkedinShareButton>
          <PinterestShareButton
            className="me-2"
            url={propertyUrl}
            media={"no image"}
            description={
              isProfile
                ? "Echa un vistazo a este vendedor en bohiio"
                : "Echa un vistazo a esta propiedad en bohiio"
            }
          >
            <Icon src={pinterestIcon}></Icon>
          </PinterestShareButton>
          <EmailShareButton
            url={propertyUrl}
            subject={
              isProfile
                ? "Echa un vistazo a este vendedor en bohiio"
                : "Echa un vistazo a esta propiedad en bohiio"
            }
            body={
              isProfile
                ? "¿Qué te parece este vendedor?"
                : "¿Qué te parece esta propiedad?"
            }
          >
            <Icon src={emailIcon}></Icon>
          </EmailShareButton>
        </div>
      </OptionsModal>
    </>
  );
};

export default SharePropertyModal;
