import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useContext, useState, FormEvent } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import styled from "styled-components";
import paths from "../routes/paths.json";
import ErrorText from "./ErrorText";
import logo from "../images/logo.svg";
import eye from "../images/eye.svg";
import eyeSlash from "../images/eye-slash.svg";
import { FloatingInput, TextLink } from "../styles/common";
import { AuthContext } from "../context/auth/AuthContext";
import { Row, Col } from "react-bootstrap";
import countryCodes, { CountryProperty } from "country-codes-list";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { USERNAME_VALIDATION_REGEX } from "./ProfileChangeInfo";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: #d2190e;
  color: white;
  margin: 20px 0px;
  width: 100%;
  &:hover {
    color: white;
  }
`;

const Divider = styled.hr`
  background-color: #c7c8c9;
  opacity: 1;
`;

const INITIAL_AREA_CODE = "DO: +1";

const RegisterModalForm = (props: any) => {
  const myCountryCodesObject: object = countryCodes.customList(
    "countryCode" as CountryProperty,
    "{countryCode}: +{countryCallingCode}"
  );

  const myCountryCodesList = Object.entries(myCountryCodesObject)
    .filter(([countryCode, _]: [string, string]) => hasFlag(countryCode))
    .sort((a, b) => {
      if (a[0] === "DO") return -1;
      else if (b[0] === "DO") return 1;
      else return 0;
    })
    .map(([countryCode, countryValue]: [string, string]) => {
      return (
        <option value={countryValue}>{`${getUnicodeFlagIcon(
          countryCode
        )} ${countryValue}`}</option>
      );
    });

  useContext(LanguageContext);
  const { signUp } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [passwordVisibilty, setPasswordVisibility] = useState(false);
  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibilty);
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          username: "",
          areaCode: INITIAL_AREA_CODE,
          phone: "",
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required("Requerido"),
          lastName: Yup.string().required("Requerido"),
          username: Yup.string()
            .required("Requerido")
            .min(3, "El nombre de usuario debe contener mas de 3 letras")
            .max(30, "El nombre de usuario no puede ser tan largo")
            .matches(USERNAME_VALIDATION_REGEX, {
              message:
                "El nombre de usuario no puede tener caracteres especiales",
            }),
          areaCode: Yup.string().required("Requerido"),
          phone: Yup.string()
            .required("Requerido")
            .matches(
              /^[+]?([0-9]+[-]?)*$/,
              "Debe ser un numero de telefono válido"
            ),
          email: Yup.string()
            .email("Debe ser una dirección de correo valida")
            .required("Requerido")
            .max(128, "El email no puede ser tan largo"),
          password: Yup.string()
            .required("Requerido")
            .min(6, "La contraseña debe tener mas de 6 caracteres")
            .max(128, "La contraseña no puede ser tan larga"),
        })}
        onSubmit={async (
          { firstName, lastName, username, areaCode, phone, email, password },
          FormikBag
        ) => {
          try {
            setShowError(false);
            const user = {
              username,
              first_name: firstName,
              last_name: lastName,
              email,
              phone_code: areaCode,
              phone,
              password,
            };
            await signUp(user);

            props.onClose({ firstName, lastName });
          } catch (e) {
            setShowError(true);
          }
        }}
      >
        {(formikProps) => (
          <div className="px-0">
            <div className="text-center py-2 py-md-4">
              <img src={logo} alt="logo" className="w-50" />
            </div>
            <p className="py-2 py-md-0 text-center">
              <Trans i18nKey="signUp.description" />
            </p>
            <Divider className="mt-3 mb-4" />
            <Form>
              <FloatingInput className="form-floating mb-2">
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder={t("signUp.firstName")}
                  className="form-control "
                />
                <label htmlFor="firstName">{t("signUp.firstName")}</label>
                <ErrorMessage name="firstName">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </FloatingInput>
              <FloatingInput className="form-floating mb-2">
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder={t("signUp.lastName")}
                  className="form-control"
                />
                <label htmlFor="lastName">{t("signUp.lastName")}</label>
                <ErrorMessage name="lastName">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </FloatingInput>
              <FloatingInput className="form-floating mb-2">
                <Field
                  id="username"
                  name="username"
                  type="text"
                  placeholder={t("signUp.username")}
                  className="form-control"
                  onChange={(e: FormEvent<HTMLInputElement>) => {
                    formikProps.setFieldValue(
                      "username",
                      e.currentTarget.value.toLowerCase()
                    );
                  }}
                />
                <label htmlFor="username">{t("signUp.username")}</label>
                <ErrorMessage name="username">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </FloatingInput>
              <Row>
                <Col xs={5}>
                  <FloatingInput className="form-floating mb-2">
                    <Field
                      id="areaCode"
                      name="areaCode"
                      as="select"
                      placeholder={t("signUp.areaCode")}
                      className="form-control"
                    >
                      {Object.entries(myCountryCodesObject)
                        .filter(([countryCode, _]: [string, string]) =>
                          hasFlag(countryCode)
                        )
                        .sort((a, b) => {
                          if (a[0] === "DO") return -1;
                          else if (b[0] === "DO") return 1;
                          else return 0;
                        })
                        .map(
                          ([countryCode, countryValue]: [string, string]) => {
                            return (
                              <option
                                value={countryValue}
                              >{`${getUnicodeFlagIcon(
                                countryCode
                              )} ${countryValue}`}</option>
                            );
                          }
                        )}
                    </Field>
                    <ErrorMessage name="areaCode">
                      {(msg) => <ErrorText>{msg}</ErrorText>}
                    </ErrorMessage>
                  </FloatingInput>
                </Col>
                <Col>
                  <FloatingInput className="form-floating mb-2">
                    <Field
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder={t("signUp.phone")}
                      className="form-control"
                    />
                    <label htmlFor="phone">{t("signUp.phone")}</label>
                    <ErrorMessage name="phone">
                      {(msg) => <ErrorText>{msg}</ErrorText>}
                    </ErrorMessage>
                  </FloatingInput>
                </Col>
              </Row>
              {/* <FloatingInput className="form-floating mb-4">
              <Field
                id="areaCode"
                name="areaCode"
                type="text"
                placeholder={t("signUp.areaCode")}
                className="form-control"
              />
              <label htmlFor="phone">{t("signUp.areaCode")}</label>
              <ErrorMessage name="phone">
                {(msg) => <ErrorText>{msg}</ErrorText>}
              </ErrorMessage>
            </FloatingInput>
            <FloatingInput className="form-floating mb-4">
              <Field
                id="phone"
                name="phone"
                type="text"
                placeholder={t("signUp.phone")}
                className="form-control"
              />
              <label htmlFor="phone">{t("signUp.phone")}</label>
              <ErrorMessage name="phone">
                {(msg) => <ErrorText>{msg}</ErrorText>}
              </ErrorMessage>
            </FloatingInput> */}
              <FloatingInput className="form-floating mb-2">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t("signUp.email")}
                  className="form-control"
                />
                <label htmlFor="email">{t("signUp.email")}</label>
                <ErrorMessage name="email">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>
              </FloatingInput>
              <FloatingInput className="input-group  form-floating">
                <Field
                  id="password"
                  name="password"
                  type={passwordVisibilty ? "text" : "password"}
                  placeholder={t("signUp.password")}
                  className="form-control border-end-0"
                  aria-describedby="password-icon"
                />
                <label htmlFor="password" style={{ zIndex: 5 }}>
                  {t("signUp.password")}
                </label>
                <span
                  id="password-icon"
                  className="input-group-text bg-transparent border-start-0"
                  onClick={changePasswordVisibility}
                >
                  <img
                    src={passwordVisibilty ? eyeSlash : eye}
                    alt="password visibility"
                    className="opacity-50"
                    style={{ height: "25px", width: "25px" }}
                  />
                </span>
              </FloatingInput>
              <ErrorMessage name="password">
                {(msg) => <ErrorText>{msg}</ErrorText>}
              </ErrorMessage>

              <div className="d-flex justify-content-center mt-1">
                <Button type="submit" className="btn poppins-bold-14">
                  <Trans i18nKey="signUp.submit" />
                </Button>
              </div>
            </Form>
            {showError ? (
              <p
                className="poppins-light-14 text-center"
                style={{ color: "red" }}
              >
                <Trans i18nKey="signUp.errorMessage">
                  El nombre de usuario, correo electrónico o el número de
                  teléfono ya fueron utilizados. Prueba con otros.
                </Trans>
              </p>
            ) : null}
            <p className="poppins-light-14 text-center">
              <Trans i18nKey="signUp.terms&conditions">
                Al registrarte, aceptas nuestros
                <Link
                  style={{ color: "blue" }}
                  className="poppins-medium-14"
                  to={paths.TERMS}
                >
                  términos y condiciones
                </Link>
                , la política de los datos y las
                <Link
                  style={{ color: "blue" }}
                  className="poppins-medium-14"
                  to={paths.TERMS}
                >
                  políticas de las cookies.
                </Link>
              </Trans>
            </p>
            <p className="poppins-light-14 text-center mb-0">
              <Trans i18nKey="signUp.login">
                ¿Ya tienes cuenta?
                <span
                  className="poppins-semibold-14 text-black clickable"
                  style={{ textDecoration: "underline" }}
                  onClick={props.toLogin}
                >
                  Inicia sesión
                </span>
              </Trans>
            </p>
          </div>
        )}
      </Formik>
    </>
  );
};

export default RegisterModalForm;
