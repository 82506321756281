import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/Navbar";
import styled from "styled-components";
import checkIcon from "../../images/check.svg";
import checkmarkRed from "../../images/checkmark-red.svg";
import checkmarkGray from "../../images/checkmark-gray.svg";
import Footer from "../../components/Footer";
import { AuthContext } from "../../context/auth/AuthContext";
import { ProfileContext } from "../../context/profile/ProfileContext";
import AuthenticationModal from "../../components/AuthenticationModal";
import ReceiptModal from "../../components/ReceiptModal";
import { Link, useHistory, useLocation } from "react-router-dom";
import paths from "../../routes/paths.json";
import { NavigationBackButton } from "../../components/NavigationBackButton";
import SubscriptionAcquiredModal from "../../components/SubscriptionAcquiredModal";
import { PaymentContext } from "../../context/payment/PaymentContext";

const GridItem = styled.div<{ position: number; selected?: any }>`
  @media screen and (min-width: 768px) {
    grid-row-start: ${(props) => props.position};
    background: #f9f9f9;
  }
  background: white;
  height: 100%;
  width: 100%;
  padding: 0.5em 0;
  color: ${(props) => (props.selected ? "#f25049" : "#999999")};
  grid-row-start: ${(props) => 2 * props.position};
  text-align: center;
`;

const Grid = styled.div<{ bigGrid: boolean }>`
  @media screen and (min-width: 768px) {
    grid-auto-columns: ${(props) =>
      props.bigGrid ? "calc(33.34%)" : "calc(25%)"};
  }
  display: grid;
  background: #efefef;
  align-items: center;
  grid-auto-flow: row;
  grid-auto-columns: ${(props) =>
    props.bigGrid ? "calc(50%)" : "calc(33.34%)"};
  grid-template-columns: unset;
  grid-template-rows: unset;
  column-gap: 0;
  row-gap: 2px;
  overflow: auto;
`;

const PlanDescription = styled(GridItem)<{ selected: any; acquired: boolean }>`
  @media screen and (min-width: 768px) {
    .plan-container {
      padding: 0.5em 1em !important;
      width: 50% !important;
    }
    padding: 1em 0 0.5em 0;
  }
  background: white;
  height: calc(100% + 2px);
  align-self: self-start;
  .plan-container {
    margin: 0 auto;
    width: 98%;
    text-align: left;
    background: ${(props) => (props.acquired ? "#707070" : "#e13214")};
    opacity: ${(props) => (props.selected ? 1 : 0.6)};
    color: white;
    position: relative;
    padding: 0.5em 0 0.5em 0.2em;
    border-radius: 5px;
    &::after {
      display: ${(props) => (props.selected ? "inline" : "none")};
      content: "";
      position: absolute;
      border: 10px solid transparent;
      border-top: 10px solid
        ${(props) => (props.acquired ? "#707070" : "#e13214")};
      width: 0px;
      height: 0px;
      left: calc(50% - 10px);
      background: rgba(0, 0, 0, 0);
      bottom: calc(-10px - 0.5rem);
      z-index: 1001;
    }
  }
`;

const BlueGridItem = styled(GridItem)`
  color: ${(props) => (props.selected ? "#7abbe0" : "#a5cee7")};
`;

const BlackGridItem = styled(GridItem)`
  color: ${(props) => (props.selected ? "#000000" : "#999999")};
  text-decoration: line-through;
`;

const GridTitle = styled(GridItem)<{ bigGrid: boolean }>`
  @media screen and (min-width: 768px) {
    grid-row-start: ${(props) => props.position};
    grid-column-start: auto;
    text-align: left;
    height: 100%;
  }
  color: inherit;
  height: calc(100% + 2px);
  align-self: self-start;
  grid-row-start: ${(props) => 2 * props.position - 1};
  grid-column-start: span ${(props) => (props.bigGrid ? "2" : "3")};
`;

interface Plan {
  title: string;
  price: number;
  subscription_key: string;
  verification_count: number;
  is_monthly: boolean;
  search_increment: string;
  regular_cost: number | null;
}
const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

function getDiscountAmount(plan: Plan) {
  return Math.max((plan.regular_cost ?? plan.price) - plan.price, 0);
}

function getPlan(
  plan: Plan,
  isAuthenticated: boolean,
  currentSubscription: string | undefined,
  openLoginModal: () => void,
  openReceiptModal: (plan: Plan) => void,
  selected: boolean = false,
  updateSelection: () => void,
  selectedPlanMode: number,
  portal: () => void
) {
  const isFreePlan = plan.price === 0;
  const acquired = currentSubscription === plan.subscription_key;
  const regularCost = plan.regular_cost ?? plan.price + getDiscountAmount(plan);
  return (
    <>
      <PlanDescription
        className="clickable"
        position={1}
        selected={selected}
        acquired={acquired}
        onClick={updateSelection}
      >
        <div className="plan-container">
          <div className="poppins-semibold-20">
            {plan.title.replace(/\s(Monthly|Yearly)/, "")}
          </div>
          <div className="d-none d-md-inline">
            {" "}
            Oferta <br />
            {Math.round((1 - plan.price / regularCost) * 100)}%<br /> de
            descuento
          </div>
        </div>
      </PlanDescription>
      <GridItem position={2} selected={selected}>
        RD${" "}
        {(plan.price / (plan.is_monthly ? 1 : 12)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </GridItem>
      {selectedPlanMode === 0 && (
        <GridItem position={3} selected={selected}>
          {plan.verification_count}
        </GridItem>
      )}
      <GridItem position={4} selected={selected}>
        {plan.search_increment}
      </GridItem>
      {selectedPlanMode === 0 && (
        <BlueGridItem position={5} selected={selected}>
          RD${" "}
          {getDiscountAmount(plan).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </BlueGridItem>
      )}
      <GridItem position={6} selected={selected}>
        <Icon src={selected ? checkmarkRed : checkmarkGray} />
      </GridItem>
      <BlackGridItem position={7} selected={selected}>
        RD${" "}
        {regularCost.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </BlackGridItem>
      <div
        className="d-none card h-100 py-5"
        style={{
          border: "1px solid black",
          borderRadius: "10px",
          opacity: (
            isAuthenticated
              ? isFreePlan && currentSubscription !== "free"
              : !isFreePlan
          )
            ? "0.4"
            : "1",
        }}
      >
        <div className="card-body">
          <h4 className="card-title poppins-medium-25">{plan.title}</h4>
          <div className="bebas-neue-regular-54 pt-3 pb-2">
            RD$ {(plan.is_monthly ? plan.price : plan.price / 12.0).toFixed(2)}
          </div>
          <div className="poppins-light-20">
            {isFreePlan
              ? "Totalmente gratis"
              : "Pagos / " + (plan.is_monthly ? "mensuales" : "anuales")}
          </div>
          <button
            type="button"
            className={
              "btn mt-4 mb-3 " +
              (acquired ? "btn-danger" : "btn-outline-danger")
            }
            disabled={isFreePlan && isAuthenticated}
            style={{
              width: "100%",
              border: "1px solid #D2190E",
              borderRadius: "5px",
              padding: "0.35rem 0",
            }}
            onClick={() => {
              if (currentSubscription) return portal();
              return isAuthenticated
                ? openReceiptModal(plan)
                : openLoginModal();
            }}
          >
            {isFreePlan
              ? isAuthenticated
                ? "Sin subscripción"
                : "¡Regístrate ahora!"
              : acquired
              ? "Cancelar mi plan"
              : "Adquirir plan " + plan.title}
          </button>
          <hr className="opacity-75" />
          {plan.price > 0 && (
            <div className="poppins-light-13">
              <p>
                Aumenta un{" "}
                <span className="fw-bold">{plan.search_increment}</span> en el
                motor de búsqueda de bohiio con el plan {plan.title}
              </p>
            </div>
          )}
          {plan.verification_count > 0 && (
            <div className="poppins-light-13">
              <p>
                <span
                  className="poppins-medium-13"
                  style={{ color: "#2583C9" }}
                >
                  Ahorra {plan.is_monthly ? "mensualmente" : "anualmente"}{" "}
                </span>
                en las certificaciones de tus propiedades
              </p>
              <h5
                className="bebas-neue-regular-21"
                style={{ color: "#2583C9" }}
              >
                RD${" "}
                {getDiscountAmount(plan).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}{" "}
                / {plan.is_monthly ? "MES" : "AÑO"}
              </h5>
            </div>
          )}
          <ul className="list-group list-group-flush text-start poppins-light-13">
            <li className="list-group-item border-0">
              <Icon src={checkIcon} /> Publica y edita tus propiedades sin
              límite
            </li>
            <li className="list-group-item border-0">
              <Icon src={checkIcon} /> Sube todas las fotos que quieras sobre la
              propiedad
            </li>
            <li className="list-group-item border-0">
              <Icon src={checkIcon} /> Ubicación de tu propiedad en el mapa
            </li>
            {plan.verification_count > 0 && (
              <li className="list-group-item border-0">
                <Icon src={checkIcon} /> Certificaciones de propiedad incluidas
                en plan {plan.title}
                <span className="fw-bold text-danger">
                  {" "}
                  {plan.verification_count}
                </span>
              </li>
            )}
            {plan.price === 0 && (
              <li className="list-group-item border-0 opacity-25">
                <Icon src={checkIcon} /> Certificaciones de propiedad incluidas
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

const ProductDisplay = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);
  const [plans, setPlans] = useState<Plan[][]>([[], []]);
  const [selectedPlanTime, setSelectedPlanTime] = useState(0);
  const [selectedPlanMode, setSelectedPlanMode] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [showLogin, setShowLogin] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const currentSubscription = profile?.profile?.subscription_key;
  const { getPlans, portal, checkout } = useContext(PaymentContext);
  const [receipt, setReceipt] = useState({
    price: 0,
    discountAmount: 0,
    currency: "USD",
    id: "1",
    receiptDetails: "",
    certifications: 0,
    proceedToPay: () => {},
  });

  useEffect(() => {
    getPlans().then((res) => {
      if (res.length === 0) {
        return;
      }

      let allPlans: Plan[][] = [[], []];
      setSelectedPlanMode(res[0].lookup_key.endsWith("lite") ? 1 : 0);
      res.forEach((p: any) => {
        let plan: Plan = {
          title: p.name,
          price: p.amount / 100,
          subscription_key: p.lookup_key,
          verification_count: p.free_verifications,
          is_monthly: p.interval === "month",
          search_increment: p.search_increment,
          regular_cost: p.regular_cost ? p.regular_cost / 100 : null,
        };
        (plan.is_monthly ? allPlans[0] : allPlans[1]).push(plan);
      });
      allPlans[0].sort((a, b) => a.price - b.price);
      allPlans[1].sort((a, b) => a.price - b.price);
      setPlans(allPlans);
    });
  }, []);

  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = () => setShowLogin(true);

  const closeReceiptModal = () => setShowReceipt(false);
  const openReceiptModal = (plan: Plan) => {
    let discountAmount = getDiscountAmount(plan);
    let receiptObj = {
      price: plan.price + discountAmount,
      discountAmount: discountAmount,
      currency: "USD",
      id: "1",
      receiptDetails: "Pago de subscripción plan " + plan.title,
      certifications: plan.verification_count,
      proceedToPay: () => checkout(plan.subscription_key),
    };
    setReceipt(receiptObj);
    setShowReceipt(true);
  };

  const plansToShow = plans[selectedPlanTime];

  if (plans[0].length > 0 && selectedPlan === undefined) {
    let plan = undefined;
    if (currentSubscription) {
      plan =
        plans[0].find((p) => p.subscription_key === currentSubscription) ||
        plans[1].find((p) => p.subscription_key === currentSubscription);
    }
    if (plan) {
      setSelectedPlan(plan);
      setSelectedPlanTime(plan.is_monthly ? 0 : 1);
    } else {
      //If user doesn't have a subscription, set Real Estate as selected
      setSelectedPlan(plans[0][plans[0].length - 1]);
    }
  }

  return (
    <section>
      <Grid
        bigGrid={selectedPlanMode === 1}
        className="align-items-center justify-content-between poppins-semibold-15"
      >
        <GridTitle
          bigGrid={selectedPlanMode === 1}
          className="bg-white ps-2 text-start"
          position={1}
          style={{ height: "calc(100% + 2px)" }}
        >
          <div className="poppins-medium-15 mb-3 mt-3">
            <p style={{ lineHeight: "0.7" }}>
              Ahorra <br />
              <span className="pacifico-regular-20">20% mas</span> Anualmente
            </p>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className={
                "btn" +
                (selectedPlanTime === 1
                  ? "poppins-semibold-14"
                  : "poppins-regular-14")
              }
              style={
                selectedPlanTime === 1
                  ? {
                      background: "#dfdfdf",
                      color: "black",
                      border: "1px solid transparent",
                      padding: "0.5rem 1rem",
                      borderRadius: "5px",
                    }
                  : {
                      background: "#efefef",
                      border: "1px solid transparent",
                      padding: "0.5rem 1rem",
                      borderRadius: "5px",
                    }
              }
              onClick={() => {
                setSelectedPlanTime(1);
                setSelectedPlan(plans[1][plans[1].length - 1]);
              }}
            >
              Anual
            </button>
            <button
              type="button"
              className={
                "btn" +
                (selectedPlanTime === 0
                  ? "poppins-semibold-14"
                  : "poppins-regular-14")
              }
              style={
                selectedPlanTime === 0
                  ? {
                      background: "#dfdfdf",
                      color: "black",
                      border: "1px solid transparent",
                      padding: "0.5rem 1rem",
                      margin: "0 1em",
                      borderRadius: "5px",
                    }
                  : {
                      background: "#efefef",
                      border: "1px solid transparent",
                      padding: "0.5rem 1rem",
                      margin: "0 1em",
                      borderRadius: "5px",
                    }
              }
              onClick={() => {
                setSelectedPlanTime(0);
                setSelectedPlan(plans[0][plans[0].length - 1]);
              }}
            >
              Mensual
            </button>
          </div>
        </GridTitle>
        <GridTitle bigGrid={selectedPlanMode === 1} position={2}>
          Precio Mensual
        </GridTitle>
        {selectedPlanMode === 0 && (
          <GridTitle bigGrid={false} position={3}>
            Verificaciones incluidas{" "}
            {selectedPlanTime === 0 ? "al mes" : "por el año"}
          </GridTitle>
        )}
        <GridTitle bigGrid={selectedPlanMode === 1} position={4}>
          Aumento en nuestro motor de búsqueda{" "}
          <span className="d-none d-md-inline">
            {selectedPlanTime === 0 ? "al mes" : "por el año"}
          </span>
        </GridTitle>
        {selectedPlanMode === 0 && (
          <GridTitle bigGrid={false} position={5}>
            Ahorra {selectedPlanTime === 0 ? "mensual" : "anualmente"} en las
            certificaciones
          </GridTitle>
        )}
        <GridTitle bigGrid={selectedPlanMode === 1} position={6}>
          Validación de perfil{" "}
          <span className="d-none d-md-inline">
            por un {selectedPlanTime === 0 ? "mes" : "año"}
          </span>
        </GridTitle>
        <GridTitle bigGrid={selectedPlanMode === 1} position={7}>
          Costo regular
        </GridTitle>
        {plansToShow.map((planProps: Plan, index: any) => (
          <>
            {getPlan(
              planProps,
              isAuthenticated,
              currentSubscription,
              openLoginModal,
              openReceiptModal,
              planProps.subscription_key === selectedPlan?.subscription_key,
              () => {
                setSelectedPlan(planProps);
              },
              selectedPlanMode,
              portal
            )}
          </>
        ))}
      </Grid>
      {selectedPlanMode === 0 && (
        <div className="mt-4">
          <p
            className="poppins-regular-13 d-none d-md-block"
            style={{ color: "gray" }}
          >
            Las verificaciones tienen una vigencia de un año para ser utilizadas
            a partir de la fecha en que se acrediten.
            <br /> Para que una propiedad sea certificada primero debe pasar
            satisfactoriamente la evaluación técnica y legal de nuestro equipo
            de profesionales, una vez que el inmueble cumpla con los requisitos
            de la verificación y no presente inconvenientes en su registro, será
            certificada por nosotros.
          </p>
          <p className="poppins-regular-13" style={{ color: "gray" }}>
            Los planes al igual que las verificaciones son individuales para
            cada usuario, por lo que la verificación de una propiedad de otro
            usuario no es aplicable para certificación si no es el dueño de la
            publicación quién lo solicita. Consulta los{" "}
            <Link to={paths.TERMS}>Términos de uso</Link> Para mas información
          </p>
        </div>
      )}
      <div className="d-flex">
        {currentSubscription &&
        currentSubscription === selectedPlan?.subscription_key ? (
          <button
            className="btn poppins-medium-16"
            style={{
              color: "white",
              background: "#707070",
              border: "1px solid transparent",
              padding: "0.5rem 1rem",
              borderRadius: "5px",
              margin: "0.5rem auto",
            }}
            onClick={() => portal()}
          >
            Administrar suscripciones
          </button>
        ) : (
          <button
            className="btn poppins-medium-16"
            style={{
              color: "white",
              background: "#e13214",
              border: "1px solid #D2190E",
              padding: "0.5rem 1rem",
              borderRadius: "5px",
              margin: "0.5rem auto",
            }}
            onClick={() => {
              return isAuthenticated
                ? checkout(selectedPlan?.subscription_key ?? "")
                : openLoginModal();
            }}
          >
            Adquirir Plan
          </button>
        )}
      </div>
      <AuthenticationModal active={showLogin} closeModal={closeLoginModal} />
      <ReceiptModal
        darkerBackground={false}
        active={showReceipt}
        closeModal={closeReceiptModal}
        receipt={receipt}
        isSubscription={true}
      />
    </section>
  );
};

const SuccessDisplay = () => {
  const [showToast, setShowToast] = useState(true);
  const { portal } = useContext(PaymentContext);
  return (
    <div className="toast-container p-3 position-absolute bottom-0 start-50 translate-middle-x">
      <div
        className={"toast " + (showToast ? "show" : "hide")}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <img
            src="/favicon.ico"
            className="rounded me-2"
            style={{ width: "30px" }}
            alt="..."
          />
          <strong className="me-auto">Success</strong>
          <button
            type="button"
            onClick={() => setShowToast(false)}
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div className="toast-body">
          Subscription activated! Thanks for choosing us
          <div className="mt-2 pt-2 border-top text-center">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => portal()}
            >
              Manage your billing info
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//TODO: Move to a toast
const Message = ({ message }: { message: string }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Payment() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { profile } = useContext(ProfileContext);
  const [showSubscriptionAcquiredModal, setShowSubscriptionAcquiredModalOpen] =
    useState(false);
  const closeSubscriptionAcquiredModal = () =>
    setShowSubscriptionAcquiredModalOpen(false);
  const openSubscriptionAcquiredModal = () =>
    setShowSubscriptionAcquiredModalOpen(true);
  const [isLitePlan, setIsLitePlan] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("success")) {
      if (queryParams.get("is_lite") === "True") {
        setIsLitePlan(true);
        queryParams.delete("is_lite");
      }
      openSubscriptionAcquiredModal();
      setSuccess(true);
    }

    if (queryParams.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled!");
    }

    queryParams.delete("success");
    queryParams.delete("canceled");
    history.replace({ search: queryParams.toString() });
  }, []);

  return (
    <div
      className="main-container d-flex flex-column justify-content-between"
      style={{ minHeight: "105vh" }}
    >
      <Navbar
        searchBarOption={
          <NavigationBackButton
            onClick={() => {
              history.push(
                paths.USER_PROFILE.replace(":username", profile?.username ?? "")
              );
            }}
          />
        }
        searchBarToRight
      />

      <div id="top" className="container py-2 py-md-4 flex-grow-1 mt-3">
        <div className="d-flex justify-content-between">
          <h1 className="poppins-bold-24">Selecciona el plan ideal para ti</h1>
        </div>
        <div>
          <div>
            <Icon src={checkmarkRed} /> <span>Sube fotos y videos</span>
          </div>
          <div>
            <Icon src={checkmarkRed} />{" "}
            <span>Publica tus propiedades sin límite</span>
          </div>
          <div>
            <Icon src={checkmarkRed} />{" "}
            <span>Ubicación de tus propiedades en el mapa</span>
          </div>
        </div>
        <ProductDisplay />
        {success && <SuccessDisplay />}
        {!success && message !== "" && <Message message={message} />}
        <SubscriptionAcquiredModal
          active={showSubscriptionAcquiredModal}
          closeModal={closeSubscriptionAcquiredModal}
          darkerBrackground={true}
          litePlan={isLitePlan}
        />
      </div>
      <Footer />
    </div>
  );
}
