import { Formik, Form } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { changePasswordEndpoint } from "../api/profile";
import Avatar from "./Avatar";
import { useHistory } from "react-router-dom";
import { ProfileContext } from "../context/profile/ProfileContext";
import { useContext, useState } from "react";
import UpdatedPasswordModal from "./UpdatedPasswordModal";
import PasswordField from "./PasswordField";
import { ButtonRed } from "../styles/common";
import BaseModal from "./BaseModal";
import BaseModalContent from "./BaseModalContent";
import BaseShortModal from "./BaseShortModal";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  padding: 0.5em 1em;
  border-radius: 5px;
`;

const WhiteButton = styled.button`
  border: 1px white solid;
  background: white;
  color: #d2190e;
  padding: 0.5em 1em;
  margin: 20px 0px;
`;

const ProfileChangePassword = (props: any) => {
  const history = useHistory();

  const { profile } = useContext(ProfileContext);
  const [showSuccessfulModal, setShowSuccessfulModal] = useState(false);
  const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =
    useState(false);
  return (
    <Formik
      initialValues={{
        password: "",
        newPassword: "",
        newPasswordConfirmation: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string().required("Required"),
        newPassword: Yup.string().required("Required"),
        newPasswordConfirmation: Yup.string().required("Required"),
      })}
      onSubmit={async (values, FormikBag) => {
        if (values.newPassword !== values.newPasswordConfirmation) {
          // TODO: launch new password mismatch error
          FormikBag.setErrors({
            newPassword: "There is a password mismatch",
          });
        } else {
          const data = {
            old_password: values.password,
            new_password: values.newPassword,
          };
          // TODO: change state notification error
          try {
            await changePasswordEndpoint(data);
            setShowSuccessfulModal(true);
          } catch (err: any) {
            if (
              err.response.data?.old_password.find((r: any) =>
                r.includes("Wrong")
              )
            ) {
              FormikBag.setErrors({
                password: "La contraseña es incorrecta",
              });
            } else {
              setShowUnexpectedErrorModal(true);
            }
            return;
          }
        }
      }}
    >
      <Form>
        <div className="d-flex mx-3 mb-4">
          <Avatar src={props.avatar} alt="avatar" />
          <div>
            <p className="poppins-medium-27">
              {props.first_name} {props.last_name}
            </p>
            <p className="poppins-light-14">@{props.username}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <PasswordField name="password" label="Contraseña anterior" />
          </div>
          <div className="col-12 mb-4">
            <PasswordField name="newPassword" label="Contraseña nueva" />
          </div>
          <div className="col-12 mb-4">
            <PasswordField
              name="newPasswordConfirmation"
              label="Confirmar contraseña nueva"
            />
          </div>
        </div>
        <div className="poppins-regular-14 d-flex justify-content-between align-items-center">
          <Button className="d-none" />
          <WhiteButton onClick={props.resetPassword}>
            ¿Olvidaste tu contraseña?
          </WhiteButton>
          <ButtonRed
            style={{
              height: "40px",
            }}
            type="submit"
          >
            Cambiar
          </ButtonRed>
        </div>
        <UpdatedPasswordModal
          children={null}
          active={showSuccessfulModal}
          closeModal={() => history.push(`/profile/${profile?.username}`)}
        />
        <BaseShortModal
          active={showUnexpectedErrorModal}
          closeModal={() => setShowUnexpectedErrorModal(false)}
        >
          <BaseModalContent
            title={"Algo paso..."}
            buttonContent={"Entendido"}
            buttonAction={() => setShowUnexpectedErrorModal(false)}
          >
            Ha ocurrido algo inesperado, intenta nuevamente.
          </BaseModalContent>
        </BaseShortModal>
      </Form>
    </Formik>
  );
};

export default ProfileChangePassword;
