import DashboardSidebar from "../../components/DashboardSidebar";
import HistoryBoard from "../../components/HistoryBoard";
import Navbar from "../../components/Navbar";
import { useHistory } from "react-router-dom";
import { NavigationBackButton } from "../../components/NavigationBackButton";

const History = () => {
  const history = useHistory();
  return (
    <>
      <div className="d-flex flex-column main-container">
        <Navbar
          searchBarOption={
            <NavigationBackButton
              onClick={() => {
                history.go(-1);
              }}
            />
          }
          searchBarToRight
        />

        <DashboardSidebar />
        <div className="col-md-6 col-12 offset-md-1 mt-2">
          <div style={{ flexWrap: "wrap" }} className="mt-4">
            <HistoryBoard favorite />
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
