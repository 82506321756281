import { useState } from "react";
import styled from "styled-components";
import { Property } from "../context/property/types";
import OptionsModal from "./OptionsModal";
import ReportPropertyCompleteModal from "./ReportPropertyCompleteModal";

const OptionMenu = styled.div`
  background: white;
  border-radius: 5px;
  text-align: center;
  div {
    border-bottom: 2px solid gray;
    padding: 1rem 4rem;
    cursor: pointer;
  }
  div:last-child {
    font-weight: 700;
    border-bottom: 0px;
  }
`;

interface IReportPropertyModalProps {
  property: Property;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const ReportPropertyModal = ({
  active,
  closeModal,
  darkerBackground,
  property,
}: IReportPropertyModalProps) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const proccessReport = (id: any) => {
    //TODO: actually send a report
    setShowCompleteModal(true);
  };

  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <div className="poppins-regular-12">
          <p>Reportar</p>
          <p>
            Tu reporte ayuda a mejorar los servicios de bohiio.{" "}
            <strong>¿Qué deseas reportar?</strong>
          </p>
        </div>
        <OptionMenu className="poppins-regular-12">
          <div onClick={() => proccessReport(1)}>
            La propiedad ya no esta disponible
          </div>
          <div onClick={() => proccessReport(2)}>
            La información no es correcta
          </div>
          <div onClick={() => proccessReport(3)}>
            No he podido contactar al vendedor
          </div>
          <div onClick={closeModal}>Cancelar</div>
        </OptionMenu>
      </OptionsModal>
      <ReportPropertyCompleteModal
        darkerBackground
        active={showCompleteModal}
        closeModal={() => {
          setShowCompleteModal(false);
          closeModal();
        }}
      />
    </>
  );
};

export default ReportPropertyModal;
