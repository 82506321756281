import { createContext, ReactNode, useContext, useState } from "react";
import API from "../../api/endpoint";
import engine from "../../api/engine";
import axios from "axios";
import { dataURIToBlob } from "../../utils";
import { AuthContext } from "../auth/AuthContext";

interface IPaymentContext {
  checkout: (arg0: string) => Promise<void>;
  getPlans: () => Promise<any[]>;
  verification: (arg0: boolean, arg1: number, arg2: Function) => Promise<void>;
  standaloneVerification: (
    arg0: boolean,
    arg1: any,
    arg2: Function,
    arg3: Function
  ) => void;
  portal: () => Promise<void>;
  addLoadPercentage: number;
}

export const PaymentContext = createContext<IPaymentContext>(
  {} as IPaymentContext
);
interface PaymentProviderProps {
  children: ReactNode;
}

export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const [addLoadPercentage, setAddLoadPercentage] = useState(0);
  const { isAuthenticated } = useContext(AuthContext);

  const checkout = async (subscriptionKey: string) => {
    // if (subscriptionKey === "" || !isLoggedIn()) {
    //   window.location.href = "/signup";
    // }

    const res = await engine.post(API.checkout, {
      subscriptionKey: subscriptionKey,
    });

    if (res.status === 200) {
      // console.log("Redirecting to stripe checkout...");
      window.location.href = res.data.checkout_url;
    } else {
      throw new Error("Checkout unsuccessful");
    }
  };

  const getPlans = async () => {
    const res = await engine.get(API.get_plans);

    if (res.status === 200) {
      return res.data.results;
    } else {
      throw new Error("Fetch plans unsuccessful");
    }
  };

  const verification = async (
    useCredit: boolean,
    propertyId: number,
    openCompletedModal: Function
  ) => {
    if (!isAuthenticated) {
      //window.location.href = "/signup";
    }

    const res = await engine.post(API.verification, {
      useCredit: useCredit,
      property: propertyId,
      url: window.location.href,
    });

    if (res.status === 200) {
      if (res.data.verified) {
        openCompletedModal(res.data.verification_is_owner, useCredit);
      } else {
        window.location.href = res.data.checkout_url;
      }
    } else {
      throw new Error("Verification unsuccessful");
    }
  };

  const standaloneVerification = async (
    useCredit: boolean,
    propertyInfo: any,
    openCompletedModal: Function,
    openCertificationNotSupportedModal: Function
  ) => {
    if (!isAuthenticated) {
      //window.location.href = "/signup";
    }

    let formData = new FormData();
    formData.append("latitude", String(propertyInfo.latitude));
    formData.append("longitude", String(propertyInfo.longitude));
    propertyInfo.documents.forEach((document: any) => {
      formData.append(
        "documents",
        dataURIToBlob(document.dataURL),
        document.name
      );
    });

    try {
      // upload documents to backend
      const verification_res = await engine.post(
        API.request_verification,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // console.log("percentCompleted", percentCompleted);
            setAddLoadPercentage(percentCompleted);
          },
        }
      );

      // throw error if bad status code received
      if (verification_res.status !== 201) {
        throw new Error("Non valid status code for document upload");
      }

      // Call stripe payment endpoint
      const verification_request_id = verification_res.data.id;
      const payment_res = await engine.post(API.standalone_verification, {
        useCredit: useCredit,
        verification_request_id: verification_request_id,
      });

      // throw error if bad status code received
      if (payment_res.status !== 200) {
        throw new Error("Non valid status code for stripe endpoint");
      }

      //redirect to stripe
      if (payment_res.data.verified) {
        openCompletedModal(payment_res.data.verification_is_owner, useCredit);
      } else {
        window.location.href = payment_res.data.checkout_url;
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data.message === "Unsupported property location") {
          openCertificationNotSupportedModal();
        }
      }
      throw new Error("documents upload unsuccessful");
    }
  };

  // Customer portal to manage billing
  const portal = async () => {
    const res = await engine.post(API.portal);

    if (res.status === 200) {
      // console.log("Redirecting to stripe portal...");
      window.location.href = res.data.session_url;
    } else {
      throw new Error("Can't access billing portal");
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        checkout,
        getPlans,
        verification,
        standaloneVerification,
        portal,
        addLoadPercentage,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
