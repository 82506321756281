import { Route, Redirect } from "react-router-dom";
import paths from "./paths.json";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth/AuthContext";

import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

interface PrivateRouteProps {
  children: React.ReactNode;
  path: string;
}

function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const { isAuthenticated, handleAuthentication, getNewToken } =
    useContext(AuthContext);
  const [internalLoading, setInternalLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        await getNewToken();
        handleAuthentication?.();
      } catch {
      } finally {
        setInternalLoading(false);
      }
    })();
  }, [getNewToken, handleAuthentication]);
  if (internalLoading) return <></>;

  return (
    <SentryRoute
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: paths.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
