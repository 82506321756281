import {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import Filter, {
  FilterState,
  initialExtraFilterState,
} from "../../components/Filter";
import Navbar from "../../components/Navbar";
import PropertyCard from "../../components/PropertyCard";
import leftIcon from "../../images/left.svg";
import rightIcon from "../../images/right.svg";
import crossIcon from "../../images/cross-dinamic.svg";
import pyramidIcon from "../../images/half-reverse-pyramid.svg";
import {
  PropertyContext,
  ShortmapPropertiesResult,
} from "../../context/property/PropertyContext";
import MapMarker from "../../components/MapMarker";
import { useHistory, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQuery } from "react-query";
import MapPlaceholder from "../../components/MapPlaceholder";
import useGeolocation from "../../hooks/useGeolocation";
import { Property, ShortmapProperty } from "../../context/property/types";
import { ScrollerItem } from "../../components/Scroller";
import { ButtonRed } from "../../styles/common";
import useScreen from "../../hooks/useScreen";
import PropertyCertification from "../../components/PropertyCertification";
import { updateUrlLocation } from "../../context/location/LocationContext";
import BaseMap, { getMapActualState } from "../../components/Map";

const PROPERTY_CONTAINER_HEIGHT_PX = 220;

export interface SetFilterProp {
  name: string;
  value: string;
}

const ScrollessDiv = styled.div`
  scrollbar-width: thin;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterDiv = styled(ScrollessDiv)<{ showMobileFilter?: any }>`
  @media screen and (min-width: 768px) {
    position: relative;
    display: inline;
    max-height: 100vh;
    border-radius: 0;
    padding: 0 5px 0 15px;
    max-width: 320px;
  }

  display: ${(props) => (props.showMobileFilter ? "inline" : "none")};
  background-color: white;
  bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 15px;
  position: fixed;
  max-height: 60vh;
  z-index: 1025;
  width: 100%;
  max-width: inherit;
  padding: 15px 15px;
  height: 100%;
  overflow-y: auto;
`;

const PropertiesContainer = styled.div<{ showMap: boolean }>`
  @media screen and (min-width: 768px) {
    height: ${(props) => (props.showMap ? "300px" : "calc(100% - 60px)")};
  }
  height: ${(props) => (props.showMap ? "0" : "100%")};
  width: 100%;
`;

const PropertyDetailCardFloatContainer = styled.div<{
  lat?: number;
  lng?: number;
  locked?: boolean;
}>`
  width: 100%;
  max-width: 360px;
  background-color: white;
  border-radius: 4px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  & .property-card-content {
    margin: 0 5px;
  }
`;
// position: absolute;
// bottom: 30px;
// left: 5px;

const PropertyListContainer = styled.div`
  min-height: 0;
  position: relative;
  overflow-y: auto;
  height: 100%;
  @media screen and (min-width: 768px) {
    min-height: 210px;
  }
`;

// mx-md-5
const MainContainer = styled.div`
  @media screen and (min-width: 768px) {
    margin-left: 55px;
  }
  margin-left: inherit;
`;

const LeftSideContainer = styled.div<{ showMap: any }>`
  @media screen and (min-width: 768px) {
    height: calc(100vh - 62px);
  }
  height: ${(props) => `calc(100vh - ${props.showMap ? "105px" : "190px"})`};
`;

const PropertyActionContainer = styled.div`
  @media screen and (min-width: 768px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const PropertySlideButton = styled.img<{ isLeft: any }>`
  &:hover {
    background: white;
  }
  position: absolute;
  color: grey;
  border: 1px white solid;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  font: 700 2rem/2.4rem Poppins; //poppins-bold-32
  cursor: pointer;
  z-index: 1002;
  top: calc(40% - 20px);
  box-shadow: 0px 1px 1px #00000029;
  //margin-top: -${PROPERTY_CONTAINER_HEIGHT_PX * 0.6}px;
  //margin-top: ${PROPERTY_CONTAINER_HEIGHT_PX * 0.4}px;
  ${(props) =>
    (props.isLeft === true &&
      `
    left: 1px;
  `) ||
    `right: 1px;`}
` as any;

const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

// ${(props) => (props.selected ? "#707070" : "#a5a4a5")}
const Button = styled.button<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? "#707070" : "#ececec")};
  color: ${(props) => (props.selected ? "white" : "black")};
  border: none;
  border-radius: 5px;
  padding: 0.25rem 1rem;
`;

export const colorMap: { [key: string]: string } = {
  rent: "#2583C9",
  sell: "#D2190E",
  both: "#1FB105",
};

export const colorMapHover: { [key: string]: string } = {
  rent: "#216CA4",
  sell: "#B9160C",
  both: "#1B8309",
};

const getShowListButton = (onClick: () => void) => {
  const button = document.createElement("button");
  button.classList.add("poppins-medium-16", "map-button-red");
  button.onclick = () => {
    onClick();
  };
  button.textContent = "Mostrar lista";
  return button;
};

const getMapShowListButton = (onClick: () => void) => {
  const button = getShowListButton(onClick);
  button.classList.add("map-button-red-space");
  return button;
};

const MobilePropertiesContainer = styled.div`
  overflow-y: auto;
`;

function useOutsideAlerter(ref: any, menu: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (menu.isOpen && ref.current && !ref.current.contains(event.target)) {
        menu.closeMenu();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, menu]);
}

const baseFilterState: FilterState = {
  bathroomsEnd: 0,
  bathroomsStart: 0,
  condition: "",
  constructionAreaEnd: 0,
  constructionAreaStart: 0,
  currentUse: "",
  propertyType: "",
  extra: initialExtraFilterState,
  landAreaEnd: 0,
  landAreaStart: 0,
  priceEnd: 0,
  priceStart: 0,
  publicationType: "",
  roomsEnd: 0,
  roomsStart: 0,
};

const Map = () => {
  const location = useLocation();
  const filterDivRef = useRef(null);
  const history = useHistory();
  const mapRef = useRef<any>(null);
  const [filter, setFilter] = useState(new URLSearchParams());
  const [showPropertyDetailsCard, setShowPropertyDetailsCard] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState<Property>();
  const { getFilteredProperties, getShortmapProperties, getProperty } =
    useContext(PropertyContext);
  const propertiesRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreen();

  const { userLocation } = useGeolocation();
  const getFilterStateFromURL = (params: URLSearchParams): FilterState => {
    const r: FilterState = {
      extra: initialExtraFilterState,
    };
    params.forEach((value, key) => {
      if (key === "attributes") {
        const extras = value.split(",");
        extras.forEach((v) => {
          r.extra[v] = true;
        });
      } else {
        if (
          key === "publicationType" ||
          key === "propertyType" ||
          key === "currentUse" ||
          key === "condition"
        ) {
          r[key] = value;
        } else {
          const n = Number.parseFloat(value);
          if (!Number.isNaN(n)) {
            r[key] = n;
          } else {
            r[key] = baseFilterState[key];
          }
        }
      }
    });
    return r;
  };

  const initialFilterState = useMemo<FilterState>(
    () => getFilterStateFromURL(new URLSearchParams(location.search)),
    []
  );

  const [showMap, setShowMap] = useState(true);
  const [markers, setMarkers] = useState<ShortmapProperty[]>([]);
  const [showMobileFilter, setShowMobileFilter] = useState(false);

  useOutsideAlerter(filterDivRef, {
    isOpen: showMobileFilter,
    closeMenu: () => setShowMobileFilter(false),
  });

  const { ref, inView } = useInView();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isLoadingProperties,
    remove,
  } = useInfiniteQuery(
    `filteredProperties-${filter.toString()}`,
    ({ pageParam = 0 }) => {
      return getFilteredProperties(
        pageParam,
        new URLSearchParams(location.search)
      );
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length;
        return lastPage.length !== 0 ? nextPage : null;
      },
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    setShowMobileFilter(false);
    remove();
  }, [filter, remove]);

  const st = `shortmapProperties-${filter.toString()}`;
  const { data: shortmapProperties } = useQuery<ShortmapPropertiesResult>(
    st,
    () => {
      return getShortmapProperties(
        0,
        userLocation.latitude,
        userLocation.longitude,
        userLocation.distance,
        100,
        filter
      );
    },
    {
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (!shortmapProperties) {
      return;
    }
    setMarkers(shortmapProperties.results);
  }, [shortmapProperties]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView, isFetchingNextPage]);

  useEffect(() => {
    setFilter(() => {
      return new URLSearchParams(location.search);
    });
  }, [location.search]);

  const onClickMapOnProperty = async (hoverKey: any, childProps: any) => {
    if (!childProps.property) {
      return;
    }
    setShowPropertyDetailsCard(true);
    const propertyInfo = await getProperty(childProps.property.id);
    setSelectedProperty(propertyInfo);
  };

  const onUserDragEnd = async () => {};

  const inViewLoadPropertyComponent = useMemo(() => {
    return {
      id: "load-more",
      children: (
        <div
          className="loader col-12 col-md-6 col-lg-4 mb-3 col-xxl-3"
          ref={ref}
        >
          {isFetchingNextPage && hasNextPage ? "Loading..." : "No search left"}
        </div>
      ),
    };
  }, [hasNextPage, isFetchingNextPage, ref]);

  const [isPropertyCertificationActive, setIsPropertyCertificationActive] =
    useState(false);
  const [propertyCertificationInfo, setPropertyCertificationInfo] = useState({
    propertyId: -1,
    isPropertyOwner: true,
  });

  const openPropertyCertification = (
    propertyId: number,
    isPropertyOwner: boolean
  ) => {
    setPropertyCertificationInfo({
      propertyId: propertyId,
      isPropertyOwner: isPropertyOwner,
    });
    setIsPropertyCertificationActive(true);
  };

  const closePropertyCertification = () => {
    setIsPropertyCertificationActive(false);
  };

  const properties = useMemo(() => {
    let properties: ScrollerItem[] = [];
    data?.pages.forEach((page) => {
      properties = properties.concat(
        page.map((property) => {
          return {
            id: property.id.toString(),
            children: (
              <div
                key={property.id}
                className="col-12 col-lg-6 col-xl-4 mb-3 col-xxl-3 px-1"
              >
                <PropertyCard
                  property={property}
                  imgSize={showMap ? 140 : 250}
                  imgSizeUnit={"px"}
                  objectFit={"cover"}
                  openPropertyCertification={openPropertyCertification}
                />
              </div>
            ),
          } as ScrollerItem;
        })
      );
    });
    return properties;
  }, [data?.pages, showMap]);

  const showPlaceholder =
    !data ||
    data.pages.map((page) => page.length).reduce((prev, val) => prev + val) ===
      0;

  const PropertiesComponentList = useMemo(() => {
    return (
      <div
        className={`d-flex flex-wrap h-100 gy-5 ${
          showMap ? "flex-column" : "flex-row"
        }`}
        style={{
          maxHeight: showMap ? `${PROPERTY_CONTAINER_HEIGHT_PX}px` : "",
          overflow: "hidden",
          position: "relative",
        }}
        ref={propertiesRef}
      >
        {showPlaceholder ? <MapPlaceholder collapsed={showMap} /> : null}
        {properties.map((p) => p.children)}
        {inViewLoadPropertyComponent.children}
        {/* <div className="loader" ref={ref}>
          {isFetchingNextPage && hasNextPage
            ? "Loading..."
            : "No search left"}
        </div> */}
      </div>
    );
  }, [properties, showMap, showPlaceholder, inViewLoadPropertyComponent]);

  const setFilterString = useCallback(
    (filter: SetFilterProp[]) => {
      const params = new URLSearchParams();
      filter.forEach((f) => {
        if (params.has(f.name)) {
          params.set(f.name, f.value);
        } else {
          params.append(f.name, f.value);
        }
      });
      const { distance, latitude, longitude, limit, offset, zoom } =
        getMapActualState(location.search, mapRef);

      params.append("longitude", longitude.toString());
      params.append("latitude", latitude.toString());
      params.append("distance", distance.toString());
      params.append("limit", limit.toString());
      params.append("offset", offset.toString());
      params.append("zoom", zoom.toString());
      updateUrlLocation(history, location, params);
    },
    [history, location]
  );

  return (
    <>
      <div className="d-flex flex-column justify-md-content-between main-container">
        <Navbar
          mobileHeight={showMap ? "105px" : undefined}
          searchBarColor={showMap ? "rgba(0, 0, 0, 0)" : undefined}
          searchBarOption={
            <div
              className="d-md-none"
              style={{
                // position: "fixed",
                // right: 15,
                // top: "63px",
                // zIndex: 1027,
                background: "white",
                borderTopLeftRadius: "50%",
                borderBottomLeftRadius: "50%",
              }}
              onClick={() => {
                setShowMobileFilter(!showMobileFilter);
              }}
            >
              <Icon src={pyramidIcon} alt="filter" />
            </div>
          }
        />

        {/* mx-0 */}
        <MainContainer className="">
          <LeftSideContainer className="col-12 d-flex" showMap={showMap}>
            {/* col-12 col-md-9 */}
            <ScrollessDiv className="">
              {showMap && (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  {
                    <BaseMap
                      filter={filter}
                      onChildClick={onClickMapOnProperty}
                      onDragEnd={onUserDragEnd}
                      onLoadMap={(map) => (mapRef.current = map)}
                      userLocation={userLocation}
                      upRightElement={getMapShowListButton(() => {
                        setShowMap(false);
                      })}
                    >
                      {/* map points */}
                      {markers.map((property) => (
                        <MapMarker
                          key={property.id}
                          property={property}
                          lat={property.latitude}
                          lng={property.longitude}
                          color={colorMap[property.typePublication]}
                          hover={colorMapHover[property.typePublication]}
                          selected={selectedProperty?.id === property.id}
                          locked
                        />
                      ))}
                    </BaseMap>
                  }
                  {showPropertyDetailsCard && selectedProperty && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        height: "auto",
                        position: "absolute",
                        bottom: "30px",
                        padding: "0 10px",
                      }}
                    >
                      <PropertyDetailCardFloatContainer>
                        <PropertyCard
                          property={selectedProperty}
                          imgSize={110}
                          imgSizeUnit={"px"}
                          objectFit={"cover"}
                          openPropertyCertification={openPropertyCertification}
                        />
                        <div
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "5px",
                            borderRadius: "100%",
                            backgroundColor: "white",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setShowPropertyDetailsCard(false);
                            setSelectedProperty(undefined);
                          }}
                          className=""
                        >
                          <Icon
                            className="clickable"
                            alt="X"
                            src={crossIcon}
                            style={{
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </div>
                      </PropertyDetailCardFloatContainer>
                    </div>
                  )}
                </div>
              )}

              <PropertiesContainer showMap={showMap}>
                <PropertyActionContainer className="d-flex justify-content-between align-items-center ms-1">
                  <div className={showMap ? "" : "opacity-0"}></div>
                  {!showMap ? (
                    <div className="d-flex justify-content-end my-2 mx-2">
                      <ButtonRed onClick={() => setShowMap(true)}>
                        Ver en el mapa
                      </ButtonRed>
                    </div>
                  ) : null}
                </PropertyActionContainer>
                <PropertyListContainer>
                  {isMobile ? (
                    <div>
                      {!showMap && (
                        // <Scroller items={properties} swapAxys="x" />
                        <MobilePropertiesContainer>
                          {properties.map((property) => (
                            <div
                              style={{ flexShrink: 0, minWidth: "0" }}
                              key={property.id}
                            >
                              {property.children}
                            </div>
                          ))}
                          {inViewLoadPropertyComponent.children}
                        </MobilePropertiesContainer>
                      )}
                    </div>
                  ) : (
                    <div className="w-100">
                      <PropertySlideButton
                        className={`${
                          showMap &&
                          data &&
                          data.pages &&
                          data.pages.length > 0 &&
                          data.pages[0].length > 0
                            ? ""
                            : "d-none"
                        }`}
                        src={leftIcon}
                        isLeft={true}
                        onClick={() => {
                          if (propertiesRef.current) {
                            propertiesRef.current.scrollTo({
                              left:
                                propertiesRef.current.scrollLeft -
                                propertiesRef.current.clientWidth,
                              top: 0,
                              behavior: "smooth",
                            });
                          }
                        }}
                      />
                      {PropertiesComponentList}
                      <PropertySlideButton
                        className={`${
                          showMap &&
                          data &&
                          data.pages &&
                          data.pages.length > 0 &&
                          data.pages[0].length
                            ? ""
                            : "d-none"
                        }`}
                        src={rightIcon}
                        isLeft={false}
                        onClick={() => {
                          if (propertiesRef.current) {
                            propertiesRef.current.scrollTo({
                              left:
                                propertiesRef.current.scrollLeft +
                                propertiesRef.current.clientWidth,
                              top: 0,
                              behavior: "smooth",
                            });
                          }
                        }}
                      />
                    </div>
                  )}
                </PropertyListContainer>
              </PropertiesContainer>
            </ScrollessDiv>
            <FilterDiv
              ref={filterDivRef}
              showMobileFilter={showMobileFilter}
              className="bg-white"
            >
              <Filter
                onClickSearchButton={setFilterString}
                initialState={initialFilterState}
              />
            </FilterDiv>
          </LeftSideContainer>
        </MainContainer>
        <PropertyCertification
          startingModalActive={isPropertyCertificationActive}
          closeStartingModal={closePropertyCertification}
          propertyCertificationInfo={propertyCertificationInfo}
        />
      </div>
    </>
  );
};

export default Map;
