import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Navbar from "../../components/Navbar";
import SimpleFooter from "../../components/SimpleFooter";
import avatar from "../../images/avatar.svg";
import certified from "../../images/certified.svg";
import verifiedIcon from "../../images/verified-user.svg";
import locationMarkerIcon from "../../images/location-marker.svg";
import optionIcon from "../../images/option.svg";
import { getProfileInfo } from "../../api/profile";
import { CertificationStatus } from "../../api/property";
import { useParams } from "react-router-dom";
import PropertyCertification from "../../components/PropertyCertification";
import { FollowersContext } from "../../context/followers/FollowersContext";
import paths from "../../routes/paths.json";
import React from "react";
import ProfilePlaceholder from "../../components/ProfilePlaceholder";
import { AuthContext } from "../../context/auth/AuthContext";
import AuthenticationModal from "../../components/AuthenticationModal";
import { PropertyContext } from "../../context/property/PropertyContext";
import { NavigationBackButton } from "../../components/NavigationBackButton";
import CertifyNowModal from "../../components/CertifyNowModal";
import ProfileOptionsModal from "../../components/ProfileOptionsModal";
import ProfileShortModal from "../../components/ProfileShortModal";
import { useInfiniteQuery } from "react-query";
import { useInView } from "react-intersection-observer";
import WhatsAppIcon from "../../images/WhatsappIcon";

const Icon = styled.img<{ size?: any; position?: boolean }>`
  width: ${(props) => props.size ?? "25px"};
  height: ${(props) => props.size ?? "25px"};
  flex-shrink: 0;

  @media screen and (min-width: 768px) {
    position: ${({ position }) => (position ? "absolute" : "initial")};
  }
`;

const YetToCertifyIcon = styled(Icon)`
  opacity: 0.3;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const PropertyFilter = styled.div<{ selected: boolean }>`
  @media screen and (min-width: 768px) {
    padding: 0.25rem 1rem;
  }
  padding: 0.25rem 0.4rem;
  cursor: pointer;
  border-radius: 5px;
  opacity: ${(props) => (props.selected ? 1 : 0.6)};
  background: ${(props) => (props.selected ? "#dedede" : "none")};
`;

const TopRightTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 50px solid white;
  border-left: 50px solid transparent;
`;

const PropertiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const PropertyContainer = styled.div`
  @media screen and (min-width: 768px) {
    height: 200px;
  }
  height: 130px;
  width: 100%;
`;

const ButtonRed = styled.button`
  @media screen and (min-width: 768px) {
    padding: 0.25rem 2rem;
  }
  background: #dfdfdf;
  color: black;
  padding: 0.25rem 1rem;
  border-radius: 5px;
  &:hover {
    background: #cfcfcf;
  }
`;

export const WhatsAppButton = styled.button`
  border-radius: 5px;
  border: none;
  background: #d2190e;
  color: white;
  padding: 5px 10px;
  transition: 0.3s;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  & svg {
    width: 20px;
    height: 20px;
  }
  & path {
    fill: white;
  }
  &:hover {
    background-color: #c2190e;
    color: white;
  }
`;

const PropertyContainerItem = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const WhiteCircle = styled.img`
  @media screen and (min-width: 992px) {
    display: inline;
  }
  display: none;
  position: relative;
  color: grey;
  border: 1px white solid;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  font: 700 2rem/2.4rem Poppins; //poppins-bold-32
  cursor: pointer;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  &:hover {
    background: white;
  }
`;

interface ProfileParams {
  username: string;
}

const ALL_PROPERTIES = "";
const SELLING_PROPERTIES = "sell";
const RENTING_PROPERTIES = "rent";
const PAGE_SIZE = 12;

const Profile = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const { ref, inView } = useInView();
  const propertiesRef = useRef<HTMLDivElement>(null);
  const { getFollowerCount, getFolloweesCount, follow, unfollow } =
    useContext(FollowersContext);

  const { isAuthenticated } = useContext(AuthContext);
  const { getUserProperties } = useContext(PropertyContext);
  const { username } = useParams<ProfileParams>();
  const [propertyScreen, setPropertyScreen] = useState(ALL_PROPERTIES);
  const [myself, setMyself] = useState(false);
  const [isCertifyNowModalOpen, setIsCertifyNowModalOpen] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followeesCount, setFolloweesCount] = useState(0);
  const [propertyCount, setPropertyCount] = useState(-1);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showProfileShortModal, setShowProfileShortModal] = useState(false);
  const [isPropertyCertificationActive, setIsPropertyCertificationActive] =
    useState(false);
  const [propertyCertificationInfo, setPropertyCertificationInfo] = useState({
    propertyId: -1,
    isPropertyOwner: true,
  });
  const [showLogin, setShowLogin] = useState(false);
  const [nextPage, setNextPage] = useState("/");
  const [user, setUserState] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    username: "",
    settings: {
      subscription: "FREE",
    },
    profile: {
      biography: "",
      city: " ",
      country: " ",
      province: " ",
      language: "es",
      phone: "",
      phone_code: "",
      client_phone: "",
      client_phone_code: "",
      profession: "",
      company: "",
      webpage: "",
      avatar: avatar,
      subscription: {
        name: "free",
      },
    },
    is_following: false,
  });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      `profile-${user.id}-${propertyScreen}`,
      ({ pageParam = 0 }) =>
        (async () => {
          let res = await getUserProperties(
            user.id,
            propertyScreen,
            pageParam,
            PAGE_SIZE
          );
          return res.results;
        })(),
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length;
          return Math.floor(propertyCount / PAGE_SIZE) >= nextPage
            ? nextPage
            : null;
        },
        staleTime: Infinity,
      }
    );

  const LoadMorePropertiesComponent = useMemo(
    () => (
      <div className="loader" ref={ref}>
        {isFetchingNextPage && hasNextPage ? "Loading..." : "No search left"}
      </div>
    ),
    [hasNextPage, isFetchingNextPage, ref]
  );

  const properties = useMemo(() => {
    let list: any[] = [];
    data?.pages.forEach((page) => {
      list = list.concat(
        ...page.map((property: any, index: number) => {
          return property;
        })
      );
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyScreen, data?.pages]);

  const urlAddOn = user.profile.webpage
    .substring(0, 8)
    .toLocaleLowerCase()
    .includes("http")
    ? ""
    : "https://";

  const toAllProperties = () => {
    setPropertyScreen(ALL_PROPERTIES);
  };

  const toSellingProperties = () => {
    setPropertyScreen(SELLING_PROPERTIES);
  };

  const toRentingProperties = () => {
    setPropertyScreen(RENTING_PROPERTIES);
  };

  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = (nextPage: string) => {
    setNextPage(nextPage);
    setShowLogin(true);
  };

  const onSelectPropertyToExpand = (property: any) => {
    history.push(`/property/${property.id}`, {
      background: location,
      propertyFromRouter: property,
    });
  };

  const openPropertyCertification = (
    propertyId?: number,
    isPropertyOwner?: boolean
  ) => {
    if (propertyId !== undefined && isPropertyOwner !== undefined) {
      setPropertyCertificationInfo({
        propertyId: propertyId,
        isPropertyOwner: isPropertyOwner,
      });
    }
    setIsPropertyCertificationActive(true);
  };

  const closePropertyCertification = () => {
    setIsPropertyCertificationActive(false);
  };

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    let allowCertification = false;
    if (location?.state?.propertyCountry) {
      if (location.state.propertyCountry === "DO") allowCertification = true;
      location.state.propertyCountry = null;
    }
    if (location?.state?.propertyIdCertification) {
      setPropertyCertificationInfo({
        propertyId: location?.state?.propertyIdCertification,
        isPropertyOwner: true,
      });
      location.state.propertyIdCertification = null;
    }
    if (location?.state?.openCertifyNowModal) {
      if (allowCertification) setIsCertifyNowModalOpen(true);
      location.state.openCertifyNowModal = null;
    }
  }, [location, location.state, props]);

  useEffect(() => {
    let isMounted = true;
    setPropertyCount(-1);
    setFolloweesCount(0);
    setFollowerCount(0);
    (async () => {
      try {
        const response = await getProfileInfo(username);
        if (isMounted) {
          setMyself(response.myself);
          setUserState((prev) => {
            return { ...prev, ...response };
          });
        }
      } catch (e) {
        // console.log(e);
        history.push(paths.NOTFOUND);
      }
    })();

    (async () => {
      let followers = await getFollowerCount(username);
      if (isMounted) {
        setFollowerCount(followers);
      }
    })();
    (async () => {
      let followers = await getFolloweesCount(username);
      if (isMounted) {
        setFolloweesCount(followers);
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, isAuthenticated]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        if (user.id) {
          const response = await getUserProperties(user.id, ALL_PROPERTIES);
          if (isMounted) {
            setPropertyCount(response.count ?? 0);
          }
        }
      } catch (e) {}
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  return (
    <>
      <div
        className="d-flex flex-column justify-content-between main-container"
        style={{ background: "" }}
      >
        <Navbar
          searchBarToRight
          searchBarOption={
            <NavigationBackButton
              onClick={() => {
                history.go(-1);
              }}
            />
          }
        />

        <div
          style={{ backgroundColor: "#f9f9f9" }}
          className="col-12 col-md-9 align-self-center flex-grow-1 mt-md-5 px-3 pt-2 px-md-none py-md-none mb-md-none mb-4"
        >
          <div className="row flex-md-row mx-1 mb-2 mb-md-4 justify-content-between">
            <div className="d-flex d-md-none w-100 align-items-end p-0 mb-1 mt-1 mb-md-none">
              {!myself && (
                <Icon
                  className="ms-auto clickable"
                  alt="options"
                  src={optionIcon}
                  onClick={() => {
                    setShowOptionsModal(true);
                  }}
                />
              )}
            </div>
            <div className="col-12 col-md-5 d-flex gap-md-2 px-0 px-md-auto flex-grow">
              <div
                className="d-flex flex-column w-100"
                style={{ maxWidth: "" }}
              >
                <div className="d-flex align-items-center gap-2">
                  <Icon
                    size="100px"
                    src={user?.profile?.avatar ?? avatar}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                    alt="avatar"
                  />
                  <div style={{ width: "calc(100% - 108px)" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex w-100 align-items-center gap-1 poppins-bold-md-32 poppins-bold-26">
                        <span
                          className=""
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {user.first_name} {user.last_name}
                        </span>
                        {user.profile.subscription &&
                          user.profile.subscription.name.toLowerCase() !==
                            "free" && (
                            <Icon
                              alt="verified"
                              className=""
                              src={verifiedIcon}
                              size={"0.7em"}
                            />
                          )}
                      </div>
                      {!myself && (
                        <Icon
                          className="d-none d-md-inline ms-auto clickable ms-2"
                          alt="options"
                          src={optionIcon}
                          onClick={() => {
                            setShowOptionsModal(true);
                          }}
                        />
                      )}
                    </div>
                    <span className="poppins-light-16">@{user.username}</span>
                  </div>
                </div>

                <div
                  style={{ marginTop: "-15px", marginBottom: "15px" }}
                  className="row d-md-none justify-content-end"
                >
                  <div className="col-auto">
                    {!myself &&
                      user.profile?.client_phone_code &&
                      user.profile?.client_phone && (
                        <WhatsAppButton
                          className="btn btn-inline d-flex align-items-center gap-1"
                          onClick={
                            isAuthenticated
                              ? () => {
                                  window.open(
                                    `//wa.me/${
                                      user.profile.client_phone_code &&
                                      user.profile?.client_phone_code.split(
                                        "+"
                                      )[1]
                                    }${
                                      user.profile?.client_phone
                                    }?text=Hola%21%20escribo%20desde%20bohiio.com`
                                  );
                                }
                              : () => {
                                  setShowLogin(true);
                                }
                          }
                        >
                          <WhatsAppIcon />
                          WhatsApp
                        </WhatsAppButton>
                      )}
                    {myself && (
                      <Link to="/profile-configuration">
                        <ButtonRed className="btn btn-inline">
                          Editar Perfil
                        </ButtonRed>
                      </Link>
                    )}
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    {!myself && (
                      <>
                        {!user.is_following && (
                          <span
                            className="clickable"
                            style={{ color: "#D2190E" }}
                            onClick={async () => {
                              if (isAuthenticated) {
                                try {
                                  await follow(user.id);
                                  setUserState({ ...user, is_following: true });
                                  //should be a backend request
                                  setFollowerCount(followerCount + 1);
                                } catch (e) {
                                  console.error(e);
                                }
                              } else {
                                openLoginModal(
                                  paths.USER_PROFILE.replace(
                                    ":username",
                                    username
                                  )
                                );
                              }
                            }}
                          >
                            Seguir +
                          </span>
                        )}
                        {user.is_following && (
                          <span
                            className="clickable"
                            onClick={async () => {
                              if (isAuthenticated) {
                                setShowProfileShortModal(true);
                              } else {
                                openLoginModal(
                                  paths.USER_PROFILE.replace(
                                    ":username",
                                    username
                                  )
                                );
                              }
                            }}
                          >
                            Siguiendo
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="d-none d-md-flex gap-1 gap-md-3 poppins-light-16">
                  <span>
                    <span className="poppins-bold-18">
                      {propertyCount < 0 ? 0 : propertyCount + " "}
                    </span>
                    Propiedades
                  </span>
                  <span
                    className={`${followerCount && "clickable"}`}
                    onClick={() => {
                      if (isAuthenticated) {
                        if (followerCount) {
                          history.push(
                            paths.FOLLOWERS.replace(":name", username)
                          );
                        }
                      } else {
                        openLoginModal(
                          paths.FOLLOWERS.replace(":name", username)
                        );
                      }
                    }}
                  >
                    <span className="poppins-bold-18">
                      {followerCount + " "}
                    </span>
                    Seguidores
                  </span>
                  <span
                    className={`${followeesCount && "clickable"}`}
                    onClick={() => {
                      if (isAuthenticated) {
                        if (followeesCount) {
                          history.push(
                            paths.FOLLOWEES.replace(":name", username)
                          );
                        }
                      } else {
                        openLoginModal(
                          paths.FOLLOWEES.replace(":name", username)
                        );
                      }
                    }}
                  >
                    <span className="poppins-bold-18">
                      {followeesCount + " "}
                    </span>
                    Seguidos
                  </span>
                </div>
                <div className="d-none d-md-flex poppins-semibold-16">
                  {user?.profile?.company}
                </div>
                <a
                  className="d-none d-md-flex poppins-semibold-16"
                  rel="noreferrer"
                  target="_blank"
                  style={{ color: "#084B7C", textDecoration: "none" }}
                  href={`${urlAddOn + user?.profile?.webpage}`}
                >
                  {user?.profile?.webpage}
                </a>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex flex-wrap justify-content-between poppins-semibold-16 px-0">
              <div className="d-flex d-md-none gap-3 poppins-light-14 w-100">
                <span>
                  <span className="poppins-bold-16">
                    {propertyCount < 0 ? 0 : propertyCount + " "}
                  </span>
                  Propiedades
                </span>
                <span
                  className={`${followerCount && "clickable"}`}
                  onClick={() => {
                    if (isAuthenticated) {
                      if (followerCount) {
                        history.push(
                          paths.FOLLOWERS.replace(":name", username)
                        );
                      }
                    } else {
                      openLoginModal(
                        paths.FOLLOWERS.replace(":name", username)
                      );
                    }
                  }}
                >
                  <span className="poppins-bold-16">{followerCount}</span>{" "}
                  Seguidores
                </span>
                <span
                  className={`${followeesCount && "clickable"}`}
                  onClick={() => {
                    if (isAuthenticated) {
                      if (followeesCount) {
                        history.push(
                          paths.FOLLOWEES.replace(":name", username)
                        );
                      }
                    } else {
                      openLoginModal(
                        paths.FOLLOWEES.replace(":name", username)
                      );
                    }
                  }}
                >
                  <span className="poppins-bold-16">{followeesCount}</span>{" "}
                  Seguidos
                </span>
              </div>
              <div className="d-flex d-md-none poppins-semibold-16 w-100">
                {user?.profile?.company}
              </div>
              <a
                className="d-block d-md-none poppins-semibold-16 w-100"
                rel="noreferrer"
                target="_blank"
                href={`${urlAddOn + user?.profile?.webpage}`}
                style={{
                  color: "#084B7C",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {user?.profile?.webpage}
              </a>
              <div className="col-12 col-md-6 d-flex flex-column">
                <div
                  style={{ position: "relative" }}
                  className="poppins-semibold-14 poppins-semibold-md-16 my-2 mt-md-1 mb-md-3"
                >
                  <Icon
                    size={"20px"}
                    src={locationMarkerIcon}
                    position
                    style={{
                      marginRight: "5px",
                      right: "100%",
                      top: 0,
                    }}
                  />
                  {`${user?.profile?.city}, ${user?.profile?.country}`}
                </div>
                <div className="poppins-light-md-16 poppins-light-16 mb-2">
                  {user?.profile?.biography
                    .split(" ")
                    .map((word: string, index) => {
                      const wordWithoutFirstCharacter = word.slice(1);
                      if (word[0] === "@") {
                        return (
                          <React.Fragment key={index}>
                            <Link
                              to={`/profile/${wordWithoutFirstCharacter}`}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <span className="poppins-bold-16">
                                <span style={{ color: "red" }}>@</span>
                                {wordWithoutFirstCharacter}
                              </span>
                            </Link>{" "}
                          </React.Fragment>
                        );
                      } else if (word[0] === "#") {
                        return (
                          <>
                            <Link
                              className="poppins-bold-16"
                              to={`/tendency/${wordWithoutFirstCharacter}`}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <span style={{ color: "red" }}>#</span>
                              {wordWithoutFirstCharacter}
                            </Link>{" "}
                          </>
                        );
                      } else {
                        return word + " ";
                      }
                    })}
                </div>
              </div>
              <div className="d-none d-md-inline col-auto">
                {!myself && user.profile?.client_phone_code && (
                  <WhatsAppButton
                    className="btn btn-inline d-flex align-items-center gap-1"
                    onClick={
                      isAuthenticated
                        ? () => {
                            window.open(
                              `//wa.me/${
                                user.profile.client_phone_code &&
                                user.profile?.client_phone_code.split("+")[1]
                              }${
                                user.profile?.client_phone
                              }?text=Hola%21%20escribo%20desde%20bohiio.com`
                            );
                          }
                        : () => {
                            setShowLogin(true);
                          }
                    }
                  >
                    <WhatsAppIcon />
                    WhatsApp
                  </WhatsAppButton>
                )}
                {myself && (
                  <Link to="/profile-configuration">
                    <ButtonRed className="btn btn-inline">
                      Editar Perfil
                    </ButtonRed>
                  </Link>
                )}
              </div>
              <div className="d-none d-md-inline col-auto">
                {!myself && (
                  <>
                    {!user.is_following && (
                      <span
                        className="clickable mt-md-1 d-md-block"
                        style={{ color: "#D2190E" }}
                        onClick={async () => {
                          if (isAuthenticated) {
                            try {
                              await follow(user.id);
                              setUserState({ ...user, is_following: true });
                              //should be a backend request
                              setFollowerCount(followerCount + 1);
                            } catch (e) {
                              console.error(e);
                            }
                          } else {
                            openLoginModal(
                              paths.USER_PROFILE.replace(":username", username)
                            );
                          }
                        }}
                      >
                        Seguir +
                      </span>
                    )}
                    {user.is_following && (
                      <span
                        className="clickable"
                        onClick={async () => {
                          if (isAuthenticated) {
                            setShowProfileShortModal(true);
                          } else {
                            openLoginModal(
                              paths.USER_PROFILE.replace(":username", username)
                            );
                          }
                        }}
                      >
                        Siguiendo
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {propertyCount === 0 && <ProfilePlaceholder myself={myself} />}
          {propertyCount > 0 && (
            <>
              <div className="d-flex poppins-medium-14 align-items-center mb-2">
                <PropertyFilter
                  selected={propertyScreen === ALL_PROPERTIES}
                  onClick={toAllProperties}
                >
                  Venta y Alquiler
                </PropertyFilter>
                <PropertyFilter
                  selected={propertyScreen === SELLING_PROPERTIES}
                  onClick={toSellingProperties}
                >
                  Venta
                </PropertyFilter>
                <PropertyFilter
                  selected={propertyScreen === RENTING_PROPERTIES}
                  onClick={toRentingProperties}
                >
                  Alquiler
                </PropertyFilter>
                <div
                  className="ms-auto clickable"
                  onClick={() => {
                    history.push(`/map?username=${user.username}`, { user });
                  }}
                >
                  <span
                    className="d-md-inline poppins-regular-14"
                    style={{
                      border: "none",
                      color: "#FFFFFF",
                      width: "100%",
                      backgroundColor: "#707070",
                      marginBottom: "10px",
                      padding: "0.25rem 1rem",
                      borderRadius: "5px",
                    }}
                  >
                    <label className="d-md-none">Mapa</label>
                    <label className="d-none d-md-inline">Ver Mapa</label>
                  </span>
                </div>
              </div>
              {/* {userProperties.results.length > 9 && (
                <>
                  <WhiteCircle
                    src={leftIcon}
                    style={{
                      float: "left",
                      marginTop: "20%",
                      left: 15,
                    }}
                    onClick={() => {
                      if (propertiesRef.current) {
                        propertiesRef.current.scrollTo({
                          left:
                            propertiesRef.current.scrollLeft -
                            propertiesRef.current.clientWidth,
                          top: 0,
                          behavior: "smooth",
                        });
                      }
                    }}
                    alt="left icon"
                  />
                  <WhiteCircle
                    src={rightIcon}
                    style={{
                      float: "right",
                      marginTop: "20%",
                      right: 15,
                    }}
                    onClick={() => {
                      if (propertiesRef.current) {
                        propertiesRef.current.scrollTo({
                          left:
                            propertiesRef.current.scrollLeft +
                            propertiesRef.current.clientWidth,
                          top: 0,
                          behavior: "smooth",
                        });
                      }
                    }}
                    alt="right icon"
                  />
                </>
              )} */}

              <PropertiesContainer className="" ref={propertiesRef}>
                {properties.map((property) => {
                  return (
                    <PropertyContainerItem
                      className="clickable"
                      key={property?.id}
                    >
                      {property?.certificationStatus ===
                        CertificationStatus.VERIFIED && (
                        <span
                          style={{
                            position: "relative",
                            zIndex: 1001,
                            float: "right",
                            marginBottom: "-75px",
                          }}
                        >
                          <TopRightTriangle />
                          <Icon
                            src={certified}
                            alt="certified"
                            style={{ marginTop: "-100px", marginLeft: "20px" }}
                          />
                        </span>
                      )}
                      {!(
                        property?.certificationStatus ===
                        CertificationStatus.VERIFIED
                      ) &&
                        property?.region === "DO" &&
                        myself && (
                          <span
                            onClick={() => {
                              if (CertificationStatus.WITHOUT)
                                openPropertyCertification(property?.id, true);
                            }}
                            style={{
                              position: "relative",
                              zIndex: 1001,
                              float: "right",
                              marginBottom: "-75px",
                            }}
                          >
                            <TopRightTriangle />
                            <YetToCertifyIcon
                              className="clickable"
                              src={certified}
                              alt="certified"
                              style={{
                                marginTop: "-100px",
                                marginLeft: "20px",
                              }}
                            />
                          </span>
                        )}
                      {(property?.media?.[0]?.type === "IMAGE" ||
                        property?.media?.length === 0) && (
                        <PropertyContainer>
                          <img
                            src={
                              property?.media?.length === 0
                                ? avatar
                                : property?.media?.[0]?.filename
                            }
                            alt="property"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: "10px",
                              flexShrink: 0,
                            }}
                            onClick={() => {
                              onSelectPropertyToExpand(property);
                            }}
                          />
                        </PropertyContainer>
                      )}

                      {property?.media?.length !== 0 &&
                        property?.media?.[0]?.type !== "IMAGE" && (
                          <PropertyContainer
                            onClick={() => {
                              onSelectPropertyToExpand(property);
                            }}
                          >
                            <video
                              muted
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover" }}
                              controls={false}
                            >
                              <source
                                src={property?.media?.[0]?.filename}
                                type="video/mp4"
                              />
                            </video>
                          </PropertyContainer>
                        )}
                    </PropertyContainerItem>
                  );
                })}
                <PropertyContainerItem>
                  {LoadMorePropertiesComponent}
                </PropertyContainerItem>
              </PropertiesContainer>
            </>
          )}
        </div>
        <SimpleFooter />
      </div>
      <PropertyCertification
        startingModalActive={isPropertyCertificationActive}
        closeStartingModal={closePropertyCertification}
        propertyCertificationInfo={propertyCertificationInfo}
      />
      <AuthenticationModal
        nextPage={nextPage}
        active={showLogin}
        closeModal={closeLoginModal}
      />
      <CertifyNowModal
        active={isCertifyNowModalOpen}
        closeModal={() => setIsCertifyNowModalOpen(false)}
        openPaymentModal={openPropertyCertification}
      />
      <ProfileOptionsModal
        active={showOptionsModal}
        profile={user}
        closeModal={() => {
          setShowOptionsModal(false);
        }}
      />
      <ProfileShortModal
        show={showProfileShortModal}
        user={user}
        propertyNumber={propertyCount}
        handleAccept={async () => {
          if (isAuthenticated) {
            try {
              await unfollow(user.id);
              setUserState({ ...user, is_following: false });
              //should be a backend request
              setFollowerCount(Math.max(followerCount - 1, 0));
              setShowProfileShortModal(false);
            } catch (e) {
              console.error(e);
            }
          } else {
            openLoginModal(paths.USER_PROFILE.replace(":username", username));
          }
        }}
        handleClose={() => {
          setShowProfileShortModal(false);
        }}
      />
    </>
  );
};

export default Profile;
