import styled from "styled-components";
import OptionsModal from "./OptionsModal";

const ButtonRed = styled.button`
  border: 1px #d2190e solid;
  background: #d2190e;
  color: white;
  padding 0.25rem 2rem;
  border-radius: 5px;
`;

interface IDeactivateCommentsCompleteModalProps {
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const DeactivateCommentsCompleteModal = ({
  active,
  closeModal,
  darkerBackground,
}: IDeactivateCommentsCompleteModalProps) => {
  return (
    <OptionsModal
      active={active}
      closeModal={closeModal}
      darkerBackground={darkerBackground}
    >
      <div className="poppins-regular-12 text-center">
        <p className="poppins-bold-12">Comentarios desactivados</p>
        <p>
          Los comentarios fueron desactivados en la publicación, solo tú podrás
          comentar en ella.
        </p>
        <ButtonRed onClick={closeModal}>Cerrar</ButtonRed>
      </div>
    </OptionsModal>
  );
};

export default DeactivateCommentsCompleteModal;
