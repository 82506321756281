import { useState } from "react";
import styled from "styled-components";
import { Comment, Property } from "../context/property/types";
import OptionsModal from "./OptionsModal";
import ReportCommentCompleteModal from "./ReportCommentCompleteModal";

const OptionMenu = styled.div`
  background: white;
  border-radius: 5px;
  text-align: center;
  div {
    border-bottom: 2px solid gray;
    padding: 1rem 4rem;
    cursor: pointer;
    font-weight: 700;
  }
  div:last-child {
    border-bottom: 0px;
  }
`;

interface IPropertyOptionsModalProps {
  property: Property;
  comment: Comment;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
  allowDelete: Boolean;
  handleDelete: Function;
  handleReport: Function;
}

const CommentOptionsModal = ({
  active,
  closeModal,
  darkerBackground,
  property,
  comment,
  allowDelete,
  handleDelete,
  handleReport,
}: IPropertyOptionsModalProps) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <OptionMenu className="poppins-regular-12">
          {/* 
            hamlet comment this
          {property.owner.myself && (
            <>
              <div>Fijar comentario</div>
            </>
          )} */}
          {(!property.owner.myself || !comment.commentator.myself) && (
            <>
              <div
                style={{ color: "red" }}
                onClick={() => {
                  handleReport();
                  setShowCompleteModal(true);
                }}
              >
                Reportar comentario
              </div>
            </>
          )}

          {allowDelete && (
            <div style={{ color: "red" }} onClick={() => handleDelete()}>
              Eliminar comentario
            </div>
          )}
          <div onClick={closeModal}>Cancelar</div>
        </OptionMenu>
      </OptionsModal>
      <ReportCommentCompleteModal
        darkerBackground
        active={showCompleteModal}
        closeModal={() => {
          setShowCompleteModal(false);
          closeModal();
        }}
      />
    </>
  );
};

export default CommentOptionsModal;
