import { ButtonRed } from "../styles/common";

export interface VerifyAccountModalProps {
  username: string;
  closeModal: () => void;
}
export default function VerifyAccountModal(props: VerifyAccountModalProps) {
  return (
    <div style={{ textAlign: "center", padding: "30px 0" }}>
      <h4 style={{ fontWeight: "bold" }} className="mb-4">
        ¡Bienvenido {props.username}!
      </h4>
      <p className="mb-3">
        Gracias por registrarte en Bohiio. Ahora podrás llevar tu experiencia de
        navegación a otro nivel, “Comenta” y dale un “Me gusta” a las mejores
        propiedades.
      </p>
      <p className="mb-4">
        Hemos enviado un correo de confirmación a tu dirección de correo
        electrónico. Por favor, revisa tu bandeja de entrada. En caso de no
        encontrar nuestro correo, verificar la carpeta de Spam o correos no
        deseados.
      </p>
      <ButtonRed
        type="button"
        className="w-100"
        onClick={props.closeModal}
        style={{ borderRadius: "5px" }}
      >
        Aceptar
      </ButtonRed>
    </div>
  );
}
