import { useContext } from "react";
import { ProfileContext } from "../context/profile/ProfileContext";
import { ButtonRed } from "../styles/common";
import BaseModal from "./BaseModal";

const CertifyOrderCompleteModal = (props: any) => {
  const { profile } = useContext(ProfileContext);

  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="certify-order-complete"
      centered
    >
      <div
        className="poppins-light-14 text-center"
        style={{ width: "100%", margin: "auto", maxWidth: "380px" }}
      >
        <div
          className="bg-white"
          style={{
            border: "1px solid rgba(0, 0, 0, 0)",
            borderRadius: "10px",
            padding: "60px 3rem",
          }}
        >
          <p className="poppins-bold-16 mt-5">
            ¡Gracias {profile?.first_name}!
          </p>
          <p>Hemos recibido tu solicitud.</p>
          <p>
            {props.isPropertyOwner
              ? "Nuestro equipo se pondrá en contacto contigo para confirmar la propiedad e iniciar la verificación."
              : "Nuestro equipo iniciará la verificación de la propiedad."}
          </p>
          <p>El tiempo estimado para este proceso es de 24 a 48 horas.</p>
          <p>
            Para cualquier inquietud enviarnos un correo a{" "}
            <a href="mailto:certificacionesdo@bohiio.com">
              certificacionesdo@bohiio.com
            </a>
          </p>
          <ButtonRed
            className="poppins-semibold-14 mb-3 mt-5 w-100"
            onClick={props.closeModal}
          >
            Aceptar
          </ButtonRed>
        </div>
      </div>
    </BaseModal>
  );
};

export default CertifyOrderCompleteModal;
