import { useState } from "react";
import HideLocationCompleteModal from "./HideLocationCompleteModal";
import OptionsModal from "./OptionsModal";

interface IHideLocationConfirmationModalProps {
  toggleLocation: () => Promise<void>;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const HideLocationConfirmationModal = ({
  active,
  closeModal,
  darkerBackground,
  toggleLocation,
}: IHideLocationConfirmationModalProps) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <div className="poppins-regular-12 text-center">
          <div style={{ padding: "0 2rem" }}>
            <p className="poppins-bold-12">¿Quieres ocultar la ubicación?</p>
            <p>
              Cuando la ubicación esta desactivada el inmueble no se mostrará
              visible en el mapa, ningún usuario aparte de ti podrá saber dónde
              se encuetra la propiedad.
            </p>
          </div>
          <div
            style={{
              borderBottom: "2px solid gray",
              padding: "1rem 4rem",
              cursor: "pointer",
              color: "red",
              fontWeight: 700,
            }}
            onClick={async () => {
              await toggleLocation();
              setShowCompleteModal(true);
            }}
          >
            Ocultar ubicación
          </div>
          <div
            style={{
              padding: "1rem 4rem",
              cursor: "pointer",
              fontWeight: 700,
            }}
            onClick={closeModal}
          >
            Cancelar
          </div>
        </div>
      </OptionsModal>
      <HideLocationCompleteModal
        darkerBackground
        active={showCompleteModal}
        closeModal={() => {
          setShowCompleteModal(false);
          closeModal();
        }}
      />
    </>
  );
};

export default HideLocationConfirmationModal;
