import styled from "styled-components";
import closeIcon from "../images/cross-dinamic.svg";
import { useContext, useState } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import AuthenticationModal from "./AuthenticationModal";
import PropertyOptionsModal from "./PropertyOptionsModal";
import PropertyCard from "./PropertyCard";

const PropertyCardContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  & .property-card-content {
    padding: 0 10px;
  }
`;

const Icon = styled.img`
  width: 15px;
  height: 15px;
`;
const HistoryProperty = ({
  property,
  index,
  removeProperty,
  addedDate,
  openCertificationModal,
}: any) => {
  useContext(LanguageContext);
  const [showLogin, setShowLogin] = useState(false);
  const [showPropertyOptions, setShowPropertyOptions] = useState(false);

  const closeLoginModal = () => setShowLogin(false);
  const closePropertyOptionsModal = () => setShowPropertyOptions(false);
  const openPropertyOptionsModal = () => setShowPropertyOptions(true);

  return (
    <div
      className="d-flex justify-content-between flex-column flex-md-row"
      style={{ minHeight: "150px" }}
    >
      <PropertyCardContainer key={property.id}>
        <PropertyCard
          property={property}
          imgSize={140}
          imgSizeUnit={"px"}
          objectFit={"cover"}
          openPropertyCertification={openCertificationModal}
        />
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            borderRadius: "100%",
            backgroundColor: "white",
            padding: "8px",
          }}
          className="action d-flex gap-2 "
        >
          <Icon
            className="clickable order-1 order-md-0"
            src={closeIcon}
            onClick={() => {
              removeProperty(property.id);
            }}
          />
        </div>
      </PropertyCardContainer>
      <AuthenticationModal active={showLogin} closeModal={closeLoginModal} />
      <PropertyOptionsModal
        darkerBackground
        active={showPropertyOptions}
        closeModal={closePropertyOptionsModal}
        property={property}
        openCertificationModal={openCertificationModal}
        deleteProperty={() => {
          removeProperty(true);
        }}
      />
    </div>
  );
};

export default HistoryProperty;
