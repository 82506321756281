import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface ConfirmationModalProps {
  title: string;
  msg: string;
  acceptText: string;
  cancelText: string;
  show: boolean;
  handleAccept: () => void;
  handleClose: () => void;
}

function ConfirmationModal({
  title,
  msg,
  acceptText,
  cancelText,
  show,
  handleAccept,
  handleClose,
}: ConfirmationModalProps) {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleAccept}>
            {acceptText}
          </Button>
          <Button variant="primary" onClick={handleClose}>
            {cancelText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
