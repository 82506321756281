import { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import { Trans, useTranslation } from "react-i18next";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import switchIcon from "../images/switch.svg";
import switchActiveIcon from "../images/switch-active.svg";
import styled from "styled-components";
import { dataURIToBlob } from "../utils";
import { PropertyContext } from "../context/property/PropertyContext";
import HashtagInput from "./HashtagInput";
import { ButtonRed, FloatingInput, SupplementaryInput } from "../styles/common";
import { Property } from "../context/property/types";
import { disableField, resetDisabledValue } from "../utils/disable";
import { useHistory } from "react-router-dom";
import paths from "../routes/paths.json";
import { ProfileContext } from "../context/profile/ProfileContext";
import countryCodes, { CountryProperty } from "country-codes-list";
import BaseShortModal from "./BaseShortModal";
import BaseModalContent from "./BaseModalContent";

const MAX_HASHTAGS = 10;

const Icon = styled.img`
  width: 35px;
  height: 25px;
`;

const Button = styled.button`
  border: 1px rgba(0, 0, 0, 0) solid;
  background: white;
  color: black;
  padding: 0.25rem 2rem;
`;

const numberInputFormatter = ({ target }: any) => {
  if (target.value.search(/[^\d,.]/g) >= 0) {
    target.value = target.value.replaceAll(/[^\d.]/g, "");
  }
  let formattedNumber = target.value
    .split(".")[0]
    .split(",")
    .join("")
    .split("")
    .reverse()
    .join("");
  target.value =
    formattedNumber
      .split("")
      .map((number: any, index: any) => {
        return index % 3 >= 2 ? "," + number : number;
      })
      .reverse()
      .join("") +
    (target.value.search("[.]") >= 0 ? "." + target.value.split(".")[1] : "");
  target.value =
    target.value[0] !== "," ? target.value : target.value.substr(1);
};

const publicationTypes = ["rent", "sell", "both"];

const propertyTypes = [
  "apartment",
  "house",
  "villa",
  "pieceOfLand",
  "farm",
  "industrialShip",
  "office",
  "building",
  "penthouse",
  "business",
  "businessPremise",
];

const propertyUses = ["residential", "commercial", "mixed", "none"];

const propertyConditions = [
  "new",
  "secondHand",
  "remodeled",
  "toRemodel",
  "inConstruction",
  "barter",
  "blueprint",
  "escrow",
  "none",
];

const measureTypes = ["mts", "fts", "ts", "ha"];

const extras = [
  "isFurnished",
  "hasStudyRoom",
  "hasElevator",
  "hasFamilyRoom",
  "hasPool",
  "hasGazebo",
  "hasBalcony",
  "hasLobby",
  "hasPowerPlant",
  "hasBackyard",
  "hasSocialArea",
  "hasJacuzzi",
  "hasGym",
  "hasPicuzzi",
  "hasPlayArea",
  "hasSauna",
  "hasTank",
  "hasWaterWell",
  "hasDressingRoom",
  "hasTerrace",
  "hasWalInCloset",
  "hasParking",
  "hasServiceRoom",
];

let currencyTypes: string[] = Object.values(
  countryCodes.customList("countryCode" as CountryProperty, "{currencyCode}")
);
currencyTypes = currencyTypes.filter((item: string) => item.length > 0);
currencyTypes = Array.from(new Set(currencyTypes));
currencyTypes = currencyTypes.sort((a, b) => {
  if (a === "DOP") return -1;
  else if (b === "DOP") return 1;
  else return 0;
});

const initialValues: any = {
  publicationType: "",
  propertyType: "",
  currentUse: "none",
  condition: "none",
  landArea: 0,
  landAreaUnit: measureTypes[0],
  constructionArea: 0,
  constructionAreaUnit: measureTypes[0],
  floor: 0,
  numberOfFloors: 0,
  rooms: 0,
  bathrooms: 0,
  kitchens: 0,
  parkingSlot: 0,
  livingRooms: 0,
  sellingPrice: 0.0,
  sellingCurrency: currencyTypes[0],
  rentPrice: 0.0,
  rentCurrency: currencyTypes[0],
};

interface PropertyFormProps {
  latitude: number;
  longitude: number;
  images: any[];
  mainImageIndex: number;
  incompleteFormHandler: () => void;
  lackOfImageHandler: () => void;
  property?: Property;
  setLoading: (arg0: boolean) => void;
  triggerSubmit?: boolean;
  onClickPropertyType?: (propertyType: string) => void;
}

const SubmitTriggerer = ({ triggerSubmit, incompleteFormHandler }: any) => {
  const { handleSubmit, isValid } = useFormikContext();

  useEffect(() => {
    if (!isValid) {
      incompleteFormHandler();
      return;
    }
    if (triggerSubmit) {
      // console.log("submitting...");
      handleSubmit();
    }
  }, [triggerSubmit]);
  return null;
};

const PropertyForm = ({
  images,
  latitude,
  longitude,
  property,
  mainImageIndex,
  setLoading,
  lackOfImageHandler,
  incompleteFormHandler,
  triggerSubmit,
  onClickPropertyType = () => {},
}: PropertyFormProps) => {
  useContext(LanguageContext);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { addProperty, updateProperty } = useContext(PropertyContext);
  const { profile } = useContext(ProfileContext);
  const history = useHistory();

  const [checked, setChecked] = useState(new Array(extras.length).fill(false));
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (property?.tendencies) {
      setHashtags(property.tendencies.map(({ tendency }) => tendency));
    }

    if (property?.detail.attributes) {
      let changes = [...checked];
      const attributes = property?.detail.attributes.map(({ name }) => name);

      extras.forEach((extra, index) => {
        if (attributes.includes(extra)) {
          changes[index] = true;
        }
      });

      setChecked(changes);
    }
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          publicationType:
            property?.typePublication ?? initialValues.publicationType,
          propertyType: property?.general?.type ?? initialValues.propertyType,
          currentUse: property?.general?.use ?? initialValues.currentUse,
          condition: property?.general?.condition ?? initialValues.condition,
          landArea: property?.detail?.terrainMeasure ?? initialValues.landArea,
          landAreaUnit:
            property?.detail?.terrainUnit ?? initialValues.landAreaUnit,
          constructionArea:
            property?.detail?.constructionMeasure ??
            initialValues.constructionArea,
          constructionAreaUnit:
            property?.detail?.constructionUnit ??
            initialValues.constructionAreaUnit,
          floor: property?.detail?.levelLocation ?? initialValues.floor,
          numberOfFloors:
            property?.detail?.levelNumber ?? initialValues.numberOfFloors,
          rooms: property?.detail?.bedroom ?? initialValues.rooms,
          bathrooms: property?.detail?.bathroom ?? initialValues.bathrooms,
          kitchens: property?.detail?.kitchen ?? initialValues.kitchens,
          parkingSlot:
            property?.detail?.parkingSlot ?? initialValues.parkingSlot,
          livingRooms:
            property?.detail?.livingRoom ?? initialValues.livingRooms,
          sellingPrice:
            property?.detail?.sellPrice ?? initialValues.sellingPrice,
          sellingCurrency:
            property?.detail?.sellCurrency ?? initialValues.sellingCurrency,
          rentPrice: property?.detail?.rentPrice ?? initialValues.rentPrice,
          rentCurrency:
            property?.detail?.rentCurrency ?? initialValues.rentCurrency,
        }}
        validationSchema={Yup.object({
          publicationType: Yup.string().required(),
          propertyType: Yup.string().required(),
          currentUse: Yup.string(),
          condition: Yup.string(),
          landArea: Yup.string().matches(/^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/),
          landAreaUnit: Yup.string(),
          constructionArea: Yup.string().matches(
            /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/
          ),
          constructionAreaUnit: Yup.string(),
          floor: Yup.number().moreThan(-1),
          numberOfFloors: Yup.number().moreThan(-1),
          rooms: Yup.number().moreThan(-1),
          bathrooms: Yup.number().moreThan(-1),
          kitchens: Yup.number().moreThan(-1),
          livingRooms: Yup.number().moreThan(-1),
          sellingPrice: Yup.string()
            .matches(/^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/)
            .required(),
          sellingCurrency: Yup.string(),
          rentPrice: Yup.string()
            .matches(/^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/)
            .required(),
          rentCurrency: Yup.string(),
        })}
        onSubmit={async (values, FormikBag) => {
          resetDisabledValue(values, initialValues);
          values.landArea = Number(
            values.landArea.toString().replaceAll(/[^\d.]/g, "")
          );
          values.constructionArea = Number(
            values.constructionArea.toString().replaceAll(/[^\d.]/g, "")
          );
          values.sellingPrice = Number(
            values.sellingPrice.toString().replaceAll(/[^\d.]/g, "")
          );
          values.rentPrice = Number(
            values.rentPrice.toString().replaceAll(/[^\d.]/g, "")
          );
          if (images.length === 0) {
            lackOfImageHandler();
            setErrorText(t("propertyForm.errorText.noImageError"));
            return;
          }
          if (Math.abs(latitude) > 90 || Math.abs(longitude) > 180) {
            setErrorText(t("propertyForm.errorText.locationError"));
            return;
          }

          const general = {
            title: "title",
            type: values.propertyType,
            use: values.currentUse,
            condition: values.condition,
            text: "text",
          };

          const location = {
            sector: "sector",
            province: "province",
            country: "country",
          };

          const detail = {
            terrainUnit: values.landAreaUnit,
            terrainMeasure: values.landArea,

            constructionUnit: values.constructionAreaUnit,
            constructionMeasure: values.constructionArea,

            levelLocation: values.floor,
            levelNumber: values.numberOfFloors,

            bedroom: values.rooms,
            bathroom: values.bathrooms,
            livingRoom: values.livingRooms,
            kitchen: values.kitchens,

            sellPrice: values.sellingPrice,
            rentPrice: values.rentPrice,
            rentCurrency: values.rentCurrency,
            sellCurrency: values.sellingCurrency,

            parkingSlot: values.parkingSlot,

            attributes: extras
              .filter((_, idx) => checked[idx])
              .map((extra) => {
                return { name: extra, description: extra };
              }),
          };

          let formData = new FormData();
          const mediaInfo: { type?: string; id?: any }[] = [];

          formData.append("general", JSON.stringify(general));
          formData.append("location", JSON.stringify(location));
          formData.append("detail", JSON.stringify(detail));
          const imagesFiles = [
            images[mainImageIndex],
            ...images.slice(0, mainImageIndex),
            ...images.slice(mainImageIndex + 1),
          ];
          imagesFiles.forEach((image) => {
            formData.append("media", dataURIToBlob(image.dataURL), image.name);
            const type = image.name.includes(".mp4") ? "video" : "image";
            if (image.id) {
              mediaInfo.push({ id: image.id });
            } else {
              mediaInfo.push({ type });
            }
          });
          formData.append("mediaInfo", JSON.stringify(mediaInfo));
          formData.append("latitude", String(latitude));
          formData.append("longitude", String(longitude));
          formData.append(
            "tendencies",
            JSON.stringify(
              hashtags.map((hashtag) => ({
                tendency: hashtag[0] === "#" ? hashtag.slice(1) : hashtag,
              }))
            )
          );
          formData.append("typePublication", values.publicationType);

          try {
            setLoading(true);
            let updatedProperty;
            if (property) {
              updatedProperty = await updateProperty?.(formData, property.id);
            } else {
              updatedProperty = await addProperty?.(formData);
            }
            setLoading(false);
            setDisableSubmit(true);
            const data = {
              openCertifyNowModal: true,
              propertyIdCertification: updatedProperty.id,
              propertyCountry: updatedProperty.region,
            };

            history.push(`/profile/${profile?.username}`, data);
          } catch (e) {
            setLoading(false);
            setDisableSubmit(false);
            setShowModal(true);
          }
        }}
      >
        {({ values, setFieldValue, initialValues, handleChange }) => {
          return (
            <Form className="poppins-medium-12 mx-3 mb-5">
              <>
                <h5 className="poppins-semibold-16 my-2">
                  <Trans i18nKey="propertyForm.title" />
                </h5>

                <FloatingInput className="form-floating mb-2">
                  <Field
                    id="publicationType"
                    name="publicationType"
                    as="select"
                    className="form-select"
                    required
                  >
                    <option value="">Tipo de publicación</option>
                    {publicationTypes.map((publicationType) => {
                      return (
                        <option value={publicationType} key={publicationType}>
                          {t(`propertyForm.publicationType.${publicationType}`)}
                        </option>
                      );
                    })}
                  </Field>
                  <label htmlFor="publicationType">Tipo de publicación</label>
                </FloatingInput>

                <FloatingInput className="form-floating mb-2">
                  <Field
                    id="propertyType"
                    name="propertyType"
                    as="select"
                    className="form-select"
                    required
                  >
                    <option value="">Tipo de propiedad</option>
                    {propertyTypes.map((propertyType) => {
                      return (
                        <option value={propertyType} key={propertyType}>
                          {t(`propertyForm.propertyType.${propertyType}`)}
                        </option>
                      );
                    })}
                  </Field>
                  <label htmlFor="propertyType">Tipo de propiedad</label>
                </FloatingInput>

                <FloatingInput className="form-floating mb-2">
                  <Field
                    id="currentUse"
                    name="currentUse"
                    as="select"
                    className="form-select"
                    disabled={disableField(values.propertyType, "currentUse")}
                  >
                    {propertyUses.map((propertyUse) => {
                      return (
                        <option value={propertyUse} key={propertyUse}>
                          {t(`propertyForm.currentUse.${propertyUse}`)}
                        </option>
                      );
                    })}
                  </Field>
                  <label htmlFor="currentUse">Uso actual</label>
                </FloatingInput>

                <FloatingInput className="form-floating mb-2">
                  <Field
                    id="condition"
                    name="condition"
                    as="select"
                    className="form-select"
                    disabled={disableField(values.propertyType, "condition")}
                  >
                    {propertyConditions.map((propertyCondition) => {
                      return (
                        <option
                          value={propertyCondition}
                          key={propertyCondition}
                        >
                          {t(`propertyForm.condition.${propertyCondition}`)}
                        </option>
                      );
                    })}
                  </Field>
                  <label htmlFor="condition">Condición</label>
                </FloatingInput>
              </>

              <div className="row g-3 align-items-center mb-2">
                <div className="col-8">
                  <FloatingInput className="form-floating">
                    <Field
                      id="landArea"
                      name="landArea"
                      type="text"
                      pattern="^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$"
                      onBlur={numberInputFormatter}
                      className="form-control"
                      disabled={disableField(values.propertyType, "landArea")}
                    />
                    <label htmlFor="landArea">
                      <Trans i18nKey="propertyForm.landArea" />{" "}
                    </label>
                  </FloatingInput>
                </div>
                <SupplementaryInput className="col-4">
                  <Field
                    name="landAreaUnit"
                    as="select"
                    className="form-select"
                    disabled={disableField(values.propertyType, "landAreaUnit")}
                  >
                    {measureTypes.map((measure) => {
                      return (
                        <option value={measure} key={measure}>
                          {t(`propertyForm.measureTypes.${measure}`)}
                        </option>
                      );
                    })}
                  </Field>
                </SupplementaryInput>
              </div>

              <div className="row g-3 align-items-center mb-2">
                <div className="col-8">
                  <FloatingInput className="form-floating">
                    <Field
                      id="constructionArea"
                      name="constructionArea"
                      type="text"
                      pattern="^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$"
                      onBlur={numberInputFormatter}
                      className="form-control"
                      disabled={disableField(
                        values.propertyType,
                        "constructionArea"
                      )}
                    />
                    <label htmlFor="constructionArea">
                      <Trans i18nKey="propertyForm.constructionArea" />
                    </label>
                  </FloatingInput>
                </div>
                <SupplementaryInput className="col-4">
                  <Field
                    name="constructionAreaUnit"
                    as="select"
                    className="form-select"
                    disabled={disableField(
                      values.propertyType,
                      "constructionAreaUnit"
                    )}
                  >
                    {measureTypes.map((measure) => {
                      return (
                        <option value={measure} key={measure}>
                          {t(`propertyForm.measureTypes.${measure}`)}
                        </option>
                      );
                    })}
                  </Field>
                </SupplementaryInput>
              </div>

              <div className="row g-3 align-items-center mb-2">
                <div className="col-6">
                  <FloatingInput className="form-floating">
                    <Field
                      id="floor"
                      name="floor"
                      min="1"
                      max="30"
                      type="number"
                      className="form-control"
                      disabled={disableField(values.propertyType, "floor")}
                    />
                    <label htmlFor="floor">
                      <Trans i18nKey="propertyForm.floor" />
                    </label>
                  </FloatingInput>
                </div>
                <div className="col-6">
                  <FloatingInput className="form-floating">
                    <Field
                      id="numberOfFloors"
                      name="numberOfFloors"
                      type="number"
                      min="1"
                      max="5"
                      className="form-control"
                      disabled={disableField(
                        values.propertyType,
                        "numberOfFloors"
                      )}
                    />
                    <label htmlFor="numberOfFloors">
                      <Trans i18nKey="propertyForm.numberOfFloors" />
                    </label>
                  </FloatingInput>
                </div>
              </div>

              <div className="row g-3 align-items-center mb-2">
                <div className="col-6">
                  <FloatingInput className="form-floating">
                    <Field
                      id="rooms"
                      name="rooms"
                      min="1"
                      max="15"
                      type="number"
                      className="form-control"
                      disabled={disableField(values.propertyType, "rooms")}
                    />
                    <label htmlFor="rooms">
                      <Trans i18nKey="propertyForm.rooms" />
                    </label>
                  </FloatingInput>
                </div>
                <div className="col-6">
                  <FloatingInput className="form-floating">
                    <Field
                      id="bathrooms"
                      name="bathrooms"
                      min="1"
                      step="0.5"
                      max="10"
                      type="number"
                      className="form-control"
                      disabled={disableField(values.propertyType, "bathrooms")}
                    />
                    <label htmlFor="bathrooms">
                      <Trans i18nKey="propertyForm.bathrooms" />
                    </label>
                  </FloatingInput>
                </div>
              </div>

              <div className="row g-3 align-items-center mb-2">
                <div className="col-6">
                  <FloatingInput className="form-floating">
                    <Field
                      id="kitchens"
                      name="kitchens"
                      min="1"
                      max="10"
                      type="number"
                      className="form-control"
                      disabled={disableField(values.propertyType, "kitchens")}
                    />
                    <label htmlFor="kitchens">
                      <Trans i18nKey="propertyForm.kitchens" />
                    </label>
                  </FloatingInput>
                </div>
                <div className="col-6">
                  <FloatingInput className="form-floating">
                    <Field
                      id="livingRooms"
                      name="livingRooms"
                      type="number"
                      min="1"
                      max="10"
                      className="form-control"
                      disabled={disableField(
                        values.propertyType,
                        "livingRooms"
                      )}
                    />
                    <label htmlFor="livingRooms">
                      <Trans i18nKey="propertyForm.livingRooms" />
                    </label>
                  </FloatingInput>
                </div>
              </div>

              <h5 className="poppins-semibold-16 mt-3">
                <Trans i18nKey="propertyForm.services.title" />
              </h5>
              <div className="row poppins-regular-14">
                {extras.map((extra, index) => {
                  return (
                    <div
                      className="col-6 d-flex align-items-center clickable"
                      key={`service-${index}`}
                      onClick={() => {
                        let changes = [...checked];
                        changes[index] = !changes[index];
                        setChecked(changes);
                      }}
                    >
                      <Icon
                        src={checked[index] ? switchActiveIcon : switchIcon}
                        alt="switch"
                        style={{ opacity: checked[index] ? "1" : "0.5" }}
                      />
                      <span
                        className="ms-2"
                        style={{ opacity: checked[index] ? "1" : "0.5" }}
                      >
                        {t(`propertyForm.services.${extra}`)}
                      </span>
                    </div>
                  );
                })}
                <div className="col-6 mb-2">
                  <div className="row g-2 g-md-3 align-items-center">
                    <div className="col-8 col-md-9">
                      {checked[
                        extras.findIndex((value) => value === "hasParking")
                      ] && (
                        <Field
                          name="parkingSlot"
                          className=" form-control"
                          type="number"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="poppins-semibold-16 my-2">Hashtag</span>
                <span className="ms-auto">{`${hashtags.length}/${MAX_HASHTAGS}`}</span>
              </div>
              <HashtagInput
                hashtags={hashtags}
                setHashtags={setHashtags}
                placeholder={t("propertyForm.caption")}
                max={MAX_HASHTAGS}
              />
              <div className="row align-items-center mb-2 mt-3">
                <h5 className="col-12 poppins-semibold-16">
                  <Trans i18nKey="propertyForm.prices.title" />
                </h5>
                <div className="col-8">
                  <FloatingInput className="form-floating">
                    <Field
                      id="sellingPrice"
                      name="sellingPrice"
                      className=" form-control"
                      type="text"
                      pattern="^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$"
                      onBlur={numberInputFormatter}
                      disabled={disableField(
                        values.publicationType,
                        "sellingPrice"
                      )}
                    />
                    <label htmlFor="sellingPrice">
                      <Trans i18nKey="propertyForm.prices.sellingPrice" />
                    </label>
                  </FloatingInput>
                </div>
                <SupplementaryInput className="col-4">
                  <Field
                    name="sellingCurrency"
                    className="form-select"
                    as="select"
                    disabled={values.publicationType === "rent"}
                  >
                    {currencyTypes.map((currency) => {
                      return (
                        <option value={currency} key={currency}>
                          {currency}
                        </option>
                      );
                    })}
                  </Field>
                </SupplementaryInput>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-8">
                  <FloatingInput className="form-floating">
                    <Field
                      id="rentPrice"
                      name="rentPrice"
                      className="form-control"
                      type="text"
                      pattern="^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$"
                      onBlur={numberInputFormatter}
                      disabled={disableField(
                        values.publicationType,
                        "rentPrice"
                      )}
                    />
                    <label htmlFor="rentPrice">
                      <Trans i18nKey="propertyForm.prices.rentPrice" />
                    </label>
                  </FloatingInput>
                </div>
                <SupplementaryInput className="col-4">
                  <Field
                    name="rentCurrency"
                    className="form-select"
                    as="select"
                    disabled={values.publicationType === "sell"}
                  >
                    {currencyTypes.map((currency) => {
                      return (
                        <option value={currency} key={currency}>
                          {currency}
                        </option>
                      );
                    })}
                  </Field>
                </SupplementaryInput>
              </div>

              <div className="d-none d-md-flex justify-content-around poppins-medium-16 mt-5">
                <Button
                  type="button"
                  style={{ background: "rgba(0, 0, 0, 0)" }}
                  onClick={() => {
                    history.push(
                      paths.USER_PROFILE.replace(
                        ":username",
                        profile?.username ?? ""
                      )
                    );
                  }}
                >
                  <Trans i18nKey="addProperty.discard" />
                </Button>
                <ButtonRed
                  type="submit"
                  disabled={disableSubmit}
                  style={{ borderRadius: "5px" }}
                >
                  {property ? (
                    <Trans i18nKey="addProperty.update" />
                  ) : (
                    <Trans i18nKey="addProperty.publish" />
                  )}
                </ButtonRed>
              </div>
              <p className="text-danger text-center poppins-medium-16">
                {errorText}
              </p>
              <SubmitTriggerer
                triggerSubmit={triggerSubmit}
                incompleteFormHandler={incompleteFormHandler}
              />
              <FormObserver onChangePublicationType={onClickPropertyType} />
            </Form>
          );
        }}
      </Formik>
      <BaseShortModal active={showModal} closeModal={() => setShowModal(false)}>
        <BaseModalContent
          title="Error en la publicación "
          buttonContent="Aceptar"
          buttonAction={() => setShowModal(false)}
        >
          <p className="mb-3">
            No se pudo completar el proceso debido a fallas en el sistema, por
            favor revisa que los archivos cargados cumplan con los
            requerimientos establecidos.
          </p>
          <p>
            <strong className="d-block">JPG, PNG o MP4</strong>
            <strong className="d-block">Peso máximo por archivo 15 MB</strong>
            <strong className="d-block">15 archivos máximo</strong>
          </p>
        </BaseModalContent>
      </BaseShortModal>
    </div>
  );
};

export default PropertyForm;

function FormObserver({ onChangePublicationType = () => {} }: any) {
  const { values } = useFormikContext<any>();
  useEffect(() => {
    if (!values["publicationType"]) {
      return;
    }
    onChangePublicationType(values["publicationType"]);
  }, [onChangePublicationType, values]);
  return <></>;
}
