import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { Comment } from "../context/property/types";
import React, { forwardRef } from "react";
import cross from "../images/cross-dinamic.svg";

const CommentInputContainer = styled.div`
  width: 100%;
  & .comment-container {
    top: -100%;
  }
  & .base-comment-container {
    position: absolute;
    bottom: 100%;
    width: 100%;
  }
`;

const CommentInput = styled(TextareaAutosize)<{ answer?: string }>`
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0px;
  &:focus {
    border: none;
    box-shadow: none;
    background-color: inherit;
  }
  overflow: hidden;
  resize: none;
  min-height: 0px !important;
  line-height: normal;
  padding-left: ${(props) => (props.answer ? "5px" : "0")};
`;

export interface CommentInputProps {
  style?: object;
  className: string;
  placeholder: string;
  commentText: string;
  setCommentText: (arg0: string) => void;
  answerComment: Comment | undefined;
  onRemoveAnswer?: () => void;
  children?: React.ReactNode;
  inputRight?: React.ReactNode;
}

export interface BaseCommentInputProps {
  style?: object;
  className: string;
  placeholder: string;
  commentText: string;
  setCommentText: (arg0: any) => void;
  children?: React.ReactNode;
  inputRight?: React.ReactNode;
}

const BaseCommentInputComponent = forwardRef(
  (
    {
      style = {},
      className,
      commentText,
      placeholder,
      setCommentText,
      children,
      inputRight,
    }: BaseCommentInputProps,
    ref: any
  ) => {
    return (
      <div
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        {children ? (
          <div
            className="comment-container"
            style={{
              width: "100%",
            }}
          >
            {children}
          </div>
        ) : null}
        <div className="comment-container--input">
          <CommentInput
            answer={children ? "true" : undefined}
            style={style}
            className={className}
            placeholder={placeholder}
            value={commentText}
            onChange={(e) => setCommentText(e)}
            ref={ref}
          />
          {inputRight}
        </div>
      </div>
    );
  }
);

const CommentInputComponent = forwardRef(
  (
    {
      style = {},
      className,
      commentText,
      placeholder,
      setCommentText,
      answerComment,
      onRemoveAnswer,
      children,
      inputRight,
    }: CommentInputProps,
    ref: any
  ) => {
    return (
      <CommentInputContainer>
        <BaseCommentInputComponent
          className={className}
          commentText={commentText}
          placeholder={placeholder}
          setCommentText={setCommentText}
          ref={ref}
          style={style}
          inputRight={inputRight}
        >
          {children || answerComment ? (
            <div className="base-comment-container">
              {children ? children : null}
              {answerComment ? (
                <div style={{ padding: "5px" }}>
                  <div
                    style={{
                      backgroundColor: "#e9e9e9",
                      borderRadius: "5px",
                      position: "relative",
                      overflow: "hidden",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <div
                      style={{
                        height: "3.5em",
                        width: "0.3em",
                        backgroundColor: "rgb(112, 112, 112)",
                        // position: "absolute",
                        // left: 0,
                      }}
                    ></div>
                    <div
                      style={{
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>
                        <span>@</span>
                        {answerComment.commentator.username}
                      </div>
                      <div>{answerComment.text}</div>
                    </div>
                    <div
                      className="clickable"
                      style={{
                        width: "10px",
                        position: "absolute",
                        top: 0,
                        right: "5px",
                        fontSize: "0.8em",
                        color: "black",
                      }}
                      onClick={onRemoveAnswer}
                    >
                      <img
                        src={cross}
                        alt="Cerrar respuesta"
                        style={{ height: "10px", width: "10px" }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </BaseCommentInputComponent>
      </CommentInputContainer>
    );
  }
);

export default CommentInputComponent;
