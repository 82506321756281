import styled from "styled-components";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import logo from "../../images/logo-2.svg";

const AboutUsContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: calc(100% - 60px);
    margin-left: 60px;
  }
`;

const AboutUs = () => {
  return (
    <div className="d-flex flex-column justify-content-between main-container">
      <Navbar />
      <AboutUsContainer className="text-justify px-3 my-3 px-md-2 my-md-2 poppins-light-16">
        <img alt="bohiio" src={logo} className="mb-3" />
        <p>
          Bohiio está haciendo posible millones de acuerdos inmobiliarios en
          todo el mundo de forma rápida y segura.
        </p>
        <p>
          Somos una plataforma-web pionera en la integrar de comercio social y
          el mercado en el sector inmobiliario, Bohiio ofrece a todos los
          usuarios una experiencia única para vender, comprar, alquilar
          cualquier propiedad de forma segura y actualizada, conectando de forma
          segura y confiable a millones de vendedores y compradores. Con los
          planes Bohiio, compra y vende casas directamente en miles de mercados
          en todo el mundo, permitiendo a los vendedores controlar en tiempo
          real el estatus de la propiedad que están ofertando sin ningún tipo de
          restricción y totalmente en línea.
        </p>
        <hr style={{ background: "#030303", opacity: 1, height: "2px" }} />
        <h1 className="poppins-semibold-16">
          ¿Qué hace que bohiio sea tan especial?
        </h1>
        <p>
          Bohiio es la plataforma Social-Inmobiliaria más grande en el mundo.
          Totalmente gratis, donde puedes buscar cualquier tipo de propiedad en
          venta o alquiler. Ademas, cuenta con la base de datos más actualizada
          del mercado, ya que es actualizada diariamente por nuestra comunidad
          de usuarios. Ahora es mas fácil buscar esa propiedad que tanto has
          soñado y a la vez contar con la tranquilidad y seguridad de que estas
          haciendo una inversión segura con nuestro sistema de verificación de
          propiedades.
        </p>
        <p>
          Vive la experiencia bohiio ahora, dale un me gusta, comenta o comparte
          la propiedad de tus sueños.
        </p>
        <hr style={{ background: "#030303", opacity: 1, height: "2px" }} />
        <h1 className="poppins-semibold-16">Preguntas frecuentes</h1>
      </AboutUsContainer>
      <Footer />
    </div>
  );
};

export default AboutUs;
