import React from "react";
import { ButtonRed } from "../styles/common";

export interface BaseModalContentProps {
  title: string;
  children: React.ReactNode;
  buttonContent: React.ReactNode;
  buttonAction: () => void;
}
export default function BaseModalContent({
  title,
  children,
  buttonContent,
  buttonAction,
}: BaseModalContentProps) {
  return (
    <div style={{ textAlign: "center", padding: "30px 0" }}>
      <h4 style={{ fontWeight: "bold" }} className="mb-4">
        {title}
      </h4>
      <div className="mb-4">{children}</div>
      <ButtonRed
        type="button"
        className="w-100"
        onClick={buttonAction}
        style={{ borderRadius: "5px" }}
      >
        {buttonContent}
      </ButtonRed>
    </div>
  );
}
