import { useContext } from "react";
import { ProfileContext } from "../context/profile/ProfileContext";
import BaseModal from "./BaseModal";
import { ButtonRed } from "../styles/common";

const SubscriptionAcquiredModal = (props: any) => {
  const { profile } = useContext(ProfileContext);
  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="subscription-acquired"
      centered={true}
    >
      <div className="poppins-light-14 d-flex">
        <div
          className="p-5 bg-white"
          style={{
            border: "1px solid rgba(0, 0, 0, 0)",
            borderRadius: "10px",
            maxWidth: "380px",
            textAlign: "center",
          }}
        >
          <p className="poppins-bold-16 mb-4">
            ¡Gracias {profile?.first_name}!
          </p>
          {props.litePlan ? (
            <p className="poppins-light-14">
              En breve tu cuenta será validada y incrementaremos tu incidencia
              en nuestro motor de búsqueda de acuerdo a tu suscripción
            </p>
          ) : (
            <>
              <p className="poppins-light-14">
                En breve tu cuenta será validada, acreditaremos tus
                verificaciones, e incrementaremos tu incidencia en nuestro motor
                de búsqueda de acuerdo a tu suscripción.
              </p>
              <p className="poppins-light-14">
                Tus verificaciones expiran en un año a partir del día de hoy.
              </p>
            </>
          )}
          <p className="mb-5 poppins-light-14">
            Para cualquier inquietud enviarnos un correo a{" "}
            <a href="mailto:help@bohiio.com">help@bohiio.com</a>
          </p>
          <ButtonRed
            className="poppins-bold-14 mb-3 w-100"
            onClick={props.closeModal}
          >
            Cerrar
          </ButtonRed>
        </div>
      </div>
    </BaseModal>
  );
};

export default SubscriptionAcquiredModal;
