import { createContext, useState, ReactNode } from "react";
import axios_engine from "../../api/engine";
import API from "../../api/endpoint";
import { Property, ShortmapProperty, Tendency } from "./types";

interface IPropertyContext {
  properties: Property[];
  getProperties: (page?: number, country?: string) => Promise<Property[]>;
  getFilteredProperties: (
    page: number,
    filters: URLSearchParams
  ) => Promise<Property[]>;
  getProperty: (arg0: number) => Promise<Property>;
  addProperty: (arg0: FormData) => Promise<Property>;
  updateProperty: (arg0: FormData, arg1: number) => Promise<Property>;
  deleteProperty: (arg0: number) => Promise<void>;
  addLike: (arg0: number) => Promise<void>;
  dislike: (arg0: number) => Promise<void>;
  addComment: (
    arg0: number,
    arg1: number,
    arg2: string,
    arg3?: number
  ) => Promise<any>;
  getComments: (arg0: number, arg1?: number, arg2?: number) => Promise<any>;
  getComment: (arg0: number) => Promise<any>;
  likeComment: (arg0: number) => Promise<any>;
  dislikeComment: (arg0: number) => Promise<any>;
  getTendencies: (country?: string, name?: string) => Promise<Tendency[]>;
  getCommentsCount: (arg0: number) => Promise<number>;
  toggleLocationStatus: (arg0: Property) => Promise<Property>;
  getPropertiesByTendency: (
    arg0: string,
    arg1?: string,
    arg2?: string,
    arg3?: number,
    arg4?: number
  ) => Promise<any>;
  getUserHistory: (arg0?: number) => Promise<any>;
  getUserFavorites: (arg0?: number) => Promise<any>;
  saveProperty: (arg0: number, arg1: number) => Promise<void>;
  unsaveProperty: (arg0: number, arg1: number) => Promise<void>;
  getUserProperties: (
    arg0: number,
    arg1?: string,
    arg3?: number,
    arg4?: number
  ) => Promise<any>;
  getShortmapProperties: (
    arg0: number,
    arg1: number,
    arg2: number,
    arg3: number,
    arg4: number,
    arg5: URLSearchParams
  ) => Promise<any>;
  deleteComment: (arg0: number) => Promise<any>;
  reportComment: (arg0: number) => Promise<any>;
  addPropertyPercentage: number;
}

export const PropertyContext = createContext<IPropertyContext>(
  {} as IPropertyContext
);

interface PropertyProviderProps {
  children: ReactNode;
}

export interface ShortmapPropertiesResult {
  count: number;
  next: string;
  previous: string;
  results: ShortmapProperty[];
}
export const PAGE_LIMIT = 10;

export const PropertyProvider = ({ children }: PropertyProviderProps) => {
  const [properties, setProperties] = useState<any[]>([]);
  const [addPropertyPercentage, setAddPropertyPercentage] = useState(0);

  const addProperty = async (formData: FormData) => {
    try {
      // perform verification to reload access_token if expired
      // for avoiding 502 on upload large files
      await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.verify}`
      );
      const res = await axios_engine.post(
        `${process.env.REACT_APP_BOHIIO_API}${API.add_property}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // console.log("percentCompleted", percentCompleted);
            setAddPropertyPercentage(percentCompleted);
          },
        }
      );
      return res.data;
    } catch (e) {
      throw new Error(
        "No se pudo completar la publicación, revisa que el formato sea JPG, PNG o MP4 y el tamaño máximo de cada archivo 15 MB"
      );
    }
  };

  const updateProperty = async (formData: FormData, id: number) => {
    try {
      // perform verification to reload access_token if expired
      // for avoiding 502 on upload large files
      await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.verify}`
      );
      const res = await axios_engine.put(
        `${process.env.REACT_APP_BOHIIO_API}${API.add_property}${id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // console.log("percentCompleted", percentCompleted);
            setAddPropertyPercentage(percentCompleted);
          },
        }
      );
      return res.data;
    } catch (e) {
      throw new Error("Couldn't update the property");
    }
  };

  const getProperties = async (page: number = 0, country: string = "") => {
    try {
      let url = `${process.env.REACT_APP_BOHIIO_API}${API.get_properties}`;
      const params: { limit?: number; offset?: number; country?: string } = {
        limit: PAGE_LIMIT,
        offset: page * PAGE_LIMIT,
        country: undefined,
      };
      if (country) {
        params.country = country;
      }
      const res = await axios_engine.get(url, {
        params: params,
      });

      // console.log("getProperty", res.data.results);

      setProperties(res.data.results);
      return res.data.results;
    } catch {
      throw new Error("Couldn't retrive the properties");
    }
  };

  const getFilteredProperties = async (
    page: number,
    filters: URLSearchParams
  ) => {
    try {
      filters.set("limit", PAGE_LIMIT.toString());
      filters.set("offset", (page * PAGE_LIMIT).toString());
      let url = `${process.env.REACT_APP_BOHIIO_API}${
        API.get_properties
      }?${filters.toString()}`;

      const res = await axios_engine.get(url);
      return res.data.results;
    } catch {
      throw new Error("Couldn't retrive the filtered properties");
    }
  };

  const getProperty = async (id: number) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_property(id)}`
      );

      return res.data;
    } catch {
      throw new Error("Couldn't retrive the property");
    }
  };

  const getShortmapProperties = async (
    page: number,
    latitude: number,
    longitude: number,
    distance: number,
    limit: number = PAGE_LIMIT,
    filters: URLSearchParams
  ) => {
    try {
      filters.set("limit", limit.toString());
      filters.set("offset", (page * limit).toString());
      filters.set("latitude", latitude.toString());
      filters.set("longitude", longitude.toString());
      filters.set("distance", distance.toString());
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${
          API.get_shortmap_properties
        }?${filters.toString()}`
      );

      return res.data;
    } catch {
      throw new Error("Couldn't retrive the properties");
    }
  };

  const deleteProperty = async (id: number) => {
    try {
      await axios_engine.delete(
        `${process.env.REACT_APP_BOHIIO_API}${API.delete_property(id)}`
      );
    } catch {
      throw new Error("Couldn't delete the property");
    }
  };

  const addLike = async (id: number) => {
    try {
      await axios_engine.post(
        `${process.env.REACT_APP_BOHIIO_API}${API.add_like}`,
        {
          property: id,
        }
      );
    } catch (e) {
      console.error(e);
      // throw new Error("Couldn't like the property");
    }
  };

  const dislike = async (id: number) => {
    try {
      await axios_engine.post(
        `${process.env.REACT_APP_BOHIIO_API}${API.dislike}`,
        {
          property: id,
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const addComment = async (
    propertyId: number,
    userId: number,
    text: string,
    parentId: number = -1
  ) => {
    try {
      const data: any = {
        property: propertyId,
        commentator: userId,
        text,
        parent: undefined,
      };
      if (parentId > -1) {
        data.parent = parentId;
      }
      return (
        await axios_engine.post(
          `${process.env.REACT_APP_BOHIIO_API}${API.add_comment}`,
          data
        )
      ).data;
    } catch (e) {
      throw e;
    }
  };

  // const getProperties = async (page?: number) => {
  //   try {
  // let url = `${process.env.REACT_APP_BOHIIO_API}${API.get_properties}`;
  // if (typeof page === "number") {
  //   url += `?limit=${PAGE_LIMIT}&offset=${page * PAGE_LIMIT}`;
  // }
  // const res = await axios_engine.get(url);

  //     setProperties(res.data.results);
  //     return res.data.results;
  //   } catch {
  // throw new Error("Couldn't retrive the properties");
  //   }
  // };

  const getComments = async (
    propertyId: number,
    page?: number,
    parent: number = -1
  ) => {
    try {
      let url = `${process.env.REACT_APP_BOHIIO_API}${API.get_property_comments(
        propertyId
      )}`;

      if (typeof page === "number") {
        url += `&limit=${PAGE_LIMIT}&offset=${page * PAGE_LIMIT}`;
      } else {
        url += "&limit=10";
      }

      if (parent > -1) {
        url += `&parent=${parent}`;
      }

      const res = await axios_engine.get(url);

      return res.data;
    } catch {
      throw new Error("Couldn't retrive the comments");
    }
  };

  const getComment = async (commentId: number) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_comment(commentId)}`
      );

      return res.data;
    } catch {}
  };

  const getCommentsCount = async (propertyId: number) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_property_comments(
          propertyId
        )}`
      );

      return res.data.count;
    } catch {}
  };

  const likeComment = async (commentId: number) => {
    try {
      await axios_engine.post(
        `${process.env.REACT_APP_BOHIIO_API}${API.comment_like}`,
        { propertyComment: commentId }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const dislikeComment = async (commentId: number) => {
    try {
      await axios_engine.post(
        `${process.env.REACT_APP_BOHIIO_API}${API.comment_dislike}`,
        { propertyComment: commentId }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const deleteComment = async (commentId: number) => {
    await axios_engine.delete(
      `${process.env.REACT_APP_BOHIIO_API}${API.comment_delete(commentId)}`
    );
  };

  const reportComment = async (commentId: number) => {
    await axios_engine.post(
      `${process.env.REACT_APP_BOHIIO_API}${API.comment_report}`,
      { propertyComment: commentId }
    );
  };

  const getTendencies = async (country: string = "", name: string = "") => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_tendencies}?limit=21${
          country ? `&country=${country}` : ""
        }${name ? `&tendency=${name}` : ""}`
      );
      return res.data.results;
    } catch {}
  };

  const getPropertiesByTendency = async (
    tendency: string,
    publicationType?: string,
    country: string = "",
    page: number = 0,
    page_limit: number = 21
  ) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_properties_by_tendency(
          tendency,
          publicationType
        )}&limit=${page_limit}&offset=${page * page_limit}${
          country ? `&country=${country}` : ""
        }`
        // limit time
      );
      return res.data;
    } catch {}
  };

  const toggleLocationStatus = async (property: Property) => {
    try {
      let formData = new FormData();
      formData.append("general", JSON.stringify(property.general));
      formData.append("location", JSON.stringify(property.location));
      formData.append("detail", JSON.stringify(property.detail));
      formData.append("latitude", String(property.latitude));
      formData.append("longitude", String(property.longitude));
      formData.append("tendencies", JSON.stringify(property.tendencies));
      formData.append(
        "enable_location",
        JSON.stringify(!property.enable_location)
      );

      const res = await axios_engine.put(
        `${process.env.REACT_APP_BOHIIO_API}${API.update_property(
          property.id
        )}`,
        formData
      );

      return res.data;
    } catch {}
  };

  const getUserHistory = async (page?: number) => {
    try {
      let url = `${process.env.REACT_APP_BOHIIO_API}${API.get_user_history}`;

      if (typeof page === "number") {
        url += `?limit=${PAGE_LIMIT}&offset=${page * PAGE_LIMIT}`;
      }

      const res = await axios_engine.get(url);
      return res.data.results;
    } catch {}
  };

  const getUserFavorites = async (page?: number) => {
    try {
      let url = `${process.env.REACT_APP_BOHIIO_API}${API.get_user_favorites}`;

      if (typeof page === "number") {
        url += `?limit=${PAGE_LIMIT}&offset=${page * PAGE_LIMIT}`;
      }

      const res = await axios_engine.get(url);
      return res.data.results;
    } catch {}
  };

  const saveProperty = async (userId: number, propertyId: number) => {
    try {
      await axios_engine.post(
        `${process.env.REACT_APP_BOHIIO_API}${API.property_save}`,
        {
          user: userId,
          property: propertyId,
        }
      );
    } catch (e) {
      throw e;
    }
  };

  const unsaveProperty = async (userId: number, propertyId: number) => {
    //still not working,
    try {
      await axios_engine.delete(
        `${process.env.REACT_APP_BOHIIO_API}${API.property_unsave(propertyId)}`
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getUserProperties = async (
    userId: number,
    publicationType?: string,
    page: number = 0,
    limit: number = PAGE_LIMIT
  ) => {
    const res = await axios_engine.get(
      `${process.env.REACT_APP_BOHIIO_API}${API.get_user_properties(userId)}`,
      {
        params: {
          typePublication: publicationType,
          limit: limit,
          offset: page * limit,
        },
      }
    );
    return res.data;
  };

  return (
    <PropertyContext.Provider
      value={{
        properties,
        getProperties,
        getFilteredProperties,
        getProperty,
        addProperty,
        updateProperty,
        deleteProperty,
        addLike,
        dislike,
        addComment,
        getComments,
        getComment,
        likeComment,
        dislikeComment,
        getTendencies,
        getCommentsCount,
        toggleLocationStatus,
        getPropertiesByTendency,
        getUserHistory,
        getUserFavorites,
        saveProperty,
        unsaveProperty,
        getUserProperties,
        addPropertyPercentage,
        getShortmapProperties,
        deleteComment,
        reportComment,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};
