import OptionsModal from "./OptionsModal";
import styled from "styled-components";

const OptionMenu = styled.div`
  background: white;
  border-radius: 5px;
  text-align: center;
  div {
    border-bottom: 2px solid gray;
    padding: 1rem 4rem;
    cursor: pointer;
  }
  div:first-child {
    color: red;
    font-weight: 700;
  }
  div:last-child {
    font-weight: 700;
    border-bottom: 0px;
  }
`;

interface OptionConfirmationModalProps {
  title: string;
  msg: string;
  acceptText: string;
  cancelText: string;
  show: boolean;
  handleAccept: () => void;
  handleClose: () => void;
  handleDiscard?: () => void;
}

function OptionConfirmationModal({
  title,
  msg,
  acceptText,
  cancelText,
  show,
  handleAccept,
  handleClose,
  handleDiscard = handleClose,
}: OptionConfirmationModalProps) {
  return (
    <>
      <OptionsModal active={show} closeModal={handleClose}>
        <div className="poppins-regular-12 text-center px-3">
          <p className="poppins-bold-12">{title}</p>
          <p>{msg}</p>
          <OptionMenu>
            <div onClick={handleAccept}>{acceptText}</div>
            <div onClick={handleDiscard}>{cancelText}</div>
          </OptionMenu>
        </div>
      </OptionsModal>
    </>
  );
}

export default OptionConfirmationModal;
