import styled from "styled-components";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import paths from "../routes/paths.json";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
`;

const Link = styled(ReactRouterLink)`
  text-decoration: none;
`;

const RememberMe = () => {
  const history = useHistory();

  const onSaveInformation = () => {
    // code for saving information goes here
    history.push(paths.HOME);
  };

  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="d-flex justify-content-center">
          <p>¿Guardar tu información de inicio de sesión?</p>
        </div>
        <div className="d-flex justify-content-center">
          <p>
            Podemos guardar tu información de inicio de sesión en este navegador
            para que no tengas que volver a ingresarla.
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <Button className="btn" onClick={() => onSaveInformation()}>
            Guardar Informacion
          </Button>
        </div>

        <div className="d-flex justify-content-center">
          <Link to={paths.HOME}>Ahora no</Link>
        </div>
      </div>
    </div>
  );
};

export default RememberMe;
