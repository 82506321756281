import Navbar from "../../components/Navbar";
import DashboardSidebar from "../../components/DashboardSidebar";
import DasboardTendency from "../../components/DashboardTendency";
import { useHistory, useParams } from "react-router-dom";
import TendencyProperties from "../../components/TendencyProperties";
import { NavigationBackButton } from "../../components/NavigationBackButton";
import Footer from "../../components/Footer";

const Tendency = (props: any) => {
  const params: any = useParams();
  const history = useHistory();
  return (
    <>
      <div className="d-flex flex-column main-container justify-content-between">
        <Navbar
          searchBarOption={
            <NavigationBackButton
              onClick={() => {
                history.go(-1);
              }}
            />
          }
          searchBarToRight
        />

        <DashboardSidebar tendency={params.tendency} />
        <div className="d-flex flex-grow-1">
          <div className="col-12 offset-md-1 col-md-6">
            {!params.hasOwnProperty("tendency") && <DasboardTendency />}
            {params.hasOwnProperty("tendency") && (
              <TendencyProperties tendency={params.tendency} />
            )}
          </div>
        </div>
        {!params.hasOwnProperty("tendency") && (
          <div className="d-md-none">
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default Tendency;
