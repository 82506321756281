import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Vendor } from "../context/vendor/types";
import { useParams } from "react-router-dom";
import VendorComponent from "./VendorComponent";
import { FollowersContext } from "../context/followers/FollowersContext";
import { Followee, Follower } from "../context/followers/types";
import { UserContainer } from "./DashboardVendor";
import { useInfiniteQuery } from "react-query";
import { useInView } from "react-intersection-observer";

interface IParams {
  name: string;
}

interface IProps {
  isFollowersList: boolean;
}

const PAGE_LIMIT = 10;

const DashboardFollowers = ({ isFollowersList }: IProps) => {
  const params = useParams<IParams>();
  const { ref, inView } = useInView();
  const { getFollowers, getFollowees } = useContext(FollowersContext);
  const [state, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      `${isFollowersList ? "followers" : "followees"}-${params.name}`,
      ({ pageParam = 0 }) => {
        return (async () => {
          const result = isFollowersList
            ? await getFollowers(params.name, pageParam, PAGE_LIMIT)
            : await getFollowees(params.name, pageParam, PAGE_LIMIT);
          return (
            result.map((r) =>
              isFollowersList
                ? (r as Follower).follower_info
                : (r as Followee).following_info
            ) ?? []
          );
        })();
      },
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length;
          return lastPage.length !== 0 ? nextPage : null;
        },
        staleTime: Infinity,
      }
    );

  const LoadMoreVendorsComponent = useMemo(
    () => (
      <div className="loader" ref={ref}>
        {isFetchingNextPage && hasNextPage ? "Loading..." : "No search left"}
      </div>
    ),
    [hasNextPage, isFetchingNextPage, ref]
  );

  const vendors = useMemo(() => {
    let list: any[] = [];
    data?.pages.forEach((page) => {
      list = list.concat(
        ...page.map((vendor: any, index: number) => {
          return {
            vendor,
            updateVendor: (newVendorData: Vendor) => {
              page.splice(index, 1, newVendorData);
              forceUpdate();
            },
          };
        })
      );
    });
    return list;
  }, [data?.pages, state]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage]);

  return (
    <div className="mw-100">
      <div className="poppins-bold-20 col-12 ps-4">
        {isFollowersList ? "Seguidores" : "Seguidos"}
      </div>
      <div className="me-2 me-md-0 md-row mw-100 d-flex flex-column flex-wrap flex-md-row align-items-center text-md-center h-100 justify-content-start">
        {vendors.map(({ vendor, updateVendor }, index) => {
          return (
            <UserContainer key={index}>
              <VendorComponent
                vendor={vendor}
                vendorIndex={index}
                updateVendor={updateVendor}
              />
            </UserContainer>
          );
        })}
        <UserContainer>{LoadMoreVendorsComponent}</UserContainer>
      </div>
    </div>
  );
};

export default DashboardFollowers;
