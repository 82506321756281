import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ButtonRed, FloatingInput } from "../styles/common";
import ErrorText from "./ErrorText";
import { AuthContext } from "../context/auth/AuthContext";
import { useContext } from "react";

const ResetPasswordModal = (props: any) => {
  const { forgotPassword } = useContext(AuthContext);
  return (
    <Formik
      initialValues={{
        user: "",
      }}
      validationSchema={Yup.object({
        user: Yup.string()
          .email("Debe ser una dirección de correo valida")
          .required("Requerido")
          .max(128, "El email no puede ser tan largo"),
      })}
      onSubmit={async (values) => {
        try {
          await forgotPassword(values.user);
          props.onSuccess(values.user);
        } catch (error) {
          // TODO: add modal here
        }
      }}
    >
      <div className="py-5 text-center">
        <div className="mb-4">
          <p className="poppins-bold-16 mb-4" style={{ lineHeight: "1em" }}>
            ¿Tienes problemas al iniciar sesión con tu cuenta ?
          </p>
          <p className="poppins-light-14">
            Ingresa tu correo electrónico, teléfono o nombre de usuario y te
            enviaremos un enlace para que recuperes el acceso a tu cuenta
          </p>
        </div>
        <Form>
          <FloatingInput className="form-floating mb-3">
            <Field
              id="user"
              name="user"
              type="text"
              placeholder="Correo Electrónico"
              className="form-control"
            />
            <label htmlFor="user">Correo Electrónico</label>
            <ErrorMessage name="user">
              {(msg) => <ErrorText>{msg}</ErrorText>}
            </ErrorMessage>
          </FloatingInput>
          <ButtonRed type="submit" className="w-100">
            Enviar
          </ButtonRed>
        </Form>
      </div>
    </Formik>
  );
};

export default ResetPasswordModal;
