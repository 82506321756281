import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import EditProfile from "../../components/EditProfile";
import Navbar from "../../components/Navbar";
import ProfileNotifications from "../../components/ProfileNotifications";
import ProfilePrivacy from "../../components/ProfilePrivacy";
import ProfileSecurityConfiguration from "../../components/ProfileSecurityConfiguration";
import SimpleFooter from "../../components/SimpleFooter";
import AvatarIcon from "../../images/avatar.svg";
import { updateProfileInfo, updateProfileImage } from "../../api/profile";
import { ProfileContext } from "../../context/profile/ProfileContext";
import { useHistory } from "react-router-dom";
import paths from "../../routes/paths.json";
import { NavigationBackButton } from "../../components/NavigationBackButton";
import BaseShortModal from "../../components/BaseShortModal";
import BaseModalContent from "../../components/BaseModalContent";

const NavBox = styled.div<{ selected: boolean }>`
  padding: 0.5em 0;
  margin: 0.5em 0;
  cursor: pointer;
  ${(props) => props.selected && `border-right: 1px #D2190E solid`}
`;

const NavBoxContainer = styled.div`
  @media and screen (min-width: 768px) {
    width: 16.67%;
    position: fixed;
  }
`;

const EDIT_PROFILE = 0;
const SECURITY_CONFIGURATION = 10;
const NOTIFICATIONS_CONFIGURATION = 20;
const PRIVACY_CONFIGURATION = 30;
const HELP = 40;

const ProfileConfiguration = () => {
  const history = useHistory();
  const [configurationScreen, setConfigurationScreen] = useState(EDIT_PROFILE);
  const [moreOptions, setMoreOptions] = useState(false);
  const [showNotificationModal, setShowMotificationModal] = useState(false);
  const toEditProfile = () => {
    setMoreOptions(false);
    setConfigurationScreen(EDIT_PROFILE);
  };
  const toChangeSecurity = () => {
    setMoreOptions(false);
    setConfigurationScreen(SECURITY_CONFIGURATION);
  };
  const toNotificationsConfigurartion = () => {
    setMoreOptions(false);
    setConfigurationScreen(NOTIFICATIONS_CONFIGURATION);
  };
  const toPrivacyConfiguration = () => {
    setMoreOptions(false);
    setConfigurationScreen(PRIVACY_CONFIGURATION);
  };

  const { getUserProfile, profile } = useContext(ProfileContext);

  const handleProfileImageChange = async (newImage: any) => {
    await updateProfileImage(newImage);
    await getUserProfile();
  };
  const handleProfileChangeInfo = async (changeUserProfile: any) => {
    await updateProfileInfo(changeUserProfile);
    await getUserProfile();
    setShowMotificationModal(true);
  };

  const onCloseNotificacionModal = () => {
    setShowMotificationModal(false);
    history.push(
      paths.USER_PROFILE.replace(":username", profile?.username ?? "")
    );
  };

  useEffect(() => {
    (async () => {
      getUserProfile();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex flex-column justify-content-between main-container">
      <Navbar
        searchBarOption={
          <NavigationBackButton
            onClick={() => {
              setMoreOptions(false);
              if (configurationScreen === EDIT_PROFILE) {
                history.push(
                  paths.USER_PROFILE.replace(
                    ":username",
                    profile?.username ?? ""
                  )
                );
              }
              if (configurationScreen % 10 > 0) {
                setConfigurationScreen(
                  Math.floor(configurationScreen / 10) * 10
                );
              } else {
                setConfigurationScreen(EDIT_PROFILE);
              }
            }}
          />
        }
        searchBarToRight
      />

      <div className="flex-grow-1 d-flex">
        <div
          className={`${
            moreOptions ? "d-none" : ""
          } d-md-inline col-12 offset-md-2 col-md-5 bg-white`}
        >
          <div className="m-lg-5 m-2">
            {configurationScreen === EDIT_PROFILE && (
              <div
                className="d-md-none text-end clickable"
                onClick={() => setMoreOptions(true)}
              >
                Mas opciones
              </div>
            )}
            {Math.floor(configurationScreen / 10) ===
              Math.floor(EDIT_PROFILE / 10) && (
              <EditProfile
                first_name={profile?.first_name}
                last_name={profile?.last_name}
                username={profile?.username}
                phone={profile?.profile?.phone}
                phone_code={profile?.profile?.phone_code}
                client_phone={profile?.profile?.client_phone}
                client_phone_code={profile?.profile?.client_phone_code}
                language={profile?.profile?.language}
                email={profile?.email}
                city={profile?.profile?.city}
                country={profile?.profile?.country}
                biography={profile?.profile?.biography}
                profession={profile?.profile?.profession}
                province={profile?.profile?.province}
                company={profile?.profile?.company}
                webpage={profile?.profile?.webpage}
                avatar={
                  profile?.profile?.avatar !== null
                    ? profile?.profile?.avatar
                    : AvatarIcon
                }
                handleProfileChangeInfo={handleProfileChangeInfo}
                handleProfileImageChange={handleProfileImageChange}
                screen={configurationScreen}
                setScreen={setConfigurationScreen}
              />
            )}
            {Math.floor(configurationScreen / 10) ===
              Math.floor(SECURITY_CONFIGURATION / 10) && (
              <ProfileSecurityConfiguration
                first_name={profile?.first_name}
                last_name={profile?.last_name}
                username={profile?.username}
                avatar={
                  profile?.profile?.avatar !== null
                    ? profile?.profile?.avatar
                    : AvatarIcon
                }
                screen={configurationScreen}
                setScreen={setConfigurationScreen}
              />
            )}
            {Math.floor(configurationScreen / 10) ===
              Math.floor(NOTIFICATIONS_CONFIGURATION / 10) && (
              <ProfileNotifications />
            )}
            {Math.floor(configurationScreen / 10) ===
              Math.floor(PRIVACY_CONFIGURATION / 10) && <ProfilePrivacy />}
          </div>
        </div>
        <div
          className={`${
            moreOptions ? "" : "d-none"
          } ms-md-5 ms-2 col-11 col-md-2 poppins-ligth-16 d-md-inline`}
        >
          <NavBoxContainer>
            <NavBox
              selected={
                Math.floor(configurationScreen / 10) ===
                Math.floor(EDIT_PROFILE / 10)
              }
              onClick={toEditProfile}
            >
              Editar perfil
            </NavBox>
            <NavBox
              selected={
                Math.floor(configurationScreen / 10) ===
                Math.floor(SECURITY_CONFIGURATION / 10)
              }
              onClick={toChangeSecurity}
            >
              Cambiar contraseña
            </NavBox>

            {/* <NavBox
              selected={
                Math.floor(configurationScreen / 10) ===
                Math.floor(NOTIFICATIONS_CONFIGURATION / 10)
              }
              onClick={toNotificationsConfigurartion}
            >
              Notificaciones
            </NavBox>
            <NavBox
              selected={configurationScreen === PRIVACY_CONFIGURATION}
              onClick={toPrivacyConfiguration}
            >
              Privacidad
            </NavBox> */}
            <NavBox
              selected={configurationScreen === HELP}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "mailto:help@bohiio.com?subject=Help";
              }}
            >
              Ayuda
            </NavBox>
          </NavBoxContainer>
        </div>
      </div>
      <SimpleFooter />
      <BaseShortModal
        active={showNotificationModal}
        closeModal={onCloseNotificacionModal}
      >
        <BaseModalContent
          title="Perfil Actualizado"
          buttonContent="Aceptar"
          buttonAction={onCloseNotificacionModal}
        >
          La información fue actualizada correctamente.
        </BaseModalContent>
      </BaseShortModal>
    </div>
  );
};

export default ProfileConfiguration;
