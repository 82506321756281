import { useContext } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import language from "../images/language-bw.svg";
import playstore from "../images/google-play.svg";
import appstore from "../images/app-store.svg";
import facebookIcon from "../images/facebook.svg";
import twitterIcon from "../images/twitter.svg";
import instagramIcon from "../images/instagram.svg";
import envelopeIcon from "../images/envelope.svg";
import { LanguageContext } from "../context/language/LanguageContext";
import { Link } from "react-router-dom";
import paths from "../routes/paths.json";
import { ProfileContext } from "../context/profile/ProfileContext";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

const ClickableSpan = styled.span`
  cursor: pointer;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const ImageContainer = styled.div``;

const LanguageContainer = styled.div`
  @media screen and (min-width: 768px) {
    order: 0;
    flex-basis: auto;
  }
  order: -1;
  flex-basis: 100%;
`;

const CertifyContainer = styled(StyledLink)`
  @media screen and (min-width: 768px) {
    flex-basis: auto;
  }
  flex-basis: 100%;
`;

const Footer = () => {
  const [currentLanguage, changeLanguage] = useContext(LanguageContext);
  const { profile } = useContext(ProfileContext);

  return (
    <div
      className="col-12 offset-md-1 col-md-10 d-flex"
      style={{ color: "#707070" }}
    >
      <div className="ms-3 ms-md-0">
        <div className="d-flex flex-wrap">
          <StyledLink className="" to={paths.ABOUT_US}>
            <Trans i18nKey="footer.bar.1" />
          </StyledLink>
          <StyledLink className="d-none d-md-inline mx-3" to={paths.HOME}>
            <Trans i18nKey="footer.bar.2" />
          </StyledLink>
          <StyledLink className="mx-2 mx-md-3" to={paths.PRIVACY}>
            <Trans i18nKey="footer.bar.3" />
          </StyledLink>
          <StyledLink className="mx-2 mx-md-3" to={paths.TERMS}>
            <Trans i18nKey="footer.bar.4" />
          </StyledLink>
          <ClickableSpan
            className="mx-2 mx-md-3"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:help@bohiio.com?subject=Help";
            }}
          >
            <Trans i18nKey="footer.bar.5" />
          </ClickableSpan>
          <LanguageContainer
            className="clickable"
            onClick={() =>
              currentLanguage === "es"
                ? changeLanguage("en")
                : changeLanguage("es")
            }
          >
            <img src={language} alt="language icon" />
            <Trans i18nKey="footer.bar.6" />
          </LanguageContainer>
        </div>
        <ImageContainer
          style={{ fontSize: "12px" }}
          className="d-flex mb-4 mt-2"
        >
          {/* <img alt="appstore" src={appstore} />
          <img alt="playstore" src={playstore} /> */}
          <label style={{ marginRight: "20px" }}>
            <Icon
              alt="email"
              src={envelopeIcon}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "mailto:hola@bohiio.com?subject=Help";
              }}
            />
          </label>
          <label style={{ marginRight: "20px" }}>
            <Icon
              alt="facebook"
              src={facebookIcon}
              onClick={() => window.open("https://facebook.com/bohiiolatam/")}
            />
          </label>
          <label style={{ marginRight: "20px" }}>
            <Icon
              alt="twitter"
              src={twitterIcon}
              onClick={() => window.open("https://twitter.com/bohiiolatam/")}
            />
          </label>
          <label style={{ marginRight: "20px" }}>
            <Icon
              alt="instagram"
              src={instagramIcon}
              onClick={() =>
                window.open("https://www.instagram.com/bohiiolatam/")
              }
            />
          </label>
        </ImageContainer>
        <p className="">Bohiio LLC, Mission, TX 78574, EE. UU.</p>
      </div>
    </div>
  );
};

export default Footer;
