import styled from "styled-components";
import certified from "../images/certified.svg";
import certifyNowPhoto from "../images/certify-property-example.jpg";
import { ButtonRed } from "../styles/common";
import BaseModal from "./BaseModal";

const Button = styled.button`
  border: 1px rgba(0, 0, 0, 0) solid;
  width: 100%;
  background: white;
  color: black;
  text-align: center;
  margin: 0 auto;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 5px;
`;

const CertifyNowModal = (props: any) => {
  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="certify-now"
    >
      <div
        className="poppins-regular-14"
        style={{ width: "100%", margin: "auto", maxWidth: "380px " }}
      >
        <div
          className="bg-white"
          style={{
            border: "1px solid rgba(0, 0, 0, 0)",
            borderRadius: "10px",
            padding: "50px 25px",
          }}
        >
          <h6 className="poppins-semibold-18" style={{ textAlign: "center" }}>
            Publicación completada exitosamente, “Aumenta hasta un{" "}
            <strong style={{ color: "#D2190E" }}>90%</strong> su alcance
            certificándola”
          </h6>
          <div
            style={{
              position: "relative",
              margin: "2em 0",
            }}
          >
            <div
              className={`d-flex py-1`}
              style={{
                backgroundColor: "rgb(0, 0, 0)",
                borderRadius: "10px",
                zIndex: 1001,
                alignItems: "center",
                top: "10px",
                left: "10px",
                position: "absolute",
                color: "white",
              }}
            >
              <span className="ms-2 me-1 ">Propiedad Certificada</span>
              <Icon src={certified} alt="certified" />
            </div>
            <img
              alt="certify now"
              src={certifyNowPhoto}
              style={{ maxWidth: "100%" }}
            />
          </div>
          <p style={{ textAlign: "center" }}>
            Al certificar tu propiedad transmites seguridad y confianza a todos
            tus clientes potenciales.
          </p>
          <ButtonRed
            className="poppins-semibold-14 mb-3 w-100"
            onClick={props.openPaymentModal}
          >
            ¡Certificar propiedad ahora!
          </ButtonRed>
          <Button onClick={props.closeModal}>
            <strong>Certificar luego</strong>
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default CertifyNowModal;
