import { createContext, useState, FC, useEffect, useContext } from "react";
import axios_engine from "../../api/engine";
import API from "../../api/endpoint";
import { Profile } from "./types";
import { AuthContext } from "../auth/AuthContext";

interface IProfileContext {
  profile: Profile | null;
  getUserProfile: any;
}

export const ProfileContext = createContext<IProfileContext>(
  {} as IProfileContext
);

export const ProfileProvider: FC = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const res = await getUserProfile();
        setProfile(res);
      } else {
        setProfile(null);
      }
    })();
  }, [isAuthenticated]);

  const getUserProfile = async () => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.user_profile}`
      );
      if (res.data) {
        setProfile(res.data);
        return res.data;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  return (
    <ProfileContext.Provider value={{ profile, getUserProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
