import paths from "../routes/paths.json";
import { Link } from "react-router-dom";
import { ButtonRed } from "../styles/common";
import BaseModal from "./BaseModal";

const CertificationNotSupportedModal = (props: any) => {
  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="certification-not-supported"
    >
      <div
        className="bg-white poppins-light-14"
        style={{
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "10px",
          textAlign: "center",
          padding: "80px 3rem",
          width: "100%",
          margin: "auto",
          maxWidth: "380px",
        }}
      >
        <p className="poppins-semibold-16 mb-5">Verificación no disponible</p>
        <p className="mt-3 mb-5">
          Aún no tenemos el servicio de verificaciones para este país, por favor
          selecciona una ubicación que se encuentre dentro de los limites de:
          República Dominicana.
        </p>
        <ButtonRed
          className="poppins-semibold-14 mb-3 w-100"
          onClick={props.closeModal}
        >
          Verificar otra propiedad
        </ButtonRed>
        <a
          href={`${process.env.REACT_APP_LANDING_LINK}#update`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <span className="poppins-semibold-14 mb-3" onClick={props.closeModal}>
            Descartar verificación
          </span>
        </a>
      </div>
    </BaseModal>
  );
};

export default CertificationNotSupportedModal;
