import React, { createContext, useContext, useEffect, useReducer } from "react";
export const ALL_COUNTRIES_CODE = "ZZ";

export interface LocalStorageProviderProps {
  children: React.ReactNode;
  id: string;
}

export interface LocalstorageState {
  value: React.MutableRefObject<LocalstorageValue | undefined>;
  set: (id: string, value: any) => void;
}

export interface LocalstorageValue {
  [key: string]: any;
  showWelcomeModal: boolean;
  countryCode: string;
  changedCountryCode: boolean;
}

const initialLocalstorage: LocalstorageValue = {
  showWelcomeModal: false,
  countryCode: ALL_COUNTRIES_CODE,
  changedCountryCode: false,
};

const LocalstorageContext = createContext<
  [
    LocalstorageValue,
    React.Dispatch<{
      type: LocalStorageAction;
      data: any;
      id: string;
    }>
  ]
>([initialLocalstorage, () => {}]);

export enum LocalStorageAction {
  load = "load",
  update = "update",
  reset = "reset",
}

const reducer = (
  state: LocalstorageValue,
  action: { type: LocalStorageAction; data: any; id: string }
): LocalstorageValue => {
  switch (action.type) {
    case LocalStorageAction.load:
      let storageString = localStorage.getItem(action.id);
      let storage = initialLocalstorage;
      if (storageString) {
        storage = JSON.parse(storageString);
      } else {
        localStorage.setItem(action.id, JSON.stringify(storage));
      }
      return storage;

    case LocalStorageAction.update:
      const sclone = JSON.stringify(state);
      const clone = JSON.parse(sclone);
      clone[action.data.id] = action.data.value;
      localStorage.setItem(action.id, JSON.stringify(clone));
      return clone;

    case LocalStorageAction.reset:
      return initialLocalstorage;
    default:
      throw new Error("Not today");
  }
};

export const LocalstorageProvider = ({
  children,
  id,
}: LocalStorageProviderProps) => {
  const storageState = useReducer(reducer, initialLocalstorage);
  const [, dispatch] = storageState;

  useEffect(() => {
    dispatch({ type: LocalStorageAction.load, id: id, data: undefined });
  }, [dispatch, id]);

  return (
    <LocalstorageContext.Provider value={storageState}>
      {children}
    </LocalstorageContext.Provider>
  );
};

export const useLocalstorageContext = () => {
  return useContext(LocalstorageContext);
};
