import { createContext, ReactNode, useContext } from "react";
import axios_engine from "../../api/engine";
import API from "../../api/endpoint";
import { Followee, Follower } from "./types";
import { PAGE_LIMIT, PropertyContext } from "../property/PropertyContext";

interface IFollowersContext {
  getFollowees: (
    arg0?: string,
    arg1?: number,
    arg2?: number
  ) => Promise<Followee[]>;
  getFollowers: (
    arg0?: string,
    arg1?: number,
    arg2?: number
  ) => Promise<Follower[]>;
  getFollowerCount: (arg0: string) => Promise<number>;
  getFolloweesCount: (arg0: string) => Promise<number>;
  follow: (arg0: number) => Promise<void>;
  unfollow: (arg0: number) => Promise<void>;
}

export const FollowersContext = createContext<IFollowersContext>(
  {} as IFollowersContext
);

interface FollowersProviderProps {
  children: ReactNode;
}

export const FollowersProvider = ({ children }: FollowersProviderProps) => {
  const { getProperties } = useContext(PropertyContext);

  const getFollowees = async (
    username?: string,
    page: number = 0,
    limit: number = PAGE_LIMIT
  ) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${
          API.get_followees
        }?limit=${limit}&offset=${page * limit}${
          username ? `&username=${username}` : ""
        }`
      );

      return res.data.results;
    } catch (error) {}
  };

  const getFollowers = async (
    username?: string,
    page: number = 0,
    limit: number = PAGE_LIMIT
  ) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${
          API.get_followers
        }?limit=${limit}&offset=${page * limit}${
          username ? `&username=${username}` : ""
        }`
      );

      return res.data.results;
    } catch (error) {}
  };

  const getFollowerCount = async (username: string) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_followers}${
          username ? `?username=${username}` : ""
        }`
      );

      return res.data.count;
    } catch (error) {}
  };

  const getFolloweesCount = async (username: string) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.get_followees}${
          username ? `?username=${username}` : ""
        }`
      );

      return res.data.count;
    } catch (error) {}
  };

  const follow = async (userId: number) => {
    try {
      await axios_engine.post(
        `${process.env.REACT_APP_BOHIIO_API}${API.follow}`,
        {
          to_user_id: userId,
        }
      );

      getProperties();
    } catch (error) {
      console.error(error);
    }
  };

  const unfollow = async (userId: number) => {
    try {
      axios_engine.delete(
        `${process.env.REACT_APP_BOHIIO_API}${API.unfollow}`,
        {
          data: { to_user_id: userId },
        }
      );

      getProperties();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FollowersContext.Provider
      value={{
        getFollowees,
        getFollowers,
        follow,
        unfollow,
        getFollowerCount,
        getFolloweesCount,
      }}
    >
      {children}
    </FollowersContext.Provider>
  );
};
