import { useState } from "react";
import styled from "styled-components";
import OptionsModal from "./OptionsModal";
import { Profile } from "../context/profile/types";
import OptionConfirmationCompleteModal from "./OptionConfirmationCompleteModal";

const OptionMenu = styled.div`
  background: white;
  border-radius: 5px;
  text-align: center;
  div {
    border-bottom: 2px solid gray;
    padding: 1rem 4rem;
    cursor: pointer;
  }
  div:last-child {
    font-weight: 700;
    border-bottom: 0px;
  }
`;

interface IReportProfileModalProps {
  profile: Profile;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const ReportProfileModal = ({
  active,
  closeModal,
  darkerBackground,
  profile,
}: IReportProfileModalProps) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const proccessReport = (id: any) => {
    //TODO: actually send a report
    setShowCompleteModal(true);
  };

  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <div className="poppins-bold-16 my-4 px-3 text-center">
          ¿Por qué quieres reportar esta cuenta?
        </div>
        <OptionMenu className="poppins-regular-12">
          <div onClick={() => proccessReport(1)}>
            Publica contenido no inmobiliario
          </div>
          <div onClick={() => proccessReport(2)}>Finge ser otra persona</div>
          <div onClick={() => proccessReport(3)}>
            No es un vendedor legitimo
          </div>
          <div onClick={closeModal}>Cancelar</div>
        </OptionMenu>
      </OptionsModal>
      <OptionConfirmationCompleteModal
        title="Gracias. Recibimos tu reporte."
        msg="Recibirás una notificación una vez que hayamos revisado tu reporte. Gracias por ayudarnos a que bohiio siga siendo una comunidad segura y solidaria."
        show={showCompleteModal}
        confirmText="Cerrar"
        handleClose={() => {
          setShowCompleteModal(false);
          closeModal();
        }}
      />
    </>
  );
};

export default ReportProfileModal;
