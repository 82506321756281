import { Formik, Field, Form } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import Avatar from "./Avatar";
import { ButtonRed } from "../styles/common";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { useContext, useState } from "react";
import ResetPasswordAccountModal from "./ResetPasswordAccountModal";
import BaseShortModal from "./BaseShortModal";
import { AuthContext } from "../context/auth/AuthContext";
import { useHistory } from "react-router-dom";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  width: 100%;
`;

const ProfileForgotPassword = (props: any) => {
  const history = useHistory();
  const { logout, handleAuthentication } = useContext(AuthContext);
  const [tempUser, setTempUser] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const onSuccessForgotPasswordModal = (email: string) => {
    setTempUser(email);
    setShowModal(true);
  };

  const onCloseResetPasswordModal = async () => {
    setShowModal(false);
    try {
      await logout();
      handleAuthentication?.();
      history.push(`/profile/${props.username}`);
    } catch (error) {
      // TODO: add modal here
    }
  };
  return (
    <>
      <div className="d-flex">
        <Avatar src={props.avatar} alt="avatar" />
        <div>
          <p className="poppins-medium-27">
            {props.first_name} {props.last_name}
          </p>
          <p className="poppins-light-14">@{props.username}</p>
        </div>
      </div>
      <ForgotPasswordModal
        closeModal={props.closeModal}
        onSuccess={onSuccessForgotPasswordModal}
      />
      <BaseShortModal active={showModal} closeModal={onCloseResetPasswordModal}>
        <ResetPasswordAccountModal
          email={tempUser}
          closeModal={onCloseResetPasswordModal}
        />
      </BaseShortModal>
    </>
  );
};

export default ProfileForgotPassword;
