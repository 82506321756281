import { ButtonRed } from "../styles/common";
import { BaseModalprops } from "./BaseModal";
import BaseShortModal from "./BaseShortModal";

export default function UpdatedPasswordModal(props: BaseModalprops) {
  return (
    <BaseShortModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="authentication"
    >
      <div style={{ textAlign: "center", padding: "30px 0" }}>
        <h4 style={{ fontWeight: "bold" }} className="mb-4">
          Contraseña actualizada
        </h4>
        <p className="mb-3">La contraseña fue actualizada correctamente</p>
        <ButtonRed
          type="button"
          className="w-100"
          onClick={props.closeModal}
          style={{ borderRadius: "5px" }}
        >
          Aceptar
        </ButtonRed>
      </div>
    </BaseShortModal>
  );
}
