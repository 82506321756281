import { Link } from "react-router-dom";
import styled from "styled-components";
import { Field } from "formik";

const ScrollTable = styled.table`
  border-spacing: 10px 0;
  border-collapse: separate;
`;

const FloatingInput = styled.div`
  & .form-control,
  .form-select {
    padding-top: 1rem !important;
    padding-bottom: 0.15rem !important;
    height: calc(2.6rem) !important;
    font-weight: bold;
  }
  & label {
    padding-top: 0.5rem !important;
  }
`;

const SupplementaryInput = styled.div`
  & .form-control,
  .form-select {
    height: calc(2.4rem) !important;
  }
`;

const TextLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
  }
`;

const FieldStyle = styled(Field)`
  font-weight: bold;
`;

const OnlyWebContainer = styled.label`
  display: none;

  @media screen and (min-width: 768px) {
    display: inherit;
  }
`;

const OnlyMobileContainer = styled.label`
  display: inherit;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const ButtonRed = styled.button<{ inverse?: boolean }>`
  @media screen and (min-width: 992px) {
    padding 0.25rem 2rem;
  }
  border: 1px #d2190e solid;
  background: ${(props) => (props.inverse ? "#f9f9f9" : "#d2190e")};
  color: ${(props) => (props.inverse ? "black" : "white")};
  padding: 0.25rem 1rem;
  border-radius: 5px;
  transition: .2s;
  &:hover{
    background: #b2190e;
    color: white;
  }
`;

export {
  ScrollTable,
  FloatingInput,
  SupplementaryInput,
  TextLink,
  FieldStyle,
  OnlyWebContainer,
  OnlyMobileContainer,
  ButtonRed,
};
