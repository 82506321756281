import certified from "../images/certified.svg";
import MediaViewer from "./MediaViewer";
import { Property } from "../context/property/types";
import styled from "styled-components";
import { useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CertificationStatus } from "../api/property";

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const TopRightTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 40px solid white;
  border-left: 40px solid transparent;
`;

interface IProps {
  property: Property;
  imgSize?: number;
  imgSizeUnit?: "%" | "px";
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
}

const PropertyImageCard = ({
  property,
  imgSize,
  imgSizeUnit,
  objectFit,
}: IProps) => {
  const location = useLocation();
  const history = useHistory();
  const mediaRef = useRef<HTMLVideoElement>(null);
  const openPropertyInfoModal = () => {
    mediaRef.current?.pause();
    goToProperty();
  };
  const goToProperty = () => {
    history.push(`/property/${property.id}`, {
      background: location,
      property: property,
    });
  };
  return (
    <div style={{ height: "100%" }} onClick={openPropertyInfoModal}>
      {property?.certificationStatus === CertificationStatus.VERIFIED && (
        <span
          style={{
            position: "relative",
            zIndex: 1001,
            float: "right",
            marginBottom: "-65px",
          }}
        >
          <TopRightTriangle />
          <Icon
            src={certified}
            alt="certified"
            style={{ marginTop: "-85px", marginLeft: "20px" }}
          />
        </span>
      )}
      <MediaViewer
        className="mh-100 mx-auto"
        media={property.media?.[0]}
        height={imgSize}
        unit={imgSizeUnit}
        videoRef={mediaRef}
        borderRadius="5px"
        alt="property"
        controls={false}
        objectFit="cover"
      />
    </div>
  );
};

export default PropertyImageCard;
