import { Modal, Spinner } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

interface LoadingModalProps {
  type?: "spinner" | "bar";
  percentage?: number;
}

const LoadingModal = ({ type, percentage }: LoadingModalProps) => {
  return (
    <Modal
      size="sm"
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center p-5">
        <h1 className="pb-4">Loading</h1>
        {type === "bar" ? (
          <ProgressBar now={percentage} label={`${percentage}%`} />
        ) : (
          <Spinner
            animation="border"
            variant="danger"
            style={{ width: "5em", height: "5em", borderWidth: "0.5em" }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
