import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import SimpleFooter from "../../components/SimpleFooter";

const NotFound = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-between main-container">
        <Navbar />
        <div className="d-flex" style={{ height: "80vh" }}>
          <div className="offset-1 col-md-4 col-10">
            <p className="poppins-bold-20">Esta página no esta diponible</p>
            <p className="poppins-regular-16">
              Es posible que el enlace que seleccionaste no este funcionando o
              que se haya eliminado la página.{" "}
              <Link to="/" style={{ textDecoration: "none" }}>
                Volver a bohiio
              </Link>
            </p>
          </div>
        </div>
        <SimpleFooter />
      </div>
    </>
  );
};

export default NotFound;
