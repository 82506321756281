import styled from "styled-components";
import avatar from "../images/avatar.svg";
import heart from "../images/heart.svg";
import redHeart from "../images/heart-red.svg";
import message from "../images/comment.svg";
import option from "../images/option.svg";
import star from "../images/star.svg";
import starActiveIcon from "../images/star-active.svg";
import verifiedUser from "../images/verified-user.svg";
import leftIcon from "../images/left.svg";
import rightIcon from "../images/right.svg";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import { formatDate, getPriceNotation } from "../utils";
import { Property } from "../context/property/types";
import MediaViewer from "../components/MediaViewer";
import AuthenticationModal from "./AuthenticationModal";
import { AuthContext } from "../context/auth/AuthContext";
import { PropertyContext } from "../context/property/PropertyContext";
import Avatar from "./Avatar";
import { Profile } from "../context/profile/types";
import { FollowersContext } from "../context/followers/FollowersContext";
import { useHistory, useLocation, Link } from "react-router-dom";
import SharePropertyButton from "./SharePropertyButton";
import { ProfileContext } from "../context/profile/ProfileContext";
import { transformCommentText } from "../utils";
import Comment from "./Comment";
import CertifiedPropertyStrip from "./CertifiedPropertyStrip";
import PropertyOptionsModal from "./PropertyOptionsModal";
import ProfileShortModal from "./ProfileShortModal";
import PropertyCertification from "./PropertyCertification";
import Scroller, { ScrollerItem } from "./Scroller";
import useScreen from "../hooks/useScreen";
import CommentInput from "./CommentInput";
import whatsappIcon from "../images/whatsapp.svg";
import PublishCommentInput, {
  getLastWordInfo,
  replaceLastWord,
} from "./PublishCommentInput";
import { ReplyComment } from "./PropertyInfo";
import UserList from "./UserList";
import TendencyList from "./TendencyList";
import {
  replaceWord,
  useCommentUserHashtagInput,
} from "../hooks/useCustomInput";
import PropertyTag from "./PropertyTag";
import WhatsAppIcon from "../images/WhatsappIcon";
import { WhatsAppButton } from "../pages/profile/Profile";

const Icon = styled.img`
  height: 25px;
  width: 25px;
  border-radius: 50%;
`;

const PropertySlideButton = styled.img<{ isLeft: any }>`
  position: absolute;
  background: white;
  color: grey;
  border: 1px white solid;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  font: 700 2rem/2.4rem Poppins; //poppins-bold-32
  cursor: pointer;
  z-index: 1001;
  top: calc(49% - 15px);
  box-shadow: 0px 1px 1px #00000029;
  ${(props) =>
    (props.isLeft === true &&
      `
    left: 5px;
  `) ||
    `right: 5px;`}
`;

const SmallIcon = styled.img`
  height: 15px;
  width: 15px;
`;

const ButtonRed = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  padding 0.25rem 2rem;
  border-radius: 5px;
`;

const ButtonContactSeller = styled.button`
  background: #ECECEC;
  color: black;
  margin-bottom: 20px;
  margin-top: 10px;
  padding 0.35rem 2rem;
  border-radius: 5px;
  border: none;
`;

const PropertyContainer = styled.div`
  padding: 20px 15px 0 15px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  background-color: white;
`;

const VendorLink = styled.a`
  text-decoration: none;
  color: black;
`;

const ProfileName = styled.a`
  text-decoration: none;
  color: black;
  display: flex;
  align-items: baseline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const CommentInputContainer = styled.div`
  margin-left: 5px;
  flex-grow: 1 !important;
`;
// border-bottom: 1px solid #c7c8c9;

interface DashboardPropertyProps {
  property: Property;
  user: Profile | null;
  onPlayVideo: any;
  updateProperty: (property?: Property) => void;
}

const colorMap: { [key: string]: string } = {
  rent: "#3091E6",
  sell: "#D63A2E",
  both: "#77AF44",
};

const CommentContainer = styled.div`
  padding-left: 15px;
`;

const DashboardProperty = ({
  property,
  user,
  onPlayVideo,
  updateProperty,
}: DashboardPropertyProps) => {
  const location = useLocation();
  const history = useHistory();

  useContext(LanguageContext);
  const { addLike, dislike, addComment, saveProperty, unsaveProperty } =
    useContext(PropertyContext);
  const { follow, unfollow } = useContext(FollowersContext);
  const { profile } = useContext(ProfileContext);

  const { isAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  const [showLogin, setShowLogin] = useState(false);
  const [showPropertyOptions, setShowPropertyOptions] = useState(false);
  const [showProfileShortModal, setShowProfileShortModal] = useState(false);
  const [isPropertyCertificationActive, setIsPropertyCertificationActive] =
    useState(false);
  const [propertySaved, setPropertySaved] = useState(
    property.is_property_saved
  );
  const mediaRef = useRef<HTMLVideoElement>(null);

  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = () => setShowLogin(true);
  const closePropertyOptionsModal = () => setShowPropertyOptions(false);
  const openPropertyOptionsModal = () => setShowPropertyOptions(true);

  const [replyComment, setReplyComment] = useState<ReplyComment>({
    parent: -1,
    comment: undefined,
  });

  const onReplyComment = (reply: ReplyComment) => {
    setReplyComment(reply);
  };

  const [commentText, setCommentTextState] = useState("");
  const idDebounceRef = useRef<number>(-1);
  useEffect(() => {
    return () => {
      clearTimeout(idDebounceRef.current);
    };
  }, []);

  const [lastPositionCursorIndex, setLastPositionCursorIndex] = useState(-1);

  useEffect(() => {
    if (lastPositionCursorIndex === -1) return;

    setLastPositionCursorIndex(-1);
    textInputRef.current.setSelectionRange(
      lastPositionCursorIndex,
      lastPositionCursorIndex
    );
  }, [commentText, lastPositionCursorIndex]);

  const {
    isTriggeredUser,
    isTriggeredHashtag,
    onChange: onChangeUserCommentText,
    resetWord,
    initialWordIndex,
    endWordIndex,
  } = useCommentUserHashtagInput({
    onCloseTrigger: () => {
      setSearchUserText("");
      setSearchTendencyText("");
      clearTimeout(idDebounceRef.current);
    },
  });

  const [searchUserText, setSearchUserText] = useState("");
  const [searchTendencyText, setSearchTendencyText] = useState("");

  const setCommentText = (event: any) => {
    const breakTrigger = " ";
    const text = event.target.value as string;
    setCommentTextState(text);
    onChangeUserCommentText(event);

    if (
      (isTriggeredUser || isTriggeredHashtag) &&
      event.nativeEvent.data !== breakTrigger
    ) {
      clearTimeout(idDebounceRef.current);
      idDebounceRef.current = setTimeout(() => {
        const searchWord = text.substring(initialWordIndex, endWordIndex + 1);
        if (isTriggeredUser) {
          setSearchUserText(searchWord);
        } else {
          setSearchTendencyText(searchWord);
        }
      }, 300) as unknown as number;
    }
  };

  const handleAddComment = async () => {
    try {
      let commentAnidated = undefined;
      if (replyComment.comment) {
        if (replyComment.parent > -1) {
          commentAnidated = replyComment.parent;
        } else {
          commentAnidated = replyComment.comment.id;
        }
      }
      let newComment = await addComment(
        property.id,
        profile?.id as number,
        transformCommentText(commentText),
        commentAnidated
      );
      setCommentTextState("");
      if (property.comment.length === 0) {
        updateProperty({
          ...property,
          comment: [newComment],
          comment_count: property.comment_count + 1,
        });
      }
      onReplyComment({
        comment: undefined,
        parent: -1,
      });
      goToProperty();
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  const openPropertyInfoModal = () => {
    mediaRef.current?.pause();
    goToProperty();
  };

  const goToProperty = () => {
    history.push(`/property/${property.id}`, {
      background: location,
      property: property,
    });
  };

  const textInputRef = useRef<any>(null);
  const focusTextInput = () => {
    textInputRef?.current?.focus();
  };

  const openPropertyCertification = () => {
    setIsPropertyCertificationActive(true);
  };

  const closePropertyCertification = () => {
    setIsPropertyCertificationActive(false);
  };
  const { isMobile } = useScreen();
  const [actualImageIndex, setActualImageIndex] = useState(0);

  const onSlide = (e: any) => {
    const element = e.target;
    if (element.scrollLeft === 0) {
      setActualImageIndex(0);
      return;
    }
    setActualImageIndex(
      Math.floor((element.scrollLeft - 1) / element.offsetWidth) + 1
    );
  };

  return (
    <>
      <PropertyContainer>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div
            className="d-flex"
            style={{ width: "calc(100% - 70px)", overflow: "hidden" }}
          >
            <VendorLink href={"/profile/" + property.owner.username}>
              <Avatar
                className="me-2"
                src={property.owner.profile.avatar ?? avatar}
                alt="avatar"
              />
            </VendorLink>
            <div className="d-flex flex-column">
              <ProfileName href={"/profile/" + property.owner.username}>
                <span className="poppins-bold-24">
                  {property.owner.first_name} {property.owner.last_name}
                </span>
                {property.owner.profile.subscription &&
                  property.owner.profile.subscription.name.toLowerCase() !==
                    "free" && (
                    <SmallIcon
                      className="ms-2"
                      src={verifiedUser}
                      alt="verified"
                    />
                  )}
              </ProfileName>
              <span className="poppins-light-14" style={{ color: "#5D5D5D" }}>
                {property.owner.properties} propiedades
              </span>
            </div>
          </div>
          <div
            className="poppins-semibold-16 clickable"
            style={{
              flexShrink: 0,
              width: "70px",
              textAlign: "end",
            }}
            onClick={isAuthenticated ? () => {} : openLoginModal}
          >
            {!property.owner.myself && (
              <span
                style={{
                  color: property.owner.myself_follow ? "black" : "#D2190E",
                }}
                onClick={async () => {
                  const ownerId = property.owner.id;
                  if (isAuthenticated) {
                    if (property.owner.myself_follow) {
                      setShowProfileShortModal(true);
                    } else {
                      try {
                        await follow(ownerId);
                        updateProperty({
                          ...property,
                          owner: { ...property.owner, myself_follow: true },
                        });
                      } catch (e) {
                        console.error(e);
                      }
                    }
                  } else {
                    openLoginModal();
                  }
                }}
              >
                {property.owner.myself_follow ? "Seguido" : "Seguir+"}
              </span>
            )}
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <span className="poppins-semibold-14 d-flex align-items-baseline mb-2">
            <PropertyTag
              type={property.typePublication}
              text={t(
                `propertyForm.publicationType.${property.typePublication}`
              )}
              size="xs"
            />
          </span>
          <Icon
            className="clickable"
            src={option}
            alt="option"
            onClick={openPropertyOptionsModal}
          />
        </div>
        <div className="d-flex flex-column" style={{ position: "relative" }}>
          <CertifiedPropertyStrip
            property={property}
            openPropertyCertification={openPropertyCertification}
          />
          <span>
            {isMobile ? (
              <div style={{ position: "relative" }}>
                <div
                  className="counter"
                  style={{
                    backgroundColor: "#000000cc",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "1rem",
                    right: "10px",
                    color: "white",
                    fontSize: "14px",
                    zIndex: 1,
                  }}
                >
                  {actualImageIndex + 1} / {property.media?.length}
                </div>
                <Scroller
                  items={property.media.map((m) => {
                    return {
                      id: m.id.toString(),
                      children: (
                        <span
                          className="clickable"
                          onClick={openPropertyInfoModal}
                        >
                          <MediaViewer
                            className="d-lg-none mh-100 mx-auto"
                            media={m}
                            alt="property"
                            objectFit="cover"
                            onPlayFunction={onPlayVideo}
                            videoRef={mediaRef}
                          />
                        </span>
                      ),
                    } as ScrollerItem;
                  })}
                  swapAxys="x"
                  onScroll={onSlide}
                />
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <PropertySlideButton
                  className={`${actualImageIndex === 0 ? "d-none" : ""}`}
                  src={leftIcon}
                  isLeft={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActualImageIndex((pr) => pr - 1);
                  }}
                />
                <PropertySlideButton
                  className={`${
                    actualImageIndex === property.media.length - 1
                      ? "d-none"
                      : ""
                  }`}
                  src={rightIcon}
                  isLeft={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActualImageIndex((pr) => pr + 1);
                  }}
                />
                <div
                  className="counter"
                  style={{
                    backgroundColor: "#000000cc",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "1rem",
                    right: "10px",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  {actualImageIndex + 1} / {property.media?.length}
                </div>
                <span className="clickable" onClick={openPropertyInfoModal}>
                  <MediaViewer
                    alt="property"
                    media={property.media?.[actualImageIndex]}
                    videoRef={mediaRef}
                    onPlayFunction={onPlayVideo}
                    objectFit="cover"
                  />
                </span>
              </div>
            )}
          </span>
          <div
            className="text-end text-white me-3 bebas-neue-regular-38"
            style={{
              marginTop: "-2rem",
              textShadow: "0px 3px 6px #000000",
              zIndex: 1001,
            }}
            onClick={openPropertyInfoModal}
          >
            {["sell", "both"].includes(property.typePublication) &&
              `${property.detail.sellCurrency} $${getPriceNotation(
                property.detail.sellPrice
              )}`}
            {property.typePublication === "rent" &&
              `${property.detail.rentCurrency} $${getPriceNotation(
                property.detail.rentPrice
              )}`}
          </div>
          <div className="d-flex mt-2 mb-2">
            <div className="col-6 poppins-medium-13 d-flex align-items-center">
              {`${property.location.sector},
                ${property.location.province},
                ${property.location.country}
              `}
            </div>
            <div className="ms-auto d-flex work-sans-medium-23">
              <div
                className="mx-1 clickable d-flex align-items-center"
                onClick={isAuthenticated ? () => {} : openLoginModal}
              >
                <Icon
                  src={message}
                  alt="message"
                  onClick={() => {
                    focusTextInput();
                  }}
                />
                {property.comment_count}
              </div>
              <div
                className="mx-1 clickable d-flex align-items-center"
                onClick={isAuthenticated ? () => {} : openLoginModal}
              >
                <Icon
                  src={property.myself_liked ? redHeart : heart}
                  alt="heart"
                  onClick={async () => {
                    if (isAuthenticated) {
                      if (property.myself_liked) {
                        try {
                          await dislike(property.id);
                          updateProperty({
                            ...property,
                            myself_liked: false,
                            likes: property.likes - 1,
                          });
                        } catch (e) {
                          console.error(e);
                        }
                      } else {
                        try {
                          await addLike(property.id);
                          updateProperty({
                            ...property,
                            myself_liked: true,
                            likes: property.likes + 1,
                          });
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    } else {
                      openLoginModal();
                    }
                  }}
                />
                {property.likes}
              </div>
              <div
                className="mx-1 clickable d-flex align-items-center"
                onClick={isAuthenticated ? () => {} : openLoginModal}
              >
                <Icon
                  src={propertySaved ? starActiveIcon : star}
                  alt="star"
                  onClick={() => {
                    if (isAuthenticated && profile?.id) {
                      if (propertySaved) {
                        unsaveProperty(profile.id, property.id);
                        setPropertySaved(false);
                      } else {
                        saveProperty(profile.id, property.id);
                        setPropertySaved(true);
                      }
                    } else {
                      openLoginModal();
                    }
                  }}
                />
              </div>
              <SharePropertyButton
                propertyUrl={`https://bohiio.com/property/${property.id}`}
              />
            </div>
          </div>
          {!property.owner.myself && (
            <div>
              {property.owner.profile.client_phone && (
                <WhatsAppButton
                  className="mt-2 mb-3"
                  onClick={
                    isAuthenticated
                      ? () => {
                          window.open(
                            `//wa.me/${
                              property.owner.profile.client_phone_code.split(
                                "+"
                              )[1]
                            }${
                              property.owner.profile.client_phone
                            }?text=Hola%21%20deseo%20informacion%20de%20esta%20propiedad%20https://bohiio.com/property/${
                              property.id
                            }`
                          );
                        }
                      : openLoginModal
                  }
                >
                  <WhatsAppIcon />
                  WhatsApp
                </WhatsAppButton>
              )}
            </div>
          )}
          <div className="mb-3">
            <div className="d-flex ">
              <Avatar
                src={user?.profile?.avatar ?? avatar}
                size="sm"
                alt="your avatar"
                className="mt-1"
              />
              <CommentInputContainer>
                <PublishCommentInput
                  commentText={commentText}
                  handleAddComment={handleAddComment}
                  isAuthenticated={isAuthenticated}
                  onReplyComment={onReplyComment}
                  replyComment={replyComment}
                  setCommentText={setCommentText}
                  setShowLogin={setShowLogin}
                  ref={textInputRef}
                  children={
                    searchUserText || searchTendencyText ? (
                      <>
                        {searchUserText ? (
                          <UserList
                            onClick={(username) => {
                              const insertedText = "@" + username;
                              setSearchUserText("");
                              resetWord();
                              setCommentTextState(
                                replaceWord(
                                  initialWordIndex - 1,
                                  endWordIndex - 1,
                                  commentText,
                                  insertedText
                                )
                              );
                              setLastPositionCursorIndex(
                                endWordIndex + insertedText.length - 2
                              );
                              focusTextInput();
                            }}
                            search={searchUserText}
                          />
                        ) : null}
                        {searchTendencyText ? (
                          <TendencyList
                            onClick={(hashtag) => {
                              const insertedText = "#" + hashtag;
                              setCommentTextState(
                                replaceWord(
                                  initialWordIndex - 1,
                                  endWordIndex - 1,
                                  commentText,
                                  insertedText
                                )
                              );
                              setSearchTendencyText("");
                              resetWord();
                              setLastPositionCursorIndex(
                                endWordIndex + insertedText.length - 2
                              );
                              focusTextInput();
                            }}
                            search={searchTendencyText}
                          />
                        ) : null}
                      </>
                    ) : null
                  }
                />
              </CommentInputContainer>
            </div>
            {property.comment.length > 0 && (
              <CommentContainer>
                <Comment
                  key={property.comment[0].id}
                  comment={property.comment[0]}
                  setText={setCommentText}
                  setFocus={focusTextInput}
                  property={property}
                  shorten
                  updateComment={(comment) => {
                    updateProperty({ ...property, comment: [comment] });
                  }}
                  handleDelete={() => {
                    updateProperty({
                      ...property,
                      comment: [],
                      comment_count: property.comment_count - 1,
                    });
                  }}
                  onReply={(comment, parent) =>
                    onReplyComment({ comment, parent })
                  }
                />
              </CommentContainer>
            )}
          </div>
          {/* <div className="d-flex justify-content-between">
            <div
              className="poppins-bold-13 clickable"
              onClick={openPropertyInfoModal}
            >
              Ver comentarios
            </div>
            <div className="poppins-medium-14" style={{ color: "#5D5D5D" }}>
              {formatDate(new Date(property.created))}
            </div>
          </div> */}
        </div>
      </PropertyContainer>
      <AuthenticationModal active={showLogin} closeModal={closeLoginModal} />
      <PropertyCertification
        startingModalActive={isPropertyCertificationActive}
        closeStartingModal={closePropertyCertification}
        propertyCertificationInfo={{
          propertyId: property.id,
          isPropertyOwner: property.owner.myself,
        }}
      />
      <PropertyOptionsModal
        active={showPropertyOptions}
        closeModal={closePropertyOptionsModal}
        property={property}
        openCertificationModal={openPropertyCertification}
        deleteProperty={() => {
          updateProperty();
        }}
      />
      <ProfileShortModal
        show={showProfileShortModal}
        user={property.owner}
        propertyNumber={property.owner.properties}
        handleAccept={async () => {
          if (isAuthenticated) {
            try {
              await unfollow(property.owner.id);
              updateProperty({
                ...property,
                owner: { ...property.owner, myself_follow: false },
              });
              setShowProfileShortModal(false);
            } catch (e) {
              console.error(e);
            }
          } else {
            openLoginModal();
          }
        }}
        handleClose={() => {
          setShowProfileShortModal(false);
        }}
      />
    </>
  );
};

export default DashboardProperty;
