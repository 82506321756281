import { useContext, useState, useEffect, useMemo } from "react";
import { NotificationContext } from "../context/notification/NotificationContext";
import { Notification } from "../context/notification/types";
import NotificationComponent from "./Notification";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { TextLink } from "../styles/common";
import paths from "../routes/paths.json";

const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

const NotificationContainer = styled.div<{ background: string }>`
  background: ${(props) => props.background};
  border-radius: 5px;
`;

const NotificationBoard = () => {
  const { getNotifications } = useContext(NotificationContext);
  const { ref, inView } = useInView();
  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    "notifications",
    ({ pageParam = 0 }) => getNotifications(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length;
        return lastPage.length !== 0 ? nextPage : null;
      },
    }
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasNextPage]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notifications = useMemo(() => {
    let res = data?.pages;
    let day = new Date();
    let oldNot: Notification[] = [];
    let newNot: Notification[] = [];
    day.setDate(day.getDate() - 1);
    res?.forEach((page) => {
      page.forEach((notification) => {
        if (new Date(notification.notification_object.created) <= day) {
          oldNot.push(notification);
        } else {
          newNot.push(notification);
        }
      });
    });
    return {
      new: newNot,
      old: oldNot,
    };
  }, [data?.pages]);

  return (
    <>
      <div>
        <div className="mb-5 mt-4 ms-2 me-1">
          {(notifications.old.length > 0 || notifications.new.length > 0) && (
            <>
              {notifications.new.length > 0 && (
                <div className="poppins-semibold-16 mt-md-5 mb-md-3 my-3">
                  Hoy
                </div>
              )}
              {notifications.new.map((notification, index) => {
                return (
                  <NotificationContainer
                    className="py-3 px-1"
                    key={index}
                    background={index % 2 ? "" : "#e9e9e9"}
                  >
                    <NotificationComponent notification={notification} />
                  </NotificationContainer>
                );
              })}
              {notifications.old.length > 0 && (
                <div className="poppins-semibold-16 mt-md-5 mb-md-3 my-3 mt-5">
                  Mas antiguos
                </div>
              )}
              {notifications.old.map((notification, index) => {
                return (
                  <NotificationContainer
                    className="py-3 px-1"
                    key={index}
                    background={index % 2 ? "" : "#e9e9e9"}
                  >
                    <NotificationComponent notification={notification} />
                  </NotificationContainer>
                );
              })}
              <div className="loader" ref={ref}>
                {isFetchingNextPage && hasNextPage
                  ? "Loading..."
                  : "No search left"}
              </div>
            </>
          )}
          {notifications.old.length <= 0 && notifications.new.length <= 0 && (
            <div className="px-3 px-md-none" style={{ marginTop: "220px" }}>
              <div className="text-center">
                <p className="poppins-bold-20">
                  Aún no tienes notificaciones para mostrar
                </p>
                <p className="poppins-medium-16">
                  Comienza a{" "}
                  <TextLink
                    style={{ textDecoration: "underline" }}
                    to={paths.ADDPROPERTY}
                  >
                    publicar
                  </TextLink>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationBoard;
