import { createContext, useState, FC, useEffect } from "react";
import API from "../../api/endpoint";
import axios_engine from "../../api/engine";
import {
  LoginBody,
  ResetPasswordBody,
  SignUpBody,
  DeleteAccountBody,
} from "./types";
import { IpLocation, getUserIpLocation } from "../../api/profile";

interface IPAuthContext {
  isAuthenticated: boolean;
  userLocation?: IpLocation;
  handleAuthentication?: () => void;
  login: (arg0: LoginBody) => Promise<void>;
  signUp: (arg0: SignUpBody) => Promise<void>;
  logout: () => Promise<void>;
  getNewToken: () => Promise<void>;
  forgotPassword: (arg0: string) => Promise<void>;
  resetPassword: (arg0: ResetPasswordBody) => Promise<void>;
  deleteAccount: (arg0: DeleteAccountBody) => Promise<boolean>;
}

export const AuthContext = createContext<IPAuthContext>({
  isAuthenticated: false,
} as IPAuthContext);
export const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userIpLocation, setUserIpLocation] = useState<
    IpLocation | undefined
  >();
  const handleAuthentication = () => {
    const nextValue = !!window.sessionStorage.getItem("access_token");
    setIsAuthenticated(nextValue);
  };

  useEffect(() => {
    getUserIpLocation().then((result) => {
      setUserIpLocation(result.result);
    });
  }, []);

  const login = async (data: LoginBody) => {
    const res = await axios_engine.post(API.login, data);

    if (res.status === 200) {
      window.sessionStorage.setItem("access_token", res.data.access);
    } else {
      throw new Error("login unsuccessful");
    }
  };

  const signUp = async (data: SignUpBody) => {
    const res = await axios_engine.post(API.register, data, {
      withCredentials: true,
    });

    if (res.status !== 200) {
      throw new Error("sign up unsuccessful");
    }
  };

  const logout = async () => {
    try {
      const access_token = window.sessionStorage.getItem("access_token");
      const res = await axios_engine.post(
        API.logout,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.status === 205) {
        window.sessionStorage.removeItem("access_token");
      } else {
        throw new Error("request unsuccessful");
      }
    } catch {
      throw new Error("request unsuccessful");
    }
  };

  const getNewToken = async () => {
    try {
      const res = await axios_engine.post(API.refresh, undefined, {
        withCredentials: true,
      });

      if (res.status === 200) {
        window.sessionStorage.setItem("access_token", res.data.access);
      } else {
        console.error("Bad / expired refresh cookie found");
      }
    } catch {
      console.error("Not refresh cookie found");
    }
  };

  const forgotPassword = async (user: string) => {
    await axios_engine.put(API.forgot_password, { email: user });
  };

  const resetPassword = async (data: ResetPasswordBody) => {
    await axios_engine.post(API.forgot_password, data);
  };

  const deleteAccount = async (data: DeleteAccountBody) => {
    const access_token = window.sessionStorage.getItem("access_token");
    const res = await axios_engine.post(API.delete_account, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (res.status === 200) {
      window.sessionStorage.removeItem("access_token");
      handleAuthentication();
      return true;
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        userLocation: userIpLocation,
        isAuthenticated,
        handleAuthentication,
        login,
        signUp,
        logout,
        getNewToken,
        forgotPassword,
        resetPassword,
        deleteAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
