import styled from "styled-components";
import avatar from "../images/avatar.svg";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { PropertyContext } from "../context/property/PropertyContext";
import paths from "../routes/paths.json";
import { useHistory } from "react-router-dom";
import PropertyImageCard from "./PropertyImageCard";
import PropertyCard from "./PropertyCard";
import PropertyCertification from "./PropertyCertification";
import { ButtonRed } from "../styles/common";
import { useInfiniteQuery } from "react-query";
import { useInView } from "react-intersection-observer";
import {
  ALL_COUNTRIES_CODE,
  useLocalstorageContext,
} from "../context/localstorage/LocalstorageContext";

const PropertyFilter = styled.div<{ selected: boolean }>`
  @media screen and (min-width: 768px) {
    padding: 0.25rem 1rem;
    margin: 0.25rem 1rem;
  }
  padding: 0.25rem 0.25rem;
  margin: 0.25rem 0rem;
  cursor: pointer;
  border-radius: 5px;
  opacity: ${(props) => (props.selected ? 1 : 0.6)};
  background: ${(props) => (props.selected ? "#dedede" : "none")};
`;

const TendencyImg = styled.img`
  @media screen and (min-width: 768px) {
    height: 100px;
    width: 100px;
  }
  height: 75px;
  width: 75px;
  min-height: 75px;
  min-width: 75px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

const Grid = styled.div`
  @media screen and (min-width: 768px) {
    grid-auto-rows: auto;
  }
  display: grid;
  grid-template-columns: calc(33.33% - 0.25em) calc(33.33% - 0.25em) calc(
      33.33% - 0.25em
    );
  gap: 0.25em;
  grid-auto-rows: calc(100vw / 3 - 0.75em);
  padding-bottom: 0.5em;
`;

const GridItem = styled.div<{ big: string }>`
  @media screen and (min-width: 768px) {
    grid-area: inherit;
  }
  grid-area: ${(props) => props.big ?? ""};
`;

const ALL_PROPERTIES = "";
const SELLING_PROPERTIES = "sell";
const RENTING_PROPERTIES = "rent";
const PAGE_SIZE = 18;

const TendencyProperties = (props: any) => {
  const { ref, inView } = useInView();
  const history = useHistory();
  const [storage] = useLocalstorageContext();
  const [propertyScreen, setPropertyScreen] = useState(ALL_PROPERTIES);
  const [photo, setPhoto] = useState<any>({});
  const [tendencyUsage, setTendencyUsage] = useState(0);
  const [currentTendency, setCurrentTendency] = useState("");
  const { getPropertiesByTendency } = useContext(PropertyContext);

  const [isPropertyCertificationActive, setIsPropertyCertificationActive] =
    useState(false);
  const [propertyCertificationInfo, setPropertyCertificationInfo] = useState({
    propertyId: -1,
    isPropertyOwner: true,
  });

  const country =
    storage.countryCode !== ALL_COUNTRIES_CODE ? storage.countryCode : "";

  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      `tendency-${props.tendency}-${propertyScreen}-${country}`,
      ({ pageParam = 0 }) =>
        (async () => {
          let res = await getPropertiesByTendency(
            props.tendency,
            propertyScreen,
            country,
            pageParam,
            PAGE_SIZE
          );
          return res.results;
        })(),
      {
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = allPages.length;
          return Math.floor(tendencyUsage / PAGE_SIZE) >= nextPage
            ? nextPage
            : null;
        },
        staleTime: Infinity,
      }
    );

  const LoadMorePropertiesComponent = useMemo(
    () => (
      <div className="loader" ref={ref}>
        {isFetchingNextPage && hasNextPage ? "Loading..." : "No search left"}
      </div>
    ),
    [hasNextPage, isFetchingNextPage, ref]
  );

  const properties = useMemo(() => {
    let list: any[] = [];
    data?.pages.forEach((page) => {
      list = list.concat(
        ...page.map((property: any, index: number) => {
          return property;
        })
      );
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyScreen, data?.pages]);

  const openPropertyCertification = (
    propertyId: number,
    isPropertyOwner: boolean
  ) => {
    setPropertyCertificationInfo({
      propertyId: propertyId,
      isPropertyOwner: isPropertyOwner,
    });
    setIsPropertyCertificationActive(true);
  };

  const closePropertyCertification = () => {
    setIsPropertyCertificationActive(false);
  };

  const toAllProperties = () => {
    setPropertyScreen(ALL_PROPERTIES);
  };
  const toSellingProperties = () => {
    setPropertyScreen(SELLING_PROPERTIES);
  };
  const toRentingProperties = () => {
    setPropertyScreen(RENTING_PROPERTIES);
  };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const res = await getPropertiesByTendency(
        props.tendency,
        propertyScreen,
        country
      );
      if (currentTendency !== props.tendency && !res.count) {
        history.push(paths.NOTFOUND);
      }
      if (mounted) {
        if (currentTendency !== props.tendency) setTendencyUsage(res.count);
        if (currentTendency !== props.tendency && res.results[0]) {
          setPhoto(
            res.results[0].media?.find((element: any) => {
              return element.type === "IMAGE";
            })
          );
        }
        setCurrentTendency(props.tendency);
      }
    })();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyScreen, props, country]);

  return (
    <>
      <div className="mx-1">
        <div className="d-flex align-items-center ms-3 ms-md-0 mw-100 mt-4 mt-md-none">
          <TendencyImg
            alt="tendecy"
            src={(photo && photo.filename) ?? avatar}
          />
          <div className="d-none d-md-inline my-md-5 w-100">
            <div
              className="poppins-semibold-54"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "15px 0",
              }}
            >
              #{props.tendency}
            </div>
            <div className="mt-2">
              <span className="poppins-bold-26" style={{ color: "#D2190E" }}>
                {tendencyUsage}
              </span>{" "}
              <span className="poppins-light-26">Propiedades</span>
            </div>
          </div>
          <div className="d-md-none" style={{ width: "calc(100% - 75px)" }}>
            <div
              className="poppins-semibold-40"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              #{props.tendency}
            </div>
            <div className="mt-1">
              <span className="poppins-bold-22" style={{ color: "#D2190E" }}>
                {tendencyUsage}
              </span>{" "}
              <span className="poppins-light-22">Propiedades</span>
            </div>
          </div>
        </div>
        <div className="d-flex poppins-medium-14 my-2">
          <PropertyFilter
            selected={propertyScreen === ALL_PROPERTIES}
            onClick={toAllProperties}
          >
            Venta y Alquiler
          </PropertyFilter>
          <PropertyFilter
            selected={propertyScreen === SELLING_PROPERTIES}
            onClick={toSellingProperties}
          >
            Venta
          </PropertyFilter>
          <PropertyFilter
            selected={propertyScreen === RENTING_PROPERTIES}
            onClick={toRentingProperties}
          >
            Alquiler
          </PropertyFilter>
          <div className="ms-auto">
            <span
              onClick={() => history.push(`/map?tendency=${props.tendency}`)}
            >
              <ButtonRed
                className="d-none d-md-inline p-2 px-5 m-0"
                style={{ textDecoration: "none", color: "white" }}
              >
                Ver en el mapa
              </ButtonRed>
              <ButtonRed className="d-md-none btn p-1">Mapa</ButtonRed>
            </span>
          </div>
        </div>
        <Grid>
          {properties.map((property, index) => {
            return (
              <Fragment key={index}>
                <GridItem
                  className="d-md-none"
                  big={
                    index % 18 === 0
                      ? `${(index / 9) * 4 + 1}/1/${(index / 9) * 4 + 3}/3`
                      : index % 18 === 10
                      ? `${((index - 1) / 9) * 4 + 1}/2/${
                          ((index - 1) / 9) * 4 + 3
                        }/4`
                      : ""
                  }
                >
                  <PropertyImageCard
                    imgSize={100}
                    imgSizeUnit="%"
                    property={property}
                  />
                </GridItem>
                <div className="d-none d-md-inline">
                  <PropertyCard
                    property={property}
                    objectFit={"cover"}
                    openPropertyCertification={openPropertyCertification}
                  />
                </div>
              </Fragment>
            );
          })}
          <div>{LoadMorePropertiesComponent}</div>
        </Grid>
      </div>
      <PropertyCertification
        startingModalActive={isPropertyCertificationActive}
        closeStartingModal={closePropertyCertification}
        propertyCertificationInfo={propertyCertificationInfo}
      />
    </>
  );
};

export default TendencyProperties;
