/*
    "apartment",
    "house",
    "villa",
    "pieceOfLand",
    "farm",
    "industrialShip",
    "office",
    "building",
    "penthouse",
    "business",
    "businessPremise",
*/

const dependentFields: any = {
  apartment: {
    landArea: true,
    landAreaUnit: true,
    numberOfFloors: true,
  },
  house: {
    floor: true,
  },
  villa: {
    floor: true,
  },
  pieceOfLand: {
    currentUse: true,
    condition: true,
    constructionArea: true,
    constructionAreaUnit: true,
    floor: true,
    numberOfFloors: true,
    rooms: true,
    bathrooms: true,
    kitchens: true,
    livingRooms: true,
  },
  farm: { floor: true },
  industrialShip: {
    floor: true,
    rooms: true,
    livingRooms: true,
  },
  office: {
    constructionArea: true,
    constructionAreaUnit: true,
    numberOfFloors: true,
    rooms: true,
    livingRooms: true,
  },
  building: {
    floor: true,
    rooms: true,
    bathrooms: true,
    kitchens: true,
    livingRooms: true,
  },
  penthouse: {
    landArea: true,
    landAreaUnit: true,
  },
  business: {
    rooms: true,
    livingRooms: true,
  },
  businessPremise: {
    landArea: true,
    landAreaUnit: true,
    numberOfFloors: true,
    rooms: true,
    livingRooms: true,
  },
  both: {},
  rent: {
    sellingPrice: true,
    sellingCurrency: true,
  },
  sell: {
    rentPrice: true,
    rentCurrency: true,
  },
};

const resetDisabledValue = (values: any, initialValues: any) => {
  Object.keys(dependentFields[values.publicationType] || []).forEach(
    (field) => (values[field] = initialValues[field])
  );
  Object.keys(dependentFields[values.propertyType] || []).forEach(
    (field) => (values[field] = initialValues[field])
  );
};

const disableField = (propertyType: string, fieldName: string) =>
  dependentFields?.[propertyType]?.[fieldName] === true;

export { disableField, resetDisabledValue };
