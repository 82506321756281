import styled from "styled-components";
import certified from "../images/certified.svg";
import chekmarkGreyIcon from "../images/checkmark-white.svg";
import { useState } from "react";
import { Property } from "../context/property/types";
import { CONSTANTS } from "../utils";
const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 5px;
`;

const ToCertify = styled.div`
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  opacity: 0.7;
  z-index: 1001;

  &:hover {
    opacity: 1;
  }
`;

interface IPropsCertifiedPropertyStrip {
  property: Property;
  marginSize?: "small" | "normal" | "big";
  openPropertyCertification: (
    propertyId: number,
    isPropertyOwner: boolean
  ) => void;
}

const CertifiedPropertyStrip = ({
  property,
  marginSize = "normal",
  openPropertyCertification,
}: IPropsCertifiedPropertyStrip) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div
      style={{ position: "absolute", top: 0, zIndex: 1019 }}
      className="text-white"
    >
      {property.certificationStatus === CONSTANTS.WITHOUT_CERTIFICATION &&
        property.owner.myself &&
        property.region === "DO" && (
          <ToCertify
            className={`d-flex py-1 clickable ${
              marginSize === "small" ? "mt-1 ms-1" : ""
            } ${marginSize === "normal" ? "mt-2 ms-2" : ""} ${
              marginSize === "big" ? "mt-3 ms-3" : ""
            }`}
            onClick={() =>
              openPropertyCertification(property.id, property.owner.myself)
            }
          >
            <span className="ms-2 me-1 ">Certificar Propiedad</span>
            <Icon src={certified} alt="certified" />
          </ToCertify>
        )}
      {property.certificationStatus !== CONSTANTS.WITHOUT_CERTIFICATION && (
        <div
          className={`d-flex py-1 clickable ${
            marginSize === "small" ? "mt-1 ms-1" : ""
          } ${marginSize === "normal" ? "mt-2 ms-2" : ""} ${
            marginSize === "big" ? "mt-3 ms-3" : ""
          }`}
          style={{
            backgroundColor: "rgb(0, 0, 0)",
            borderRadius: "10px",
            zIndex: 1001,
            alignItems: "center",
          }}
          onMouseOver={() => setShowMenu(!showMenu)}
          onMouseOut={() => setShowMenu(!showMenu)}
        >
          <span className="ms-2 me-1 ">Propiedad Certificada</span>
          <Icon src={certified} alt="certified" />
        </div>
      )}
      {showMenu && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0,0.8)",
            marginTop: "0.5em",
            borderRadius: "10px",
            padding: "0.5rem 0",
            zIndex: 1001,
          }}
        >
          <div className="d-flex">
            <Icon src={chekmarkGreyIcon} alt="certified" />
            Certificado de Título
          </div>
          <div className="d-flex">
            <Icon src={chekmarkGreyIcon} alt="certified" />
            Registro Complementario
          </div>
          <div className="d-flex">
            <Icon src={chekmarkGreyIcon} alt="certified" />
            Plano Catastral
          </div>
          <div className="d-flex">
            <Icon src={chekmarkGreyIcon} alt="certified" />
            Vista Satelital
          </div>
        </div>
      )}
    </div>
  );
};

export default CertifiedPropertyStrip;
