import { useEffect, useRef, useState } from "react";
import LoginModalForm from "./LoginModalForm";
import RegisterModalForm from "./RegisterModalForm";
import ForgotPasswordModal from "./ForgotPasswordModal";
import BaseModal from "./BaseModal";
import VerifyAccountModal from "./VerifyAccountModal";
import ResetPasswordAccountModal from "./ResetPasswordAccountModal";
import logo from "../images/logo.svg";

const LOGIN_MODAL = 0;
const REGISTER_MODAL = 1;
const FORGOT_PASSWORD_MODAL = 2;
const VERIFY_ACCOUNT = 3;
const RESET_PASSWORD_ACCOUNT = 4;

interface TempUser {
  firstName: string;
  lastName: string;
  email: string;
}

const AuthenticationModal = (props: any) => {
  const [showModal, setShowModal] = useState(LOGIN_MODAL);
  const [tempUser, setTempUser] = useState<TempUser>({
    firstName: "",
    lastName: "",
    email: "",
  });
  const modalSpace = useRef(null);
  const toRegister = () => {
    setShowModal(REGISTER_MODAL);
  };
  const toLogin = () => {
    setShowModal(LOGIN_MODAL);
  };
  const toForgotPassword = () => {
    setShowModal(FORGOT_PASSWORD_MODAL);
  };
  const successSignUp = (user: TempUser) => {
    setTempUser(user);
    setShowModal(VERIFY_ACCOUNT);
  };
  const successResetPassword = (email: string) => {
    setTempUser((prevUser) => ({ ...prevUser, email }));
    setShowModal(RESET_PASSWORD_ACCOUNT);
  };

  useEffect(() => {
    if (props.active) {
      toLogin();
    }
  }, [props.active]);
  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="authentication"
    >
      <div
        className="bg-white px-3 px-md-5 py-4"
        ref={modalSpace}
        style={{
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "10px",
          maxWidth: "380px",
        }}
      >
        {showModal === LOGIN_MODAL && (
          <LoginModalForm
            nextPage={props?.nextPage}
            toRegister={toRegister}
            toForgotPassword={toForgotPassword}
            closeModal={props.closeModal}
          />
        )}
        {showModal === REGISTER_MODAL && (
          <RegisterModalForm
            nextPage={props?.nextPage}
            toLogin={toLogin}
            onClose={successSignUp}
          />
        )}
        {showModal === FORGOT_PASSWORD_MODAL && (
          <>
            <div className="text-center mt-5">
              <img src={logo} alt="logo" className="w-50" />
            </div>
            <ForgotPasswordModal
              closeModal={props.closeModal}
              onSuccess={successResetPassword}
            />
          </>
        )}
        {showModal === VERIFY_ACCOUNT && (
          <VerifyAccountModal
            username={`${tempUser.firstName} ${tempUser.lastName}`}
            closeModal={toLogin}
          />
        )}
        {showModal === RESET_PASSWORD_ACCOUNT && (
          <ResetPasswordAccountModal
            email={`${tempUser.email}`}
            closeModal={toLogin}
          />
        )}
      </div>
    </BaseModal>
  );
};

export default AuthenticationModal;
