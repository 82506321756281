import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import avatar from "../images/avatar.svg";
import plansIcon from "../images/plans.svg";
import plansActiveIcon from "../images/plans-active.svg";
import verificationIcon from "../images/verification.svg";
import verificationActiveIcon from "../images/verification-active.svg";
import configurationIcon from "../images/configuration.svg";
import configurationActiveIcon from "../images/configuration-active.svg";
import paths from "../routes/paths.json";
import { AuthContext } from "../context/auth/AuthContext";
import { useContext, useEffect, useRef } from "react";
import { ProfileContext } from "../context/profile/ProfileContext";

const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

const Menu = styled.div<{ show: boolean }>`
  position: absolute;
  right: 0;
  width: 250px;
  top: 45px;
  z-index: 10120;
  border-radius: 10px;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
  ${(props) => !props.show && `display:none;`}
`;

const MenuItemsDiv = styled.div`
  padding: 0.75em 1em;
  cursor: pointer;
  color: black;
  text-decoration: none;
  height: 45px;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;

const MenuItems = styled(Link)`
  padding: 0.75em 1em;
  cursor: pointer;
  color: black;
  text-decoration: none;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;

const MenuItemsLink = styled.a`
  padding: 0.75em 1em;
  cursor: pointer;
  color: black;
  text-decoration: none;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;

function useOutsideAlerter(ref: any, menu: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (menu.isOpen && ref.current && !ref.current.contains(event.target)) {
        menu.closeMenu();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, menu]);
}

const NavbarMenu = (props: any) => {
  const history = useHistory();
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const { logout, isAuthenticated, handleAuthentication } =
    useContext(AuthContext);
  const { profile } = useContext(ProfileContext);
  const wrapperRef = useRef(null);
  const handleLogout = async () => {
    props.closeMenu();
    await logout();
    history.push("/");
    handleAuthentication?.();
  };

  useOutsideAlerter(wrapperRef, props);

  return (
    <Menu
      className="poppins-regular-14 overflow-hidden"
      show={props.isOpen}
      ref={wrapperRef}
    >
      {isAuthenticated ? (
        <MenuItems
          className="d-flex align-items-center"
          to={paths.USER_PROFILE.replace(":username", profile?.username ?? "")}
        >
          <Icon className="me-2" src={avatar} />
          <span>Mi Perfil</span>
        </MenuItems>
      ) : null}
      <MenuItems className="d-flex align-items-center" to={paths.PAYMENT}>
        <Icon
          className="me-2"
          src={
            location && paths.PAYMENT.includes(location)
              ? plansActiveIcon
              : plansIcon
          }
        />
        <span>{isAuthenticated ? "Mi plan Bohiio" : "Planes de Bohiio"}</span>
      </MenuItems>
      <MenuItemsLink
        className="d-flex align-items-center"
        href={`${process.env.REACT_APP_LANDING_LINK}#update`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Icon className="me-2" src={verificationIcon} />
        <span>Servicios Bohiio</span>
      </MenuItemsLink>
      {isAuthenticated ? (
        <MenuItems
          className="d-flex align-items-center"
          to={paths.PROFILE_CONFIGURATION}
        >
          <Icon
            className="me-2"
            src={
              location &&
              !paths.USER_PROFILE.includes(location) &&
              paths.PROFILE_CONFIGURATION.includes(location)
                ? configurationActiveIcon
                : configurationIcon
            }
          />
          <span>Editar mi perfil</span>
        </MenuItems>
      ) : null}
      {/* <MenuItems className="d-flex align-items-center" to={"/"}>
        <Icon className="me-3" src={teamToolsIcon} />
        <span>Herramientas de equipo</span>
      </MenuItems> */}
      <hr className="m-0" />
      {isAuthenticated ? (
        <MenuItemsDiv
          className="d-flex align-items-center poppins-bold-14"
          onClick={handleLogout}
        >
          Cerrar sesión
        </MenuItemsDiv>
      ) : (
        <MenuItemsDiv
          className="d-flex align-items-center poppins-bold-14"
          onClick={props.openLoginModal}
        >
          Iniciar sesión
        </MenuItemsDiv>
      )}
    </Menu>
  );
};

export default NavbarMenu;
