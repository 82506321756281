import { Media } from "../context/property/types";
import avatar from "../images/avatar.svg";

interface MediaViewerProps {
  media: Media;
  height?: number;
  unit?: "%" | "px";
  className?: string;
  alt?: string;
  controls?: boolean;
  videoRef?: any;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  onPlayFunction?: any;
  borderRadius?: string;
  autoplay?: boolean;
}

const MediaViewer = ({
  media,
  height = 250,
  className = "",
  alt = "",
  unit = "px",
  controls = true,
  videoRef,
  objectFit = "contain",
  onPlayFunction = () => {},
  borderRadius = "0",
  autoplay = true,
}: MediaViewerProps) => {
  const type = media?.type ?? "IMAGE";
  const filename = media?.filename ?? avatar;

  return (
    <div className={className} style={{ height: `${height}${unit}` }}>
      {type === "IMAGE" ? (
        <img
          src={filename}
          alt={alt}
          height="100%"
          style={{ objectFit: objectFit, borderRadius: borderRadius }}
          width="100%"
        />
      ) : (
        <video
          muted
          autoPlay={autoplay}
          width="100%"
          height="100%"
          style={{ objectFit: objectFit }}
          controls={controls}
          ref={videoRef}
          disablePictureInPicture
          controlsList="noplaybackrate nodownload "
          onPlay={() => {
            onPlayFunction(videoRef);
          }}
        >
          <source src={media.filename} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default MediaViewer;
