import { Formik, Field, Form } from "formik";
import styled from "styled-components";

const Divider = styled.hr`
  background-color: black;
  opacity: 1;
`;

const ProfilePrivacy = (props: any) => {
  return (
    <Formik
      initialValues={{
        activityState: false,
        shareProperties: false,
      }}
      onSubmit={() => {}}
    >
      <Form>
        <div>
          <p className="poppins-medium-24">Privacidad de actividad</p>
          <div>
            <Field type="checkbox" name="activityState" />
            Mostrar estado de actividad
          </div>
          <p className="poppins-ligth-14" style={{ color: "rgba(0,0,0,0.5)" }}>
            Permite que las cuentas que sigues y cualquier persona quien envíes
            mensajes vea cuando fue la ultima vez que estuviste activo en la app
            de bohiio.
          </p>
        </div>
        <Divider />
        <div>
          <p className="poppins-medium-24">Compartir propiedades</p>
          <div>
            <Field type="checkbox" name="shareProperties" />
            Permitir compartir propiedades
          </div>
          <p className="poppins-ligth-14" style={{ color: "rgba(0,0,0,0.5)" }}>
            Permite que las personas compartan tus propiedades con otros
            usuarios.
          </p>
        </div>
        <Divider />
      </Form>
    </Formik>
  );
};

export default ProfilePrivacy;
