import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import avatar from "../images/avatar.svg";
import check from "../images/check-grey.svg";
import option from "../images/option.svg";
import leftIcon from "../images/left.svg";
import rightIcon from "../images/right.svg";
import verifiedUser from "../images/verified-user.svg";
import buildAreaIcon from "../images/build-area.svg";
import propertyTypeIcon from "../images/home.svg";
import useIcon from "../images/use.svg";
import landArea from "../images/land-area.svg";
import kitchenIcon from "../images/kitchen.svg";
import parkingIcon from "../images/parking.svg";
import bathroomIcon from "../images/bathroom.svg";
import livinroomIcon from "../images/livingroom.svg";
import bedroomIcon from "../images/bedroom.svg";
import floorIcon from "../images/x-diamond.svg";
import Comment from "./Comment";
import { useTranslation } from "react-i18next";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LanguageContext } from "../context/language/LanguageContext";
import {
  PAGE_LIMIT,
  PropertyContext,
} from "../context/property/PropertyContext";
import { formatDate, getPriceNotation } from "../utils";
import MediaViewer from "./MediaViewer";
import { Property } from "../context/property/types";
import Avatar from "./Avatar";
import { ProfileContext } from "../context/profile/ProfileContext";
import {
  OnlyWebContainer,
  TextLink,
  OnlyMobileContainer,
} from "../styles/common";
import PropertyCertification from "./PropertyCertification";
import AuthenticationModal from "./AuthenticationModal";
import { AuthContext } from "../context/auth/AuthContext";
import { FollowersContext } from "../context/followers/FollowersContext";
import switchIcon from "../images/switch.svg";
import switchActiveIcon from "../images/switch-active.svg";
import MapMarker from "./MapMarker";
import { transformCommentText } from "../utils";
import paths from "../routes/paths.json";
import { Modal } from "react-bootstrap";
import PropertyPhotos from "./PropertyPhoto";
import { useInfiniteQuery } from "react-query";
import { Comment as CommentType } from "../context/property/types";
import Navbar from "./Navbar";
import { PropertyIconBar } from "./PropertyIconBar";
import { NavigationBackButton } from "./NavigationBackButton";
import CertifiedPropertyStrip from "./CertifiedPropertyStrip";
import PropertyOptionsModal from "./PropertyOptionsModal";
import HideLocationConfirmationModal from "./HideLocationConfirmationModal";
import Scroller, { ScrollerItem } from "./Scroller";
import ProfileShortModal from "./ProfileShortModal";
// import useScreen from "../hooks/useScreen";
import BaseMap from "./Map";
import useGeolocation from "../hooks/useGeolocation";
import PublishCommentInput, { replaceLastWord } from "./PublishCommentInput";
import UserList from "./UserList";
import TendencyList from "./TendencyList";
import {
  replaceWord,
  useCommentUserHashtagInput,
} from "../hooks/useCustomInput";
import PropertyTag from "./PropertyTag";
import WhatsAppIcon from "../images/WhatsappIcon";
import { WhatsAppButton } from "../pages/profile/Profile";

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 5px;
`;

const SmallIcon = styled.img`
  height: 15px;
  width: 15px;
`;

const YetToCertifyIcon = styled(Icon)`
  opacity: 0.3;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const ScrollessDiv = styled.div`
  @media screen and (min-width: 768px) {
    scrollbar-width: thin;
    overflow: auto;
  }
`;

const DetailsDiv = styled(ScrollessDiv)<{ showOnMobile?: any }>`
  @media screen and (min-width: 768px) {
    display: inline;
  }
  display: ${(props) => (props.showOnMobile ? "" : "none")};
`;

const CommentsDiv = styled(ScrollessDiv)<{ showOnMobile?: any }>`
  @media screen and (min-width: 768px) {
    display: none;
  }
  display: ${(props) => (props.showOnMobile ? "flex" : "none")};
`;

const PropertyDescription = styled.div`
  border: 1px #030303 solid;
  background: white;
  color: black;
  padding 0.25rem 0.75rem;
  border-radius: 11px;
  font-size: 12px;
`;

const MainContainer = styled.div`
  @media screen and (min-width: 768px) {
    border-radius: 15px;
    height: 100%;
    width: 100%;
  }
`;

const PropertyMainContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  @media screen and (min-width: 768px) {
    min-width: 390px;
    width: 550px;
    padding: 0;
  }
`;

const Button = styled.button`
  border: none;
  background: #ECECEC;
  padding 0.55rem 2rem;
  border-radius: 5px;
`;

const ShowPriceButton = styled.span`
  transition: 0.2s;
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    background-color: #ffafaa;
  }
`;

const WhiteCircle = styled.img`
  height: 30px;
  width: 30px;
  color: grey;
  border: 1px white solid;
  border-radius: 50%;
  text-align: center;
  font: 700 2rem/2.4rem Poppins; //poppins-bold-32
  cursor: pointer;
  &:hover {
    background: white;
  }
`;

const colorMap: { [key: string]: string } = {
  rent: "#3091E6",
  sell: "#D63A2E",
  both: "#77AF44",
};

interface PropertyInfoProps {
  property: Property;
  certified: any;
  zoom: any;
  closeModal?: any;
}

export interface ReplyComment {
  comment: CommentType | undefined;
  parent: number;
}

const PropertyInfo = (props: PropertyInfoProps) => {
  const history = useHistory();

  const { userLocation } = useGeolocation();
  useContext(LanguageContext);
  const { profile } = useContext(ProfileContext);
  const {
    addComment,
    getComments,
    addLike,
    dislike,
    toggleLocationStatus,
    saveProperty,
    unsaveProperty,
  } = useContext(PropertyContext);
  const { isAuthenticated } = useContext(AuthContext);
  const { follow, unfollow } = useContext(FollowersContext);

  const [currentProperty, setCurrentProperty] = useState(props.property);
  // const [propertyCommentsOld, setPropertyComments] = useState<any[]>([]);
  // const [propertyCommentsCount, setPropertyCommentsCount] = useState<any>(0);
  // const [state, updateState] = useState<any>();
  // const { isMobile } = useScreen();
  // const forceUpdate = useCallback(() => updateState({}), []);
  const [replyComment, setReplyComment] = useState<ReplyComment>({
    parent: -1,
    comment: undefined,
  });

  const onReplyComment = (reply: ReplyComment) => {
    setReplyComment(reply);
  };

  if (!currentProperty) {
    history.replace("/error");
  }

  const [showPropertyDetails, setShowPropertyDetails] = useState(true);
  const [commentText, setCommentTextState] = useState("");

  const idDebounceRef = useRef<number>(-1);
  const [searchUserText, setSearchUserText] = useState("");
  const [searchTendencyText, setSearchTendencyText] = useState("");

  const {
    isTriggeredUser,
    isTriggeredHashtag,
    onChange: onChangeUserCommentText,
    resetWord,
    initialWordIndex,
    endWordIndex,
  } = useCommentUserHashtagInput({
    onCloseTrigger: () => {
      setSearchUserText("");
      setSearchTendencyText("");
      clearTimeout(idDebounceRef.current);
    },
  });

  const setCommentText = (event: any) => {
    const breakTrigger = " ";
    const text = event.target.value as string;
    setCommentTextState(text);
    onChangeUserCommentText(event);

    if (
      (isTriggeredUser || isTriggeredHashtag) &&
      event.nativeEvent.data !== breakTrigger
    ) {
      clearTimeout(idDebounceRef.current);
      idDebounceRef.current = setTimeout(() => {
        const searchWord = text.substring(initialWordIndex, endWordIndex + 1);
        if (isTriggeredUser) {
          setSearchUserText(searchWord);
        } else {
          setSearchTendencyText(searchWord);
        }
      }, 300) as unknown as number;
    }
  };

  const {
    data: commentsData,
    isError: isErrorComments,
    isLoading: isLoadingComments,
    hasNextPage: hasNextCommentPage,
    fetchNextPage: fetchNextCommentsPage,
    refetch: refetchComments,
  } = useInfiniteQuery<any>({
    getNextPageParam: (lastPage, allPage) => {
      return allPage.length * PAGE_LIMIT < lastPage.count
        ? allPage.length
        : undefined;
    },
    queryKey: `comments-${props.property.id}`,
    queryFn: (context) => {
      return getComments(props.property.id, context.pageParam);
    },
  });

  const handleAddComment = async () => {
    let commentAnidated = undefined;
    if (replyComment.comment) {
      if (replyComment.parent > -1) {
        commentAnidated = replyComment.parent;
      } else {
        commentAnidated = replyComment.comment.id;
      }
    }
    try {
      await addComment(
        currentProperty.id,
        profile?.id as number,
        transformCommentText(commentText),
        commentAnidated
      );
      onReplyComment({ comment: undefined, parent: -1 });
      setCommentTextState("");
      refetchComments();
      // const newComments = await getComments(currentProperty.id);
      // setPropertyComments(newComments.results);
      // setPropertyCommentsCount(newComments.count);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    (async () => {
      refetchComments();
      // let res = await getComments(currentProperty.id);
      // setPropertyComments(res.results);
      // setPropertyCommentsCount(res.count);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProperty]);

  const propertyComments = useMemo<any>(
    () =>
      commentsData?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page.results];
      }, []) || [],
    [commentsData]
  );

  const propertyCommentsCount = currentProperty.comment_count;

  const showLoadMoreComments = hasNextCommentPage && !isLoadingComments;

  const [propertySaved, setPropertySaved] = useState(
    props.property.is_property_saved
  );

  const handleToggleLocation = async () => {
    setCurrentProperty(await toggleLocationStatus(currentProperty));
  };

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isPropertyCertificationActive, setIsPropertyCertificationActive] =
    useState(false);
  const [selectedPrice, setSelectedPrice] = useState(
    currentProperty.typePublication === "both"
      ? "sell"
      : currentProperty.typePublication
  );

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const openPropertyCertification = () => {
    setIsPropertyCertificationActive(true);
  };

  const closePropertyCertification = () => {
    setIsPropertyCertificationActive(false);
  };

  const handleKeyDown = useCallback(
    (event: any) => {
      if (
        event.key === "ArrowLeft" &&
        textInputDesktopRef.current &&
        !textInputDesktopRef.current.contains(event.target)
      ) {
        setPhotoIndex(
          Math.max(
            0,
            Math.min(currentProperty.media.length - 1, photoIndex - 1)
          )
        );
      }
      if (
        event.key === "ArrowRight" &&
        textInputDesktopRef.current &&
        !textInputDesktopRef.current.contains(event.target)
      ) {
        setPhotoIndex(
          Math.max(
            0,
            Math.min(currentProperty.media.length - 1, photoIndex + 1)
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [photoIndex]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const [showLogin, setShowLogin] = useState(false);
  const [showSharePropertyModal, setShowSharePropertyModal] = useState(false);
  const [showPropertyOptions, setShowPropertyOptions] = useState(false);
  const [showHideLocationModal, setShowHideLocationModal] = useState(false);
  const [showProfileShortModal, setShowProfileShortModal] = useState(false);

  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = () => setShowLogin(true);
  const closePropertyOptionsModal = () => setShowPropertyOptions(false);
  const openPropertyOptionsModal = () => setShowPropertyOptions(true);
  const closeHideLocationModal = () => setShowHideLocationModal(false);
  const openHideLocationModal = () => setShowHideLocationModal(true);

  const [lastPositionCursorIndex, setLastPositionCursorIndex] = useState({
    position: -1,
    input: null as any,
  });

  const textInputMobileRef = useRef<any>(null);
  const focusTextMobileInput = () => {
    textInputMobileRef?.current?.focus();
  };

  const textInputDesktopRef = useRef<any>(null);
  const focusTextDesktopInput = () => {
    textInputDesktopRef?.current?.focus();
  };

  useEffect(() => {
    if (lastPositionCursorIndex.position === -1) return;

    setLastPositionCursorIndex({ ...lastPositionCursorIndex, position: -1 });
    lastPositionCursorIndex.input.current.setSelectionRange(
      lastPositionCursorIndex.position,
      lastPositionCursorIndex.position
    );
  }, [commentText, lastPositionCursorIndex]);

  const seeCommentsButtonRef = useRef<HTMLButtonElement>(null);

  const changeSelectedPrice = () => {
    setSelectedPrice(selectedPrice === "rent" ? "sell" : "rent");
  };

  // manage new likes locally in the component
  const [likedByMyself, setLikedByMyself] = useState(
    currentProperty.myself_liked
  );
  const [localLikes, setLocalLikes] = useState(currentProperty.likes);
  const handleLocalLikes = (value: number) => {
    if (likedByMyself) {
      setLikedByMyself(() => {
        localStorage.setItem("likedByMyself", String(0));
        return false;
      });
    } else {
      setLikedByMyself(() => {
        localStorage.setItem("likedByMyself", String(1));
        return true;
      });
    }

    setLocalLikes(() => {
      localStorage.setItem("localLikes", String(value));
      return value;
    });
  };

  useEffect(() => {
    const prevLocalLikes = localStorage.getItem("localLikes");
    const prevLikedByMyself = localStorage.getItem("likedByMyself");

    if (prevLocalLikes && prevLikedByMyself) {
      setLocalLikes(Number(prevLocalLikes));
      setLikedByMyself(Boolean(Number(prevLikedByMyself)));
    }

    return () => {
      localStorage.removeItem("localLikes");
      localStorage.removeItem("likedByMyself");
      clearTimeout(idDebounceRef.current);
    };
  }, []);

  const onSlide = (e: any) => {
    const element = e.target;
    if (element.scrollLeft === 0) {
      setPhotoIndex(0);
      return;
    }
    setPhotoIndex(Math.floor(element.scrollLeft / element.offsetWidth) + 1);
  };

  return (
    <>
      <Navbar
        className="d-lg-none"
        searchBarToRight
        searchBarOption={
          <NavigationBackButton
            onClick={() => {
              props.closeModal();
            }}
          />
        }
      />

      <MainContainer
        style={{ borderRadius: "10px" }}
        className="bg-white d-flex"
      >
        <ScrollessDiv
          className="d-none d-lg-flex flex-column w-100"
          style={{ height: "100%" }}
        >
          <div
            className="d-flex flex-column"
            style={{
              height: "100%",
              minHeight: "calc(100vh - 90px)",
              position: "relative",
            }}
          >
            <ScrollessDiv style={{ position: "relative", height: "100%" }}>
              {photoIndex > 0 && (
                <WhiteCircle
                  src={leftIcon}
                  style={{
                    zIndex: 1001,
                    top: "calc(50% - 15px)",
                    position: "absolute",
                    left: "5px",
                  }}
                  onClick={() => {
                    setPhotoIndex(
                      Math.max(
                        0,
                        Math.min(
                          currentProperty.media.length - 1,
                          photoIndex - 1
                        )
                      )
                    );
                  }}
                />
              )}
              {photoIndex + 1 < currentProperty.media.length && (
                <WhiteCircle
                  src={rightIcon}
                  style={{
                    zIndex: 1001,
                    top: "calc(50% - 15px)",
                    position: "absolute",
                    right: "5px",
                  }}
                  onClick={() => {
                    setPhotoIndex(
                      Math.max(
                        0,
                        Math.min(
                          currentProperty.media.length - 1,
                          photoIndex + 1
                        )
                      )
                    );
                  }}
                />
              )}
              <div
                className="h-100"
                style={{
                  overflow: "hidden",
                  position: "relative",
                  minHeight: "100%",
                }}
              >
                <div style={{ height: "100%" }} onClick={handleShow}>
                  <MediaViewer
                    unit="%"
                    height={100}
                    media={currentProperty.media[photoIndex]}
                    objectFit="cover"
                  />

                  <div
                    className="counter"
                    style={{
                      backgroundColor: "#000000cc",
                      padding: "2px 6px",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "1rem",
                      right: "10px",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    {photoIndex + 1} / {currentProperty.media.length}
                  </div>
                </div>
                {/*
                {currentProperty.media.map((photo: any, index: any) => {
                  return (
                    <div
                      className="col-12"
                      style={{ height: "100%", borderRadius: "10px" }}
                      key={photo.id}
                      onClick={() => {
                        handleShow(index);
                      }}
                    >
                      {photo.type === "VIDEO" ? (
                        <video
                          muted
                          width="100%"
                          height="100%"
                          style={{ objectFit: "contain" }}
                          controls
                        >
                          <source src={photo.filename} type="video/mp4" />
                        </video>
                      ) : (
                        <Img src={photo.filename} alt={`photo ${index}`} />
                      )}
                    </div>
                  );
                })} */}
              </div>
              <CertifiedPropertyStrip
                property={currentProperty}
                marginSize="big"
                openPropertyCertification={openPropertyCertification}
              />
            </ScrollessDiv>
            <div
              className="text-end text-white me-3 bebas-neue-regular-38"
              style={{
                position: "absolute",
                right: "0",
                bottom: "1.4em",
                textShadow: "0px 3px 6px #000000",
              }}
            >
              {selectedPrice === "rent" &&
                `${currentProperty.detail.rentCurrency} $${getPriceNotation(
                  currentProperty.detail.rentPrice
                )}`}
              {selectedPrice === "sell" &&
                `${currentProperty.detail.sellCurrency} $${getPriceNotation(
                  currentProperty.detail.sellPrice
                )}`}
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2 px-lg-3">
              <div
                className="poppins-semibold-14"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PropertyTag
                  type={currentProperty.typePublication}
                  text={t(
                    `propertyForm.publicationType.${currentProperty.typePublication}`
                  )}
                />
              </div>
              <div style={{ fontSize: "23px" }}>
                <PropertyIconBar
                  className="d-flex justify-content-end gap-2"
                  currentProperty={currentProperty}
                  likedByMyself={likedByMyself}
                  localLikes={localLikes}
                  commentCount={propertyCommentsCount}
                  propertySaved={propertySaved}
                  heartOnClick={() => {
                    if (isAuthenticated) {
                      if (likedByMyself) {
                        dislike(currentProperty.id);
                        handleLocalLikes(localLikes - 1);
                      } else {
                        addLike(currentProperty.id);
                        handleLocalLikes(localLikes + 1);
                      }
                      refetchComments();
                    } else {
                      openLoginModal();
                    }
                  }}
                  commentOnClick={focusTextDesktopInput}
                  starOnClick={() => {
                    if (isAuthenticated && profile?.id) {
                      if (propertySaved) {
                        unsaveProperty(profile.id, currentProperty.id);
                        setPropertySaved(false);
                      } else {
                        saveProperty(profile.id, currentProperty.id);
                        setPropertySaved(true);
                      }
                    } else {
                      openLoginModal();
                    }
                  }}
                  shareOnClick={() => setShowSharePropertyModal(true)}
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column mt-2 px-lg-3 py-lg-2"
            style={{ height: "auto" }}
          >
            <div className="d-flex flex-row gap-1">
              <div className="mt-1">
                <Avatar
                  src={profile?.profile?.avatar ?? avatar}
                  size="sm"
                  alt="avatar"
                />
              </div>
              <PublishCommentInput
                commentText={commentText}
                handleAddComment={handleAddComment}
                isAuthenticated={isAuthenticated}
                onReplyComment={onReplyComment}
                replyComment={replyComment}
                setCommentText={setCommentText}
                setShowLogin={setShowLogin}
                ref={textInputDesktopRef}
                children={
                  searchUserText || searchTendencyText ? (
                    <>
                      {searchUserText ? (
                        <UserList
                          onClick={(username) => {
                            const insertedText = "@" + username;
                            setSearchUserText("");
                            resetWord();
                            setCommentTextState(
                              replaceWord(
                                initialWordIndex - 1,
                                endWordIndex - 1,
                                commentText,
                                insertedText
                              )
                            );
                            setLastPositionCursorIndex({
                              input: textInputDesktopRef,
                              position: endWordIndex + insertedText.length - 2,
                            });
                            focusTextDesktopInput();
                          }}
                          search={searchUserText}
                        />
                      ) : null}
                      {searchTendencyText ? (
                        <TendencyList
                          onClick={(hashtag) => {
                            const insertedText = "#" + hashtag;
                            setCommentTextState(
                              replaceWord(
                                initialWordIndex - 1,
                                endWordIndex - 1,
                                commentText,
                                insertedText
                              )
                            );
                            setSearchTendencyText("");
                            resetWord();
                            setLastPositionCursorIndex({
                              input: textInputDesktopRef,
                              position: endWordIndex + insertedText.length - 2,
                            });
                            focusTextDesktopInput();
                          }}
                          search={searchTendencyText}
                        />
                      ) : null}
                    </>
                  ) : null
                }
              />
            </div>
            <div>
              {propertyComments.map((comment: CommentType, index: number) => {
                return (
                  <div className="mb-1" key={comment.id}>
                    <Comment
                      comment={comment}
                      setText={setCommentText}
                      setFocus={focusTextDesktopInput}
                      property={currentProperty}
                      updateComment={(updatedComment) => {
                        // setPropertyComments([
                        //   ...propertyComments.slice(0, index),
                        //   updatedComment,
                        //   ...propertyComments.slice(index + 1),
                        // ]);
                        refetchComments();
                      }}
                      handleDelete={() => {
                        refetchComments();
                        // forceUpdate();
                      }}
                      // refetchComments={refetch}
                      onReply={(comment, parent) =>
                        onReplyComment({ comment, parent: parent })
                      }
                    />
                  </div>
                );
              })}
              {showLoadMoreComments && !isLoadingComments ? (
                <span
                  onClick={() => fetchNextCommentsPage()}
                  className="clickable"
                  style={{
                    color: "#065fd4",
                  }}
                >
                  Ver mas comentarios
                </span>
              ) : null}
              {isLoadingComments ? "Cargando..." : null}
              {isErrorComments ? (
                <span onClick={() => refetchComments()}>
                  Ocurrio algo inesperado buscando los comentarios, haz click
                  aqui para reintentar
                </span>
              ) : null}
              {/* <div className="loader" ref={ref}>
                {isFetchingNextPage && hasNextPage
                  ? "Loading..."
                  : "No search left"}
              </div> */}
            </div>
            {!propertyComments.length &&
              !isErrorComments &&
              !isLoadingComments && (
                <div className="d-flex flex-column justify-content-center h-100 text-center my-3">
                  <h1 className="poppins-bold-20">
                    Todavia no hay comentarios en esta propiedad
                  </h1>
                  <p className="poppins-regular-14">Inicia la conversacion</p>
                </div>
              )}
          </div>
        </ScrollessDiv>
        <PropertyMainContainer className=" d-flex flex-column py-3 py-lg-3 px-lg-3">
          <div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <Link
                  to={paths.USER_PROFILE.replace(
                    ":username",
                    currentProperty.owner.username
                  )}
                  className="poppins-medium-24"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Avatar
                    src={currentProperty.owner.profile.avatar ?? avatar}
                    alt="author"
                  />
                </Link>
                <div className="d-flex flex-column">
                  <Link
                    to={paths.USER_PROFILE.replace(
                      ":username",
                      currentProperty.owner.username
                    )}
                    className="poppins-bold-24"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <label
                      style={{
                        flexWrap: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <>
                        {currentProperty.owner.first_name}{" "}
                        {currentProperty.owner.last_name}
                      </>
                      {currentProperty.owner.profile.subscription &&
                        currentProperty.owner.profile.subscription.name.toLowerCase() !==
                          "free" && (
                          <SmallIcon
                            className="ms-2 "
                            src={verifiedUser}
                            alt="verified"
                          />
                        )}
                    </label>
                    <div className="poppins-light-15">
                      @{currentProperty.owner.username}
                    </div>
                  </Link>
                </div>
              </div>
              <div
                className="ms-auto poppins-semibold-14 clickable"
                onClick={isAuthenticated ? () => {} : () => setShowLogin(true)}
              >
                {!currentProperty.owner.myself && (
                  <span
                    style={{
                      color: currentProperty.owner.myself_follow
                        ? "black"
                        : "#D2190E",
                    }}
                    onClick={async () => {
                      const ownerId = currentProperty.owner.id;
                      if (isAuthenticated) {
                        if (currentProperty.owner.myself_follow) {
                          setShowProfileShortModal(true);
                        } else {
                          try {
                            await follow(ownerId);
                            setCurrentProperty({
                              ...currentProperty,
                              owner: {
                                ...currentProperty.owner,
                                myself_follow: true,
                              },
                            });
                          } catch (e) {
                            console.error(e);
                          }
                        }
                      } else {
                        setShowLogin(true);
                      }
                    }}
                  >
                    {currentProperty.owner.myself_follow
                      ? "Seguido"
                      : "Seguir+"}
                  </span>
                )}
              </div>
            </div>
            <div
              className="d-none d-lg-block text-end poppins-light-12 w-100"
              style={{ color: "#5D5D5D" }}
            >
              {currentProperty.owner.properties} propiedades
            </div>
            <hr />
            <div className="d-flex poppins-semibold-12 align-items-center mb-2">
              <div className="d-none align-items-center d-lg-flex">
                {currentProperty.owner.myself && (
                  <Icon
                    className="clickable"
                    style={{ width: "25px", height: "15px" }}
                    src={
                      currentProperty.enable_location
                        ? switchActiveIcon
                        : switchIcon
                    }
                    onClick={() => {
                      currentProperty.enable_location
                        ? openHideLocationModal()
                        : handleToggleLocation();
                    }}
                  />
                )}
                <span className="poppins-bold-16">Ubicación</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  className="me-1 poppins-semibold-14 "
                  style={{
                    width: "100%",
                    height: "8px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <OnlyMobileContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PropertyTag
                        type={currentProperty.typePublication}
                        text={t(
                          `propertyForm.publicationType.${currentProperty.typePublication}`
                        )}
                      />
                    </div>
                  </OnlyMobileContainer>
                </div>

                <Icon
                  className="ms-auto clickable"
                  src={option}
                  onClick={openPropertyOptionsModal}
                />
              </div>
            </div>
            <div
              className="d-flex flex-column mb-1"
              style={{ position: "relative" }}
            >
              <OnlyWebContainer>
                {photoIndex > 0 && (
                  <WhiteCircle
                    className="d-lg-none"
                    src={leftIcon}
                    style={{
                      zIndex: 1001,
                      top: "calc(50% - 15px)",
                      position: "absolute",
                      left: "10px",
                    }}
                    onClick={() => {
                      setPhotoIndex(
                        Math.max(
                          0,
                          Math.min(
                            currentProperty.media.length - 1,
                            photoIndex - 1
                          )
                        )
                      );
                    }}
                  />
                )}
                {photoIndex + 1 < currentProperty.media.length && (
                  <WhiteCircle
                    className="d-lg-none"
                    src={rightIcon}
                    style={{
                      zIndex: 1001,
                      marginTop: "30%",
                      position: "absolute",
                      right: 0,
                    }}
                    onClick={() => {
                      setPhotoIndex(
                        Math.max(
                          0,
                          Math.min(
                            currentProperty.media.length - 1,
                            photoIndex + 1
                          )
                        )
                      );
                    }}
                  />
                )}

                <span onClick={handleShow}>
                  <MediaViewer
                    className="d-lg-none mh-100 mx-auto"
                    media={currentProperty.media[photoIndex]}
                    alt="property"
                    objectFit="cover"
                    onPlayFunction={() => {}}
                  />
                </span>
              </OnlyWebContainer>
              <OnlyMobileContainer>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    className="counter"
                    style={{
                      backgroundColor: "#000000cc",
                      padding: "2px 6px",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "1rem",
                      right: "10px",
                      color: "white",
                      fontSize: "14px",
                      zIndex: 1,
                    }}
                  >
                    {photoIndex + 1} / {currentProperty.media.length}
                  </div>
                  <Scroller
                    items={currentProperty.media.map((m) => {
                      return {
                        id: m.id.toString(),
                        children: (
                          <span>
                            <MediaViewer
                              className="d-lg-none mh-100 mx-auto"
                              media={m}
                              alt="property"
                              objectFit="cover"
                              onPlayFunction={() => {}}
                            />
                          </span>
                        ),
                      } as ScrollerItem;
                    })}
                    swapAxys="x"
                    onScroll={onSlide}
                  />
                  <CertifiedPropertyStrip
                    property={currentProperty}
                    marginSize="small"
                    openPropertyCertification={openPropertyCertification}
                  />
                </div>
              </OnlyMobileContainer>

              <div className="d-none d-lg-inline">
                {(currentProperty.owner.myself ||
                  currentProperty.enable_location) && (
                  <div style={{ height: "250px", width: "100%" }}>
                    <BaseMap
                      userLocation={{
                        ...userLocation,
                        zoom: props.zoom,
                        latitude: currentProperty.latitude,
                        longitude: currentProperty.longitude,
                      }}
                    >
                      <MapMarker
                        color={colorMap[currentProperty.typePublication]}
                        locked={true}
                        lat={currentProperty.latitude}
                        lng={currentProperty.longitude}
                      />
                    </BaseMap>
                  </div>
                )}
              </div>
              <div
                className="text-end text-white me-3 bebas-neue-regular-38 d-lg-none"
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  textShadow: "0px 3px 6px #000000",
                  zIndex: 1,
                }}
              >
                {selectedPrice === "sell" &&
                  `${currentProperty.detail.sellCurrency.toLowerCase()} $${getPriceNotation(
                    currentProperty.detail.sellPrice
                  )}`}
                {selectedPrice === "rent" &&
                  `${currentProperty.detail.rentCurrency.toLowerCase()} $${getPriceNotation(
                    currentProperty.detail.rentPrice
                  )}`}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center poppins-medium-12">
              <div style={{ flexBasis: "50%" }} className="poppins-semibold-12">
                {`${currentProperty.location.sector},
                ${currentProperty.location.province},
                ${currentProperty.location.country}`}
              </div>
              <PropertyIconBar
                className="d-flex d-lg-none justify-content-end align-items-center work-sans-medium-23 flex-grow-1 gap-2"
                currentProperty={currentProperty}
                likedByMyself={likedByMyself}
                localLikes={localLikes}
                commentCount={propertyCommentsCount}
                propertySaved={propertySaved}
                commentOnClick={() => {
                  if (showPropertyDetails) {
                    seeCommentsButtonRef?.current?.click();
                  }
                  textInputMobileRef?.current?.focus();
                }}
                heartOnClick={() => {
                  if (isAuthenticated) {
                    if (likedByMyself) {
                      dislike(currentProperty.id);
                      handleLocalLikes(localLikes - 1);
                    } else {
                      addLike(currentProperty.id);
                      handleLocalLikes(localLikes + 1);
                    }
                    refetchComments();
                  } else {
                    openLoginModal();
                  }
                }}
                starOnClick={() => {
                  if (isAuthenticated && profile?.id) {
                    if (propertySaved) {
                      unsaveProperty(profile.id, currentProperty.id);
                      setPropertySaved(false);
                    } else {
                      saveProperty(profile.id, currentProperty.id);
                      setPropertySaved(true);
                    }
                  } else {
                    openLoginModal();
                  }
                }}
                shareOnClick={() => setShowSharePropertyModal(true)}
              />
              <div className="d-none d-lg-inline">
                {formatDate(new Date(currentProperty.general.created))}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2 mt-md-3 mb-md-4">
              {!currentProperty.owner.myself &&
                currentProperty.owner.profile.client_phone && (
                  <WhatsAppButton
                    onClick={
                      isAuthenticated
                        ? () => {
                            window.open(
                              `//wa.me/${
                                currentProperty.owner.profile.client_phone_code.split(
                                  "+"
                                )[1]
                              }${
                                currentProperty.owner.profile.client_phone
                              }?text=Hola%21%20deseo%20informacion%20de%20esta%20propiedad%20https://bohiio.com/property/${
                                currentProperty.id
                              }`
                            );
                          }
                        : openLoginModal
                    }
                  >
                    <WhatsAppIcon />
                    WhatsApp
                  </WhatsAppButton>
                )}
              {/* {props.property.owner.myself && (
                <Button
                  className="poppins-semibold-12"
                  onClick={() => {
                    history.push(`/add-property`, {
                      currentProperty: props.property,
                    });
                  }}
                >
                  Editar Publicación
                </Button>
              )} */}
              {currentProperty.typePublication === "both" && (
                <ShowPriceButton
                  className="clickable poppins-semibold-12 ms-1"
                  style={{ color: "#D2190E" }}
                  onClick={changeSelectedPrice}
                >
                  {t(
                    `propertyInfo.swapPrices.${
                      selectedPrice === "sell" ? "sell" : "rent"
                    }`
                  )}
                </ShowPriceButton>
              )}
            </div>
            <div className="d-lg-none text-end poppins-light-11">
              {formatDate(new Date(currentProperty.general.created))}
            </div>
            <hr className="d-lg-none" />
            <Button
              className="d-lg-none poppins-regular-14 mb-4"
              style={{
                border: "none",
                color: "#FFFFFF",
                width: "100%",
                backgroundColor: "#707070",
                marginBottom: "10px",
              }}
              onClick={() => {
                setShowPropertyDetails(!showPropertyDetails);
              }}
              ref={seeCommentsButtonRef}
            >
              {showPropertyDetails
                ? "Ver comentarios"
                : "Ver características de la propiedad"}
            </Button>
          </div>
          <DetailsDiv showOnMobile={showPropertyDetails}>
            <div className="poppins-regular-14 mb-4">
              <div className="d-flex flex-wrap gap-1">
                {currentProperty.tendencies.map(({ tendency }, index) => {
                  return (
                    <TextLink to={`/tendency/${tendency}`} key={index}>
                      <span style={{ color: "#D2190E" }}>#</span>
                      {tendency}
                    </TextLink>
                  );
                })}
              </div>
            </div>
            <div className="poppins-regular-14">
              <div
                className="d-flex flex-wrap align-items-center pb-3 poppins-regular-14"
                style={{ gap: "5px", justifyContent: "space-between" }}
              >
                <div style={{ maxWidth: "49%", width: "100%" }}>
                  <PropertyTag
                    type="neutral"
                    text={`${t(
                      `propertyForm.propertyType.${currentProperty.general.type}`
                    )}`}
                    justify="flex-start"
                    width="100%"
                    size="noInverse"
                  >
                    <img
                      style={{
                        marginLeft: "9px",
                        width: "20px",
                        marginRight: "2px",
                        height: "20px",
                      }}
                      src={propertyTypeIcon}
                      alt="Tipo de propiedad"
                    />
                  </PropertyTag>
                </div>
                {currentProperty.detail.levelNumber ||
                currentProperty.detail.levelLocation ? (
                  <div style={{ maxWidth: "49%", width: "100%" }}>
                    <PropertyTag
                      type="neutral"
                      text={`${
                        currentProperty.detail.levelNumber
                          ? `${currentProperty.detail.levelNumber} ${t(
                              `propertyForm.floor`
                            )}`
                          : ""
                      } ${
                        currentProperty.detail.levelLocation
                          ? `${currentProperty.detail.levelLocation} ${t(
                              `propertyForm.numberOfFloors`
                            )}`
                          : ""
                      }`}
                      justify="flex-start"
                      width="100%"
                      size="noInverse"
                    >
                      <img
                        style={{
                          marginLeft: "7px",
                          width: "25px",
                          marginRight: "0px",
                          height: "25px",
                        }}
                        src={floorIcon}
                        alt="Piso"
                      />
                    </PropertyTag>
                  </div>
                ) : null}
                {currentProperty.general.use !== "none" && (
                  <div style={{ maxWidth: "49%", width: "100%" }}>
                    <PropertyTag
                      type="neutral"
                      text={t(
                        `propertyForm.currentUse.${currentProperty.general.use}`
                      )}
                      justify="flex-start"
                      width="100%"
                      size="noInverse"
                    >
                      <img
                        style={{
                          marginLeft: "7px",
                          width: "25px",
                          height: "25px",
                        }}
                        src={useIcon}
                        alt="Uso"
                      />
                    </PropertyTag>
                  </div>
                )}
                {currentProperty.detail.terrainMeasure ? (
                  <div style={{ maxWidth: "49%", width: "100%" }}>
                    <PropertyTag
                      type="neutral"
                      text={`${getPriceNotation(
                        currentProperty.detail.terrainMeasure
                      )} ${t(
                        `propertyForm.measureTypes.${currentProperty.detail.terrainUnit}`
                      )}`}
                      justify="flex-start"
                      width="100%"
                      size="noInverse"
                    >
                      <img
                        style={{
                          marginLeft: "7px",
                          width: "25px",
                          height: "25px",
                        }}
                        src={landArea}
                        alt="Área de terreno"
                      />
                    </PropertyTag>
                  </div>
                ) : null}

                {currentProperty.detail.constructionMeasure ? (
                  <div style={{ maxWidth: "49%", width: "100%" }}>
                    <PropertyTag
                      type="neutral"
                      text={`${getPriceNotation(
                        currentProperty.detail.constructionMeasure
                      )}
                      ${t(
                        `propertyForm.measureTypes.${currentProperty.detail.constructionUnit}`
                      )}`}
                      justify="flex-start"
                      width="100%"
                      size="noInverse"
                    >
                      <img
                        style={{
                          marginLeft: "7px",
                          width: "25px",
                          height: "25px",
                        }}
                        src={buildAreaIcon}
                        alt="Área de construcción"
                      />
                    </PropertyTag>
                  </div>
                ) : null}
              </div>
              <div className="d-flex flex-wrap mb-4 gap-2">
                {currentProperty.detail.bedroom ? (
                  <PropertyTag
                    size="square"
                    type="noBk"
                    text={`${Number(currentProperty.detail.bedroom)} ${t(
                      `propertyForm.rooms`
                    )}`}
                  >
                    <img
                      style={{
                        marginLeft: "7px",
                        width: "25px",
                        height: "25px",
                      }}
                      src={bedroomIcon}
                      alt="Habitaciones"
                    />
                  </PropertyTag>
                ) : null}
                {Number(currentProperty.detail.bathroom) !== 0 ? (
                  <PropertyTag
                    size="square"
                    type="noBk"
                    text={`${Number(currentProperty.detail.bathroom)} ${t(
                      `propertyForm.bathrooms`
                    )}`}
                  >
                    <img
                      style={{
                        marginLeft: "7px",
                        width: "25px",
                        height: "25px",
                      }}
                      src={bathroomIcon}
                      alt="Baños"
                    />
                  </PropertyTag>
                ) : null}
                {currentProperty.detail.kitchen ? (
                  <PropertyTag
                    size="square"
                    type="noBk"
                    text={`${Number(currentProperty.detail.kitchen)} ${t(
                      `propertyForm.kitchens`
                    )}`}
                  >
                    <img
                      style={{
                        marginLeft: "7px",
                        width: "25px",
                        height: "25px",
                      }}
                      src={kitchenIcon}
                      alt="Área de construcción"
                    />
                  </PropertyTag>
                ) : null}
                {currentProperty.detail.livingRoom ? (
                  <PropertyTag
                    size="square"
                    type="noBk"
                    text={`${Number(currentProperty.detail.livingRoom)} ${t(
                      `propertyForm.livingRooms`
                    )}`}
                  >
                    <img
                      style={{
                        marginLeft: "7px",
                        width: "25px",
                        height: "25px",
                      }}
                      src={livinroomIcon}
                      alt="Sala"
                    />
                  </PropertyTag>
                ) : null}
                {currentProperty.detail.parkingSlot ? (
                  <PropertyTag
                    size="square"
                    type="noBk"
                    text={`${Number(currentProperty.detail.parkingSlot)} ${t(
                      `propertyForm.parkings`
                    )}`}
                  >
                    <img
                      style={{
                        marginLeft: "7px",
                        width: "25px",
                        height: "25px",
                      }}
                      src={parkingIcon}
                      alt="Aparcamientos"
                    />
                  </PropertyTag>
                ) : null}
              </div>
              {currentProperty.detail.attributes.length ? (
                <span className="poppins-bold-16">
                  Características y áreas comunes
                </span>
              ) : null}
              <div className="d-flex flex-wrap poppins-light-14 align-items-center mb-4 mt-2">
                {currentProperty.detail.attributes.map(
                  (attribute: any, index: any) => {
                    return (
                      <div
                        className="col-6 d-flex align-items-center mb-1"
                        key={index}
                      >
                        <div className="d-flex align-items-center gap-1">
                          <Icon
                            src={check}
                            style={{ margin: 0, width: "16px", height: "16px" }}
                          />
                          {t(`propertyForm.services.${attribute.name}`)}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="d-lg-none">
                <div className="d-flex align-items-center">
                  {currentProperty.owner.myself && (
                    <Icon
                      className="clickable"
                      style={{ width: "25px", height: "15px" }}
                      src={
                        currentProperty.enable_location
                          ? switchActiveIcon
                          : switchIcon
                      }
                      onClick={() => {
                        currentProperty.enable_location
                          ? openHideLocationModal()
                          : handleToggleLocation();
                      }}
                    />
                  )}
                </div>
                {(currentProperty.owner.myself ||
                  currentProperty.enable_location) && (
                  <>
                    <span className="poppins-bold-16">Ubicación</span>
                    <div style={{ height: "250px", width: "100%" }}>
                      <BaseMap
                        userLocation={{
                          ...userLocation,
                          zoom: props.zoom,
                          latitude: currentProperty.latitude,
                          longitude: currentProperty.longitude,
                        }}
                      >
                        <MapMarker
                          color={colorMap[currentProperty.typePublication]}
                          locked={true}
                          lat={currentProperty.latitude}
                          lng={currentProperty.longitude}
                        />
                      </BaseMap>
                    </div>
                  </>
                )}
              </div>
              {/* <button onClick={() => handleDeleteProperty(currentProperty.id)}>
                Delete Property
              </button> */}
            </div>
          </DetailsDiv>
          <CommentsDiv
            className="flex-column mt-2"
            showOnMobile={!showPropertyDetails}
          >
            <div className="d-flex flex-row gap-1">
              <div className="mt-1">
                <Avatar
                  src={profile?.profile?.avatar ?? avatar}
                  size="sm"
                  alt="avatar"
                />
              </div>
              <PublishCommentInput
                commentText={commentText}
                handleAddComment={handleAddComment}
                isAuthenticated={isAuthenticated}
                onReplyComment={onReplyComment}
                replyComment={replyComment}
                setCommentText={setCommentText}
                setShowLogin={setShowLogin}
                ref={textInputMobileRef}
                children={
                  searchUserText || searchTendencyText ? (
                    <>
                      {searchUserText ? (
                        <UserList
                          onClick={(username) => {
                            const insertedText = "@" + username;
                            setSearchUserText("");
                            resetWord();
                            setCommentTextState(
                              replaceWord(
                                initialWordIndex - 1,
                                endWordIndex - 1,
                                commentText,
                                insertedText
                              )
                            );
                            setLastPositionCursorIndex({
                              input: textInputMobileRef,
                              position: endWordIndex + insertedText.length - 2,
                            });
                            focusTextMobileInput();
                          }}
                          search={searchUserText}
                        />
                      ) : null}
                      {searchTendencyText ? (
                        <TendencyList
                          onClick={(hashtag) => {
                            const insertedText = "#" + hashtag;
                            setCommentTextState(
                              replaceWord(
                                initialWordIndex - 1,
                                endWordIndex - 1,
                                commentText,
                                insertedText
                              )
                            );
                            setSearchTendencyText("");
                            resetWord();
                            setLastPositionCursorIndex({
                              input: textInputMobileRef,
                              position: endWordIndex + insertedText.length - 2,
                            });
                            focusTextMobileInput();
                          }}
                          search={searchTendencyText}
                        />
                      ) : null}
                    </>
                  ) : null
                }
              />
            </div>
            <ScrollessDiv>
              {propertyComments.map((comment: CommentType, index: number) => {
                return (
                  <div className="mb-1" key={comment.id}>
                    <Comment
                      comment={comment}
                      setText={setCommentText}
                      setFocus={focusTextMobileInput}
                      property={currentProperty}
                      updateComment={(updatedComment) => {
                        // setPropertyComments([
                        //   ...propertyComments.slice(0, index),
                        //   updatedComment,
                        //   ...propertyComments.slice(index + 1),
                        // ]);
                        refetchComments();
                      }}
                      handleDelete={() => {
                        refetchComments();
                        // forceUpdate();
                      }}
                      // refetchComments={refetch}
                      onReply={(comment, parent) =>
                        onReplyComment({ comment, parent: parent })
                      }
                    />
                  </div>
                );
              })}
              {showLoadMoreComments && !isLoadingComments ? (
                <span
                  onClick={() => fetchNextCommentsPage()}
                  className="clickable"
                >
                  Ver mas comentarios
                </span>
              ) : null}
              {isLoadingComments ? "Cargando..." : null}
              {isErrorComments ? (
                <span onClick={() => refetchComments()}>
                  Ocurrio algo inesperado, click aqui para reintentar
                </span>
              ) : null}
              {/* <div className="loader" ref={ref}>
                {isFetchingNextPage && hasNextPage
                  ? "Loading..."
                  : "No search left"}
              </div> */}
            </ScrollessDiv>
            {!propertyComments.length && (
              <div className="d-flex flex-column justify-content-center h-100 text-center mt-3">
                <h1 className="poppins-bold-20">
                  Todavia no hay comentarios en esta propiedad
                </h1>
                <p className="poppins-regular-14">Inicia la conversacion</p>
              </div>
            )}
          </CommentsDiv>
        </PropertyMainContainer>
      </MainContainer>
      <Modal
        fullscreen={true}
        show={show}
        onHide={handleClose}
        contentClassName="modal-background"
      >
        <Modal.Body>
          <PropertyPhotos
            photos={currentProperty.media}
            index={photoIndex}
            handleClose={handleClose}
            setPhotoIndex={setPhotoIndex}
            showPreviousPhotoIcon={photoIndex > 0}
            showNextPhotoIcon={photoIndex < currentProperty.media.length - 1}
          />
        </Modal.Body>
      </Modal>
      <PropertyCertification
        darkerBackground
        startingModalActive={isPropertyCertificationActive}
        closeStartingModal={closePropertyCertification}
        propertyCertificationInfo={{
          propertyId: currentProperty.id,
          isPropertyOwner: currentProperty.owner.myself,
        }}
      />
      <AuthenticationModal
        darkerBackground
        active={showLogin}
        closeModal={closeLoginModal}
      />
      <PropertyOptionsModal
        darkerBackground
        active={showPropertyOptions}
        closeModal={closePropertyOptionsModal}
        property={currentProperty}
        handleClose={() => {
          props.closeModal();
        }}
        openCertificationModal={openPropertyCertification}
        deleteProperty={() => {
          props.closeModal();
        }}
      />
      <HideLocationConfirmationModal
        darkerBackground
        active={showHideLocationModal}
        closeModal={closeHideLocationModal}
        toggleLocation={handleToggleLocation}
      />
      <ProfileShortModal
        show={showProfileShortModal}
        user={currentProperty.owner}
        propertyNumber={currentProperty.owner.properties}
        darkerBackground={true}
        handleAccept={async () => {
          if (isAuthenticated) {
            try {
              await unfollow(currentProperty.owner.id);
              setCurrentProperty({
                ...currentProperty,
                owner: {
                  ...currentProperty.owner,
                  myself_follow: false,
                },
              });
              setShowProfileShortModal(false);
            } catch (e) {
              console.error(e);
            }
          } else {
            openLoginModal();
          }
        }}
        handleClose={() => {
          setShowProfileShortModal(false);
        }}
      />
    </>
  );
};

PropertyInfo.defaultProps = {
  certified: true,
  zoom: 11,
};

export default PropertyInfo;
