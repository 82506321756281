import { ReactNode, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import crossIcon from "../images/cross-white.svg";

const Icon = styled.img`
  height: 18px;
  width: 18px;
`;

const ModalCloseIconContainer = styled.div`
  position: absolute;
  left: 0;
  top: -30px;
  right: inherit;
  @media screen and (min-width: 992px) {
    right: -25px;
    top: 0;
    left: inherit;
  }
`;

export const ModalCloseIcon = (props: any) => {
  return (
    <ModalCloseIconContainer onClick={props.closeModal}>
      <Icon className="clickable" alt="X" src={crossIcon} />
    </ModalCloseIconContainer>
  );
};

function useOutsideAlerter(ref: any, closeFunction: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeFunction();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

export interface BaseModalprops {
  id?: string;
  children: ReactNode;
  closeModal?: () => any;
  darkerBackground?: boolean;
  active: boolean;
  fullscreen?:
    | true
    | "sm-down"
    | "md-down"
    | "lg-down"
    | "xl-down"
    | "xxl-down"
    | undefined;
  centered?: boolean;
  className?: string;
  useDefaultBackground?: boolean;
}

const BaseModal = ({
  active,
  children = "",
  closeModal = () => {},
  darkerBackground,
  id,
  centered = true,
  fullscreen = true,
  className = "",
  useDefaultBackground = true,
}: BaseModalprops) => {
  const modalSpace = useRef(null);
  useOutsideAlerter(modalSpace, closeModal);
  return (
    <Modal
      contentClassName={`${className} ${
        useDefaultBackground && darkerBackground
          ? "modal-background"
          : "transparent-background"
      } `}
      show={active}
      onHide={closeModal}
      fullscreen={fullscreen}
      centered={centered}
    >
      <Modal.Body style={{ paddingTop: "35px" }} id={id}>
        <div
          style={{ position: "relative" }}
          className="d-flex justify-content-center align-items-center w-100 h-100"
        >
          <div
            style={{ position: "relative", marginBottom: "10px" }}
            className="my-auto"
          >
            <div className="modal-container" ref={modalSpace}>
              {children}
            </div>
            <ModalCloseIcon closeModal={closeModal} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BaseModal;
