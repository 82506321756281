import { useState } from "react";
import { Property } from "../context/property/types";
import DeactivateCommentsCompleteModal from "./DeactivateCommentsCompleteModal";
import OptionsModal from "./OptionsModal";

interface IDeactivateCommentsConfirmationModalProps {
  property: Property;
  active: boolean;
  closeModal: () => void;
  darkerBackground?: boolean;
}

const DeactivateCommentsConfirmationModal = ({
  active,
  closeModal,
  darkerBackground,
  property,
}: IDeactivateCommentsConfirmationModalProps) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  return (
    <>
      <OptionsModal
        active={active}
        closeModal={closeModal}
        darkerBackground={darkerBackground}
      >
        <div className="poppins-regular-12 text-center">
          <div style={{ padding: "0 2rem" }}>
            <p className="poppins-bold-12">
              ¿Quieres desactivar los comentarios?
            </p>
            <p>
              Cuando los comentarios están inhabilitados solo tu podrás realizar
              comentarios en la publicación.
            </p>
          </div>
          <div
            style={{
              borderBottom: "2px solid gray",
              padding: "1rem 4rem",
              cursor: "pointer",
              color: "red",
              fontWeight: 700,
            }}
            onClick={() => {
              //TODO: deactivate comment callback
              setShowCompleteModal(true);
            }}
          >
            Desactivar comentarios
          </div>
          <div
            style={{
              padding: "1rem 4rem",
              cursor: "pointer",
              fontWeight: 700,
            }}
            onClick={closeModal}
          >
            Cancelar
          </div>
        </div>
      </OptionsModal>
      <DeactivateCommentsCompleteModal
        darkerBackground
        active={showCompleteModal}
        closeModal={() => {
          setShowCompleteModal(false);
          closeModal();
        }}
      />
    </>
  );
};

export default DeactivateCommentsConfirmationModal;
