import styled from "styled-components";
import { useHistory } from "react-router-dom";
import paths from "../routes/paths.json";
import RightIcon from "../images/right.svg";
import BaseModal from "./BaseModal";
import {
  LocalStorageAction,
  useLocalstorageContext,
} from "../context/localstorage/LocalstorageContext";
import { LOCAL_STORAGE_NAME } from "../App";

const LinkContainer = styled.div`
  border-bottom: 2px solid black;
  & img {
    position: relative;
    left: 12.5px;
  }
  &:hover img {
    transform: translateX(20%);
  }
`;

const ArrowIcon = styled.img`
  height: 40px;
  width: 40px;
  transition: 0.1s;
`;

const WelcomeModal = (props: any) => {
  const [, dispatch] = useLocalstorageContext();
  const history = useHistory();
  const links = [
    {
      id: "search",
      text: "Buscar una propiedad",
      action: () => {
        history.push(paths.MAP);
      },
    },
    {
      id: "verify",
      text: "Verificar una propiedad",
      action: () => {
        history.push(paths.REQUEST_VERIFICATION);
      },
    },
  ];
  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="welcome-modal"
    >
      <div
        className="bg-white px-1 px-md-5"
        style={{
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "10px",
          maxWidth: "540px",
          padding: "60px 20px",
        }}
      >
        <div className="options px-2">
          {links.map((link, index) => (
            <div
              key={link.id}
              className="clickable"
              style={{
                textDecoration: "none",
                color: "black",
              }}
              onClick={link.action}
            >
              <LinkContainer
                className="relative d-flex justify-content-between align-items-center w-100 poppins-bold-24 pb-4"
                style={{
                  marginBottom: index < links.length - 1 ? "2em" : "",
                }}
              >
                <span>{link.text}</span>
                <ArrowIcon
                  style={{}}
                  className="clickable"
                  alt="X"
                  src={RightIcon}
                />
              </LinkContainer>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center mt-5">
          <label className="poppins-medium-16">
            <input
              name="skip"
              type="checkbox"
              className="me-1"
              onClick={(e) => {
                dispatch({
                  type: LocalStorageAction.update,
                  data: {
                    id: "showWelcomeModal",
                    value: !e.currentTarget.checked,
                  },
                  id: LOCAL_STORAGE_NAME,
                });
              }}
            />
            No volver a mostrar este aviso al inicio
          </label>
        </div>
      </div>
    </BaseModal>
  );
};

export default WelcomeModal;
