import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonRed } from "../styles/common";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  padding: 0.5em 1em;
  border-radius: 5px;
`;

const WhiteButton = styled.button`
  border: 1px white solid;
  background: white;
  color: #d2190e;
  margin: 20px 0px;
  padding: 0.5em 1em;
`;

const DeleteAccountReasons = (props: any) => {
  return (
    <Formik
      initialValues={{
        picked: "",
        opinion: "",
      }}
      onSubmit={(values) => {
        props.deleteAccount(values);
      }}
    >
      {({ values }) => (
        <Form>
          <p className="poppins-semibold-24">
            {props.first_name}, lamentamos que te vayas
          </p>
          <p className="poppins-semibold-16" style={{ color: "#D2190E" }}>
            Nos gustaría saber por qué quieres cerrar tu cuenta:
          </p>
          <div className="mb-2">
            <label className="d-block">
              <Field
                className="me-1"
                type="radio"
                name="picked"
                value="multipleAccounts"
              />
              Tengo dos cuentas.
            </label>
            <label className="d-block">
              <Field
                className="me-1"
                type="radio"
                name="picked"
                value="excessiveEmails"
              />
              Recibo demasiados emails.
              {values.picked === "excessiveEmails" && (
                <div>
                  Quizás tengas la solución en{" "}
                  <Link to="/">preguntas frecuentes</Link>
                </div>
              )}
            </label>
            <label className="d-block">
              <Field
                className="me-1"
                type="radio"
                name="picked"
                value="noBenefitsAsMember"
              />
              No estoy obteniendo ningún beneﬁcio por ser miembro.
            </label>
            <label className="d-block">
              <Field
                className="me-1"
                type="radio"
                name="picked"
                value="privacy"
              />
              Me preocupa la privacidad.
            </label>
            <label className="d-block">
              <Field
                className="me-1"
                type="radio"
                name="picked"
                value="unwantedContacts"
              />
              Me contactan de manera no deseada.
            </label>
            <label className="d-block">
              <Field
                className="me-1"
                type="radio"
                name="picked"
                value="Others"
              />
              Otro
            </label>
          </div>
          <p>
            Valoramos mucho tu opinión, cuéntanos cómo podríamos mejorar nuestro
            servicio en este formulario. Si tienes alguna duda o solicitud sobre
            tu cuenta, envíanos un correo a{" "}
            <a href="mailto:help@bohiio.com">help@bohiio.com</a>
          </p>
          <Field
            name="opinion"
            className="form-control mb-3"
            as="textarea"
            rows="5"
            style={{ lineHeight: "normal" }}
          />
          <div className="poppins-semibold-14 d-flex align-items-center">
            <ButtonRed style={{ height: "40px" }} onClick={props.cancel}>
              Atras
            </ButtonRed>
            {values.picked && (
              <WhiteButton type="submit">Siguiente</WhiteButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DeleteAccountReasons;
