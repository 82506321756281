import axios from "axios";
import { getNewToken, isExpired } from "./index";
const axios_engine = axios.create({
  baseURL: process.env.REACT_APP_BOHIIO_API,
  headers: {
    "Content-Encoding": "gzip",
  },
});

axios_engine.interceptors.request.use(async (req) => {
  const access_token = window.sessionStorage.getItem("access_token");
  if (access_token !== null && typeof access_token === "string") {
    req.headers["Authorization"] = `Bearer ${access_token}`;
    req.withCredentials = true;
  }
  return req;
});

axios_engine.interceptors.response.use(
  (r) => r,
  async (err) => {
    //Unauthorize code
    if (err.response.status === 401) {
      try {
        await getNewToken();
        if (!(await isExpired())) return await axios_engine.request(err.config);
        else window.sessionStorage.removeItem("access_token");
      } catch {
        // TODO: send user to login
        window.sessionStorage.removeItem("access_token");
        return Promise.reject(err);
      }
    }
    return Promise.reject(err);
  }
);

axios_engine.defaults.withCredentials = true;

export default axios_engine;
