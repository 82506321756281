import styled from "styled-components";
import OptionsModal from "./OptionsModal";
import React from "react";
import { ButtonRed } from "../styles/common";
import BaseModal from "./BaseModal";

interface OptionConfirmationCompleteModalProps {
  title: string;
  msg: string;
  confirmText: string;
  show: boolean;
  handleClose: () => void;
  handleComplete?: () => void;
  children?: React.ReactNode;
}

function OptionConfirmationCompleteModal({
  title,
  msg,
  confirmText,
  show,
  handleClose,
  handleComplete = handleClose,
  children,
}: OptionConfirmationCompleteModalProps) {
  return (
    <>
      <BaseModal
        active={show}
        closeModal={handleClose}
        darkerBackground={false}
        id="confirmation-modal"
      >
        <div
          className="row"
          style={{
            width: "100%",
            margin: "auto",
            position: "relative",
            maxWidth: "380px",
          }}
        >
          <div
            className="col-12 bg-white poppins-light-14 p-2"
            style={{
              border: "1px solid rgba(0, 0, 0, 0)",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <div className="poppins-regular-14 text-center px-4 py-5">
              <p className="poppins-bold-16">{title}</p>
              <p>{msg}</p>
              {children}
              <ButtonRed className="mt-2" onClick={handleComplete}>
                {confirmText}
              </ButtonRed>
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
}

export default OptionConfirmationCompleteModal;
