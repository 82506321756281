import GoogleMapReact from "google-map-react";
import React, { useRef, useCallback, useEffect, useState } from "react";
import { haversine_distance } from "../utils/maths";
import {
  updateUrlLocation,
  Location,
  useLocationContext,
  LocationState,
  LocationOrigin,
} from "../context/location/LocationContext";
import { useHistory, useLocation } from "react-router-dom";
import useScreen from "../hooks/useScreen";
import userLocationSvg from "../images/user-location.svg";
import plusSvg from "../images/plus.svg";
import lessSvg from "../images/less.svg";
import loadingGif from "../images/loading.gif";
import "../styles/map-style.css";
import { UserMarker } from "./MapMarker";

export const getMapActualState = (
  search: string,
  mapRef: React.MutableRefObject<any>
) => {
  const url = new URLSearchParams(search);
  if (!mapRef.current) {
    const r = {
      latitude: parseFloat(url.get("latitude") || "0"),
      longitude: parseFloat(url.get("longitude") || "0"),
      distance: parseFloat(url.get("distance") || "1000"),
      limit: parseFloat(url.get("limit") || "100"),
      offset: parseFloat(url.get("offset") || "0"),
      zoom: parseFloat(
        url.get("zoom") || process.env.REACT_APP_DEFAULT_MAP_ZOOM || "9"
      ),
    };
    return r;
  }
  const bo = mapRef.current.getBounds();
  const ne = bo.getNorthEast();
  const se = bo.getCenter();

  const rightTopCoords: Location = {
    latitude: ne.lat() as number,
    longitude: ne.lng() as number,
    distance: 0,
    zoom: 0,
  };

  const centerCoords: Location = {
    latitude: se.lat() as number,
    longitude: se.lng() as number,
    distance: 0,
    zoom: 0,
  };
  return {
    latitude: centerCoords.latitude,
    longitude: centerCoords.longitude,
    distance: haversine_distance(centerCoords, rightTopCoords),
    limit: parseFloat(url.get("limit") || "100"),
    offset: parseFloat(url.get("offset") || "0"),
    zoom: parseFloat(
      url.get("zoom") || process.env.REACT_APP_DEFAULT_MAP_ZOOM || "12"
    ),
  };
};

export enum MapVision {
  Roadmap = "roadmap",
  Hybrid = "hybrid",
  Automatic = "auto",
}

const getZoomControl = (
  onClickZoomIn: () => void,
  onClickZoomOut: () => void
) => {
  const container = document.createElement("div");
  container.classList.add("map-zoom-control-container");

  const zoomInButton = document.createElement("button");
  const zoomInIcon = document.createElement("img");
  zoomInButton.classList.add("map-zoom-control-button");
  zoomInIcon.src = plusSvg;
  zoomInIcon.style.width = "20px";
  zoomInIcon.style.height = "20px";
  zoomInButton.appendChild(zoomInIcon);
  zoomInButton.onclick = onClickZoomIn;

  const line = document.createElement("hr");
  line.classList.add("map-zoom-control-line");

  const zoomOutButton = document.createElement("button");
  const zoomOutIcon = document.createElement("img");
  zoomOutButton.classList.add("map-zoom-control-button");
  zoomOutIcon.src = lessSvg;
  zoomOutIcon.style.width = "20px";
  zoomOutIcon.style.height = "20px";
  zoomOutButton.appendChild(zoomOutIcon);
  zoomOutButton.onclick = onClickZoomOut;

  container.appendChild(zoomInButton);
  container.appendChild(line);
  container.appendChild(zoomOutButton);

  return container;
};

const getCurrentLocationButton = (onClick: () => void) => {
  const container = document.createElement("div");
  const button = document.createElement("button");
  button.classList.add("map-base-button");
  const icon = document.createElement("img");
  icon.src = userLocationSvg;
  icon.style.width = "20px";
  icon.style.height = "20px";
  button.appendChild(icon);
  button.onclick = onClick;

  container.appendChild(button);
  return container;
};

const dropdownOptionsInfo = {
  map: {
    text: "Mapa",
    value: MapVision.Roadmap,
  },
  satellite: {
    text: "Satélite",
    value: MapVision.Hybrid,
  },
  auto: {
    text: "Automatico",
    value: MapVision.Automatic,
  },
};

const getDropdownMapView = (
  onChange: (e: any) => void,
  initialValue: MapVision
) => {
  const container = document.createElement("div");
  const select = document.createElement("select");
  select.classList.add("map-select");

  Object.entries(dropdownOptionsInfo).forEach(([, value]) => {
    const view = document.createElement("option");
    view.textContent = value.text;
    view.value = value.value;
    if (value.value === initialValue) {
      view.selected = true;
    }
    select.appendChild(view);
  });

  select.onchange = onChange;

  container.appendChild(select);
  return container;
};

const getViewBasedOnZoom = (zoom: number) => {
  if (zoom > 17) {
    return MapVision.Hybrid;
  }

  return MapVision.Roadmap;
};

interface BaseMapProps {
  children: React.ReactNode;
  filter?: URLSearchParams;
  userLocation: Location;
  onChildClick?: (hoverKey: any, childProps: any) => void;
  onDragEnd?: (map: any) => void;
  mapVision?: MapVision;
  onLoadMap?: (map: any) => void;
  onUpdateMapZoom?: () => void;
  onClick?: (v: GoogleMapReact.ClickEventValue) => void;
  gestureHandling?: string;
  keyboardShortcuts?: boolean;
  draggableCursor?: string;
  showUserLocationMarker?: boolean;
  upRightElement?: HTMLElement;
  updateUrl?: boolean;
}

export default function BaseMap({
  children,
  filter = new URLSearchParams(),
  userLocation,
  onChildClick = () => {},
  onDragEnd = () => {},
  mapVision = MapVision.Automatic,
  onLoadMap = () => {},
  onClick = () => {},
  draggableCursor = "default",
  keyboardShortcuts = true,
  gestureHandling = "auto",
  showUserLocationMarker = true,
  upRightElement,
  updateUrl = true,
}: BaseMapProps) {
  const history = useHistory();
  const location = useLocation();
  const mapRef = useRef<any>(null);
  const MAP_CONFIG_CONSTANTS = useRef<any>();
  const { isMobile } = useScreen();
  const currentLocation = useLocationContext();
  const currentLocationRef = useRef<LocationState>();
  // console.log("current: ", currentLocation);
  useEffect(() => {
    currentLocationRef.current = currentLocation;
  }, [currentLocation]);
  const [mapVisionState, setMapVisionState] = useState<MapVision>(mapVision);

  const getUrlOfMapAttributes = useCallback(
    (prevFilter: URLSearchParams, search: string) => {
      const url = new URLSearchParams(search);
      prevFilter.forEach((value, key) => {
        url.set(key, value);
      });
      return url;
    },
    []
  );

  const updateMapPosition = useCallback(
    (newLocation?: Location) => {
      if (!updateUrl) {
        return;
      }

      if (!newLocation) {
        const r = getMapActualState(location.search, mapRef);
        newLocation = {
          latitude: r.latitude,
          distance: r.distance,
          longitude: r.longitude,
          zoom: r.zoom,
        };
      }
      const url = getUrlOfMapAttributes(filter, location.search);
      url.set("longitude", newLocation.longitude.toString());
      url.set("latitude", newLocation.latitude.toString());
      updateUrlLocation(history, location, url);
    },
    [filter, getUrlOfMapAttributes, history, location, updateUrl]
  );

  const updateMapZoom = () => {
    if (!mapRef.current) {
      return;
    }

    if (!updateUrl) {
      return;
    }

    const r = getMapActualState(location.search, mapRef);
    const url = getUrlOfMapAttributes(filter, location.search);
    url.set("distance", r.distance.toString());
    url.set("zoom", mapRef.current.getZoom());
    updateUrlLocation(history, location, url);
  };

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (mapVisionState === MapVision.Automatic) {
      mapRef.current.setMapTypeId(getViewBasedOnZoom(mapRef.current.zoom));
    } else {
      mapRef.current.setMapTypeId(mapVisionState);
    }
  }, [mapVisionState]);

  const getControlContainer = (initialState: MapVision) => {
    const container = document.createElement("div");
    container.classList.add("map-container");
    if (currentLocation.origin === LocationOrigin.Device) {
      container.appendChild(
        getCurrentLocationButton(() =>
          updateMapPosition(currentLocationRef.current?.location)
        )
      );
    }

    const mapAndZoomContainer = document.createElement("div");
    mapAndZoomContainer.classList.add("map-zoom-view-container");
    const dropdown = getDropdownMapView((e: any) => {
      setMapVisionState(e.target.value);
    }, mapVision);
    dropdown.nodeValue = initialState;
    mapAndZoomContainer.appendChild(dropdown);

    if (!isMobile) {
      mapAndZoomContainer.appendChild(
        getZoomControl(
          () => {
            if (mapRef.current.zoom < 20) {
              mapRef.current.setZoom(mapRef.current.zoom + 1);
            }
          },
          () => {
            if (mapRef.current.zoom > 3) {
              mapRef.current.setZoom(mapRef.current.zoom - 1);
            }
          }
        )
      );
    }

    container.appendChild(mapAndZoomContainer);
    return container;
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {currentLocation.loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: "rgba(255,255,255,0.4)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <img src={loadingGif} alt="loading" width={70} />
        </div>
      ) : null}

      <GoogleMapReact
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
          onLoadMap(map);
          map.controls[
            MAP_CONFIG_CONSTANTS.current.ControlPosition.RIGHT_BOTTOM
          ].push(getControlContainer(mapVision));

          map.controls[
            MAP_CONFIG_CONSTANTS.current.ControlPosition.RIGHT_TOP
          ].push(upRightElement);

          if (mapVisionState === MapVision.Automatic) {
            mapRef.current.setMapTypeId(getViewBasedOnZoom(map.getZoom()));
          }
        }}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAPS_API_KEY ?? "",
        }}
        defaultCenter={{
          lat: 0,
          lng: 0,
        }}
        center={{
          lat: userLocation.latitude,
          lng: userLocation.longitude,
        }}
        defaultZoom={userLocation.zoom}
        onZoomAnimationEnd={async (zoom) => {
          await updateMapZoom();
          if (mapVisionState === MapVision.Automatic) {
            mapRef.current.setMapTypeId(getViewBasedOnZoom(zoom));
          }
        }}
        onChildClick={onChildClick}
        onDragEnd={(map) => {
          updateMapPosition();
          if (onDragEnd) {
            onDragEnd(map);
          }
        }}
        options={(maps) => {
          MAP_CONFIG_CONSTANTS.current = maps;
          return {
            gestureHandling: gestureHandling,
            keyboardShortcuts: keyboardShortcuts,
            draggableCursor: draggableCursor,
            clickableIcons: false,
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: false,
            controlSize: 30,
            styles: [
              {
                featureType: "poi",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.park",
                stylers: [{ visibility: "on" }],
              },
            ],
          };
        }}
        onClick={(t) => onClick(t)}
      >
        {children}

        {showUserLocationMarker &&
        currentLocation.origin === LocationOrigin.Device ? (
          <UserMarker
            lat={currentLocation.location.latitude}
            lng={currentLocation.location.longitude}
            disableHover={true}
          />
        ) : null}
      </GoogleMapReact>
    </div>
  );
}
