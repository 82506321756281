import API from "./endpoint";
import engine from "./engine";

interface PropertyInterface {
  user_id?: Number;
  username?: String;
  typePublication?: string;
  tendency?: string;
}

const CertificationStatus = {
  WITHOUT: "WITHOUT",
  IN_PROGRESS: "IN_PROGRESS",
  VERIFIED: "CERTIFIED",
};

const getProperty = async (data: PropertyInterface = {}) => {
  const res = await engine.get(
    `${process.env.REACT_APP_BOHIIO_API}${API.get_properties}`,
    {
      params: data,
    }
  );
  if (res.status === 200) {
    return res.data;
  }
  return {};
};

export { getProperty, CertificationStatus };
