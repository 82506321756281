import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { LanguageContext } from "../../context/language/LanguageContext";
import styled from "styled-components";
import paths from "../../routes/paths.json";
import ErrorText from "../../components/ErrorText";
import logo from "../../images/logo.svg";
import eye from "../../images/eye.svg";
import eyeSlash from "../../images/eye-slash.svg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/SimpleFooter";
import { AuthContext } from "../../context/auth/AuthContext";
import { ButtonRed } from "../../styles/common";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  width: 100%;
`;

const Divider = styled.hr`
  background-color: black;
  opacity: 1;
`;

const Link = styled(ReactRouterLink)`
  text-decoration: none;
`;

const Box = styled.div`
  @media screen and (min-width: 768px) {
    border: 2px solid black;
    border-radius: 15px;
    padding: 60px 60px;
    background-color: white;
  }
`;

const SignUp = () => {
  useContext(LanguageContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { signUp } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const [passwordVisibilty, setPasswordVisibility] = useState(false);
  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibilty);
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        username: "",
        phone: "",
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        username: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().required("Required"),
      })}
      onSubmit={async (
        { firstName, lastName, username, phone, email, password },
        FormikBag
      ) => {
        try {
          setShowError(false);
          await signUp({
            username,
            first_name: firstName,
            last_name: lastName,
            email,
            phone_code: "+1",
            phone,
            password,
          });

          history.push(paths.LOGIN);
        } catch (e) {
          setShowError(true);
        }
      }}
    >
      <div className="d-flex flex-column justify-content-between main-container">
        <Navbar />
        <div className="container text-center py-4 poppins-light-12">
          <div className="row justify-content-center">
            <Box className="col-12 col-md-6 col-lg-4">
              <div className="text-center py-5">
                <img src={logo} alt="logo" className="w-50" />
              </div>
              <p className="py-4">
                <Trans i18nKey="signUp.description" />
              </p>
              <Divider className="mt-3 mb-4" />
              <Form>
                <Field
                  name="firstName"
                  type="text"
                  placeholder={t("signUp.firstName")}
                  className="form-control mb-4"
                />
                <ErrorMessage name="firstName">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <Field
                  name="lastName"
                  type="text"
                  placeholder={t("signUp.lastName")}
                  className="form-control mb-4"
                />
                <ErrorMessage name="lastName">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <Field
                  name="username"
                  type="text"
                  placeholder={t("signUp.username")}
                  className="form-control mb-4"
                />
                <ErrorMessage name="username">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <Field
                  name="phone"
                  type="text"
                  placeholder={t("signUp.phone")}
                  className="form-control mb-4"
                />
                <ErrorMessage name="phone">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <Field
                  name="email"
                  type="email"
                  placeholder={t("signUp.email")}
                  className="form-control mb-4"
                />
                <ErrorMessage name="email">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <div className="input-group mb-4">
                  <Field
                    name="password"
                    type={passwordVisibilty ? "text" : "password"}
                    placeholder={t("signUp.password")}
                    className="form-control border-end-0"
                    aria-describedby="password-icon"
                  />
                  <span
                    id="password-icon"
                    className="input-group-text bg-transparent border-start-0"
                    onClick={changePasswordVisibility}
                  >
                    <img
                      src={passwordVisibilty ? eyeSlash : eye}
                      alt="password visibility"
                      className="opacity-50"
                      style={{ height: "25px", width: "25px" }}
                    />
                  </span>
                </div>
                <ErrorMessage name="password">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <div className="d-flex justify-content-center">
                  <ButtonRed
                    type="submit"
                    className="btn poppins-medium-14 w-100 mb-4"
                  >
                    <Trans i18nKey="signUp.submit" />
                  </ButtonRed>
                </div>
              </Form>
              {showError ? (
                <p
                  className="poppins-light-12 text-center"
                  style={{ color: "red" }}
                >
                  El nombre de usuario, correo electrónico o el número de
                  teléfono ya fueron utilizados. Prueba con otros.
                </p>
              ) : null}
              <p className="poppins-light-12">
                <Trans i18nKey="signUp.terms&conditions">
                  Al registrarte, aceptas nuestros
                  <span className="poppins-medium-12">
                    términos y condiciones
                  </span>
                  , la política de los datos y las
                  <span className="poppins-medium-12">
                    políticas de las cookies.
                  </span>
                </Trans>
              </p>
            </Box>
          </div>
          <div className="row justify-content-center">
            <Box className="col-12 col-md-6 col-lg-4 mt-4 px-2 py-4">
              <span className="poppins-light-14">
                <Trans i18nKey="signUp.login">
                  ¿Ya tienes cuenta?
                  <Link
                    to={paths.LOGIN}
                    className="poppins-semibold-14 text-black"
                  >
                    Inicia sesión
                  </Link>
                </Trans>
              </span>
            </Box>
          </div>
        </div>
        <Footer />
      </div>
    </Formik>
  );
};

export default SignUp;
