import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import paths from "../../routes/paths.json";
import ErrorText from "../../components/ErrorText";
import logo from "../../images/logo.svg";
import eye from "../../images/eye.svg";
import eyeSlash from "../../images/eye-slash.svg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/SimpleFooter";
import { useContext, useState } from "react";
import { LanguageContext } from "../../context/language/LanguageContext";
import { AuthContext } from "../../context/auth/AuthContext";
import { ButtonRed } from "../../styles/common";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  width: 100%;
`;

const Link = styled(ReactRouterLink)`
  text-decoration: none;
`;

const Box = styled.div`
  @media screen and (min-width: 768px) {
    border: 2px solid black;
    border-radius: 15px;
    padding: 60px 60px;
    background-color: white;
  }
`;

const Login = () => {
  useContext(LanguageContext);
  const { handleAuthentication, login } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [showError, setShowError] = useState(false);
  const [passwordVisibilty, setPasswordVisibility] = useState(false);
  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibilty);
  };

  return (
    <Formik
      initialValues={{ user: "", password: "" }}
      validationSchema={Yup.object({
        user: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
      })}
      onSubmit={async ({ user, password }, FormikBag) => {
        try {
          setShowError(false);
          await login({ username: user, password });
          handleAuthentication?.();
          history.push(paths.HOME);
        } catch (e) {
          setShowError(true);
        }

        FormikBag.setSubmitting(false);
      }}
    >
      <div className="d-flex flex-column justify-content-md-between main-container">
        <Navbar />
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <Box className="col-12 col-md-6 col-lg-4">
              <div className="text-center py-5">
                <img src={logo} alt="logo" className="w-50" />
              </div>
              <Form>
                <Field
                  name="user"
                  type="text"
                  placeholder={t("login.user")}
                  className="form-control mb-4"
                />
                <ErrorMessage name="user">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <div className="input-group mb-4">
                  <Field
                    name="password"
                    type={passwordVisibilty ? "text" : "password"}
                    placeholder={t("login.password")}
                    className="form-control border-end-0"
                    aria-describedby="password-icon"
                  />
                  <span
                    id="password-icon"
                    className="input-group-text bg-transparent border-start-0"
                    onClick={changePasswordVisibility}
                  >
                    <img
                      src={passwordVisibilty ? eyeSlash : eye}
                      alt="password visibility"
                      className="opacity-50"
                      style={{ height: "25px", width: "25px" }}
                    />
                  </span>
                </div>
                <ErrorMessage name="password">
                  {(msg) => <ErrorText>{msg}</ErrorText>}
                </ErrorMessage>

                <div className="d-flex justify-content-center">
                  <ButtonRed type="submit" className="btn w-100 mb-5">
                    <Trans i18nKey="login.submit" />
                  </ButtonRed>
                </div>
              </Form>
              {showError ? (
                <p
                  className="poppins-light-14 text-center"
                  style={{ color: "red" }}
                >
                  <Trans i18nKey="login.errorMessage">
                    Se ha enviado un enlace de activación a tu correo
                    electrónico. Por favor, revisa tu bandeja de entrada y sigue
                    las instrucciones para activar tu cuenta.
                  </Trans>
                </p>
              ) : null}
              {/* <p className="poppins-semibold-12">
                <Trans i18nKey="login.reset_password" />
              </p> */}
            </Box>
          </div>
          {/* <div className="row justify-content-center">
            <Box className="col-12 col-md-6 col-lg-4 px-2 py-4 mt-4">
              <span className="poppins-light-14">
                <Trans i18nKey="login.signUp">
                  ¿No tienes una cuenta?
                  <Link
                    className="poppins-semibold-14 text-black"
                    to={paths.SIGNUP}
                  >
                    Regístrate
                  </Link>
                </Trans>
              </span>
            </Box>
          </div> */}
        </div>
        <Footer />
      </div>
    </Formik>
  );
};

export default Login;
