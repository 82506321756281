import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CertifyOrderCompleteModal from "./CertifyOrderCompleteModal";
import CertifyPaymentOptionModal from "./CertifyPaymentOptionModal";
import ReceiptModal from "./ReceiptModal";
import paths from "../routes/paths.json";
import { ProfileContext } from "../context/profile/ProfileContext";
import CertificationNotSupportedModal from "./CertificationNotSupportedModal";

const PropertyCertification = (props: any) => {
  const location = useLocation();
  const history = useHistory();
  const { profile } = useContext(ProfileContext);
  const receipt = {
    price: 60,
    discountAmount: 30,
    currency: "USD",
    id: "1",
    receiptDetails:
      "Certificación de la propiedad ubicada en (Dirección de google map)",
    proceedToPay: () => {},
  };
  const [isCertifyOrderCompleteModalOpen, setIsCertifyOrderCompleteModalOpen] =
    useState(false);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [
    isCertificationNotSupportedModalOpen,
    setIsCertificationNotSupportedModalOpen,
  ] = useState(false);
  const [isPropertyOwner, setIsPropertyOwner] = useState(false);
  const [usingCredits, setUsingCredits] = useState(false);

  const openCertifyOrderCompleteModal = (
    propertyOwner: boolean,
    usingCredits: boolean
  ) => {
    props.closeStartingModal();
    setIsPropertyOwner(propertyOwner);
    setUsingCredits(usingCredits);
    setIsCertifyOrderCompleteModalOpen(true);
  };

  const closeCertifyOrderCompleteModal = () => {
    setIsCertifyOrderCompleteModalOpen(false);
    history.push(
      paths.USER_PROFILE.replace(":username", profile?.username ?? "")
    );
  };

  const openReceiptModal = () => {
    setIsReceiptModalOpen(true);
    props.closeStartingModal();
  };

  const closeReceiptModal = () => {
    setIsReceiptModalOpen(false);
    history.push(
      paths.USER_PROFILE.replace(":username", profile?.username ?? "")
    );
  };

  const openCertificationNotSupportedModal = () => {
    setIsCertificationNotSupportedModalOpen(true);
    props.closeStartingModal();
  };

  const closeCertificationNotSupportedModal = () => {
    setIsCertificationNotSupportedModalOpen(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("verification_success")) {
      if (queryParams.get("verification_success") === "True") {
        openCertifyOrderCompleteModal(
          queryParams.get("verification_is_owner") === "True",
          false
        );
        queryParams.delete("verification_success");
        queryParams.delete("verification_is_owner");
      }
      history.replace({ search: queryParams.toString() });
    }
  }, []);

  return (
    <>
      <CertifyPaymentOptionModal
        darkerBackground={props.darkerBackground}
        active={props.startingModalActive}
        closeModal={props.closeStartingModal}
        openOrderCompleteModal={openCertifyOrderCompleteModal}
        openReceiptModal={openReceiptModal}
        openCertificationNotSupportedModal={openCertificationNotSupportedModal}
        propertyCertificationInfo={props.propertyCertificationInfo}
      />
      <CertifyOrderCompleteModal
        darkerBackground={props.darkerBackground}
        active={isCertifyOrderCompleteModalOpen}
        closeModal={closeCertifyOrderCompleteModal}
        isPropertyOwner={isPropertyOwner}
        usingCredits={usingCredits}
      />
      <ReceiptModal
        darkerBackground={props.darkerBackground}
        active={isReceiptModalOpen}
        closeModal={closeReceiptModal}
        isSubscription={false}
        receipt={receipt}
      />
      <CertificationNotSupportedModal
        active={isCertificationNotSupportedModalOpen}
        closeModal={closeCertificationNotSupportedModal}
      />
    </>
  );
};

export default PropertyCertification;
