import { useEffect, useState, useCallback } from "react"

export default function useScreen() {
    const [isMobile, setIsMobile] = useState(false)

    const onResize = useCallback(() => {
        setIsMobile(window.screen.availWidth <= 768)
    }, [setIsMobile])

    useEffect(() => {
        window.addEventListener('resize', onResize)
        onResize()
        return () => {
            window.removeEventListener('resize', onResize)    
        }
    }, [onResize])

    return {
        isMobile
    }
}