import { createContext, ReactNode, useState } from "react";
import axios_engine from "../../api/engine";
import { Place } from "./types";
import API from "../../api/endpoint";

interface ISearchContext {
  places: Place[];
  searchPlaces: (arg0: string) => Promise<void>;
  searchUsers: (arg0: string) => Promise<any>;
  quickSearch: (arg0: string) => Promise<any>;
}

export const SearchContext = createContext<ISearchContext>(
  {} as ISearchContext
);

interface SearchProviderProps {
  children: ReactNode;
}

export async function apiSearchPlaces(name: string) {
  try {
    const res = await axios_engine.get(
      `${process.env.REACT_APP_BOHIIO_API}${API.search_place}?text=${name}`
    );
    return res;
  } catch {
    throw new Error("Couldn't search the place");
  }
}

export const SearchProvider = ({ children }: SearchProviderProps) => {
  const [places, setPlaces] = useState<Place[]>([]);
  const searchPlaces = async (name: string) => {
    const res = await apiSearchPlaces(name);
    setPlaces(res.data ?? []);
  };
  const searchUsers = async (name: string) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.search_user}?search=${name}`
      );
      return res.data ?? [];
    } catch {
      throw new Error("Couldn't search the place");
    }
  };
  const quickSearch = async (name: string) => {
    try {
      const res = await axios_engine.get(
        `${process.env.REACT_APP_BOHIIO_API}${API.quick_search}?search=${name}`
      );
      return res.data ?? [];
    } catch {
      throw new Error("Couldn't make the search");
    }
  };
  return (
    <SearchContext.Provider
      value={{
        places,
        searchPlaces,
        searchUsers,
        quickSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
