import { useState } from "react";
import DeleteAccountConfirmation from "./DeleteAccountConfirmation";
import DeleteAccountReasons from "./DeleteAccountReasons";
import ProfileChangeInfo from "./ProfileChangeInfo";

const PROFILE_INFO = 0;
const DELETE_ACCOUNT_REASONS = 1;
const DELETE_ACCOUNT_CONFIRMATION = 2;

const EditProfile = (props: any) => {
  const [closureReasons, setClosureReasons] = useState({});
  const toProfileInfo = () => {
    props.setScreen(PROFILE_INFO);
  };
  const toDeleteAccountReasons = (reasons: any) => {
    setClosureReasons(reasons);
    props.setScreen(DELETE_ACCOUNT_REASONS);
  };
  const toDeleteAccountConfirmation = () => {
    props.setScreen(DELETE_ACCOUNT_CONFIRMATION);
  };
  return (
    <>
      {props.screen === PROFILE_INFO && (
        <ProfileChangeInfo
          first_name={props.first_name}
          last_name={props.last_name}
          username={props.username}
          phone={props.phone}
          phone_code={props?.phone_code}
          client_phone={props.client_phone}
          client_phone_code={props.client_phone_code}
          email={props.email}
          city={props.city}
          country={props.country}
          biography={props.biography}
          profession={props.profession}
          province={props.province}
          company={props.company}
          webpage={props.webpage}
          avatar={props.avatar}
          language={props?.language}
          deleteAccount={toDeleteAccountReasons}
          handleProfileChangeInfo={props.handleProfileChangeInfo}
          handleProfileImageChange={props.handleProfileImageChange}
        />
      )}
      {props.screen === DELETE_ACCOUNT_REASONS && (
        <DeleteAccountReasons
          first_name={props.first_name}
          deleteAccount={toDeleteAccountConfirmation}
          cancel={toProfileInfo}
        />
      )}
      {props.screen === DELETE_ACCOUNT_CONFIRMATION && (
        <DeleteAccountConfirmation
          first_name={props.first_name}
          last_name={props.last_name}
          username={props.username}
          avatar={props.avatar}
          closureReasons={closureReasons}
          cancel={toProfileInfo}
        />
      )}
    </>
  );
};

export default EditProfile;
