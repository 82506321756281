import styled from "styled-components";
import message from "../images/comment.svg";
import heart from "../images/heart.svg";
import redHeart from "../images/heart-red.svg";
import share from "../images/share.svg";
import star from "../images/star.svg";
import starActiveIcon from "../images/star-active.svg";
import SharePropertyButton from "./SharePropertyButton";

const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

interface IProps {
  className?: string;
  currentProperty: any;
  likedByMyself: any;
  localLikes: any;
  commentCount: any;
  propertySaved: any;
  commentOnClick?: Function;
  heartOnClick?: Function;
  starOnClick?: Function;
  shareOnClick?: Function;
}

export const PropertyIconBar = ({
  className,
  currentProperty,
  likedByMyself,
  localLikes,
  commentCount,
  propertySaved,
  commentOnClick,
  heartOnClick,
  starOnClick,
  shareOnClick,
}: IProps) => {
  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <Icon
          src={message}
          className="clickable"
          alt="message"
          onClick={() => {
            if (commentOnClick) {
              commentOnClick();
            }
          }}
        />
        {commentCount}
      </div>
      <div className="d-flex align-items-center">
        <Icon
          className="clickable"
          src={likedByMyself ? redHeart : heart}
          alt="heart"
          onClick={() => {
            if (heartOnClick) heartOnClick();
          }}
        />
        {localLikes}
      </div>
      <div className="d-flex align-items-center">
        <Icon
          src={propertySaved ? starActiveIcon : star}
          className="clickable"
          alt="star"
          onClick={() => {
            if (starOnClick) starOnClick();
          }}
        />
      </div>
      <SharePropertyButton
        propertyUrl={`https://bohiio.com/property/${currentProperty.id}`}
      />
    </div>
  );
};
