import styled from "styled-components";
import paths from "../routes/paths.json";
import { Link } from "react-router-dom";
import BaseModal from "./BaseModal";

const ButtonRed = styled.button`
  background-color: #d2190e;
  border: 1px solid #d2190e;
  border-radius: 5px;
  color: white;
  padding: 0.5em 0;
  font-weight: bold;
  width: 100%;
  &:hover {
    color: white;
  }
`;

const Button = styled.button`
  border: 1px rgba(0, 0, 0, 0) solid;
  width: 100%;
  background: white;
  color: black;
  margin: 0 auto;
`;

const CertificationNoLocationModal = (props: any) => {
  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="certification-no-location"
    >
      <div
        className="p-5 bg-white poppins-light-14 text-center"
        style={{
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "10px",
          margin: "auto",
          width: "100%",
          maxWidth: "380px",
        }}
      >
        <p className="poppins-semibold-16 mb-4">
          Aún no has marcado la ubicación en el mapa
        </p>
        <p>
          Debes marcar la ubicación del inmueble en el mapa para solicitar la
          verificación.
        </p>
        <p>
          Si no conoces la ubicación exacta y deseas que realicemos la
          verificación utilizando un documento como el{" "}
          <span className="poppins-semibold-14">
            (Certificado de Título, Plano Catastral o los derechos que sustentan
            al mismo)
          </span>{" "}
          puedes marcar una ubicación cercana a la propiedad
        </p>
        <ButtonRed
          className="poppins-semibold-14 mb-3 mt-4"
          onClick={props.closeModal}
        >
          Marcar ubicación
        </ButtonRed>
        <a href={`${process.env.REACT_APP_LANDING_LINK}#update`}>
          <Button className="poppins-semibold-14 mb-3">
            Descartar verificación
          </Button>
        </a>
      </div>
    </BaseModal>
  );
};

export default CertificationNoLocationModal;
