import { useContext } from "react";
import { ProfileContext } from "../context/profile/ProfileContext";
import styled from "styled-components";
import crossIcon from "../images/cross-white.svg";
import bohiioLogo from "../images/logo.svg";
import BaseModal from "./BaseModal";
import { ButtonRed } from "../styles/common";

const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

const ReceiptDiv = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin: 0.25em 0;
  }
`;

const ReceiptModal = (props: any) => {
  const { profile } = useContext(ProfileContext);
  return (
    <BaseModal
      active={props.active}
      closeModal={props.closeModal}
      darkerBackground={props.darkerBackground}
      id="receipt-modal"
    >
      <div style={{ maxWidth: "380px", width: "100%" }}>
        <div className=" w-100" style={{}}>
          <div
            className="bg-white poppins-regular-14 p-5"
            style={{
              border: "1px solid rgba(0, 0, 0, 0)",
              borderRadius: "25px",
            }}
          >
            <div className="text-end">
              <img alt="bohiio" src={bohiioLogo} style={{ width: "30%" }} />
            </div>
            <p className="poppins-light-14">
              {new Date()
                .toJSON()
                .split("T")[0]
                .split("-")
                .reverse()
                .join(" - ")}
            </p>
            <p>
              <div className="poppins-medium-16 d-block">Factura No.</div>
              <div className="poppins-light-14 d-block">{props.receipt.id}</div>
            </p>
            <p className="poppins-medium-14">
              {profile?.first_name} / {profile?.last_name}
            </p>
            <p>
              <div className="poppins-medium-14">Detalle</div>
              <div className="poppins-light-14">
                {props.receipt.receiptDetails}
              </div>
            </p>
            <hr style={{ opacity: 1 }} />
            <ReceiptDiv>
              <div className="d-flex justify-content-between poppins-regular-14">
                <div>Costo regular del servicio</div>
                <div style={{ textDecorationLine: "line-through" }}>
                  {props.receipt.currency} {props.receipt.price.toFixed(2)}
                </div>
              </div>
              <div
                className="d-flex justify-content-between poppins-semibold-14"
                style={{ color: "#D2190E" }}
              >
                <div>
                  Descuento(-
                  {Math.round(
                    (props.receipt.discountAmount / props.receipt.price) * 100
                  )}
                  %)
                </div>
                <div>
                  {props.receipt.currency}{" "}
                  {props.receipt.discountAmount.toFixed(2)}
                </div>
              </div>
              <div className="d-flex justify-content-between poppins-regular-14">
                <div>
                  {props.isSubscription
                    ? "Certificaciones incluidas en el plan"
                    : "Certificaciones Disponibles"}
                </div>
                <div>{props.receipt.certifications ?? 1}</div>
              </div>
            </ReceiptDiv>
            <div className="d-flex justify-content-between poppins-bold-16 mt-3">
              <p>Total</p>
              <p>
                {props.receipt.currency}{" "}
                {(props.receipt.price - props.receipt.discountAmount).toFixed(
                  2
                )}
              </p>
            </div>
            <ButtonRed
              onClick={props.receipt.proceedToPay}
              className="poppins-semibold-14 mt-4 w-100"
            >
              Pagar
            </ButtonRed>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ReceiptModal;
