import googlePlayBanner from "../images/google-play.svg";
import appStoreBanner from "../images/app-store.svg";
import profilePlaceholderImage from "../images/profile-placeholder.png";
import addPropertyIcon from "../images/publish.svg";
import { useState, useEffect, useContext } from "react";
import { VendorContext } from "../context/vendor/VendorContext";
import { FollowersContext } from "../context/followers/FollowersContext";
import { Vendor } from "../context/vendor/types";
import { TextLink } from "../styles/common";
import avatar from "../images/avatar.svg";
import verifiedUser from "../images/verified-user.svg";
import styled from "styled-components";
import paths from "../routes/paths.json";
import ProfileShortModal from "./ProfileShortModal";

const VendorImg = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  @media screen and (min-width: 768px) {
    height: 100px;
    width: 100px;
  }
`;

const CentralIcon = styled.img`
  @media screen and (min-width: 768px) {
    height: 100px;
    width: 100px;
  }
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

const SmallIcon = styled.img`
  height: 15px;
  width: 15px;
`;

const ButtonRed = styled.button`
border: 1px #d2190e solid;
color: black;
margin: 20px 0px;
padding 0.25rem 1.5rem;
border-radius: 5px;
background-color: transparent;
`;

const ButtonPressedRed = styled(ButtonRed)`
  background: #d2190e;
  color: white;
`;

const ProfilePlaceholder = (props: any) => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [showProfileShortModal, setShowProfileShortModal] = useState(false);
  const [vendorToUnfollowIndex, setVendorToUnfollowIndex] = useState(0);
  const { getRecommendedVendors } = useContext(VendorContext);
  const { follow, unfollow } = useContext(FollowersContext);
  useEffect(() => {
    let isMounted = true;
    (async () => {
      let res = await getRecommendedVendors();
      if (isMounted) {
        setVendors(res.slice(0, 5));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <div className="my-5">
        <div className="text-center">
          <CentralIcon src={addPropertyIcon} alt="add" className="p-2 p-md-3" />
        </div>
        {!props.myself && (
          <p className="poppins-regular-28 text-center my-3">
            Aún no hay propiedades publicadas
          </p>
        )}
        {props.myself && (
          <>
            <p className="poppins-regular-28 text-center my-3">
              Aún no has publicado ninguna propiedad
            </p>
            <p className="poppins-regular-16 text-center my-3">
              Sube tu primera propiedad,{" "}
              <TextLink
                to={paths.ADDPROPERTY}
                style={{ textDecoration: "underline" }}
              >
                Publica ahora
              </TextLink>
            </p>
          </>
        )}

        <div
          className="d-flex justify-content-between mb-1 gap-1"
          style={{ marginTop: "100px" }}
        >
          <p className="d-none d-md-inline poppins-regular-18">
            Vendedores recomendados
          </p>
          <p className="d-md-none poppins-regular-18">
            Vendedores recomendados
          </p>
          <TextLink to={paths.VENDORS} className="poppins-semibold-14">
            Más perfiles
          </TextLink>
        </div>
        <div className="d-flex flex-row overflow-hidden justify-content-around">
          {vendors.map((vendor, index) => {
            return (
              <div className="col-4 col-md-auto" key={index}>
                <div className="d-flex flex-column align-items-center text-center h-100 justify-content-between">
                  <TextLink
                    to={paths.USER_PROFILE.replace(
                      ":username",
                      vendor.username
                    )}
                  >
                    <VendorImg
                      className="m-2"
                      src={vendor.profile.avatar ?? avatar}
                      alt="avatar"
                    />
                  </TextLink>
                  <TextLink
                    className="poppins-semibold-19"
                    style={{ textDecoration: "none", color: "black" }}
                    to={paths.USER_PROFILE.replace(
                      ":username",
                      vendor.username
                    )}
                  >
                    {vendor.first_name} {vendor.last_name}{" "}
                    {vendor.profile.subscription &&
                      vendor.profile.subscription.name.toLowerCase() !==
                        "free" && (
                        <SmallIcon
                          className="ms-2"
                          src={verifiedUser}
                          alt="verified"
                        />
                      )}
                    <div className="poppins-light-12">
                      <span className="poppins-light-11">
                        @{vendor.username}
                      </span>{" "}
                      <br className="d-md-none" />
                      <span className="poppins-bold-14">
                        {vendor.properties}
                      </span>{" "}
                      Propiedades
                    </div>
                  </TextLink>
                  <div className="ms-0 flex-grow-1">
                    {!vendor.myself && !vendor.myself_follow && (
                      <ButtonRed
                        className="poppins-semibold-12"
                        onClick={() => {
                          (async () => {
                            await follow(vendor.id);
                            setVendors([
                              ...vendors.slice(0, index),
                              { ...vendor, myself_follow: true },
                              ...vendors.slice(index + 1),
                            ]);
                          })();
                        }}
                      >
                        Seguir
                      </ButtonRed>
                    )}
                    {!vendor.myself && vendor.myself_follow && (
                      <ButtonPressedRed
                        className="poppins-semibold-12"
                        onClick={() => {
                          (async () => {
                            setVendorToUnfollowIndex(index);
                            //await unfollow(vendor.id);
                            // setVendors([
                            //   ...vendors.slice(0, index),
                            //   { ...vendor, myself_follow: false },
                            //   ...vendors.slice(index + 1),
                            // ]);
                          })();
                        }}
                      >
                        Seguido
                      </ButtonPressedRed>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ProfileShortModal
        show={showProfileShortModal}
        user={vendors.length > 0 ? vendors[vendorToUnfollowIndex] : {}}
        propertyNumber={
          vendors.length > 0 ? vendors[vendorToUnfollowIndex].properties : 0
        }
        handleAccept={async () => {
          try {
            await unfollow(vendors[vendorToUnfollowIndex].id);
            setVendors([
              ...vendors.slice(0, vendorToUnfollowIndex),
              { ...vendors[vendorToUnfollowIndex], myself_follow: false },
              ...vendors.slice(vendorToUnfollowIndex + 1),
            ]);
            setShowProfileShortModal(false);
          } catch (e) {
            console.error(e);
          }
        }}
        handleClose={() => {
          setShowProfileShortModal(false);
        }}
      />
    </>
  );
};

export default ProfilePlaceholder;
