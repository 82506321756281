import { Formik, Field, Form } from "formik";
import { AuthContext } from "../context/auth/AuthContext";
import styled from "styled-components";
import Avatar from "./Avatar";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import BaseShortModal from "./BaseShortModal";
import { ButtonRed } from "../styles/common";
import PasswordField from "./PasswordField";
import BaseModalContent from "./BaseModalContent";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  padding: 0.5em 1em;
  border-radius: 5px;
`;

const WhiteButton = styled.button`
  border: 1px white solid;
  background: white;
  color: #d2190e;
  margin: 20px 0px;
  padding: 0.5em 1em;
`;

const DeleteAccountConfirmation = (props: any) => {
  const { deleteAccount, handleAuthentication } = useContext(AuthContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const onCloseModal = () => {
    setShowModal(false);
    if (hasError) {
      return;
    }
    handleAuthentication?.();
    history.push("/");
  };
  return (
    <Formik
      initialValues={{
        password: "",
        unsubscribe: false,
      }}
      onSubmit={async (values, FormikBag) => {
        try {
          const res = await deleteAccount({
            password: values.password,
          });
          if (res === true) {
            setHasError(false);
            setShowModal(true);
          } else {
            throw new Error();
          }
        } catch (error: any) {
          if (error?.message?.includes("403")) {
            FormikBag.setErrors({
              password: "Contraseña no es correcta. Compruébala.",
            });
            return;
          }
          setHasError(true);
          setShowModal(true);
        }
      }}
    >
      <>
        <Form>
          <p className="poppins-semibold-24">
            Último paso antes de cerrar tu cuenta…
          </p>
          <p className="poppins-medium-16 mb-3" style={{ color: "#D2190E" }}>
            El cierre de tu cuenta conlleva que perderás tu perﬁl y todos tus
            datos en bohiio
          </p>
          <div className="d-flex my-2">
            <Avatar src={props.avatar} alt="avatar" />
            <div>
              <div className="poppins-medium-27">
                {props.first_name} {props.last_name}
              </div>
              <div className="poppins-light-14">@{props.username}</div>
            </div>
          </div>
          <p className="poppins-light-14">
            Para tu seguridad, introduce tu contraseña para realizar este
            cambio.
          </p>
          <div className="mb-3">
            <PasswordField name="password" label="Contraseña" />
          </div>
          <label className="mb-2">
            <Field type="checkbox" name="unsubscribe" className="me-1" />
            Darme de baja de las comunicaciones por email de bohiio, incluidas
            las invitaciones.
          </label>
          <div className="poppins-semibold-14 d-flex align-items-center">
            <ButtonRed style={{ height: "40px" }} onClick={props.cancel}>
              Cancelar
            </ButtonRed>
            <WhiteButton type="submit">Cerrar cuenta</WhiteButton>
          </div>
        </Form>
        <BaseShortModal active={showModal} closeModal={onCloseModal}>
          <BaseModalContent
            title={
              hasError ? "Ups, ha ocurrido algo inesperado" : "Cuenta eliminada"
            }
            buttonContent="Aceptar"
            buttonAction={onCloseModal}
          >
            {hasError
              ? "Mientras intentamos eliminar tu cuenta algo salio mal, por favor intenta nuevamente."
              : "Tu cuenta de Bohiio fue eliminada correctamente."}
          </BaseModalContent>
        </BaseShortModal>
      </>
    </Formik>
  );
};

export default DeleteAccountConfirmation;
