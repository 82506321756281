import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Props } from "react-pro-sidebar/dist/ProSidebar/ProSidebar";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import { LanguageContext } from "../context/language/LanguageContext";
import avatar from "../images/avatar.svg";
import chevronDown from "../images/chevron-down.svg";
import chevronUp from "../images/chevron-up.svg";
import compass from "../images/compass.svg";
import compassActive from "../images/compass-active.svg";
import home from "../images/home.svg";
import homeActive from "../images/home-active.svg";
import publish from "../images/publish.svg";
import publishActive from "../images/publish-active.svg";
import star from "../images/star.svg";
import starActive from "../images/star-active.svg";
import trend from "../images/trend.svg";
import trendActive from "../images/trend-active.svg";
import verification from "../images/verification.svg";
import verificationActive from "../images/verification-active.svg";
import verifiedUser from "../images/verified-user.svg";
import styled from "styled-components";
import { AuthContext } from "../context/auth/AuthContext";
import AuthenticationModal from "./AuthenticationModal";
import paths from "../routes/paths.json";
import { FollowersContext } from "../context/followers/FollowersContext";
import { Followee } from "../context/followers/types";
import Avatar from "./Avatar";
import { ButtonRed } from "../styles/common";

// pointer-events: ${(props) => (props.collapsed ? "none" : "inherit")};
// margin-left: ${(props) => (props.collapsed ? "-255px" : "0px")};
// margin-left: ${(props) => (props.collapsed ? "-270px" : "0px")};

const MySidebar = styled.div<{ collapsed: any }>`
@media screen and (min-width: 768px) {
  width: ${(props) => (props.collapsed ? "55px" : "310px")};
  top: 52px;
  height: calc(100vh - 52px);
  & nav {
    max-width: 100% !important;
  }
}
  width: ${(props) => (props.collapsed ? "0" : "100%")};
  background-color: ${(props) =>
    props.collapsed ? "transparent" : "rgba(128, 128, 128, 0.25)"};
& nav {
  width: 100%;
  max-width: 270px;
  height: 100%;
  background: white;
  overflow-y: auto;
}
position: fixed;
top: 106px;
left: 0;
z-index: 1022;
transition: 0.35s;
height: calc(100vh - 106px);
overflow: scroll;
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
scrollbar-width: none; /* Firefox */
-ms-overflow-style: none; /* IE 10+ *
`;

const MenuItem = styled.div<{ collapsed: any }>`
  padding: 0.75rem 15px;
  align-items: center;
  &:hover {
    background-color: rgba(128, 128, 128, 0.25);
  }
  ${(props) =>
    props.collapsed &&
    `
    flex-direction: row-reverse;
    & div {
      display: none;
    }
  `}
`;

const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

const MenuLink = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
`;

const MenuLinkA = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
`;

const ImageContainer = styled.span<{ collapsed: any }>`
  margin-right: 0.75rem;
  display: inline-block;
  ${(props) => props.collapsed && `margin: 0 0 0 0.75rem`}
`;

const TextLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const Sidebar: FunctionComponent<Props> = (props: any) => {
  const { collapsed, className, onClick } = props;
  useContext(LanguageContext);
  const { getFollowees } = useContext(FollowersContext);

  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const history = useHistory();
  const { isAuthenticated } = useContext(AuthContext);
  const closeLoginModal = () => setShowLogin(false);
  const openLoginModal = () => setShowLogin(true);
  const toggleVendors = () => setShowVendors(!showVendors);

  const [followees, setFollowees] = useState<Followee[]>([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showVendors, setShowVendors] = useState(!location);
  useEffect(() => {
    let isMounted = true;
    (async () => {
      let followers = await getFollowees();
      if (isMounted) {
        setFollowees(followers);
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MySidebar collapsed={collapsed} className={className} onClick={onClick}>
      <nav onClick={(e) => e.stopPropagation()}>
        <div className="poppins-regular-12">
          <MenuLink to={paths.HOME}>
            <MenuItem className="d-flex" collapsed={collapsed}>
              <ImageContainer collapsed={collapsed}>
                <Icon src={!location ? homeActive : home} alt="home" />
              </ImageContainer>
              <div
                style={{
                  fontWeight: !location ? "bold" : "normal",
                }}
              >
                <Trans i18nKey="sidebar.home" />
              </div>
            </MenuItem>
          </MenuLink>
          <MenuLink to={paths.MAP}>
            <MenuItem className="d-flex" collapsed={collapsed}>
              <ImageContainer collapsed={collapsed}>
                <Icon
                  src={
                    location && paths.MAP.includes(location)
                      ? compassActive
                      : compass
                  }
                  alt="compass"
                />
              </ImageContainer>
              <div
                style={{
                  fontWeight:
                    location && paths.MAP.includes(location)
                      ? "bold"
                      : "normal",
                }}
              >
                <Trans i18nKey="sidebar.map" />
              </div>
            </MenuItem>
          </MenuLink>
          <div
            className="clickable"
            onClick={() => history.push(paths.TENDENCY)}
          >
            <MenuItem className="d-flex" collapsed={collapsed}>
              <ImageContainer collapsed={collapsed}>
                <Icon
                  src={
                    location && paths.TENDENCY.includes(location)
                      ? trendActive
                      : trend
                  }
                  alt="trends"
                />
              </ImageContainer>
              <div
                style={{
                  fontWeight:
                    location && paths.TENDENCY.includes(location)
                      ? "bold"
                      : "normal",
                }}
              >
                <Trans i18nKey="sidebar.trend" />
              </div>
            </MenuItem>
          </div>
          <MenuLinkA href={`${process.env.REACT_APP_LANDING_LINK}#update`}>
            <MenuItem className="d-flex" collapsed={collapsed}>
              <ImageContainer collapsed={collapsed}>
                <Icon src={verification} alt="verification" />
              </ImageContainer>
              <div
                style={{
                  fontWeight:
                    location && paths.BOHIIO_SERVICE.includes(location)
                      ? "bold"
                      : "normal",
                }}
              >
                <Trans i18nKey="sidebar.certificate" />
              </div>
            </MenuItem>
          </MenuLinkA>

          <hr />
          <div
            className="clickable"
            onClick={
              isAuthenticated
                ? () => {
                    history.push(paths.ADDPROPERTY);
                  }
                : openLoginModal
            }
          >
            <MenuItem className="d-flex" collapsed={collapsed}>
              <ImageContainer collapsed={collapsed}>
                <Icon
                  src={
                    location && location === "add-property"
                      ? publishActive
                      : publish
                  }
                  alt="publish"
                />
              </ImageContainer>
              <div
                style={{
                  fontWeight:
                    location && location === "add-property" ? "bold" : "normal",
                }}
              >
                <Trans i18nKey="sidebar.publish" />
              </div>
            </MenuItem>
          </div>
          <div
            className="clickable"
            onClick={
              isAuthenticated
                ? () => {
                    history.push(paths.FAVORITES);
                  }
                : openLoginModal
            }
          >
            <MenuItem className="d-flex" collapsed={collapsed}>
              <ImageContainer collapsed={collapsed}>
                <Icon
                  src={
                    location && paths.FAVORITES.includes(location)
                      ? starActive
                      : star
                  }
                  alt="star"
                />
              </ImageContainer>
              <div
                style={{
                  fontWeight:
                    location && paths.FAVORITES.includes(location)
                      ? "bold"
                      : "normal",
                }}
              >
                <Trans i18nKey="sidebar.saved" />
              </div>
            </MenuItem>
          </div>
          {!isAuthenticated && (
            <>
              <hr />
              <div
                className="m-3"
                style={{ display: collapsed ? "none" : "block" }}
              >
                <p>
                  <Trans i18nKey="sidebar.logIn" />
                </p>
                <ButtonRed
                  inverse={true}
                  className="btn"
                  onClick={openLoginModal}
                >
                  <Trans i18nKey="sidebar.logInButton" />
                </ButtonRed>
              </div>
            </>
          )}
        </div>
      </nav>
      <AuthenticationModal active={showLogin} closeModal={closeLoginModal} />
    </MySidebar>
  );
};

export default Sidebar;
