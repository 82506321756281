import { Formik, Field, Form } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import Navbar from "../../components/Navbar";
import { AuthContext } from "../../context/auth/AuthContext";
import { ProfileContext } from "../../context/profile/ProfileContext";
import { useTranslation } from "react-i18next";
import { ButtonRed } from "../../styles/common";
import BaseShortModal from "../../components/BaseShortModal";
import PasswordField from "../../components/PasswordField";
import BaseModalContent from "../../components/BaseModalContent";

const Button = styled.button`
  border: 1px #d2190e solid;
  background: white;
  color: black;
  margin: 20px 0px;
  padding: 0.5em 2em;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
`;
const WhiteButton = styled.button`
  border: 1px white solid;
  background: rgb(0, 0, 0, 0);
  color: #d2190e;
  padding: 0.5em 2em;
  margin: 20px 0px;
`;
const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const { profile } = useContext(ProfileContext);
  const { resetPassword } = useContext(AuthContext);
  const [passwordVisibilty, setPasswordVisibility] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorHappened, setErrorHappened] = useState(false);
  const changePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibilty);
  };
  const onCloseModal = useCallback(() => {
    setShowModal(false);
    if (errorHappened) {
      return;
    }
    history.push("/");
  }, [errorHappened, history]);

  useEffect(() => {
    if (!queryParameters.get("uid") || !queryParameters.get("token")) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, queryParameters]);
  return (
    <>
      <div className="d-flex flex-column main-container">
        <Navbar />
        <div className="d-flex">
          <div className="col-10 offset-1 offset-md-2 col-md-4">
            <Formik
              initialValues={{
                newPassword: "",
                newPasswordConfirmation: "",
              }}
              validationSchema={Yup.object({
                newPassword: Yup.string().required("Required"),
                newPasswordConfirmation: Yup.string()
                  .required("Required")
                  .equals([Yup.ref<string>("newPassword")]),
              })}
              onSubmit={async (values, FormikBag) => {
                try {
                  await resetPassword({
                    token: queryParameters.get("token") ?? "",
                    uid: Number(queryParameters.get("uid")) ?? 0,
                    new_password: values.newPassword,
                  });
                  setErrorHappened(false);
                  setShowModal(true);
                } catch (e) {
                  setErrorHappened(true);
                  setShowModal(true);
                }
              }}
            >
              {({ isValid, dirty }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 poppins-bold-20 mt-5 mb-4">
                      Cambia tu contraseña
                    </div>
                    <div className="col-12">
                      <PasswordField
                        label="Contraseña nueva"
                        name="newPassword"
                        type="password"
                      />
                      <div className="mb-2"></div>
                      <PasswordField
                        label="Confirmar contraseña nueva"
                        id="newPasswordConfirmation"
                        name="newPasswordConfirmation"
                      />
                      <div className="mb-4"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <label>
                      <input type="checkbox" className="me-1" />
                      Cerrar sesión en todos los dispositivos
                    </label>
                  </div>
                  <div className="col-12 poppins-regular-14 d-flex justify-content-start align-items-center">
                    <ButtonRed
                      type="submit"
                      disabled={!isValid && !dirty}
                      style={{
                        opacity: isValid && dirty ? 1 : 0.6,
                        height: "40px",
                      }}
                    >
                      Guardar
                    </ButtonRed>
                    <WhiteButton
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Cancelar
                    </WhiteButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <BaseShortModal active={showModal} closeModal={onCloseModal}>
        <BaseModalContent
          title={
            errorHappened ? "Ups, algo salio mal" : "Contraseña Actualizada"
          }
          buttonAction={onCloseModal}
          buttonContent="Aceptar"
        >
          {errorHappened
            ? "Intentamos cambiar tu contraseña, pero, no lo logramos. Por favor intenta nuevamente"
            : "Tu contraseña fue actualizada correctamente."}
        </BaseModalContent>
      </BaseShortModal>
    </>
  );
};

export default ResetPassword;
